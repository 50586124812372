import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useAuthMailOrderInitMutation } from 'services/mailOrder';
import useAuth from 'Hooks/useAuth';
import { useGetUserDetailsQuery } from 'services/user';
import { MailOrderProps } from '../';

const confirmNeedType = 'WakixProvision';

interface WakixLayoutValues {
  initials: string;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
}));

const WakixLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  onSaveData,
  contextData,
  savedData,
}) => {
  const dispatch = useDispatch();

  const { mailOrderAuthKeystring: mailOrderId } = contextData;

  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const { firstName, lastName } = userDetails || {};
  const userInitials = `${firstName?.[0].toUpperCase()}${lastName?.[0].toUpperCase()}`;

  const [putAuthMailOrderInit] = useAuthMailOrderInitMutation();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const wakixSchema = Yup.object().shape({
    initials: Yup.string()
      .uppercase()
      .oneOf([userInitials], "Characters don't match your initials")
      .required('Initials Required'),
  });

  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        initials: (savedData.wakix as string) || '',
      }}
      validationSchema={wakixSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSaveData({
          wakix: values.initials,
        });

        const mailOrderInitInfo = {
          mailOrderId,
          authorizationInitials: values.initials,
          confirmationNeedType: confirmNeedType,
        };
        await putAuthMailOrderInit(mailOrderInitInfo);

        setSubmitting(false);
        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<WakixLayoutValues>) => (
        <Form>
          <Box
            className={classes.screenBox}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Wakix Disclosure
            </Typography>
            <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
              The medication strengths are the same but there is a labeling difference in the US vs
              Canada. In the US, Wakix tablet strengths are labeled by the drug base 4.45mg or
              17.8mb of pitolisant free base. Whereas in CAN, Wakix tablet strengths are labeled by
              the entire drug salt product, 5mg or 20mg of pitolisant hydrochloride. There is no
              chemical difference between the US product and the Canadian product.
            </Typography>
            <Box display={'flex'} marginTop={2}>
              <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                I, {firstName} {lastName}, verify that I have read and understand the above
                disclosure regarding my order of Wakix.
              </Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              marginTop={1}
              marginBottom={1}
            >
              <Box flexBasis={'60%'}>
                <TextFieldComponent
                  label="Initial Here"
                  name={'initials'}
                  fullWidth={false}
                  inputProps={{ maxLength: 2 }}
                  onInput={(e: { target: { value: string } }) => {
                    e.target.value = e.target.value.slice(0, 2);
                  }}
                />
              </Box>
            </Box>
            <Box marginTop={1} marginBottom={1}>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={previousOnClickCallback}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default WakixLayout;
