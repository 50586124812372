import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import AddressEntryForm from 'Components/AddressEntryForm';
import { useGetBillingAddressQuery, useUpdateBillingAddressMutation } from 'services/mailOrder';

import { MailOrderProps } from '../';
import { Address } from 'services/person';
import LoadingSpinner from 'Components/LoadingSpinner';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

const CreditCardBillingLayout: FunctionComponent<MailOrderProps> = ({
  contextData,
  previousOnClickCallback,
  nextOnClickCallback,
}) => {
  const { mailOrderAuthKeystring } = contextData;
  const { data: billingAddress, isLoading: isLoadingBillAddress } = useGetBillingAddressQuery(
    mailOrderAuthKeystring ?? skipToken
  );
  const [updateBillingAddress] = useUpdateBillingAddressMutation();

  const handleSubmit = async (values: Address) => {
    await updateBillingAddress({
      mailOrderKeystring: mailOrderAuthKeystring,
      billingAddress: {
        ...values,
      },
    });

    nextOnClickCallback();
  };

  if (isLoadingBillAddress) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Box>
        <Typography
          align={'center'}
          variant={'body1'}
          color={'textPrimary'}
          style={{ fontWeight: 'bold' }}
        >
          Billing Information
        </Typography>
        <AddressEntryForm
          address={billingAddress as Address}
          onSubmit={handleSubmit}
          secondaryActionClick={previousOnClickCallback}
        />
      </Box>
    </>
  );
};

export default CreditCardBillingLayout;
