import { FunctionComponent, useState } from 'react';

import AddAddressLayout from './layouts/AddAddressLayout';
import AddPhoneEmailLayout from './layouts/AddPhoneEmailLayout';
import Intro from './layouts/Intro';
import PersonalInfoLayout from './layouts/PersonalInfoLayout';
import PerferredContactLayout from './layouts/PreferredContactLayout';

import useStepNavigator from '../hooks/useStepNavigator';
import { useHistory } from 'react-router';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';

import { WizardProps, StepProps } from '../types';
import useHouseholdMembersData from 'Hooks/useHouseholdMembersData';
import { Member } from 'services/user';
import useAuth from 'Hooks/useAuth';
import { useDispatch } from 'react-redux';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import Wizard from '../Wizard';

interface PersonalInfoProps extends WizardProps {
  personId: string;
}

export interface PersonalInfoStepProps extends StepProps {
  onSaveData: (payload: any) => void;
  contextData: {
    patient: Member;
    isUser: boolean;
  };
}

enum Steps {
  INTRO = 1,
  PERSONAL_INFO,
  ADD_PHONE_EMAIL,
  PREFERRED_CONTACT,
  ADD_ADDRESS,
}

const COMPONENT_MAP: any = {
  [Steps.INTRO]: Intro,
  [Steps.PERSONAL_INFO]: PersonalInfoLayout,
  [Steps.ADD_PHONE_EMAIL]: AddPhoneEmailLayout,
  [Steps.PREFERRED_CONTACT]: PerferredContactLayout,
  [Steps.ADD_ADDRESS]: AddAddressLayout,
};

const PersonalInfo: FunctionComponent<PersonalInfoProps> = (props) => {
  const { onComplete, onPrevious } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { viewableMembers, isLoading } = useHouseholdMembersData();
  const {
    data: { personIdEncryptedMaybe: userId },
  } = useAuth();

  const [contactPersonId, setContactPersonId] = useState<string>('');
  const [currentMemberIndex, setCurrentMemberIndex] = useState<number>(0);
  const currentMember = viewableMembers ? viewableMembers[currentMemberIndex] : ({} as Member);

  const contextData = {
    patient: currentMember,
    isUser: currentMember?.patientId === userId,
    isContactPerson: contactPersonId === userId,
  };

  const order = [
    Steps.INTRO,
    Steps.PERSONAL_INFO,
    contextData.isUser && Steps.ADD_PHONE_EMAIL,
    contextData.isUser && contextData.isContactPerson && Steps.PREFERRED_CONTACT,
    Steps.ADD_ADDRESS,
  ].filter(Boolean);

  const { current, next, previous, reset } = useStepNavigator({ steps: order });

  const Component: FunctionComponent<PersonalInfoStepProps> = COMPONENT_MAP[current];

  const handleContinueLater = () => {
    dispatch(setIsNextStepReminderEligible(false));
    history.push('/');
  };

  const handleNextStep = () => {
    if (viewableMembers && currentMemberIndex === viewableMembers.length - 1) {
      if (current === Steps.ADD_ADDRESS) {
        onComplete();
      } else {
        next();
      }
    } else {
      if (current === Steps.ADD_ADDRESS) {
        reset();
        setCurrentMemberIndex(currentMemberIndex + 1);
      } else {
        next();
      }
    }
  };

  const handlePreviousStep = () => {
    if (current === Steps.INTRO && onPrevious) {
      onPrevious();
    } else {
      previous();
    }
  };

  const handleSaveData = (payload: string) => {
    setContactPersonId(payload);
  };

  return (
    <Wizard isLoading={isLoading}>
      <Component
        onSaveData={handleSaveData}
        contextData={contextData}
        onNextStep={handleNextStep}
        onPreviousStep={handlePreviousStep}
        onComplete={onComplete}
      />
      <TertiaryButtonComponent text="Continue Later" onClick={handleContinueLater} />
    </Wizard>
  );
};

export default PersonalInfo;
