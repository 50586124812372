import { useEffect, useState } from 'react';
import { Prescriber, useGetAllPrescribersQuery } from 'services/prescriber';

const useGetPrescriberInfoById: any = (prescriberId: string) => {
  const { data: queriedPrescribers = [], isLoading: isLoadingPrescriberInfo } =
    useGetAllPrescribersQuery('');
  const [prescriberInfo, setPrescriberInfo] = useState<Prescriber | undefined>();

  useEffect(() => {
    const found = queriedPrescribers.find((prescriber) => prescriber.prescriberId === prescriberId);

    setPrescriberInfo(found);
  }, [prescriberId, queriedPrescribers]);

  return {
    prescriberInfo,
    isLoadingPrescriberInfo,
  };
};

export default useGetPrescriberInfoById;
