import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { userApi } from 'services/user';
import { authApi } from 'services/auth';
import { fileApi } from 'services/file';
import { hipaaAuthApi } from 'services/hipaaAuth';
import { mailOrderApi } from 'services/mailOrder';
import { medicationApi } from 'services/medication';
import { messagingApi } from 'services/messaging';
import { personApi } from 'services/person';
import { prescriberApi } from 'services/prescriber';
import { registerApi } from 'services/register';
import { whiteLabelApi } from 'services/whiteLabel';
import { utilityApi } from 'services/utility';

const services = [
  authApi,
  userApi,
  fileApi,
  hipaaAuthApi,
  mailOrderApi,
  medicationApi,
  messagingApi,
  personApi,
  prescriberApi,
  registerApi,
  whiteLabelApi,
  utilityApi,
];

export const reset = createAction('app/reset');

export const clearQueryCache = createAsyncThunk('app/clearQueryCache', async (_, { dispatch }) => {
  services.map((service) => {
    dispatch(service.util.resetApiState());
  });

  return {};
});
