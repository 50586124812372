import React, { FunctionComponent } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OpenInNew from '@mui/icons-material/OpenInNew';

interface Item {
  key: string;
  value: string;
}
interface AlertComponentProps {
  alertType: 'error' | 'info' | 'success' | 'warning';
  title?: string;
  message: string;
  links?: Array<Item>;
  onClose?: () => void;
  [transferProps: string]: any;
}

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'left',
  },
}));

const AlertComponent: FunctionComponent<AlertComponentProps> = ({
  alertType,
  title,
  message,
  links,
  onClose,
  ...transferProps
}) => {
  const classes = useStyles();

  return (
    <Alert severity={alertType} onClose={onClose} {...transferProps} className={classes.container}>
      {title ? <AlertTitle>{title}</AlertTitle> : null}
      {message}
      {links?.length
        ? links.map((linkObj, idx) => {
            return (
              <React.Fragment key={`${linkObj}+${idx}`}>
                <a
                  href={linkObj.value}
                  target={'_blank'}
                  rel="noreferrer"
                  key={`${linkObj.key}+${idx}`}
                >
                  <OpenInNew sx={{ verticalAlign: 'middle', fontSize: 'medium' }} />
                  {linkObj.key}
                </a>
                <br />
              </React.Fragment>
            );
          })
        : null}
    </Alert>
  );
};

export default AlertComponent;
