import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import ScriptCoWizard from 'containers/Wizards/ScriptCo';

const ScriptCoHouseholdInfoPage: FunctionComponent = () => {
  const history = useHistory();

  const handleComplete = () => {
    history.push('/Dashboard');
  };

  return <ScriptCoWizard onComplete={handleComplete} />;
};

export default ScriptCoHouseholdInfoPage;
