import { useCallback, useEffect, useState, useMemo, FunctionComponent } from 'react';

import { Box, debounce, Typography } from '@mui/material';
import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';
import SelectComponent from 'Components/SelectComponent/SelectComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';

import { useLazyGetSimilarNameDrugsQuery } from 'services/medication';
import { useGetAllHouseholdMembersQuery } from 'services/user';
import { useGetAllPrescribersQuery } from 'services/prescriber';

import AddPrescriberDialog from 'Components/AddPrescriberDialog/AddPrescriberDialog';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import LoadingSpinner from 'Components/LoadingSpinner';
import EditPrescriberDialog from 'Components/EditPrescriberDialog';
import useGetPrescriberInfoById from 'Hooks/useGetPresciberInfoById';

interface Drug {
  name: string;
  id: number | null;
}

interface EditMedicationValues {
  drug?: Drug | undefined;
  recipient?: string;
  prescriberId?: string;
}

interface MedicationEntryProps {
  medication?: EditMedicationValues;
  onPreviousClick?: () => void;
  previousBtnText?: string;
  onSubmit: (values: EditMedicationValues) => void;
  nextbtntext?: string;
  isSubmitting?: boolean;
  errorMessage?: string;
}

const addPrescriptionSchema = yup.object().shape({
  drug: yup.object().shape({
    name: yup.string().required('Required'),
    id: yup.mixed().required('Required'),
  }),
  prescriberId: yup.mixed().required('Required'),
  recipient: yup.mixed().required('Required'),
});

const DEBOUNCE_DELAY = 400;

const MedicationEntry: FunctionComponent<MedicationEntryProps> = (props) => {
  const {
    onSubmit,
    medication,
    onPreviousClick,
    previousBtnText,
    nextbtntext,
    isSubmitting,
    errorMessage,
  } = props;

  const [isAddPrescriberDialogOpen, setAddPrescriberDialogOpen] = useState<boolean>(false);
  const [isEditPrescriberDialogOpen, setEditPrescriberDialogOpen] = useState<boolean>(false);
  const [selectedMedication, setSelectedMedication] = useState<{
    name?: string;
    id?: number;
  }>({});

  const { data: allMembersInHousehold } = useGetAllHouseholdMembersQuery();
  const { data: queriedPrescribers = [], isLoading: isLoadingPrescribers } =
    useGetAllPrescribersQuery('');

  const [selectedRecipient, setSelectedRecipient] = useState<string>(
    medication?.recipient ? medication.recipient : ''
  );
  const [selectedPrescriber, setSelectedPrescriber] = useState<string>(
    medication?.prescriberId ? medication.prescriberId : ''
  );
  const { prescriberInfo, isLoadingPrescriberInfo } = useGetPrescriberInfoById(selectedPrescriber);

  const [getDrugNames, { data: drugNames }] = useLazyGetSimilarNameDrugsQuery();
  const [drugOptions, setDrugOptions] = useState<Drug[]>([]);

  const searchDrugs = useCallback(
    debounce((value: string) => {
      if (value) {
        getDrugNames(value);
      }
    }, DEBOUNCE_DELAY),
    []
  );

  const handlePrescriberAdded = (id: string) => {
    setSelectedPrescriber(id);
  };

  const handleAddPrescriberDialogClose = () => {
    setAddPrescriberDialogOpen(false);
  };

  const handleEditPrescriberDialogClose = () => {
    setEditPrescriberDialogOpen(false);
  };

  const householdMemberSelectOptions = useMemo(() => {
    return (
      allMembersInHousehold?.map((member) => ({
        label: `${member.firstName} ${member.lastName}`,
        value: member.patientId,
      })) || []
    );
  }, [allMembersInHousehold]);

  const prescriberSelectOptions = useMemo(() => {
    return (
      queriedPrescribers?.map((prescriber: any) => ({
        label: `${prescriber.firstName} ${prescriber.lastName}`,
        value: prescriber.prescriberId,
      })) || []
    );
  }, [queriedPrescribers]);

  const handleAddPrescriberClick = () => {
    setAddPrescriberDialogOpen(true);
  };

  const handleEditPrescriberClick = () => {
    setEditPrescriberDialogOpen(true);
  };

  const handleOptionSelect = (value?: any) => {
    setSelectedMedication(value);
  };

  useEffect(() => {
    if (drugNames) {
      setDrugOptions(drugNames);
    }
  }, [drugNames]);

  if (isLoadingPrescribers) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AddPrescriberDialog
        onClose={handleAddPrescriberDialogClose}
        open={isAddPrescriberDialogOpen}
        onPrescriberAdded={handlePrescriberAdded}
      />
      <EditPrescriberDialog
        onClose={handleEditPrescriberDialogClose}
        open={isEditPrescriberDialogOpen}
        prescriberInfo={prescriberInfo}
        turnOffEditName={true}
      />
      <Formik
        enableReinitialize
        initialValues={{
          drug: {
            name: selectedMedication?.name || medication?.drug?.name || '',
            id: selectedMedication?.id || medication?.drug?.id || null,
          },
          recipient: selectedRecipient || medication?.recipient || '',
          prescriberId: selectedPrescriber || medication?.prescriberId || '',
        }}
        validationSchema={addPrescriptionSchema}
        onSubmit={(values: EditMedicationValues) => {
          onSubmit(values);
        }}
      >
        {({ submitForm, errors }: FormikProps<EditMedicationValues>) => (
          <Form>
            <Box>
              <Box marginBottom={3} marginTop={2}>
                <TextFieldComponent
                  onChange={searchDrugs}
                  onOptionSelect={handleOptionSelect}
                  auto={drugOptions}
                  label={'Medication Name'}
                  name={'drug'}
                  placeholder="Start typing your medication's name"
                  error={Boolean(errors.drug)}
                  helperText={errors.drug ? 'Required' : null}
                />
                <Box display={'flex'} flexDirection={'row'} alignItems={'flex-end'}>
                  <SelectComponent
                    label={'Who is the medication for?'}
                    name={'recipient'}
                    errorMessage={errors.recipient}
                    selectOptions={householdMemberSelectOptions}
                    onChange={(e: { target: { value: string } }) => {
                      setSelectedRecipient(e.target.value);
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <SelectComponent
                    label={'Who is the doctor/prescriber?'}
                    name={'prescriberId'}
                    errorMessage={errors.prescriberId}
                    selectOptions={prescriberSelectOptions}
                    onChange={(e: { target: { value: string } }) => {
                      setSelectedPrescriber(e.target.value);
                    }}
                  />
                  <SecondaryButtonComponent
                    text={'Add...'}
                    width={'auto'}
                    style={{
                      margin: '0',
                      marginBottom: '3px',
                      marginLeft: '8px',
                    }}
                    onClick={handleAddPrescriberClick}
                  />
                </Box>
              </Box>
              {prescriberInfo && (
                <>
                  <Typography
                    align={'center'}
                    variant={'body1'}
                    color={'textPrimary'}
                    style={{ fontWeight: 'bold' }}
                  >
                    Is the prescriber information correct?
                  </Typography>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginTop={1}
                    marginBottom={3}
                  >
                    <Box marginTop={1} marginBottom={1} style={{ marginLeft: 'auto' }}>
                      <Typography variant={'body2'}>
                        Doctor/Prescriber:{' '}
                        {prescriberInfo
                          ? prescriberInfo?.firstName + ' ' + prescriberInfo?.lastName
                          : 'No doctor selected'}
                      </Typography>
                      <Typography variant={'body2'}>
                        Phone Number:{' '}
                        {prescriberInfo?.phoneNumber
                          ? prescriberInfo?.phoneNumber
                          : 'No phone number'}
                      </Typography>
                    </Box>
                    <SecondaryButtonComponent
                      text={'Edit...'}
                      width={'auto'}
                      style={{
                        margin: '0',
                        marginBottom: '3px',
                        marginLeft: 'auto',
                      }}
                      onClick={handleEditPrescriberClick}
                    />
                  </Box>
                </>
              )}
              {onPreviousClick ? (
                <ProgressButtonGroupComponent
                  isLoading={isSubmitting}
                  nextOnClickCallback={submitForm}
                  previousOnClickCallback={onPreviousClick}
                  nextButtonProps={{
                    text: nextbtntext ? nextbtntext : 'Next',
                    disabled: !!errorMessage,
                  }}
                  previousButtonProps={{
                    text: previousBtnText ? previousBtnText : 'Previous',
                  }}
                />
              ) : (
                <PrimaryButtonComponent
                  isLoading={isSubmitting && !errorMessage}
                  text="Submit"
                  onClick={submitForm}
                  disabled={!!errorMessage}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MedicationEntry;
