import { Box, Typography } from '@mui/material';
import Dialog from 'Components/Dialog';
import { FunctionComponent } from 'react';

interface HipaaAuthNoticeProps {
  onClose: () => void;
  open: boolean;
}

const PapInfo: FunctionComponent<HipaaAuthNoticeProps> = (props) => {
  const { onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open} title="Patient Assistance Programs">
      <Box>
        <Typography>
          Pharmaceutical manufacturers may sponsor patient assistance programs (PAPs) to provide
          free medications to those who qualify. Your advocate may submit an application on your
          behalf.
        </Typography>
      </Box>
    </Dialog>
  );
};

export default PapInfo;
