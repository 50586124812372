import { FunctionComponent, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorIconComponent from 'Components/ErrorIconComponent/ErrorIconComponent';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

interface UnqualifiedProps {
  nextOnClickCallback(): void;
  previousOnClickCallback?(): void;
  hasMeds: boolean;
  contextData: any;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
}));

const Unqualified: FunctionComponent<UnqualifiedProps> = ({
  previousOnClickCallback,
  hasMeds,
  contextData,
}) => {
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  const dispatch = useDispatch();
  const { groupThreshold } = contextData;

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
  }, [appName, dispatch]);

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <ErrorIconComponent />
      {hasMeds ? (
        <>
          <InfoLayoutBodyComponent
            header={<span>Discount At Your Pharmacy:</span>}
            paragraph={
              <span>
                There is discount prescription information printed on the back of your member ID
                card! Present your card at your pharmacy to take advantage of this program.
              </span>
            }
          />
          <InfoLayoutBodyComponent
            header={<span>Mail Order Option:</span>}
            paragraph={
              <span>
                Our partner GoGoMeds offers a convenient mail order service.
                <br />
                Click{' '}
                <b>
                  <a href="http://www.sharxplan.com/generics" target="_blank" rel="noreferrer">
                    here{' '}
                  </a>
                </b>
                or visit sharxplan.com/generics and use the promo code SHARx5 at checkout.
              </span>
            }
          />
        </>
      ) : (
        <InfoLayoutBodyComponent
          header={<span>Not taking any maintenance medications? That&apos;s great!</span>}
          paragraph={
            <span>
              Should you ever get prescribed a maintenance medication over {groupThreshold} a month,
              please revisit this site.
            </span>
          }
        />
      )}

      <ProgressButtonGroupComponent previousOnClickCallback={previousOnClickCallback} />
    </Box>
  );
};

export default Unqualified;
