import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Box, Typography } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import SelectComponent from 'Components/SelectComponent/SelectComponent';
import LoadingSpinner from 'Components/LoadingSpinner';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import {
  PreferredContactType,
  useGetContactPersonQuery,
  useGetEligibleContactPersonsQuery,
  useGetContactInformationQuery,
  useUpdateContactInfoMutation,
} from 'services/person';
import { ContactInformation } from 'services/person';
import { selectFirstName, selectLastName } from 'Store/Reducers/personInfoSlice';
import { selectEmailAddress } from 'Store/Reducers/contactInfoSlice';

import useAuth from 'Hooks/useAuth';

interface AddPhoneLayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
}
interface AddPhoneValues {
  phoneNumber: string;
  isCell: string;
}

const phoneRegExp = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-/\s.]|\d)+$/;

const AddPhoneSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Must be a valid phone number')
    .required('Required'),
});

const AddPhoneLayout: FunctionComponent<AddPhoneLayoutProps> = ({ onNextStep, onPreviousStep }) => {
  const dispatch = useDispatch();
  const {
    data: { groupIdEncryptedMaybe: groupId, personIdEncryptedMaybe: personId },
  } = useAuth();
  const [postUpdateContactInfo] = useUpdateContactInfoMutation();
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const personFullName = `${firstName} ${lastName}`;
  const personEmailAddress = useSelector(selectEmailAddress);

  const [needValueForIsCell, setNeedValueForIsCell] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setTitle('Personal Info'));
  }, [dispatch]);

  const handleContactPersonSelection = (contactPersonId: string) => {
    setNeedValueForIsCell(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        contactPersonId: '',
        phoneNumber: '',
        isCell: '',
        email: '',
      }}
      validationSchema={AddPhoneSchema}
      onSubmit={async (values, { setSubmitting }) => {
        if (!values.isCell) {
          setNeedValueForIsCell(true);
          return;
        }
        // TODO : what is name here? are we updating a table with this?
        const updatedPhoneInfo: ContactInformation = {
          patientPersonId: personId,
          name: personFullName,
          phonePrimary: values.phoneNumber as string,
          phoneSecondary: '',
          phonePrimaryIsCell: values.isCell === 'true' ? true : false,
          phoneSecondaryIsCell: false,
          emailPrimary: personEmailAddress,
          emailSecondary: '',
          preferredContact: values.isCell === 'false' ? 'Email' : 'Text',
          preferredSpanishUpdates: false,
        };

        await postUpdateContactInfo({
          patientPersonId: personId,
          contactInfo: updatedPhoneInfo,
        });
        onNextStep();
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm, errors }: FormikProps<AddPhoneValues>) => (
        <Form>
          <>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              What&apos;s the best number and email to reach you?
            </Typography>
            <Box marginBottom={3} marginTop={2}>
              <TextFieldComponent label={'Phone'} name={'phoneNumber'} />
            </Box>
          </>

          <Box marginBottom={3} marginTop={2}>
            <SelectComponent
              label={'Is this a cellphone where we may text you?'}
              name={'isCell'}
              errorMessage={errors.isCell}
              selectOptions={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
            />
          </Box>

          {/* <Box sx={{ mb: 3, mt: 2 }}>
            <TextFieldComponent label={'Email'} name={'email'} />
          </Box> */}
          {needValueForIsCell ? (
            <AlertComponent
              alertType="error"
              message="Need to know if phone is a cellphone we may text"
            />
          ) : null}
          <ProgressButtonGroupComponent
            isLoading={isSubmitting}
            nextOnClickCallback={submitForm}
            previousOnClickCallback={onPreviousStep}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddPhoneLayout;
