import { createContext } from 'react';

export interface AppInstallProps {
  isAppInstalled: boolean;
  isAppInstallable: boolean;
  openInstallBanner: () => void;
  showInstallPrompt: () => void;
}

const AppInstall = createContext<AppInstallProps>({
  isAppInstalled: false,
  isAppInstallable: false,
  openInstallBanner: () => undefined,
  showInstallPrompt: () => undefined,
});

export default AppInstall;
