import { FunctionComponent } from 'react';
import HipaaWizard from 'containers/Wizards/Hipaa';
import { useHistory } from 'react-router-dom';
import useNextStep from 'containers/NextStep/hooks/useNextStep';

const HipaaPage: FunctionComponent = () => {
  const { refetchNextStep } = useNextStep();
  const history = useHistory();
  const handleComplete = () => {
    refetchNextStep();
    history.push('/');
  };

  return <HipaaWizard onComplete={handleComplete} />;
};

export default HipaaPage;
