import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reset } from '../actions/commonActions';

export type AuthState = {
  clientError: boolean;
  internalServerError: boolean;
  unauthorizedResponse: boolean;
  registrationTempToken: string;
};

const initialState = {
  clientError: false,
  internalServerError: false,
  unauthorizedResponse: false,
  registrationTempToken: '',
} as AuthState;

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setClientError: (state, { payload }: PayloadAction<boolean>) => {
      state.clientError = payload;
    },
    setUnauthorizedResponse: (state, { payload }: PayloadAction<boolean>) => {
      state.unauthorizedResponse = payload;
    },
    setInternalServerError: (state, { payload }: PayloadAction<boolean>) => {
      state.internalServerError = payload;
    },
    setRegistrationTempToken: (state, { payload }: PayloadAction<string>) => {
      state.registrationTempToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState);
  },
});

export const {
  setClientError,
  setInternalServerError,
  setUnauthorizedResponse,
  setRegistrationTempToken,
} = slice.actions;

export default slice.reducer;

export const selectClientError = (state: Record<string, AuthState>): boolean =>
  state.auth.clientError;
export const selectUnauthorizedResponse = (state: Record<string, AuthState>): boolean =>
  state.auth.unauthorizedResponse;
export const selectInternalServerError = (state: Record<string, AuthState>): boolean =>
  state.auth.internalServerError;
export const selectRegistrationTempToken = (state: Record<string, AuthState>): string =>
  state.auth.registrationTempToken;
