import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { useGetAdvocateInfoQuery, useGetUserDetailsQuery } from 'services/user';
import useAuth from 'Hooks/useAuth';
import { MedicationWizardStepProps } from '..';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
}));

const IntroNoMedsLayout: FunctionComponent<MedicationWizardStepProps> = ({
  onNextStep,
  onPreviousStep,
}) => {
  const dispatch = useDispatch();
  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const firstName = userDetails?.firstName;
  const { data: advocateInfo } = useGetAdvocateInfoQuery();
  const advocate = advocateInfo?.advocateFirstName;

  useEffect(() => {
    dispatch(setTitle('Medications'));
  }, [dispatch]);

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <InfoLayoutBodyComponent
        header={<span>Thanks {firstName}, we have all the personal details we need.</span>}
        paragraph={
          <span>
            Now, please add any high cost medications you or your dependants are starting or
            currently taking that your advocate, {advocate}, should be aware of. We need the name of
            the medication and who presribed it.
          </span>
        }
      />
      <ProgressButtonGroupComponent
        nextOnClickCallback={onNextStep}
        previousOnClickCallback={onPreviousStep}
      />
    </Box>
  );
};

export default IntroNoMedsLayout;
