import { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Toolbar,
  Tooltip,
  Fab,
  Input,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import AddIcon from '@mui/icons-material/Add';
import FileItemComponent from 'Components/FileItemComponent/FileItemComponent';
import LoadingSpinner from 'Components/LoadingSpinner';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import { FileNeedInfo, useUploadFilesMutation } from 'services/file';
import useNextStep from 'containers/NextStep/hooks/useNextStep';
import { validateSizeOfEachUploadedFileInArray } from 'containers/Common/commonFunctions';
import { FILE_UPLOAD_MAX_SIZE_ERROR_MESSAGE } from 'App/Constants';

interface FileCardProps {
  title: string;
  member: any;
  fileDelete: (fileKeyString: string) => void;
  need?: FileNeedInfo;
  isLoading?: boolean;
  onError: (error: string) => void;
}

interface UploadedFile {
  file: File;
  patientPersonId: string;
  taskKeystring: string;
  needTypeName: string;
}

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 5,
    marginBottom: '0.5em',
  },
  toolbarBtn: {
    marginLeft: 'auto',
  },
}));

const FileCard: FunctionComponent<FileCardProps> = (props) => {
  const { title, member, fileDelete, need, isLoading: isDeletingFile, onError } = props;
  const [putUploadFiles, { isLoading: isUploadingFile }] = useUploadFilesMutation();
  const { refetchNextStep } = useNextStep();
  const classes = useStyles();

  const [isMaxFileSizeExceeded, setIsMaxFileSizeExceeded] = useState<boolean>(false);

  const uploadFile = async (singleUploadedFile: UploadedFile) => {
    const formToUploadFiles = new FormData();
    formToUploadFiles.append('file', singleUploadedFile.file);
    formToUploadFiles.append('patientPersonId', singleUploadedFile.patientPersonId);
    formToUploadFiles.append('taskKeystring', singleUploadedFile.taskKeystring);
    formToUploadFiles.append('needTypeName', singleUploadedFile.needTypeName);

    const response: any = await putUploadFiles(formToUploadFiles);
    if (response.error?.data?.userInputValidationError) {
      onError(response.error.data.userInputValidationError);
    } else {
      refetchNextStep();
    }
  };

  const handleUploadedFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    const doOneOrMoreExceedMaxSize = validateSizeOfEachUploadedFileInArray(files);

    if (!files || files.length === 0) {
      return;
    } else if (doOneOrMoreExceedMaxSize) {
      setIsMaxFileSizeExceeded(true);
      return;
    } else {
      setIsMaxFileSizeExceeded(false);
      const newUploadedFile: UploadedFile = {
        file: files[0],
        patientPersonId: member.id,
        taskKeystring: need ? need.taskKeystring : '',
        needTypeName: need ? need.needTypeName : '',
      };

      uploadFile(newUploadedFile);
      event.target.value = '';
    }
  };

  if (isUploadingFile || isDeletingFile) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Card className={classes.card} elevation={5}>
        <Toolbar>
          <Typography variant="h6" align="left">
            {title}
          </Typography>
          <Tooltip title="Add File" className={classes.toolbarBtn}>
            <label htmlFor="uploadFile">
              <Input
                id="uploadFile"
                style={{ display: 'none' }}
                name="uploadFile"
                type="file"
                inputProps={{ accept: 'image/*,.pdf' }}
                onChange={handleUploadedFile}
              />
              <Fab
                color="secondary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                <AddIcon /> Add
              </Fab>
            </label>
          </Tooltip>
        </Toolbar>
        <Divider />
        <CardContent>
          {need && need.files && need.files.length > 0 ? (
            need.files?.map(
              (
                file: {
                  fileKeystring: string;
                  fileName: string;
                  displayTitleOfFile: string | null | undefined;
                },
                idx
              ) => (
                <FileItemComponent
                  key={file.fileKeystring}
                  fileKeystring={file.fileKeystring}
                  fileItemComponentNdx={idx}
                  fileName={file.fileName}
                  onDeleteFileClick={() => fileDelete(file.fileKeystring)}
                />
              )
            )
          ) : (
            <FileItemComponent fileName="Need to upload file(s)" fileItemComponentNdx={1} />
          )}
          {isMaxFileSizeExceeded && (
            <AlertComponent
              alertType="error"
              title="File Not Uploaded"
              message={FILE_UPLOAD_MAX_SIZE_ERROR_MESSAGE}
              onClose={() => setIsMaxFileSizeExceeded(false)}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default FileCard;
