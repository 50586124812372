import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAdvocateInfoQuery } from 'services/user';

import { Box, Typography } from '@mui/material';
import useAuth from 'Hooks/useAuth';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

const ErrorLayout: FunctionComponent = () => {
  const { logout, isLoggedIn } = useAuth();
  const { data: advocateInfo } = useGetAdvocateInfoQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const phoneNumber = whiteLabelInfo?.phoneNumber;

  const dispatch = useDispatch();

  const phoneNumberFinal = advocateInfo?.advocatePhoneNumber
    ? advocateInfo?.advocatePhoneNumber
    : phoneNumber;

  useEffect(() => {
    logout({ redirect: false });
  }, [dispatch, logout]);

  return (
    <Box>
      <Typography>
        Uh oh! Something is not quite right, so the system was stopped. Please close the
        application, log back in, <br /> and try again.
      </Typography>

      <Typography>
        {/* just used the test site for now */}
        If you continue to have an issue, don&apos;t hesitate to call at {phoneNumberFinal}.
      </Typography>
    </Box>
  );
};

export default ErrorLayout;
