import { FunctionComponent, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';

import { useForgotPasswordMutation } from 'services/auth';
import Dialog from 'Components/Dialog';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';

interface ForgotPasswordLayoutValues {
  username: string;
}

const usernameSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
});

const ForgotPasswordLayout: FunctionComponent = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const [postForgotPassword] = useForgotPasswordMutation();

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    history.push('/');
  };

  return (
    <>
      <Dialog
        onClose={handleModalClose}
        open={open}
        title="Check Email"
        actions={[
          {
            label: 'Continue',
            onClick: () => history.push('/'),
            buttonColor: 'primary',
          },
        ]}
      >
        <Typography gutterBottom paragraph>
          Please check your email. If the provided username is associated with a registered account,
          we have sent an email with instructions on resetting your password.
        </Typography>
        <Typography>Click 'CONTINUE' to go back to the login page.</Typography>
      </Dialog>
      <Formik
        initialValues={{
          username: '',
        }}
        validationSchema={usernameSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await postForgotPassword(values.username);
          handleModalOpen();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }: FormikProps<ForgotPasswordLayoutValues>) => (
          <Box>
            <Form>
              <Box marginBottom={2}>
                <InfoLayoutBodyComponent
                  header={<span>Forgot Password</span>}
                  paragraph={<span>Enter the username associated with your account.</span>}
                />
                <TextFieldComponent label={'Username'} name={'username'} />
              </Box>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={() => {
                  history.push('/');
                }}
              />
            </Form>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default ForgotPasswordLayout;
