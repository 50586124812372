import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import { setHasAdvocateBeenIntroduced } from 'Store/Reducers/memberSlice';

import ImageComponent from 'Components/ImageComponent/ImageComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { useGetAdvocateInfoQuery } from 'services/user';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import LoadingSpinner from 'Components/LoadingSpinner';

interface AdvocateInfoFPALayoutProps {
  onNextStep(): void;
  onComplete(): void;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  leadIcon: {
    'max-width': '100%',
    'max-height': '20vh',
    background: '#D46833',
    borderRadius: '90px',
    padding: '25px',
    marginBottom: '20px',
  },
  accentText: {
    color: theme.palette.secondary.main,
    'font-weight': 'bold',
  },
}));

const AdvocateInfoFPALayout: FunctionComponent<AdvocateInfoFPALayoutProps> = ({
  onNextStep,
  onComplete,
}) => {
  const { whiteLabelInfo, isLoading: isWhiteLabelInfoLoading } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;
  const { data: advocateInfo } = useGetAdvocateInfoQuery();
  const advocateFirstName = advocateInfo ? advocateInfo.advocateFirstName : '';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
  }, [appName, dispatch]);

  const classes = useStyles();

  const handleOnComplete = () => {
    dispatch(setHasAdvocateBeenIntroduced(true));
    onComplete();
  };

  if (isWhiteLabelInfoLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <ImageComponent
        className={classes.leadIcon}
        src={'/Assets/Images/advocate_abstract_small.png'}
        alt={'small white single color icon depicting an advocate wearing a headset'}
      />
      <InfoLayoutBodyComponent
        header={
          <span>
            <span className={classes.accentText}>{advocateFirstName}</span>
            <span>
              &nbsp;will be your personal {advocateInfo?.advocateTitle.toLowerCase()} to help you
              every step of the way while using {appName}
            </span>
          </span>
        }
        paragraph={
          <span>
            You can contact {advocateFirstName} anytime by clicking on the{' '}
            {advocateInfo?.advocateTitle} Button at the bottom of your screen.
          </span>
        }
      />
      <ProgressButtonGroupComponent
        nextOnClickCallback={whiteLabelInfo?.providerIsTrueAdvocate ? handleOnComplete : onNextStep}
      />
    </Box>
  );
};

export default AdvocateInfoFPALayout;
