import { FunctionComponent } from 'react';
import { useField } from 'formik';
import { TextField, Autocomplete } from '@mui/material';
import { useErrorMessage } from 'Hooks';
import { shallowEqual } from 'react-redux';

interface TextFieldComponentProps {
  label: string;
  name: string;
  type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'url';
  auto?: { name: string; id?: any }[] | false; //string[] | false;
  noOptionsText?: string | null;
  required?: boolean;
  disabled?: boolean;
  [transferProps: string]: any;
}

interface AutoOption {
  name: string;
  id?: number | string;
}

const TextFieldComponent: FunctionComponent<TextFieldComponentProps> = ({
  label,
  name,
  type,
  auto = false,
  noOptionsText = 'no options',
  required = false,
  disabled = false,
  ...transferProps
}) => {
  const [field, meta, helpers] = useField(name);
  const errorMessage = useErrorMessage(meta);

  if (auto) {
    const options = auto as AutoOption[];
    return (
      <Autocomplete
        blurOnSelect
        clearOnBlur
        handleHomeEndKeys
        noOptionsText={noOptionsText}
        options={options}
        getOptionLabel={(option: AutoOption) => option.name}
        isOptionEqualToValue={(option, value) => shallowEqual(option, value)}
        defaultValue={field.value}
        onChange={(event, value) => {
          transferProps.onOptionSelect && transferProps.onOptionSelect(value);
          helpers.setValue(value);
        }}
        onInputChange={(event, value, reason) => {
          transferProps.onChange && transferProps.onChange(value);
        }}
        filterOptions={(options) => options}
        renderInput={(params: any) => (
          <TextField
            {...transferProps}
            {...params}
            {...field}
            label={label}
            type={type}
            required={required}
            disabled={disabled}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
          />
        )}
      />
    );
  } else {
    return (
      <TextField
        {...field}
        {...transferProps}
        label={label}
        name={name}
        type={type}
        required={required}
        disabled={disabled}
        variant={'outlined'}
        margin={'dense'}
        fullWidth
        value={field.value}
        helperText={errorMessage}
        error={errorMessage ? true : false}
      />
    );
  }
};

export default TextFieldComponent;
