import { ChangeEvent, FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton, Input, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from 'yup';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { FILE_UPLOAD_MAX_SIZE_ERROR_MESSAGE } from 'App/Constants';
import { Formik, Form } from 'formik';
import { useState } from 'react';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { useHistory } from 'react-router-dom';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import LoadingSpinner from 'Components/LoadingSpinner';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import useAuth from 'Hooks/useAuth';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';
import { usePhotoIdFileNeedQuery, useUploadPhotoIdMutation } from 'services/mailOrder';
import { MailOrderProps } from '..';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { validateSizeOfEachUploadedFileInArray } from 'containers/Common/commonFunctions';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '3px solid #D46833',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'auto',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  primaryButton: {
    width: '100%',
  },
  secondaryButton: {
    width: '100%',
    'margin-right': theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  extraMargin: {
    marginTop: theme.spacing(2),
  },
}));

interface UploadedFile {
  file: File;
  patientPersonId: string;
  mailOrderId: string;
}

const UploadIdLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  contextData,
  onSaveData,
  savedData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { mailOrderAuthKeystring: mailOrderId } = contextData;

  const classes = useStyles();
  const [putUploadPhotoId] = useUploadPhotoIdMutation();
  const { data: photoIdFileNeedInfo, isLoading: isFileNeedInfoLoading } = usePhotoIdFileNeedQuery(
    mailOrderId ?? skipToken
  );
  const [uploadedFilesArray, updateUploadedFiles] = useState([] as UploadedFile[]);
  const [fileNameArray, updateFileNameArray] = useState(['']);
  const [uploadError, setUploadError] = useState<string | undefined>();
  const [noFileChosen, setNoFileChosen] = useState<string | undefined>();
  const [isMaxFileSizeExceeded, setIsMaxFileSizeExceeded] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const uploadFilesSchema = Yup.object().shape({
    uploadedFilesArray: Yup.array().min(1, 'At least one file needs to be uploaded'),
  });

  const handleContinueLater = () => {
    dispatch(setIsNextStepReminderEligible(false));
    history.push('/');
  };

  const uploadFile = async (singleUploadedFile: UploadedFile) => {
    const formToUploadFiles = new FormData();
    formToUploadFiles.append('photoIdFile', singleUploadedFile.file);
    formToUploadFiles.append('patientPersonId', singleUploadedFile.patientPersonId);
    formToUploadFiles.append('mailOrderId', mailOrderId);

    const response: any = await putUploadPhotoId(formToUploadFiles);
    if (response.error?.data?.userInputValidationError) {
      setUploadError(response.error.data.userInputValidationError);
      const isUploadSuccess = false;
      return isUploadSuccess;
    } else {
      setUploadError(undefined);
      const isUploadSuccess = true;
      return isUploadSuccess;
    }
  };

  const handleUploadedFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    const doOneOrMoreExceedMaxSize = validateSizeOfEachUploadedFileInArray(files);

    if (!files || files.length === 0) {
      return;
    } else if (doOneOrMoreExceedMaxSize) {
      setIsMaxFileSizeExceeded(true);
      return;
    } else {
      setUploadError(undefined);
      setIsMaxFileSizeExceeded(false);
      const newUploadedFileName = files[0].name;

      let count = 1;
      let currentFileName = newUploadedFileName;
      while (fileNameArray.includes(currentFileName)) {
        const fileName =
          newUploadedFileName.substring(0, newUploadedFileName.lastIndexOf('.')) ||
          newUploadedFileName;
        const extension =
          newUploadedFileName.substring(
            newUploadedFileName.lastIndexOf('.'),
            newUploadedFileName.length
          ) || '';
        currentFileName = fileName + `(${count})` + extension;
        count++;
      }

      const adjustedFile: UploadedFile = {
        file: new File([files[0]], currentFileName, {
          type: files[0].type,
        }),
        patientPersonId: personId,
        mailOrderId: mailOrderId,
      };

      updateUploadedFiles([...uploadedFilesArray, adjustedFile]);
      updateFileNameArray([...fileNameArray, adjustedFile.file.name]);

      event.target.value = '';
    }
  };

  if (isFileNeedInfoLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Formik
        initialValues={{
          uploadedFile: (savedData.uploadedFile as Blob | string) || '',
        }}
        validationSchema={uploadFilesSchema}
        onSubmit={async (values) => {
          const isUploadSuccess: boolean[] = await Promise.all(
            uploadedFilesArray.map((file) => uploadFile(file))
          );
          if (!isUploadSuccess.includes(false)) {
            onSaveData(values);
            updateUploadedFiles([]);
            nextOnClickCallback();
          }
        }}
      >
        {(submitFormProps) => (
          <Form>
            <Box
              className={classes.screenBox}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography
                align={'center'}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                {photoIdFileNeedInfo?.needTypeName} is needed for you (
                {photoIdFileNeedInfo?.patientsName}):
              </Typography>
              {photoIdFileNeedInfo?.description ? (
                <Typography color={'textPrimary'}>{photoIdFileNeedInfo?.description}</Typography>
              ) : null}
              {photoIdFileNeedInfo?.instructions ? (
                <Typography color={'textPrimary'}>
                  Instructions: {photoIdFileNeedInfo?.instructions}
                </Typography>
              ) : null}
              {uploadedFilesArray
                ? uploadedFilesArray.map((singleFile, index) => (
                    <Box key={index} display={'flex'} marginTop={2} alignItems={'center'}>
                      <Typography noWrap color={'primary'}>
                        {singleFile.file?.name}
                      </Typography>
                      <IconButton
                        id={singleFile.file.name}
                        size={'small'}
                        aria-label={'Delete File'}
                        color={'secondary'}
                        onClick={() => {
                          // removes file from current list
                          updateUploadedFiles(
                            uploadedFilesArray.filter(
                              (item) => item.file?.name !== singleFile.file?.name
                            )
                          );
                          updateFileNameArray(
                            fileNameArray.filter((item: string) => item !== singleFile.file?.name)
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))
                : null}
              <Box marginTop={2} marginBottom={2}>
                <Input
                  name="uploadedFile"
                  type="file"
                  inputProps={{ accept: 'image/*,.pdf' }}
                  onChange={handleUploadedFile}
                />
              </Box>
              {uploadError ? (
                <AlertComponent
                  alertType="error"
                  title="Upload Unsuccessful"
                  message={uploadError}
                  onClose={() => setUploadError(undefined)}
                  className={classes.margin}
                />
              ) : null}
              {noFileChosen ? (
                <AlertComponent
                  alertType="error"
                  title="No File Chosen"
                  message={noFileChosen}
                  onClose={() => setNoFileChosen(undefined)}
                  className={classes.margin}
                />
              ) : null}
              {isMaxFileSizeExceeded && (
                <AlertComponent
                  alertType="error"
                  title="File Not Uploaded"
                  message={FILE_UPLOAD_MAX_SIZE_ERROR_MESSAGE}
                  onClose={() => setIsMaxFileSizeExceeded(false)}
                  className={classes.extraMargin}
                />
              )}
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Box className={classes.buttonGroup}>
                  <SecondaryButtonComponent
                    className={classes.secondaryButton}
                    text="Previous"
                    onClick={previousOnClickCallback}
                  />
                  {uploadedFilesArray.length > 0 ? (
                    <PrimaryButtonComponent
                      className={classes.primaryButton}
                      text={'Next'}
                      onClick={submitFormProps.handleSubmit}
                      isLoading={submitFormProps.isSubmitting}
                    />
                  ) : (
                    <PrimaryButtonComponent
                      className={classes.primaryButton}
                      text={'Next'}
                      onClick={() =>
                        setNoFileChosen(
                          'Please upload pictures of your photo ID before moving forward'
                        )
                      }
                    />
                  )}
                </Box>
                <TertiaryButtonComponent text={'Continue later'} onClick={handleContinueLater} />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UploadIdLayout;
