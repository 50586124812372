import { FunctionComponent } from 'react';
import MedicationWizard from 'containers/Wizards/Medication';
import PersonalInfoWizard from 'containers/Wizards/PersonalInfo';
import PAPWizard from 'containers/Wizards/Pap';
import MedicaidAndMedicareWizard from 'containers/Wizards/MedicaidAndMedicare';
import HIPAAWizard from 'containers/Wizards/Hipaa';
import OnboardingComplete from './OnboardingComplete';
import useStepNavigator from 'containers/Wizards/hooks/useStepNavigator';
import useAuth from 'Hooks/useAuth';
import { useGetIfPapQualifiedQuery } from 'services/medication';
import { useGetGroupInfoQuery } from 'services/whiteLabel';
import useNextStep from 'containers/NextStep/hooks/useNextStep';
import LoadingSpinner from 'Components/LoadingSpinner';
import Wizard from 'containers/Wizards/Wizard';
import { WizardProps } from 'containers/Wizards/types';

enum Wizards {
  MEDICATION = 1,
  PERSONAL_INFO,
  PAP,
  MEDICAID_AND_MEDICARE,
  HIPAA,
  COMPLETE,
}

const COMPONENT_MAP: any = {
  [Wizards.MEDICATION]: MedicationWizard,
  [Wizards.PERSONAL_INFO]: PersonalInfoWizard,
  [Wizards.PAP]: PAPWizard,
  [Wizards.MEDICAID_AND_MEDICARE]: MedicaidAndMedicareWizard,
  [Wizards.HIPAA]: HIPAAWizard,
  [Wizards.COMPLETE]: OnboardingComplete,
};

export interface OnboardingWizardProps extends WizardProps {
  groupInfoContextData: {
    groupThreshold: string;
    isPapQualified: boolean;
    shouldWeAskMemberAboutSecondaryInsurance: boolean;
    canMemberAddMedications: boolean;
  };
}

const Onboarding: FunctionComponent = () => {
  const {
    data: { personIdEncryptedMaybe: personId, groupIdEncryptedMaybe: groupId },
  } = useAuth();
  const { data: isPapQualified } = useGetIfPapQualifiedQuery();
  const { data: groupInfo, isLoading: isGroupInfoLoading } = useGetGroupInfoQuery(groupId, {
    skip: !groupId,
  });

  const groupInfoContextData = {
    groupThreshold: groupInfo?.groupThreshold,
    isPapQualified: isPapQualified,
    shouldWeAskMemberAboutSecondaryInsurance: groupInfo?.shouldWeAskMemberAboutSecondaryInsurance,
    canMemberAddMedications: groupInfo?.canMemberAddMedications,
  };

  const order = [
    Wizards.PERSONAL_INFO,
    Wizards.MEDICATION,
    isPapQualified && Wizards.PAP,
    groupInfo?.shouldWeAskMemberAboutSecondaryInsurance && Wizards.MEDICAID_AND_MEDICARE,
    Wizards.HIPAA,
    Wizards.COMPLETE,
  ].filter(Boolean);

  const { next, current, previous } = useStepNavigator({ steps: order });

  const { goToNextStep, isFetching } = useNextStep();

  const handleNext = () => {
    if (current === Wizards.COMPLETE) {
      goToNextStep();
    } else {
      next();
    }
  };

  const Component = COMPONENT_MAP[current];

  return isFetching || isGroupInfoLoading ? (
    <Wizard>
      <LoadingSpinner />
    </Wizard>
  ) : (
    <Component
      personId={personId}
      groupInfoContextData={groupInfoContextData}
      onComplete={handleNext}
      onPrevious={previous}
    />
  );
};

export default Onboarding;
