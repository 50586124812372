import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ImageComponent from 'Components/ImageComponent/ImageComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { useGetAdvocateInfoQuery } from 'services/user';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

interface AdvocateInfoFPALayoutProps {
  onNextStep(): void;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  leadIcon: {
    'max-width': '100%',
    'max-height': '20vh',
    background: '#D46833',
    borderRadius: '90px',
    padding: '25px',
    marginBottom: '20px',
  },
  accentText: {
    color: theme.palette.secondary.main,
    'font-weight': 'bold',
  },
}));

const IntroLayout: FunctionComponent<AdvocateInfoFPALayoutProps> = ({ onNextStep }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <InfoLayoutBodyComponent
        header="Patient Assistance Programs"
        paragraph="One or more of your medications may qualify for a Patient Assistance Program. These are programs that pharmaceutical manufacturers sponsor to provide free or low cost brand name medications to those who qualify.
          The following questions regarding about your household will help determine your eligibility for the program."
      />
      <ProgressButtonGroupComponent nextOnClickCallback={onNextStep} />
    </Box>
  );
};

export default IntroLayout;
