import { FunctionComponent, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import AppBarComponent from 'Components/AppBarComponent/AppBarComponent';
import SecondaryAppBarComponent from 'Components/SecondaryAppBarComponent';

interface DefaultLayoutProps {
  children: ReactNode;
  showMenu: boolean;
  showSecondaryMenu?: boolean;
}
// TODO Make secondaryMenu to show instead of regular AppBarComponent here
const DefaultLayout: FunctionComponent<DefaultLayoutProps> = (props) => {
  const { children, showMenu, showSecondaryMenu } = props;

  return (
    <DefaultLayoutContainer>
      {showSecondaryMenu ? <SecondaryAppBarComponent /> : <AppBarComponent showMenu={showMenu} />}

      <Box
        sx={{
          width: '100%',
          height: '100%',
          p: 1,
        }}
      >
        <Box
          sx={{
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',

            alignItems: 'center',
            // boxShadow: 8,
          }}
        >
          {children}
        </Box>
      </Box>
    </DefaultLayoutContainer>
  );
};

const DefaultLayoutContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr auto',
  height: '100vh',
});

export default DefaultLayout;
