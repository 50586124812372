import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Fab,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Formik, Form, FormikProps } from 'formik';
import { setTitle } from 'Store/Reducers/appInfoSlice';

import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';

import { useUpdateShipAddressMutation } from 'services/mailOrder';
import AddIcon from '@mui/icons-material/Add';

import EditAddressDialog from 'Components/EditAddressDialog/EditAddressDialog';
import { ShippingAddress, useGetAllShippingAddressesQuery } from 'services/person';
import useAuth from 'Hooks/useAuth';
import { MailOrderProps } from '../';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

interface VerifyAddressLayoutValues {
  addressIndex: string | number;
}

const useStyles = makeStyles((theme) => ({
  alert: {
    margin: theme.spacing(1),
  },
  addBtn: {
    margin: theme.spacing(2),
  },
}));

const VerifyAddressLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  onSaveData,
  savedData,
  contextData,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { mailOrderAuthKeystring: mailOrderId, isTemperatureSensitive: isTemperatureSensitive } =
    contextData;
  const { data: addresses, refetch: refetchAddresses } = useGetAllShippingAddressesQuery();
  const [putUpdateShipAddress] = useUpdateShipAddressMutation();
  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    savedData?.addressIndex as number
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressAlert, setAddressAlert] = useState(false);
  const [newAddressID, setNewAddressID] = useState<string | undefined>(undefined);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedIndex(parseInt((event.target as HTMLInputElement).value, 10));
    setAddressAlert(false);
  };

  const handleAddAddressClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    refetchAddresses();
    setIsModalOpen(false);
  };

  const handleAddAddressSubmit = async (values: any) => {
    const addressFromDialog: ShippingAddress = {
      shippingAddressId: null,
      address: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      zip: values.zip,
    };

    const response: any = await putUpdateShipAddress({
      ...addressFromDialog,
      patientPersonId: personId,
      mailOrderId,
    });

    if (response.data) {
      setNewAddressID(response.data.encryptedShippingAddressID);
      setAddressAlert(false);
    }

    handleModalClose();
  };

  const handleSubmit = async (indexOfChosenAddress: number) => {
    const chosenShipAddress = addresses?.[indexOfChosenAddress];

    await putUpdateShipAddress({
      ...chosenShipAddress,
      patientPersonId: personId,
      mailOrderId,
    });
  };

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  useEffect(() => {
    const index = addresses?.findIndex((element) => {
      if (element.shippingAddressId === newAddressID) return true;
    });

    if (index && index > -1) {
      setSelectedIndex(index);
    }
  }, [newAddressID, addresses]);

  const handlePrevious = () => {
    onSaveData({ addressIndex: selectedIndex });
    previousOnClickCallback();
  };

  return (
    <>
      <EditAddressDialog
        open={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleAddAddressSubmit}
        title="Add Shipping Address"
        actionText="Add Address"
      />
      <Formik
        enableReinitialize
        initialValues={{
          addressIndex: selectedIndex || (savedData?.addressIndex as number) || 0,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);
          if (selectedIndex !== undefined && selectedIndex > -1) {
            onSaveData({ addressIndex: selectedIndex });
            handleSubmit(selectedIndex);
            nextOnClickCallback();
          } else {
            setAddressAlert(true);
          }
        }}
      >
        {({ isSubmitting, submitForm }: FormikProps<VerifyAddressLayoutValues>) => (
          <Form>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Where should we ship your order?
            </Typography>
            {isTemperatureSensitive ? (
              <AlertComponent
                className={classes.alert}
                alertType="warning"
                message="Your order contains a temperature sensitive medication. Please
                keep this in mind when choosing an address to ship to."
              />
            ) : null}
            {addresses?.length === 0 ? (
              <PrimaryButtonComponent text="ADD ADDRESS" onClick={handleAddAddressClick} />
            ) : (
              <Box>
                <Box>
                  <FormControl>
                    <RadioGroup name="address" value={selectedIndex} onChange={handleRadioChange}>
                      {addresses?.length
                        ? addresses.map((address: ShippingAddress, index: number) => {
                            const label = [
                              address?.address?.toUpperCase(),
                              address?.address2?.toUpperCase(),
                              address?.city?.toUpperCase(),
                              address?.state?.toUpperCase(),
                            ]
                              .filter(Boolean)
                              .join(', ');
                            return (
                              <FormControlLabel
                                key={index}
                                value={index.toString()}
                                control={<Radio name="addressIndex" />}
                                label={label}
                                labelPlacement="end"
                                checked={selectedIndex === index}
                              />
                            );
                          })
                        : null}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Fab
                  color="secondary"
                  size="small"
                  component="span"
                  aria-label="add"
                  variant="extended"
                  className={classes.addBtn}
                  onClick={handleAddAddressClick}
                >
                  <AddIcon /> Add Address
                </Fab>
                {addressAlert ? (
                  <AlertComponent
                    className={classes.alert}
                    alertType="error"
                    message="Choose a shipping address"
                  />
                ) : null}
              </Box>
            )}
            <Box>
              <ProgressButtonGroupComponent
                nextOnClickCallback={submitForm}
                previousOnClickCallback={handlePrevious}
                isLoading={isSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default VerifyAddressLayout;
