import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

type DialogActionType = {
  label: string;
  onClick: () => void;
  buttonColor?: 'primary' | 'secondary';
};

type DialogActionTypes = Array<DialogActionType>;

type Props = {
  fullWidth?: boolean;
  onClose: () => void;
  open: boolean;
  children: any;
  title: string;
  actions?: DialogActionTypes;
};

const Dialog: FunctionComponent<Props> = (props: Props) => {
  const { actions = [], children, fullWidth = false, onClose, open, title } = props;

  return (
    <MuiDialog
      fullWidth={fullWidth}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {actions.length ? (
        <DialogActions>
          {actions.map(({ onClick, buttonColor = 'primary', label }) => (
            <Button autoFocus onClick={onClick} color={buttonColor} key={label}>
              {label}
            </Button>
          ))}
        </DialogActions>
      ) : undefined}
    </MuiDialog>
  );
};

export default Dialog;
