import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import {
  useGetAllAssistedMedsUserCanSeeQuery,
  useGetAllNotAssistedMedsUserCanSeeQuery,
} from 'services/medication';
import {
  Member,
  useGetAllHouseholdMembersQuery,
  useInviteAdultDependentToRegisterMutation,
} from 'services/user';
import { useHistory } from 'react-router-dom';
import { useGetAllPrescribersQuery } from 'services/prescriber';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import ProxyAuthDialog from 'Components/ProxyAuthDialog/ProxyAuthDialog';
import { ExpandMore } from '@mui/icons-material';
import { useGetGroupInfoQuery } from 'services/whiteLabel';
import useAuth from 'Hooks/useAuth';
import Page from 'Pages/BasePage';
import InfoCardPrimary from 'Components/InfoCardPrimary';

const StyledPatientAccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: '0px',
    padding: '0px 8px',
    borderRadius: '5px 5px 5px 5px',
    background: theme.palette.common.white,
    '&$expanded': {
      minHeight: '0px',
      padding: '0px 8px',
      borderRadius: '5px 5px 0px 0px',
      background: theme.palette.primary.main,
    },
  },
  content: {
    color: theme.palette.common.black,
    margin: '5px 0px',
    '&$expanded': {
      color: theme.palette.common.white,
      margin: '5px 0px',
    },
  },
  expanded: {},
}))(AccordionSummary);

const StyledNotAssistedMedsAccordianSummary = withStyles((theme) => ({
  root: {
    minHeight: '0px',
    padding: '0px 8px',
    borderRadius: '5px 5px 5px 5px',
    background: theme.palette.common.white,
    '&$expanded': {
      minHeight: '0px',
      padding: '0px 8px',
      borderRadius: '5px 5px 0px 0px',
      background: theme.palette.secondary.main,
    },
  },
  content: {
    color: theme.palette.common.black,
    margin: '5px 0px',
    '&$expanded': {
      color: theme.palette.common.white,
      margin: '5px 0px',
    },
  },
  expanded: {},
}))(AccordionSummary);

const MedicationManagementLayout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    data: { groupIdEncryptedMaybe: groupId },
  } = useAuth();
  const { data: prescribers } = useGetAllPrescribersQuery('');
  const { data: allHouseholdMembers } = useGetAllHouseholdMembersQuery();
  const { data: assistedMedications, refetch: refetchAssistedMedications } =
    useGetAllAssistedMedsUserCanSeeQuery();
  const { data: notAssistedMedications } = useGetAllNotAssistedMedsUserCanSeeQuery();
  const { data: groupInfo, isLoading: isLoadingGroupInfo } = useGetGroupInfoQuery(groupId, {
    skip: !groupId,
  });
  const [isProxyDialogOpen, setIsProxyDialogOpen] = useState<boolean>(false);
  const [currentProxyMember, setCurrentProxyMember] = useState({} as Member);
  const [inviteAdultDependent] = useInviteAdultDependentToRegisterMutation();

  useEffect(() => {
    refetchAssistedMedications();
  });

  const sortedMembers = useMemo(() => {
    const processedMembers = allHouseholdMembers?.map((dep) => ({
      id: dep.patientId,
      ...dep,
      medCount: assistedMedications
        ? assistedMedications.filter((med) => med.patientPersonId === dep.patientId).length
        : 0,
    }));

    return processedMembers?.sort((a: any, b: any) => b.medCount - a.medCount);
  }, [allHouseholdMembers, assistedMedications]);

  const handleProxyDialogOpen = (member: any) => () => {
    setCurrentProxyMember(member);
    setIsProxyDialogOpen(true);
  };

  const handleProxyDialogClose = () => {
    setIsProxyDialogOpen(false);
  };

  const handleInvitingMember = (personId: string) => {
    inviteAdultDependent(personId);
  };

  useEffect(() => {
    dispatch(setTitle('Medication Management'));
  });

  const handleEditMedicationClick = (medicationId: string | null) => () => {
    history.push(`/editMedication/${medicationId}`);
  };

  return (
    <Page isLoading={isLoadingGroupInfo}>
      <ProxyAuthDialog
        open={isProxyDialogOpen}
        onClose={handleProxyDialogClose}
        proxyMember={currentProxyMember}
      />
      <Box>
        {sortedMembers?.map((member, i) => (
          <Accordion
            key={member.id}
            style={{ margin: '5px', minHeight: '0px' }}
            defaultExpanded={i === 0}
          >
            <StyledPatientAccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant={'h5'}>{`${member.firstName} ${member.lastName}`}</Typography>
            </StyledPatientAccordionSummary>
            <AccordionDetails style={{ display: 'block', padding: '4px 16px 4px' }}>
              <Box
                display={'grid'}
                gap={'5px'}
                gridTemplateColumns={'repeat(auto-fill, minmax(225px, 1fr))'}
                width={'100%'}
              >
                {assistedMedications
                  ?.filter((med) => med.patientPersonId === member.id)
                  .map((med) => {
                    const prescriber = prescribers?.find(
                      (doc: { prescriberId?: string }) => doc.prescriberId === med.prescriberId
                    );
                    return (
                      <Box
                        style={{
                          padding: '8px',
                          margin: '0 auto',
                          display: 'grid',
                          gap: '5px',
                          gridTemplateColumns: 'repeat(auto-fill, minmax(225px, 1fr))',
                          width: '100%',
                        }}
                        key={med.drugId}
                      >
                        <InfoCardPrimary
                          title={`${med.name} ${med.strength ? med.strength : ''}`}
                          onActionClick={handleEditMedicationClick(med.medicationId)}
                          contentItems={[
                            {
                              title: 'Doctor/Prescriber: ',
                              content: `${
                                prescriber && (prescriber.lastName || prescriber.firstName)
                                  ? `${prescriber.firstName ?? ''} ${prescriber.lastName ?? ''}`
                                  : 'none'
                              }`,
                            },
                            {
                              title: 'Assistance Type: ',
                              content: `${med.assistanceType ? med.assistanceType : 'none'}`,
                            },
                            {
                              title: 'Current Status: ',
                              content: `${med.assistanceStatus}`,
                            },
                            {
                              title: 'Waiting On: ',
                              content: `${med.waitingOnStatus}`,
                            },
                            med.additionalInstructions
                              ? {
                                  title: 'Additional Instructions: ',
                                  content: med.additionalInstructions,
                                }
                              : null,
                          ].filter(Boolean)}
                        />
                      </Box>
                    );
                  })}
              </Box>
              {notAssistedMedications && notAssistedMedications.length > 0 ? (
                <Accordion style={{ marginTop: '10px', minHeight: '0px' }}>
                  <StyledNotAssistedMedsAccordianSummary expandIcon={<ExpandMore />}>
                    <Typography variant={'h6'}>Medications With No Assistance Required</Typography>
                  </StyledNotAssistedMedsAccordianSummary>
                  <AccordionDetails>
                    <List>
                      {notAssistedMedications
                        .filter(
                          (med: { patientPersonId: string }) => med.patientPersonId === member.id
                        )
                        .map(
                          (
                            med: {
                              prescriberId: string;
                              name: any;
                              strength: any;
                            },
                            index
                          ) => {
                            return (
                              <ListItem key={index}>
                                <ListItemText secondary={`Medication: ${med.name}`} />
                              </ListItem>
                            );
                          }
                        )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {groupInfo?.shouldWeAskMemberAboutSecondaryInsurance ? (
                <PrimaryButtonComponent
                  text={'Add Medication'}
                  onClick={() => {
                    history.push(`/addMedication/${member.patientId}`);
                  }}
                />
              ) : null}
              {member.someMedsAreHidden === false ? undefined : (
                <Box style={{ margin: '0.5em' }}>
                  <Divider variant="middle" />
                  <Typography style={{ marginTop: '0.5em' }}>
                    You are only allowed to see prescriptions you have created. If you would like to
                    see all information for {member.firstName} {member.lastName}, you must request
                    their authorization below.
                  </Typography>
                  <PrimaryButtonComponent
                    text={'Get Authorization'}
                    onClick={handleProxyDialogOpen(member)}
                    width={'auto'}
                  />
                </Box>
              )}
              {member.isOtherAdultNeedingToSignHipaaAuth && groupInfo?.isOpenEnrollmentAllowed ? (
                <Box style={{ margin: '0.5em' }}>
                  <Divider variant="middle" />
                  <Typography style={{ marginTop: '0.5em' }}>
                    Below you can invite this member to create a user account and sign their HIPAA
                    Authorization.
                  </Typography>
                  <PrimaryButtonComponent
                    text="Invite User"
                    onClick={() => handleInvitingMember(member.id)}
                    width="auto"
                  />
                </Box>
              ) : null}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Page>
  );
};

export default MedicationManagementLayout;
