import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useAuthMailOrderInitMutation } from 'services/mailOrder';
import { useGetGroupInfoQuery } from 'services/whiteLabel';
import useAuth from 'Hooks/useAuth';
import { useGetUserDetailsQuery } from 'services/user';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import { MailOrderProps } from '../';

const confirmNeedType = 'StndrdProvisions';

interface TermsAndConditionsLayoutValues {
  initials: string;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  links: {
    color: '#3771A1',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const TermsAndConditionsLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  contextData,
  onSaveData,
  savedData,
}) => {
  const dispatch = useDispatch();
  const { mailOrderAuthKeystring: mailOrderId } = contextData;

  const {
    data: { personIdEncryptedMaybe: personId, groupIdEncryptedMaybe: groupId },
  } = useAuth();
  const { data: groupInfo } = useGetGroupInfoQuery(groupId, {
    skip: !groupId,
  });
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const { firstName, lastName } = userDetails || {};
  const userInitials = `${firstName?.[0].toUpperCase()}${lastName?.[0].toUpperCase()}`;

  const [putAuthMailOrderInit] = useAuthMailOrderInitMutation();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const termsAndConditionsSchema = Yup.object().shape({
    initials: Yup.string()
      .uppercase()
      .oneOf([userInitials], "Characters don't match your initials")
      .required('Initials Required'),
  });

  const classes = useStyles();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  return (
    <Formik
      initialValues={{
        initials: (savedData.termsAndConditions as string) || '',
      }}
      validationSchema={termsAndConditionsSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSaveData({
          termsAndConditions: values.initials,
        });

        const mailOrderInitInfo = {
          mailOrderId,
          authorizationInitials: values.initials,
          confirmationNeedType: confirmNeedType,
        };
        await putAuthMailOrderInit(mailOrderInitInfo);
        setSubmitting(false);

        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<TermsAndConditionsLayoutValues>) => (
        <Form>
          <Box
            className={classes.screenBox}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
          >
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Terms and Conditions
            </Typography>
            <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
              By initialing the box below, you are stating you understand and agree {appName}{' '}
              provides administrative assistance to place mail orders for medications in order to
              assist you with acquiring prescribed medications. You are also stating that you
              understand and agree {appName} is not responsible for the products received and cannot
              be held liable for any damage or delay caused by the mail order process, shipping
              companies, any third parties, or the medications themselves.
              <br />
              <br />
            </Typography>
            {!groupInfo?.requestedToNotHaveHsaClause && (
              <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
                If you are contributing towards an HSA, it is your responsibility to ensure that you
                have met the minimum deductible required by the{' '}
                <a
                  className={classes.links}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.irs.gov/instructions/i8889#idm139631566344128"
                >
                  IRS
                </a>{' '}
                before proceeding.
              </Typography>
            )}
            <Box display={'flex'} marginTop={2} marginBottom={1}>
              <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                I, {firstName} {lastName}, verify that I have read and agree to the Terms and
                Conditions listed above.
              </Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              <Box flexBasis={'40%'}>
                <TextFieldComponent
                  label={'Initial Here'}
                  name={'initials'}
                  fullWidth={false}
                  inputProps={{ maxLength: 2 }}
                  onInput={(e: { target: { value: string } }) => {
                    e.target.value = e.target.value.slice(0, 2);
                  }}
                />
              </Box>
            </Box>
            <Box marginTop={2}>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={previousOnClickCallback}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TermsAndConditionsLayout;
