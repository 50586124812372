import { FunctionComponent } from 'react';

import Intro from './layouts/Intro';
import AdvocateInfo from './layouts/AdvocateInfo';
import Acknowledgements from './layouts/Acknowledgements';

import useStepNavigator from '../hooks/useStepNavigator';

import { WizardProps, StepProps } from '../types';
import Wizard from '../Wizard';

enum Steps {
  INTRO = 1,
  ADVOCATE_INFO,
  ACKNOWLEDGEMENTS,
}

const COMPONENT_MAP: any = {
  [Steps.INTRO]: Intro,
  [Steps.ADVOCATE_INFO]: AdvocateInfo,
  [Steps.ACKNOWLEDGEMENTS]: Acknowledgements,
};

const WelcomeWizard: FunctionComponent<WizardProps> = (props) => {
  const { onComplete, onPrevious } = props;

  const order = [Steps.INTRO, Steps.ADVOCATE_INFO, Steps.ACKNOWLEDGEMENTS];

  const { current, next, previous } = useStepNavigator({ steps: order });

  const Component: FunctionComponent<StepProps> = COMPONENT_MAP[current];
  const isLoading = false;

  const handlePreviousStep = () => {
    if (current === Steps.INTRO && onPrevious) {
      onPrevious();
    } else {
      previous();
    }
  };

  return (
    <Wizard isLoading={isLoading}>
      <Component onNextStep={next} onPreviousStep={handlePreviousStep} onComplete={onComplete} />
    </Wizard>
  );
};

export default WelcomeWizard;
