import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';

import { MailOrderProps } from '../';
import { useGetAdvocateInfoQuery } from 'services/user';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
}));

const IntroLayout: FunctionComponent<MailOrderProps> = ({ nextOnClickCallback }) => {
  const classes = useStyles();
  const { data: advocateInfo } = useGetAdvocateInfoQuery();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <InfoLayoutBodyComponent
        header={<span>Mail Order Authorization</span>}
        paragraph={`At any time, if you have a question regarding mail order authorization, please contact ${advocateInfo?.advocateFirstName}. Click Next to start the process.`}
      />
      <PrimaryButtonComponent text={'NEXT'} onClick={nextOnClickCallback} />
    </Box>
  );
};

export default IntroLayout;
