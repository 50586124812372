import { FunctionComponent, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import LeadLogoComponent from 'Components/LeadLogoComponent/LeadLogoComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

interface QualifiedFPALayoutProps {
  nextOnClickCallback(): void;
  previousOnClickCallback?(): void;
  contextData: any;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
}));

const QualifiedFPALayout: FunctionComponent<QualifiedFPALayoutProps> = ({
  nextOnClickCallback,
  contextData,
}) => {
  const { groupThreshold } = contextData;
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
  }, [appName, dispatch]);

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <LeadLogoComponent />
      <InfoLayoutBodyComponent
        header={
          <span>
            With a maintenance or specialty medication that costs over {groupThreshold} a month, you
            qualify for our prescription advocacy program!
          </span>
        }
        paragraph={
          <span>
            Click &apos;Next&apos; to register and create your {appName} account. From there, you
            will be able to add prescription information for you and your dependents, and coordinate
            with a Prescription Advocate who will work with you to get your medications at a
            fraction of the normal costs.
          </span>
        }
      />
      <ProgressButtonGroupComponent nextOnClickCallback={nextOnClickCallback} />
    </Box>
  );
};

export default QualifiedFPALayout;
