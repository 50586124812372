import { FunctionComponent } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { Prescriber, useAddPrescriberMutation } from 'services/prescriber';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';

import Dialog from 'Components/Dialog';

const phoneRegExp = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-/\s.]|\d)+$/;
const AddPrescriberSchema = Yup.object().shape({
  firstName: Yup.string().required('Required').max(100, 'Name is too long'),
  lastName: Yup.string().required('Required').max(100, 'Name is too long'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Invalid value')
    .min(10, 'Invalid value')
    .required('Required'),
  fax: Yup.string().matches(phoneRegExp, 'Invalid value').nullable().notRequired(),
});

interface AddPrescriberValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  fax: string | null;
}

type AddPrescriberDialogProps = {
  onClose: () => void;
  open: boolean;
  onPrescriberAdded: (id: string) => void;
};

const AddPrescriberDialog: FunctionComponent<AddPrescriberDialogProps> = (props) => {
  const { onClose, open, onPrescriberAdded } = props;

  const [postNewlyAddedPrescriber] = useAddPrescriberMutation();

  return (
    <Dialog open={open} onClose={onClose} title="Add Doctor">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumber: '',
          fax: '',
        }}
        validationSchema={AddPrescriberSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const newPrescriber: Prescriber = {
            prescriberId: '',
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            fax: values.fax,
          };

          const response: any = await postNewlyAddedPrescriber(newPrescriber);
          onPrescriberAdded(response.data.prescriberId);
          onClose();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }: FormikProps<AddPrescriberValues>) => (
          <Form>
            <Box>
              <Typography
                align={'center'}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                Let&apos;s get some basic info about your doctor.
              </Typography>
              <Typography align={'center'} variant={'body1'} color={'textPrimary'}>
                Fax isn&apos;t required, but it&apos;s nice to have.
              </Typography>
              <Box marginBottom={3} marginTop={2}>
                <TextFieldComponent label={"Doctor's First Name"} name={'firstName'} />
                <TextFieldComponent label={"Doctor's Last Name"} name={'lastName'} />
                <TextFieldComponent
                  label={"Doctor's Phone Number"}
                  name={'phoneNumber'}
                  type={'tel'}
                />
                <TextFieldComponent label={"Doctor's Fax Number"} name={'fax'} type={'tel'} />
              </Box>
            </Box>
            <PrimaryButtonComponent
              text="ADD DOCTOR"
              onClick={submitForm}
              isLoading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddPrescriberDialog;
