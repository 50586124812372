import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import { Typography } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { useUpdateSsnMutation } from 'services/person';
import LoadingSpinner from 'Components/LoadingSpinner';
import { StepProps } from 'containers/Wizards/types';
import { Member, useGetUserDetailsQuery } from 'services/user';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

interface SSNLayoutProps extends StepProps {
  onNextStep(): void;
  onPreviousStep(): void;
  viewableMembers: Member[];
}

interface SSNLayoutValues {
  ssn: string;
}

const ssnRegExp =
  /^((?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4})|((?!219 09 9999|078 05 1120)(?!666|000|9\d{2})\d{3} (?!00)\d{2} (?!0{4})\d{4})|((?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4})$/;
const ssnSchema = Yup.object().shape({
  ssn: Yup.string()
    .required('Required')
    .matches(ssnRegExp, 'Invalid value')
    .min(9, 'Invalid value')
    .max(11, 'Invalid value'),
});

const SSNLayout: FunctionComponent<SSNLayoutProps> = ({
  onNextStep,
  onPreviousStep,
  viewableMembers,
}) => {
  const dispatch = useDispatch();
  const [updateSsn] = useUpdateSsnMutation();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const [currentMemberIndex, setCurrentMemberIndex] = useState(0);
  const { firstName, lastName, patientId } = viewableMembers[currentMemberIndex];

  const {
    data: userDetails,
    isLoading: isLoadingDetails,
    isFetching,
    refetch: refetchUserDetails,
  } = useGetUserDetailsQuery(patientId);

  useEffect(() => {
    dispatch(setTitle('Patient Assistance Programs'));
  }, [dispatch]);

  useEffect(() => {
    refetchUserDetails();
  }, [currentMemberIndex, refetchUserDetails]);

  useEffect(() => {
    if (userDetails?.hasSsn && !isLoadingDetails) {
      if (currentMemberIndex === viewableMembers.length - 1) {
        onNextStep();
      } else {
        setCurrentMemberIndex(currentMemberIndex + 1);
      }
    }
  }, [currentMemberIndex, isLoadingDetails, viewableMembers, onNextStep, userDetails]);

  if (isLoadingDetails || isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <Formik
      initialValues={{
        ssn: '',
      }}
      validationSchema={ssnSchema}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        const response: any = await updateSsn({
          patientPersonId: patientId,
          ssn: values.ssn,
        });

        if (response.error) {
          if (response.error.data?.userInputValidationError) {
            setErrorMsg(response.error.data.userInputValidationError);
          }
        } else {
          setErrorMsg(undefined);
          if (currentMemberIndex === viewableMembers.length - 1) {
            onNextStep();
          } else {
            setFieldValue('ssn', '', false);
            setCurrentMemberIndex(currentMemberIndex + 1);
          }
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<SSNLayoutValues>) => (
        <Form>
          <Typography
            align="center"
            variant="body1"
            color="textPrimary"
            style={{ fontWeight: 'bold' }}
          >
            A Social Security Number for {firstName} {lastName} is required for patient assistant
            programs
          </Typography>
          <TextFieldComponent label="Social Security Number" name="ssn" />
          {errorMsg ? <AlertComponent alertType="error" message={errorMsg} /> : null}
          <ProgressButtonGroupComponent
            isLoading={isSubmitting}
            nextOnClickCallback={submitForm}
            previousOnClickCallback={onPreviousStep}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SSNLayout;
