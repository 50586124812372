import { useEffect, useState, useContext, FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { setTitle } from 'Store/Reducers/appInfoSlice';

import { Box, Typography } from '@mui/material';

import LeadLogoComponent from 'Components/LeadLogoComponent/LeadLogoComponent';
import SelectComponent from 'Components/SelectComponent/SelectComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';

import Qualified from './Qualified';
import Unqualified from './Unqualified';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

const QUALIFICATION_NO_PRESCRIPTIONS = 1;
const QUALIFICATION_MULTIPLE_PRESCRIPTIONS = 2;
const QUALIFICATION_HIGH_COST_PRESCRIPTION = 3;

interface qualifiedQuestionValues {
  qualifiedStatus: number | '';
}

interface QualificationProps {
  nextOnClickCallback(): void;
  previousOnClickCallback?(): void;
  contextData: any;
}

const qualifiedQuestionSchema = Yup.object().shape({
  qualifiedStatus: Yup.mixed().required('Required'),
});

const Qualification: FunctionComponent<QualificationProps> = ({
  nextOnClickCallback,
  contextData,
}) => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState<number | ''>(0);
  const { groupThreshold } = contextData;
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  const handleReset = () => {
    setSelectedStatus(0);
  };

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
  }, [appName, dispatch]);

  switch (selectedStatus) {
    case QUALIFICATION_NO_PRESCRIPTIONS:
      return (
        <Unqualified
          previousOnClickCallback={handleReset}
          nextOnClickCallback={() => undefined}
          hasMeds={false}
          contextData={contextData}
        />
      );
    case QUALIFICATION_MULTIPLE_PRESCRIPTIONS:
      return (
        <Unqualified
          previousOnClickCallback={handleReset}
          nextOnClickCallback={() => undefined}
          hasMeds={true}
          contextData={contextData}
        />
      );
      break;
    case QUALIFICATION_HIGH_COST_PRESCRIPTION:
      return <Qualified nextOnClickCallback={nextOnClickCallback} contextData={contextData} />;
    default:
      return (
        <Formik
          initialValues={{ qualifiedStatus: '' }}
          validationSchema={qualifiedQuestionSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false);
            setSelectedStatus(values.qualifiedStatus);
          }}
        >
          {({ errors, submitForm, isSubmitting }: FormikProps<qualifiedQuestionValues>) => (
            <Form>
              <LeadLogoComponent />
              <Box marginBottom={4} marginTop={2}>
                <Typography align={'center'} variant={'body1'} color={'textPrimary'}>
                  The {appName} program is designed for members with at least one medication that
                  costs over {groupThreshold} for a one-month supply. Please select the option below
                  that best describes your prescription needs and then follow the instructions
                  provided.
                </Typography>
                <Box marginBottom={1}>
                  <Typography
                    align={'center'}
                    variant={'body1'}
                    color={'textPrimary'}
                    style={{ fontWeight: 'bold' }}
                  >
                    Are you or a dependent currently taking a high-cost medication?
                  </Typography>
                </Box>
                <SelectComponent
                  label={'Prescription status'}
                  name={'qualifiedStatus'}
                  errorMessage={errors.qualifiedStatus}
                  selectOptions={[
                    {
                      value: QUALIFICATION_NO_PRESCRIPTIONS,
                      label: "We don't have any prescriptions.",
                    },
                    {
                      value: QUALIFICATION_MULTIPLE_PRESCRIPTIONS,
                      label: 'We have multiple prescriptions and their costs add up.',
                    },
                    {
                      value: QUALIFICATION_HIGH_COST_PRESCRIPTION,
                      label: `We have at least 1 prescription that costs at least ${groupThreshold} per month.`,
                    },
                  ]}
                />
              </Box>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
              />
            </Form>
          )}
        </Formik>
      );
  }
};

export default Qualification;
