import { FunctionComponent, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { AdvocacyDetails, useEditAdvocacyDetailsMutation } from 'services/user';
import Dialog from 'Components/Dialog';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

interface EditAdditionalCoverageValues {
  noIns?: boolean | null;
  medicarePartB?: boolean | null;
  medicarePartC?: boolean | null;
  medicarePartD?: boolean | null;
  medicaid?: boolean | null;
  miltaryVA?: boolean | null;
  childrens?: boolean | null;
  spouse?: boolean | null;
}

type CustomizedDialogsProps = {
  onClose: () => void;
  open: boolean;
  contextData: any;
};

const EditAdditionalCoverageDialog: FunctionComponent<CustomizedDialogsProps> = (props) => {
  const { onClose, open, contextData } = props;
  const personId = contextData?.personId;
  const advocacyDetails: AdvocacyDetails = { ...contextData };

  const [secondaryIns, setChecked] = useState<AdvocacyDetails>(advocacyDetails);
  const [insuranceError, setInsuranceError] = useState(false);
  const [nothingSelectedError, setNothingSelectedError] = useState(false);

  const [editAdvocacyDetails] = useEditAdvocacyDetailsMutation();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ ...secondaryIns, [event.target.name]: event.target.checked });
  };

  return (
    <Dialog title="Edit Additional Coverage" onClose={onClose} open={open}>
      <Formik
        enableReinitialize
        initialValues={{
          noIns: secondaryIns.noIns,
          medicarePartB: secondaryIns.medicarePartB,
          medicarePartC: secondaryIns.medicarePartC,
          medicarePartD: secondaryIns.medicarePartD,
          medicaid: secondaryIns.medicaid,
          miltaryVA: secondaryIns.miltaryVA,
          childrens: secondaryIns.childrens,
          spouse: secondaryIns.spouse,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          //reset
          setInsuranceError(false);
          setNothingSelectedError(false);

          if (secondaryIns.noIns) {
            const obj = { ...secondaryIns };
            const clone = (({ noIns, ...secondaryIns }) => secondaryIns)(obj);
            const insCount = Object.values(clone).filter((x) => x === true).length;
            if (insCount > 0) {
              setInsuranceError(true);
              return;
            }
          } else {
            const insCount = Object.values(secondaryIns).filter((x) => x === true).length;
            if (insCount == 0) {
              setNothingSelectedError(true);
              return;
            }
          }

          const selectedNoIns = secondaryIns.noIns === true ? true : false;
          const advocacyDetails: AdvocacyDetails = {
            noIns: selectedNoIns,
            medicarePartB: selectedNoIns ? false : secondaryIns.medicarePartB ? true : false,
            medicarePartC: selectedNoIns ? false : secondaryIns.medicarePartC ? true : false,
            medicarePartD: selectedNoIns ? false : secondaryIns.medicarePartD ? true : false,
            medicaid: selectedNoIns ? false : secondaryIns.medicaid ? true : false,
            miltaryVA: selectedNoIns ? false : secondaryIns.miltaryVA ? true : false,
            childrens: selectedNoIns ? false : secondaryIns.childrens ? true : false,
            spouse: selectedNoIns ? false : secondaryIns.spouse ? true : false,
          };

          await editAdvocacyDetails({
            patientPersonId: personId,
            advocacyDetails: advocacyDetails,
          });
          onClose();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }: FormikProps<EditAdditionalCoverageValues>) => (
          <Form>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.noIns ? secondaryIns.noIns : false}
                      name="noIns"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="No secondary insurance"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.spouse ? secondaryIns.spouse : false}
                      name="spouse"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Spouse's Plan"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.medicaid ? secondaryIns.medicaid : false}
                      name="medicaid"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Medicaid"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.medicarePartB ? secondaryIns.medicarePartB : false}
                      name="medicarePartB"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Medicare Part B"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.medicarePartC ? secondaryIns.medicarePartC : false}
                      name="medicarePartC"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Medicare Advantage Part C"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.medicarePartD ? secondaryIns.medicarePartD : false}
                      name="medicarePartD"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Medicare Part D"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.miltaryVA ? secondaryIns.miltaryVA : false}
                      name="miltaryVA"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Veteran Benefits (Tri-Care, VA Benefits, CHAMPVA)"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.childrens ? secondaryIns.childrens : false}
                      name="childrens"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Children's Special Health Care Services"
                  labelPlacement="end"
                />
                {insuranceError ? (
                  <AlertComponent
                    alertType="error"
                    title="Invalid selection"
                    message="Cannot have No secondary insurance with other insurance options."
                  />
                ) : null}
                {nothingSelectedError ? (
                  <AlertComponent
                    alertType="error"
                    title="Invalid selection"
                    message="Please select at least one insurance option."
                  />
                ) : null}
              </FormGroup>
            </Box>
            <PrimaryButtonComponent
              text="Save Additional Coverage Info"
              onClick={submitForm}
              width="auto"
              isLoading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditAdditionalCoverageDialog;
