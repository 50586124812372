import axios from 'axios';
import { KEY_AUTH_TOKEN } from 'App/contexts/constants';

export interface ClientError {
  statusCode?: string | number | null;
  message?: string;
}

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
});

const headers = {
  Authorization: localStorage.getItem(KEY_AUTH_TOKEN),
};

const loggerService = (data: ClientError): void => {
  try {
    client.post('error', data, { headers });
  } catch (e) {
    // do nothing
  }
};

export const useLoggerService = (): { log: (data: ClientError) => void } => ({
  log: (data: ClientError) => {
    loggerService(data);
  },
});

export default loggerService;
