import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import { useHistory } from 'react-router-dom';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
}));

const AlreadyRegistered: FunctionComponent = () => {
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  const dispatch = useDispatch();
  const history = useHistory();

  const redirectToLogin = () => {
    history.push('/login');
  };

  useEffect(() => {
    dispatch(setTitle(`Login to ${appName}`));
  }, [appName, dispatch]);

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <InfoIcon
        color={'primary'}
        style={{
          fontSize: 130,
          marginBottom: 20,
        }}
      />
      <InfoLayoutBodyComponent
        header={<span>It looks like you&apos;re already registered on {appName}.</span>}
        paragraph={<span>Please login to your account to continue using the app.</span>}
      />
      <PrimaryButtonComponent text={'Back To Login'} onClick={redirectToLogin} />
    </Box>
  );
};

export default AlreadyRegistered;
