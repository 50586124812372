import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

export interface Prescriber {
  prescriberId?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  fax?: string | null;
}

// Define a service using a base URL and expected endpoints
export const prescriberApi = createApi({
  reducerPath: 'services/prescriber',
  tagTypes: ['Prescriber'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getPrescriber: builder.query<Prescriber, string>({
      query: (prescriberId) => {
        return {
          url: `prescriber/${prescriberId}`,
          method: 'get',
        };
      },
      providesTags: ['Prescriber'],
    }),
    addPrescriber: builder.mutation({
      query: (prescriberInfo) => {
        return {
          url: 'prescriber',
          method: 'post',
          data: prescriberInfo,
        };
      },
      invalidatesTags: ['Prescriber'],
    }),
    editPrescriber: builder.mutation({
      query: ({ prescriberId, prescriberInfo }) => {
        return {
          url: `prescriber/${prescriberId}`,
          method: 'put',
          data: prescriberInfo,
        };
      },
      invalidatesTags: ['Prescriber'],
    }),
    getAllPrescribers: builder.query<Prescriber[], string>({
      query: (newPrescriberId) => {
        return {
          url: `prescribers/household/${newPrescriberId}`,
          method: 'get',
        };
      },
      providesTags: ['Prescriber'],
    }),
  }),
});

export const {
  useGetPrescriberQuery,
  useAddPrescriberMutation,
  useEditPrescriberMutation,
  useGetAllPrescribersQuery,
} = prescriberApi;
