import { FunctionComponent, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import { useGetAllAssistedMedsUserCanSeeQuery } from 'services/medication';
import { useGetAllHouseholdMembersQuery } from 'services/user';
import ProfilePersonalInfoCard from 'Components/ProfilePersonalInfoCard/ProfilePersonInfoCard';
import ProfileAddressCard from 'Components/ProfileAddressCard/ProfileAddressCard';
import ProfileContactCard from 'Components/ProfileContactCard/ProfileContactCard';
import { ExpandMore } from '@mui/icons-material';
import ProfileAdditionalCoverageCard from 'Components/ProfileAdditionalCoverageCard/ProfileAdditionalCoverageCard';
import Page from 'Pages/BasePage';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import LoadingSpinner from 'Components/LoadingSpinner';

const StyledPatientAccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: '0px',
    padding: '0px 8px',
    borderRadius: '5px 5px 5px 5px',
    background: theme.palette.common.white,
    '&$expanded': {
      minHeight: '0px',
      padding: '0px 8px',
      borderRadius: '5px 5px 0px 0px',
      background: theme.palette.primary.main,
    },
  },
  content: {
    color: theme.palette.common.black,
    margin: '5px 0px',
    '&$expanded': {
      color: theme.palette.common.white,
      margin: '5px 0px',
    },
  },
  expanded: {},
}))(AccordionSummary);

const MemberProfileManagementLayout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { whiteLabelInfo, isLoading: isWhiteLabelInfoLoading } = useContext(WhiteLabelContext);
  const { data: allHouseholdMembers } = useGetAllHouseholdMembersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: assistedMedications } = useGetAllAssistedMedsUserCanSeeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  // Move this into useHouseholdMembersData hook potentially
  const sortedMembers = useMemo(() => {
    const processedMembers = allHouseholdMembers?.map((member) => ({
      id: member.patientId,
      ...member,
      medCount: assistedMedications?.filter((med) => med.patientPersonId === member.patientId)
        .length,
    }));

    return processedMembers?.sort((a: any, b: any) => b.medCount - a.medCount);
  }, [allHouseholdMembers, assistedMedications]);

  useEffect(() => {
    dispatch(setTitle('Member Profile'));
  });

  if (isWhiteLabelInfoLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Page>
      <Box>
        {sortedMembers?.map((member, i) => (
          <Accordion
            key={member.id}
            style={{ margin: '5px', minHeight: '0px' }}
            defaultExpanded={i === 0}
          >
            <StyledPatientAccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant={'h5'}>{`${member.firstName} ${member.lastName}`}</Typography>
            </StyledPatientAccordionSummary>
            <AccordionDetails style={{ display: 'block', padding: '4px 16px 4px' }}>
              <Box
                display={'grid'}
                gap={'3px'}
                gridTemplateColumns={'repeat(auto-fill, minmax(225px, 1fr))'}
                width={'100%'}
              >
                <ProfilePersonalInfoCard personId={member.id} />
                <ProfileContactCard personId={member.id} isAdultMaybe={member.isAdult} />
                <ProfileAddressCard personId={member.id} />
                {!whiteLabelInfo?.providerIsTrueAdvocate ? (
                  <ProfileAdditionalCoverageCard personId={member.id} />
                ) : null}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Page>
  );
};

export default MemberProfileManagementLayout;
