import { FunctionComponent } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Box, Typography } from '@mui/material';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import * as yup from 'yup';
import { StepProps } from 'containers/Wizards/types';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';

interface Props extends StepProps {
  firstName: string;
  lastName: string;
  appName: string;
  onSubmit: (values: any, helpers: FormikHelpers<any>) => void;
  isOpenEnrollmentAllowed: boolean;
}

const initialValues = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
};

const identityConfirmSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  dateOfBirth: yup
    .string()
    .required('Required')
    .matches(
      /^(0?[1-9]|1[0-2])(\/|-|\s|\.)(0?[1-9]|1\d|2\d|3[01])(\/|-|\s|\.)(19|20|)\d{2}$/gi,
      'Must be a valid date. (MM/DD/YYYY)'
    ),
});

const DefaultConfirm: FunctionComponent<Props> = (props) => {
  const { onSubmit, appName, firstName, onPreviousStep, isOpenEnrollmentAllowed } = props;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={identityConfirmSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Box>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Welcome to {appName}
              <br />
              First, let&apos;s get some basic info.
            </Typography>
            <Box marginBottom={3} marginTop={2}>
              <TextFieldComponent label={'First Name'} name={'firstName'} />
              <TextFieldComponent label={'Last Name'} name={'lastName'} />
              <TextFieldComponent label={'Date of Birth'} name={'dateOfBirth'} />
            </Box>
            {isOpenEnrollmentAllowed ? (
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextButtonProps={{
                  type: 'submit',
                }}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={onPreviousStep}
              />
            ) : (
              <PrimaryButtonComponent isLoading={isSubmitting} text="Next" onClick={submitForm} />
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default DefaultConfirm;
