import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import LeadLogoComponent from 'Components/LeadLogoComponent/LeadLogoComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { Box, FormControl, FormGroup, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import useAuth from 'Hooks/useAuth';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  loginErrorMsg: {
    padding: theme.spacing(2),
    color: theme.palette.error.main,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '3px solid #D46833',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'auto',
  },
  links: {
    color: '#3771A1',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('You must enter a username.'),
  password: Yup.string().required('You must enter a password.'),
});
interface LoginValues {
  username: string;
  password: string;
}

const loginInitialValues = {
  username: '',
  password: '',
};

const LoginLayout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  const { login: auth, isLoggedIn, isEmailNotConfirmed, isMemberMaybe, data: loginRes } = useAuth();

  useEffect(() => {
    dispatch(setTitle('Login'));
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
  }, [history, isLoggedIn]);

  return (
    <Formik
      initialValues={loginInitialValues}
      validationSchema={LoginSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        auth(values, false);
      }}
    >
      {({ isSubmitting }: FormikProps<LoginValues>) => (
        <Form>
          <FormControl component={'fieldset'}>
            <LeadLogoComponent />
            <FormGroup>
              <TextFieldComponent
                label={'Username'}
                name={'username'}
                autoComplete={'email'}
                placeholder={'name@example.com'}
              />
              <TextFieldComponent
                label={'Password'}
                name={'password'}
                type={'password'}
                autoComplete={'current-password'}
              />
              {isEmailNotConfirmed && (
                <AlertComponent
                  alertType="error"
                  title="Confirm Email"
                  message={`Your account has been created but we need you to confirm your email address. Check your email inbox for an email from ${appName} asking you to
                  confirm your email address.`}
                />
              )}
              {loginRes &&
                !loginRes.loginSuccessful &&
                !isEmailNotConfirmed &&
                isMemberMaybe === null && (
                  <AlertComponent
                    alertType="error"
                    title="Login Unsuccessful"
                    message="Are you sure your username and password are correct?"
                  />
                )}
              {isMemberMaybe === false && (
                <AlertComponent
                  alertType="error"
                  title="Login Unsuccessful"
                  message="Are you sure you should be logging in from the member portal?"
                />
              )}
              <Box className={classes.wrapper}>
                <PrimaryButtonComponent
                  type={'submit'}
                  text={'Log In'}
                  width={'full'}
                  isLoading={isSubmitting}
                />
                <TertiaryButtonComponent
                  text={'Forgot Password?'}
                  width={'full'}
                  onClick={() => {
                    history.push('login/forgotPassword');
                  }}
                  disabled={isSubmitting}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
};

export default LoginLayout;
