export const KEY_AUTHENTICATED = 'authenticated';
export const KEY_LOGIN_DATA = 'loginData';
export const KEY_AUTH_TOKEN = 'authToken';
export const KEY_APP_INSTALL_SHOWN = 'appInstallShown';
export const KEY_APP_VERSION = 'appVersion';

export const SHARX_PHONE_NUMBER = '(314) 451-3555';
export const scriptCoShieldGroupIdEncoded = '1GCsxyUbAUo';

export const assistanceTypeNdx_Medrop = 'RefMedrop';
export const assistanceTypeNdx_ScriptCo = 'RefScriptCo';
export interface medicationObj {
  cleanDrugName: string;
  scriptCoIDMaybe: number | null;
  sharxIDMaybe: number | null;
  isGenericMaybe: boolean | null;
  brandNamesOfGenericMaybe: string | null;
  genericNameOfBrandMaybe: string | null;
}

export interface providerInfoObj {
  message: string;
  link: string;
  viewableLink: string;
}

export const providerLinkList: { [key: string]: string } = {
  Sharx: '/getHouseholdInfo',
  SharxViewLink: 'the ScriptCo Shield manufacturer program',
  SharxType: 'a ScriptCo Shield manufacturer program',
  ScriptCo: 'https://scriptco.com/join',
  ScriptCoViewLink: 'the ScriptCo Shield generic medications program',
  ScriptCoType: 'ScriptCo generic medications program',
  Medrop: 'https://getmedrop.com/collections/all',
  MedropViewLink: 'the Medrop mail order program',
  MedropType: 'Medrop mail order program',
};

export const providerMessaging: { [key: string]: string } = {
  Sharx: `Numerous manufacturers offer programs that could significantly lower your medication expenses. Click 'get started' to check your eligibility for any available manufacturer programs.`,
  ScriptCo: `For accessing generic medications, the most optimal choice is the ScriptCo generic program. Click 'get started' to open another tab to the ScriptCo generic site. When you are asked to set up a ScriptCo account enter SCRIPTCOSHIELD as your Coupon code.`,
  Medrop: `Both brand name and generic medications can be accessed through a mail order pharmacy. Click 'get started' to open another tab to the Medrop mail order site.`,
};
