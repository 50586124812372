import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import AddressEntryForm from 'Components/AddressEntryForm';
import { Address, useGetHomeAddressQuery, useUpdateHomeAddressMutation } from 'services/person';
import { Box, Typography } from '@mui/material';
import LoadingSpinner from 'Components/LoadingSpinner';
import { PersonalInfoStepProps } from '..';

const AddAddressLayout: FunctionComponent<PersonalInfoStepProps> = ({
  onNextStep,
  onPreviousStep,
  contextData,
}) => {
  const dispatch = useDispatch();
  const { patient } = contextData;
  const { data: address, isLoading: isAddressLoading } = useGetHomeAddressQuery(patient.patientId);
  const [updateHomeAddress] = useUpdateHomeAddressMutation();

  const handleSubmit = async (values: any) => {
    const newAddress = {
      patientPersonId: patient.patientId,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      zip: values.zip,
    };

    await updateHomeAddress(newAddress);
    onNextStep();
  };

  useEffect(() => {
    dispatch(setTitle('Personal Info'));
  }, [dispatch]);

  if (isAddressLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <Typography
        align={'center'}
        variant={'body1'}
        color={'textPrimary'}
        style={{ fontWeight: 'bold' }}
      >
        Home Address
      </Typography>
      <AddressEntryForm
        onSubmit={handleSubmit}
        primaryActionText="Next"
        secondaryActionClick={onPreviousStep}
        address={address as Address}
      />
    </Box>
  );
};

export default AddAddressLayout;
