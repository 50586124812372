import React, { FunctionComponent } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import contactInfoReducer from './Reducers/contactInfoSlice';
import appInfoReducer from './Reducers/appInfoSlice';
import authReducer from './Reducers/authSlice';
import appReducer from './Reducers/appSlice';
import memberInfoReducer from './Reducers/memberSlice';
import personInfoReducer from './Reducers/personInfoSlice';
import drugSearchInfoReducer from './Reducers/drugSearchSlice';
import householdInfoReducer from './Reducers/householdInfoSlice';
import serviceWorkerReducer from './Reducers/srvwkrSlice';

import { userApi } from 'services/user';
import { authApi } from 'services/auth';
import { fileApi } from 'services/file';
import { hipaaAuthApi } from 'services/hipaaAuth';
import { mailOrderApi } from 'services/mailOrder';
import { prescriberApi } from 'services/prescriber';
import { registerApi } from 'services/register';
import { messagingApi } from 'services/messaging';
import { medicationApi } from 'services/medication';
import { whiteLabelApi } from 'services/whiteLabel';
import { personApi } from 'services/person';
import { scriptCoApi } from 'services/scriptCo';
import { utilityApi } from 'services/utility';
interface StoreProps {
  children: React.ReactNode;
}

const store = configureStore({
  reducer: {
    app: appReducer,
    addressInfo: contactInfoReducer,
    appInfo: appInfoReducer,
    householdInfo: householdInfoReducer,
    memberInfo: memberInfoReducer,
    personInfo: personInfoReducer,
    drugSearchInfo: drugSearchInfoReducer,
    auth: authReducer,
    serviceWorker: serviceWorkerReducer,
    [userApi.reducerPath]: userApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [hipaaAuthApi.reducerPath]: hipaaAuthApi.reducer,
    [mailOrderApi.reducerPath]: mailOrderApi.reducer,
    [prescriberApi.reducerPath]: prescriberApi.reducer,
    [registerApi.reducerPath]: registerApi.reducer,
    [messagingApi.reducerPath]: messagingApi.reducer,
    [medicationApi.reducerPath]: medicationApi.reducer,
    [whiteLabelApi.reducerPath]: whiteLabelApi.reducer,
    [personApi.reducerPath]: personApi.reducer,
    [scriptCoApi.reducerPath]: scriptCoApi.reducer,
    [utilityApi.reducerPath]: utilityApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(authApi.middleware)
      .concat(fileApi.middleware)
      .concat(hipaaAuthApi.middleware)
      .concat(mailOrderApi.middleware)
      .concat(prescriberApi.middleware)
      .concat(registerApi.middleware)
      .concat(messagingApi.middleware)
      .concat(medicationApi.middleware)
      .concat(whiteLabelApi.middleware)
      .concat(personApi.middleware)
      .concat(scriptCoApi.middleware)
      .concat(utilityApi.middleware),
});

const Store: FunctionComponent<StoreProps> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export type RootState = ReturnType<typeof store.getState>;

export default Store;
