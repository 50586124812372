import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import { Box, InputAdornment } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import { HouseholdInfo } from 'services/person';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import { StepProps } from 'containers/Wizards/types';

interface HouseholdIncomeLayoutProps extends StepProps {
  onSaveData(payload: { annualIncome: number }): void;
  contextData: HouseholdInfo;
}

interface HouseHoldIncomeLayoutValues {
  annualIncome: number;
}

const houseHoldIncomeSchema = Yup.object().shape({
  annualIncome: Yup.number()
    .typeError('Invalid amount (remove commas if present)')
    .min(1, "Please enter your household's annual income")
    .required('Required'),
});

const HouseHoldIncomeLayout: FunctionComponent<HouseholdIncomeLayoutProps> = ({
  onNextStep,
  onPreviousStep,
  onSaveData,
  contextData,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Patient Assistance Programs'));
  }, [dispatch]);

  return (
    <Formik
      initialValues={{
        annualIncome: contextData.annualIncome ? contextData.annualIncome : 0,
      }}
      validationSchema={houseHoldIncomeSchema}
      onSubmit={async (values, { setSubmitting }) => {
        onSaveData({
          annualIncome: Number(values.annualIncome),
        });

        setSubmitting(false);
        onNextStep();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<HouseHoldIncomeLayoutValues>) => (
        <Form>
          <Box>
            <Box marginBottom={2}>
              <InfoLayoutBodyComponent
                header="What is your household's annual income?"
                paragraph="Include income from your spouse or any other member contributing to your household income."
              />
              <TextFieldComponent
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                label="Household Income"
                name="annualIncome"
              />
            </Box>
            <Box>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={onPreviousStep}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default HouseHoldIncomeLayout;
