import { createSlice } from '@reduxjs/toolkit';

export type MemberInfoState = {
  isMemberAlreadyRegistered: boolean;
  hasAdvocateBeenIntroduced: boolean;
};

const initialState = {
  isMemberAlreadyRegistered: false,
  hasAdvocateBeenIntroduced: false,
} as MemberInfoState;

export const slice = createSlice({
  name: 'memberInfo',
  initialState,
  reducers: {
    setIsMemberAlreadyRegistered: (state, action) => {
      state.isMemberAlreadyRegistered = action.payload;
    },
    setHasAdvocateBeenIntroduced: (state, action) => {
      state.hasAdvocateBeenIntroduced = action.payload;
    },
  },
});

export const { setIsMemberAlreadyRegistered, setHasAdvocateBeenIntroduced } = slice.actions;

export const selectHasAdvocateBeenIntroduced = (state: Record<string, MemberInfoState>): boolean =>
  state.memberInfo.hasAdvocateBeenIntroduced;

export default slice.reducer;
