import { FunctionComponent } from 'react';
import { Box } from '@mui/material';

import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import useAuth from 'Hooks/useAuth';

interface YesNoButtonGroupComponentProps {
  yesOnClickCallback?(): void;
  noOnClickCallback?(): void;
  continueLaterOnClickCallback?(): void;
  yesButtonProps?: { [key: string]: any };
  noButtonProps?: { [key: string]: any };
  continueLaterButtonProps?: { [key: string]: any };
}

const YesNoButtonGroupComponent: FunctionComponent<YesNoButtonGroupComponentProps> = ({
  yesOnClickCallback,
  noOnClickCallback,
  continueLaterOnClickCallback,
  yesButtonProps,
  noButtonProps,
  continueLaterButtonProps,
}) => {
  const { isLoggedIn } = useAuth();

  return (
    <Box
      sx={{
        width: '100%',
        my: ({ spacing }) => spacing(1),
        mx: 'auto',
        maxWidth: ({ spacing }) => spacing(24),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          margin: 'auto',
        }}
      >
        {yesOnClickCallback ? (
          <PrimaryButtonComponent onClick={yesOnClickCallback} {...yesButtonProps}>
            YES
          </PrimaryButtonComponent>
        ) : null}
        {noOnClickCallback ? (
          <SecondaryButtonComponent onClick={noOnClickCallback} {...noButtonProps}>
            NO
          </SecondaryButtonComponent>
        ) : null}
        {continueLaterOnClickCallback && isLoggedIn ? (
          <TertiaryButtonComponent
            onClick={continueLaterOnClickCallback}
            {...continueLaterButtonProps}
          >
            CONTINUE LATER
          </TertiaryButtonComponent>
        ) : null}
      </Box>
    </Box>
  );
};

export default YesNoButtonGroupComponent;
