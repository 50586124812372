import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Box, Typography, List, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import Dialog from 'Components/Dialog';
import { useGetMessagesQuery, useAddMessageMutation } from 'services/messaging';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

import useAuth from 'Hooks/useAuth';
import { useGetUserDetailsQuery } from 'services/user';

const submitMessageSchema = Yup.object().shape({
  message: Yup.string().required('Required').trim(),
});

interface messageValues {
  message: string;
}

const useStyles = makeStyles((theme) => ({
  submitButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertStyle: {
    //backgroundColor: '#3771A1'
  },
  userMessage: {
    gridColumn: '3 / 7',
    flexDirection: 'row-reverse',
  },
  userMessageBubble: {
    background: '#3771A1',
    color: '#FFFFFF',
    borderRadius: '10px',
    padding: theme.spacing(1),
  },
  advocateMessage: {
    gridColumn: '1 / 5',
  },
  advocateMessageBubble: {
    background: '#F6F6F6',
    border: '#F6F6F6',
    borderRadius: '10px',
    padding: theme.spacing(1),
  },
  previousMessages: {
    display: 'flex',
    overflow: 'auto',
    height: '35vh',
    flexDirection: 'column-reverse',
  },
}));

type CustomizedDialogsProps = {
  onClose: () => void;
  open: boolean;
  advocateInfo: any;
};

const MessageDialog: FunctionComponent<CustomizedDialogsProps> = (props) => {
  const { onClose, open, advocateInfo } = props;
  const { advocateFirstName, advocatePhoneNumber } = advocateInfo || {};
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const classes = useStyles();

  const [postAddMessage] = useAddMessageMutation();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: userDetails, refetch: refetchUserDetails } = useGetUserDetailsQuery(personId, {
    skip: !personId,
  });
  const [firstName, setFirstName] = useState(userDetails?.firstName);

  const { data: messages, refetch: refetchMessages } = useGetMessagesQuery();

  useEffect(() => {
    refetchUserDetails();
    setFirstName(userDetails?.firstName);

    refetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseAlert = () => {
    setMessageSent(false);
  };

  const previousMessagesList = messages?.map((message: any, index: any) => {
    if (message?.name === firstName) {
      return (
        <ListItem key={index} className={classes.userMessage}>
          <Box textAlign="right" justifyContent="right">
            <Typography variant={'body2'}>
              <b>{message.name}</b>
            </Typography>
            <Typography variant={'body2'} gutterBottom={true} className={classes.userMessageBubble}>
              {message.message}
            </Typography>
            <Typography variant={'caption'} color={'textPrimary'}>
              {message.date}
            </Typography>
          </Box>
        </ListItem>
      );
    } else {
      return (
        <ListItem key={index} className={classes.advocateMessage}>
          <Box>
            <Typography variant={'body2'}>
              <b>{message?.name}</b>
            </Typography>
            <Typography
              variant={'body2'}
              gutterBottom={true}
              className={classes.advocateMessageBubble}
            >
              {message?.message}
            </Typography>
            <Typography variant={'caption'}>{message?.date}</Typography>
          </Box>
        </ListItem>
      );
    }
  });

  return (
    <Dialog fullWidth title={`Message ${advocateFirstName}`} onClose={onClose} open={open}>
      <Formik
        initialValues={{
          message: '',
        }}
        validationSchema={submitMessageSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);

          postAddMessage({
            name: firstName,
            message: values.message,
          });

          const resetTextField = () => {
            values.message = '';
            setMessageSent(true);
          };
          resetTextField();
        }}
      >
        {({ submitForm }: FormikProps<messageValues>) => (
          <Form>
            <Box className={classes.submitButton}>
              {messages && messages?.length > 0 ? (
                <Box width="100%">
                  <Typography
                    align={'center'}
                    variant={'body1'}
                    color={'textPrimary'}
                    style={{ fontWeight: 'bold' }}
                  >
                    Previous Messages
                  </Typography>
                  <List className={classes.previousMessages}>{previousMessagesList}</List>
                </Box>
              ) : null}
              <TextFieldComponent
                label={'Question/Message'}
                name={'message'}
                variant={'outlined'}
                multiline
                rows={4}
                fullWidth
              />
              {messageSent ? (
                <>
                  <AlertComponent
                    alertType="info"
                    title={`Feel free to call ${advocateFirstName} at ${advocatePhoneNumber}.`}
                    message={`Keep an eye out for an email from ${appName} letting you know ${advocateFirstName} has responded. They will respond within two days, but usually much faster.`}
                    onClose={handleCloseAlert}
                  />
                </>
              ) : null}
              <Box className={classes.submitButton}>
                <PrimaryButtonComponent
                  text={'Submit Message'}
                  type={'reset'}
                  onClick={submitForm}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default MessageDialog;
