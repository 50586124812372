import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useAuthMailOrderInitMutation } from 'services/mailOrder';
import { useGetUserDetailsQuery } from 'services/user';
import useAuth from 'Hooks/useAuth';
import { MailOrderProps } from '../index';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

const confirmNeedType = 'AuthorizeCC';

interface creditCardAuthValues {
  initials: string;
}

const CreditCardAuthLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  contextData,
  onSaveData,
  savedData,
}) => {
  const dispatch = useDispatch();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  const { mailOrderAuthKeystring: mailOrderId } = contextData;

  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const { firstName, lastName } = userDetails || {};
  const userInitials = `${firstName?.[0].toUpperCase()}${lastName?.[0].toUpperCase()}`;

  const [putAuthMailOrderInit] = useAuthMailOrderInitMutation();

  useEffect(() => {
    dispatch(setTitle('Credit Card Authorization'));
  }, [dispatch]);

  const creditCardAuthSchema = Yup.object().shape({
    initials: Yup.string()
      .uppercase()
      .oneOf([userInitials], "Characters don't match your initials")
      .required('Initials Required'),
  });

  return (
    <Formik
      initialValues={{
        initials: (savedData.creditCardAuth as string) || '',
      }}
      validationSchema={creditCardAuthSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSaveData({
          creditCardAuth: values.initials,
        });

        const mailOrderInitInfo = {
          mailOrderId,
          authorizationInitials: values.initials,
          confirmationNeedType: confirmNeedType,
        };

        await putAuthMailOrderInit(mailOrderInitInfo);
        setSubmitting(false);
        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<creditCardAuthValues>) => (
        <Form>
          <Box>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{
                fontWeight: 'bold',
                marginBottom: '1em',
              }}
            >
              Authorize Card Use
            </Typography>
            <Typography
              align={'center'}
              variant={'body2'}
              color={'textSecondary'}
              style={{
                marginBottom: '1em',
              }}
            >
              I, {firstName} {lastName}, hereby authorize {appName} to utilize my credit or debit
              card information for the purchase of mail order prescription medication(s) and
              shipping. I understand that this information will only be used for this order, and any
              future orders will require additional authorization.
            </Typography>
            <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
              <strong>
                I understand that orders using a MasterCard or American Express card will generate
                an additional 2% fee.
              </strong>
            </Typography>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              marginTop={1}
              marginBottom={1}
            >
              <Box flexBasis={'40%'}>
                <TextFieldComponent
                  label={'Initial Here'}
                  name={'initials'}
                  fullWidth={false}
                  inputProps={{ maxLength: 2 }}
                  onInput={(e: { target: { value: string } }) => {
                    e.target.value = e.target.value.slice(0, 2);
                  }}
                />
              </Box>
            </Box>
            <ProgressButtonGroupComponent
              isLoading={isSubmitting}
              nextOnClickCallback={submitForm}
              previousOnClickCallback={previousOnClickCallback}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CreditCardAuthLayout;
