import React, { FunctionComponent, useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import * as yup from 'yup';

import { Box, Typography } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';

import { useGetUserDetailsQuery } from 'services/user';
import {
  RegistrationInfo,
  useRegisterExistingMemberMutation,
  useRegisterNewMemberMutation,
} from 'services/register';
import useAuth from 'Hooks/useAuth';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import LoadingSpinner from 'Components/LoadingSpinner';
import { useHistory } from 'react-router';

interface AccountCreationLayoutProps {
  previousOnClickCallback?(): void;
  onComplete(): void;
  contextData: any;
}

interface AccountCreationValues {
  username: string;
  emailAddress: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
}

const AccountCreationLayout: FunctionComponent<AccountCreationLayoutProps> = ({
  previousOnClickCallback,
  onComplete,
  contextData,
}) => {
  const { personId, groupId, oldIndividualGroupIdMaybe, data, emailToConfirm } = contextData;
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  const [errorMessage, setErrorMsg] = useState<string | undefined>();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: userMemberDetails, isLoading } = useGetUserDetailsQuery(personId, {
    skip: !personId,
  });
  const { firstName = '', lastName = '' } = userMemberDetails || {};
  const [postRegisterNewMember] = useRegisterNewMemberMutation();
  const [postRegisterExistingMember] = useRegisterExistingMemberMutation();
  const { login, data: loginData, isEmailNotConfirmed } = useAuth();

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
  }, [appName, dispatch]);

  const accountCreationSchema = !emailToConfirm
    ? yup.object().shape({
        username: yup.string().required('Required'),
        emailAddress: yup.string().required('Required').email('Must be a valid email address'),
        emailConfirmation: yup
          .string()
          .required('Both email fields must match')
          .when('emailAddress', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref('emailAddress')], 'Both email fields mush match'),
          }),
        password: yup
          .string()
          .required(
            'Password needs to be at least 8 characters long, with a number, uppercase and lowercase, and a special character like an ! or @'
          )
          .min(8, 'Password must be at least 8 characters long')
          .matches(/\d/g, 'Password must contain at least 1 number')
          .matches(
            /[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g,
            'Password must contain at least 1 special character'
          )
          .matches(/[A-Z]/g, 'Password must contain at least 1 uppercase letter')
          .matches(/[a-z]/g, 'Password must contain at least 1 lowercase letter'),
        passwordConfirmation: yup
          .string()
          .required('Both password fields must match')
          .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref('password')], 'Both password fields must match.'),
          }),
      })
    : yup.object().shape({
        username: yup.string().required('Required'),
        password: yup
          .string()
          .required(
            'Password needs to be at least 8 characters long, with a number, uppercase and lowercase, and a special character like an ! or @'
          )
          .min(8, 'Password must be at least 8 characters long')
          .matches(/\d/g, 'Password must contain at least 1 number')
          .matches(
            /[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g,
            'Password must contain at least 1 special character'
          )
          .matches(/[A-Z]/g, 'Password must contain at least 1 uppercase letter')
          .matches(/[a-z]/g, 'Password must contain at least 1 lowercase letter'),
        passwordConfirmation: yup
          .string()
          .required('Both password fields must match')
          .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref('password')], 'Both password fields must match.'),
          }),
      });

  useEffect(() => {
    // This fast-forwards the user to advocate screen ...
    // Should maybe logout/reset on each visit of the registration route?
    if (isEmailNotConfirmed) {
      history.push('/emailConfirmation');
    }
    if (loginData?.loginSuccessful) {
      onComplete();
    }
  }, [history, isEmailNotConfirmed, loginData, onComplete]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Formik
      initialValues={{
        username: '',
        emailAddress: emailToConfirm ? emailToConfirm : '',
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={accountCreationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const registerNewValues: RegistrationInfo = {
          firstName: firstName || data?.firstName,
          lastName: lastName || data?.lastName,
          dateOfBirth: data?.dateOfBirth,
          groupId,
          oldIndividualGroupIdMaybe,
          emailAddress: values.emailAddress,
          confirmEmail: !!emailToConfirm,
          username: values.username,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
        };

        const register =
          data?.registrationType === 'new' ? postRegisterNewMember : postRegisterExistingMember;
        const response: any = await register({
          values: registerNewValues,
          token: data.token,
        });
        if (response.error) {
          if (response.error.data?.userInputValidationError) {
            setErrorMsg(response.error.data.userInputValidationError);
          }
        } else {
          login(
            {
              username: values.username,
              password: values.password,
            },
            false
          );
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<AccountCreationValues>) => (
        <Form>
          <Box marginBottom={1}>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              You will need to set up a unique username for yourself, as well as a secure password
              to access the {appName} portal
            </Typography>
          </Box>
          <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
            Password requirements: at least 8 characters long, with a number, uppercase and
            lowercase, and a special character like an ! or @
          </Typography>
          <Box marginTop={2}>
            <TextFieldComponent
              label={'Username'}
              name={'username'}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  submitForm();
                }
              }}
            />
            <TextFieldComponent
              label={'Password'}
              name={'password'}
              type={'password'}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  submitForm();
                }
              }}
            />
            <TextFieldComponent
              label={'Confirm Password'}
              name={'passwordConfirmation'}
              type={'password'}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  submitForm();
                }
              }}
            />
            {!emailToConfirm ? (
              <>
                <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                  Please enter an email for your account
                </Typography>
                <TextFieldComponent
                  label={'Email Address'}
                  name={'emailAddress'}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      submitForm();
                    }
                  }}
                />
                <TextFieldComponent
                  label={'Confirm Email Address'}
                  name={'emailConfirmation'}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      submitForm();
                    }
                  }}
                />
              </>
            ) : null}
          </Box>
          {errorMessage ? (
            <AlertComponent
              alertType="error"
              title="Registration Unsuccessful"
              message={errorMessage}
              onClose={() => setErrorMsg(undefined)}
            />
          ) : null}
          <ProgressButtonGroupComponent
            isLoading={isSubmitting}
            nextOnClickCallback={submitForm}
            previousOnClickCallback={previousOnClickCallback}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AccountCreationLayout;
