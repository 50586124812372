import React, { useContext } from 'react';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider,
  ThemeOptions,
} from '@mui/material/styles';

import WhiteLabelContext from '../whiteLabel/WhiteLabelContext';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const createCustomTheme = (theme: ThemeOptions): Theme => createTheme(theme);

const ThemeProvider: React.FC<ThemeProviderProps> = (props) => {
  const { children } = props;
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const themeColors = whiteLabelInfo?.colors;

  const theme = createCustomTheme({ palette: themeColors });

  return (
    <StyledEngineProvider>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
