import { useState } from 'react';
import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useCheckIfMemberAlreadyRegisteredQuery } from 'services/register';

import { Box, Typography } from '@mui/material';
import ErrorIconComponent from 'Components/ErrorIconComponent/ErrorIconComponent';

import useStepNavigator from 'containers/Wizards/hooks/useStepNavigator';

import IdentityConfirmLayout from 'containers/Wizards/Account/layouts/IdentifyConfirm';
import AccountCreationLayout from 'containers/Wizards/Account/layouts/AccountCreation';
import QualificationLayout from 'containers/Wizards/Account/layouts/Qualification';
import AlreadyRegisteredLayout from './layouts/AlreadyRegistered';
import { useGetGroupInfoQuery } from 'services/whiteLabel';
import { useValidateEmailLinkQuery } from 'services/auth';
import { WizardProps } from '../types';

import { encodeToHttpSafeBase64String } from 'containers/Common/commonFunctions';
import Wizard from '../Wizard';

const REGISTRATION_TYPE_NEW = 'new';
const REGISTRATION_TYPE_EXISTING = 'existing';

type RegistrationType = typeof REGISTRATION_TYPE_NEW | typeof REGISTRATION_TYPE_EXISTING;

enum Steps {
  QUALIFICATION_QUESTION = 1,
  QUALIFIED,
  IDENTITY_CONFIRM,
  ACCOUNT_CREATION,
  ALREADY_REGISTERED,
}

const COMPONENT_MAP: any = {
  [Steps.QUALIFICATION_QUESTION]: QualificationLayout,
  [Steps.IDENTITY_CONFIRM]: IdentityConfirmLayout,
  [Steps.ACCOUNT_CREATION]: AccountCreationLayout,
  [Steps.ALREADY_REGISTERED]: AlreadyRegisteredLayout,
};

const AccountWizard: FunctionComponent<WizardProps> = (props) => {
  const { onComplete } = props;
  const [data, setData] = useState<{
    registrationType: RegistrationType;
  }>({
    registrationType: REGISTRATION_TYPE_EXISTING,
  });
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const urks = encodeToHttpSafeBase64String(query.get('urks') || '');
  const { data: validationOutput, isLoading: isValidateLoading } = useValidateEmailLinkQuery(urks, {
    skip: !urks,
  });
  const userLinkDetails = validationOutput?.userLinkDetails;
  const personIdFromLink = userLinkDetails?.personId as string;
  const advocateInfo = validationOutput?.advocateInfo;

  const { data: isMemberAlreadyRegistered, isLoading: isMemberAlreadyRegisteredLoading } =
    useCheckIfMemberAlreadyRegisteredQuery(personIdFromLink, {
      skip: !personIdFromLink,
    });

  const groupId = userLinkDetails?.groupId
    ? userLinkDetails.groupId
    : encodeToHttpSafeBase64String(query.get('gid') || query.get('GID') || '');
  const { data: groupInfo, isLoading: isGroupInfoLoading } = useGetGroupInfoQuery(groupId, {
    skip: !groupId,
  });

  const order = isMemberAlreadyRegistered
    ? [Steps.ALREADY_REGISTERED]
    : [
        groupInfo?.isOpenEnrollmentAllowed && Steps.QUALIFICATION_QUESTION, // open enrollment
        Steps.IDENTITY_CONFIRM,
        Steps.ACCOUNT_CREATION,
      ].filter(Boolean);

  const { next, previous, current } = useStepNavigator({ steps: order });

  const handleSaveData = async (payload: any) => {
    setData({
      ...data,
      ...payload,
    });
  };

  const Component = COMPONENT_MAP[current];

  const getErrorText = () => {
    let errorText = 'the phone number in the email with the expired link.';

    if (advocateInfo) {
      errorText = advocateInfo.advocatePhoneNumber;
    }

    if (groupInfo?.hasBeenCanceled) {
      errorText = 'your human resources department.';
    }

    return errorText;
  };

  return (
    <Wizard isLoading={isValidateLoading || isMemberAlreadyRegisteredLoading || isGroupInfoLoading}>
      {userLinkDetails?.hasLinkExpired || groupInfo?.hasBeenCanceled ? (
        <Box>
          <ErrorIconComponent />
          <Typography
            align={'center'}
            variant={'body1'}
            color={'textPrimary'}
            paragraph
            sx={{
              marginBottom: 20,
              fontWeight: 'bold',
            }}
          >
            Uh oh! The link has expired.
            <br />
            To request a new link, please call
            <br />
            {getErrorText()}
          </Typography>
        </Box>
      ) : (
        <Component
          contextData={{
            personId: personIdFromLink,
            groupId: groupInfo?.isCallCentersGroup ? groupInfo.callCentersGroupId : groupId,
            oldIndividualGroupIdMaybe: groupInfo?.oldIndividualGroupIdMaybe,
            groupName: groupInfo?.groupName,
            groupThreshold: groupInfo?.groupThreshold,
            isOpenEnrollmentAllowed: groupInfo?.isOpenEnrollmentAllowed,
            emailToConfirm: userLinkDetails?.emailToConfirm,
            data,
          }}
          nextOnClickCallback={next}
          previousOnClickCallback={previous}
          onComplete={onComplete}
          onSaveData={handleSaveData}
        />
      )}
    </Wizard>
  );
};

export default AccountWizard;
