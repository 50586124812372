import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import { useContext } from 'react';

const useWhiteLabelApp = () => {
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;
  const appLogoPath = whiteLabelInfo?.appLogoPath;
  const faviconPath = whiteLabelInfo?.faviconPath;

  const whiteLabelApp = () => {
    const appTitle = document.getElementById('appTitle') as HTMLTitleElement;
    appTitle.text = appName ? appName : '';

    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    favicon.href = faviconPath ? faviconPath : '';

    const apple_favicon = document.getElementById('apple-favicon') as HTMLLinkElement;
    apple_favicon.href = appLogoPath ? appLogoPath : '';
  };

  return {
    whiteLabelApp,
  };
};

export default useWhiteLabelApp;
