import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { HipaaWizardStepProps } from '..';

const IntroLayout: FunctionComponent<HipaaWizardStepProps> = ({
  onNextStep,
  hipaaAuth,
  memberDetails,
}) => {
  const dispatch = useDispatch();

  const { relationshipToPatient } = hipaaAuth;
  const { firstName, lastName } = memberDetails;

  useEffect(() => {
    dispatch(setTitle('HIPAA Authorization'));
  }, [dispatch]);

  const memberName = `${firstName} ${lastName}`;
  const paragraph =
    relationshipToPatient === 'Self'
      ? `This authorization complies with the Health Insurance Portability
    and Accountability Act of 1996 (HIPAA) Privacy Standards, and enables
    us to ask for and share health information with drug manufacturers
    and doctors when necessary to advocate on your behalf.`
      : `This authorization complies with the Health Insurance Portability
    and Accountability Act of 1996 (HIPAA) Privacy Standards, and enables
    us to ask for and share health information with drug manufacturers
    and doctors when necessary to advocate on ${memberName}'s behalf.`;

  return (
    <Box>
      <InfoLayoutBodyComponent header={<span>HIPAA Authorization</span>} paragraph={paragraph} />
      <ProgressButtonGroupComponent nextOnClickCallback={onNextStep} />
    </Box>
  );
};

export default IntroLayout;
