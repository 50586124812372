import { useEffect, useContext, FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { Theme, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import { useGetAdvocateInfoQuery } from 'services/user';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import LeadLogoComponent from 'Components/LeadLogoComponent/LeadLogoComponent';
import Page from 'Pages/BasePage';

const useStyles = makeStyles((theme: Theme) => ({
  leadIcon: {
    'max-width': '100%',
    'max-height': '20vh',
    background: '#D46833',
    borderRadius: '90px',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  content: {
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '3px solid #3771A1',
    borderRadius: '10px',
    padding: theme.spacing(2),
  },
  typographyPadding: {
    padding: theme.spacing(1),
  },
  links: {
    color: '#3771A1',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const Contact: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;
  const whiteLabelAddress = whiteLabelInfo?.address;
  const whiteLabelCityStateZip = whiteLabelInfo?.cityStateZip;
  const providerIsSHARx = whiteLabelInfo?.providerIsSHARx;
  const { data: advocateInfo } = useGetAdvocateInfoQuery();
  const advocateFirstName = advocateInfo?.advocateFirstName;
  const advocatePhone = advocateInfo?.advocatePhoneNumber;

  const privacyPolicyPath =
    'https://admin.sharxplan.com/static%20files/NOTICE%20OF%20PRIVACY%20RIGHTS%20AND%20PRACTICES%20FOR%20YOUR%20PERSONAL%20INFORMATION.pdf';

  useEffect(() => {
    dispatch(setTitle(`Contact ${appName} Team`));
  });

  return (
    <Page>
      <Box className={classes.content}>
        <LeadLogoComponent />
        <Typography className={classes.typographyPadding}>
          {advocateInfo?.advocateTitle ? advocateInfo.advocateTitle : 'Advocate'}:{' '}
          {advocateFirstName}
        </Typography>
        <Typography className={classes.typographyPadding}>
          Message {advocateInfo?.advocateTitle ? advocateInfo.advocateTitle : 'Advocate'}: Click the{' '}
          {advocateInfo?.advocateTitle ? advocateInfo.advocateTitle : 'Advocate'} Button at the
          bottom of the screen
        </Typography>
        <Typography
          className={classes.typographyPadding}
        >{`Phone Number: ${advocatePhone}`}</Typography>
        <Box className={classes.typographyPadding}>
          <Typography>Address:</Typography>
          <Typography>{`${appName} Member Services`}</Typography>
          <Typography>{whiteLabelAddress}</Typography>
          <Typography>{whiteLabelCityStateZip}</Typography>
        </Box>
        <Typography className={classes.typographyPadding}>
          Your privacy is important to us! All information is kept private and secure for maximum
          protection, and used for the sole purpose of prescription advocacy. None of your
          information will be shared, sold, or distributed for marketing purposes.
          {providerIsSHARx ? (
            <b>
              {' '}
              Please read our{' '}
              <a
                className={classes.links}
                href={privacyPolicyPath}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              for more information.
            </b>
          ) : null}
        </Typography>
      </Box>
    </Page>
  );
};

export default Contact;
