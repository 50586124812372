import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Paper, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import { setTitle } from 'Store/Reducers/appInfoSlice';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import RadioGroupComponent from 'Components/RadioGroupComponent/RadioGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useAuthMailOrderInitMutation, useMedsToVerifyForMOQuery } from 'services/mailOrder';
import useAuth from 'Hooks/useAuth';
import { useGetUserDetailsQuery } from 'services/user';
import { useGetGroupInfoQuery } from 'services/whiteLabel';
import { MailOrderProps } from '../';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

const confirmNeedType = 'SignatureOptOut';

interface HighCostLayoutValues {
  initials: string;
}

const SignatureOptOut: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  contextData,
}) => {
  const dispatch = useDispatch();
  const { mailOrderAuthKeystring: mailOrderId } = contextData;
  const { data: mailOrderMedInfo } = useMedsToVerifyForMOQuery(mailOrderId ?? skipToken);
  const {
    data: { personIdEncryptedMaybe: personId, groupIdEncryptedMaybe: groupId },
  } = useAuth();
  const { data: groupInfoMaybe } = useGetGroupInfoQuery(groupId, {
    skip: !groupId,
  });
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const { firstName, lastName } = userDetails || {};
  const userInitials = `${firstName?.[0].toUpperCase()}${lastName?.[0].toUpperCase()}`;
  const [putAuthMailOrderInit] = useAuthMailOrderInitMutation();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);
  const [optOutAlert, setOptOutAlert] = useState(false);

  const sigOptOutSchema = Yup.object().shape({
    initials: Yup.string()
      .uppercase()
      .oneOf([userInitials], "Characters don't match your initials")
      .required('Initials Required'),
  });

  const firstBulletPoint = () => {
    let oFirstBulletPoint: string;
    if (mailOrderMedInfo?.totalCost) {
      oFirstBulletPoint = `The cost for your order is $${
        mailOrderMedInfo.totalCost
      } plus shipping and is being 
       paid for by ${groupInfoMaybe ? groupInfoMaybe.groupName : 'your group'} on your behalf.`;
    } else if (mailOrderMedInfo?.medicationCost) {
      oFirstBulletPoint = `The cost for your order is $${
        mailOrderMedInfo.medicationCost
      }. This cost does not include 
       the shipping cost. The total order cost is being paid for by 
       ${groupInfoMaybe ? groupInfoMaybe.groupName : 'your group'} on your behalf.`;
    } else {
      oFirstBulletPoint = `The cost for your order is high, thousands of dollars in many cases, and is being 
       paid for by ${groupInfoMaybe ? groupInfoMaybe.groupName : 'your group'} on your behalf.`;
    }

    return oFirstBulletPoint;
  };

  return (
    <Formik
      initialValues={{
        sigOptOutCheckbox: false,
        initials: '',
        chosenSignForPackageMaybe: undefined,
      }}
      validationSchema={sigOptOutSchema}
      onSubmit={async (values, { setSubmitting }) => {
        if (!values.chosenSignForPackageMaybe) {
          setOptOutAlert(true);
          return;
        }
        const isOptOutSignForPackage = values.chosenSignForPackageMaybe === 'false';

        if (isOptOutSignForPackage) {
          const mailOrderInitInfo = {
            mailOrderId,
            authorizationInitials: values.initials,
            confirmationNeedType: confirmNeedType,
          };

          putAuthMailOrderInit(mailOrderInitInfo);
        }

        setSubmitting(false);
        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<HighCostLayoutValues>) => (
        <Form>
          <Box
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              alignItems: 'left',
            }}
          >
            <Typography
              gutterBottom={true}
              sx={{
                align: 'left',
                color: 'textPrimary',
                fontWeight: 'bold',
              }}
              variant={'body1'}
            >
              Signature Opt-Out Policy
            </Typography>
            <Box display={'flex'} marginTop={2} marginBottom={2}>
              <Typography>
                You will be emailed a tracking number for your package. You may change the shipping
                address if you have a nearby neighbor, friend, or family member who would be able to
                sign for your package upon receipt. If you are unable to sign for your package and
                are willing to assume the risks impacting safety of your medications, you may opt
                out of signing for your package.
              </Typography>
            </Box>
            <Paper elevation={2}>
              <Typography
                component={'div'}
                variant={'body1'}
                sx={{
                  overflow: 'scroll',
                  height: '550px',
                  m: '1',
                  p: '1',
                  align: 'left',
                  color: 'textPrimary',
                }}
              >
                <Box
                  sx={{
                    textAlign: 'left',
                    color: 'textPrimary',
                    p: '0.5em',
                    m: '1',
                  }}
                >
                  <ul>
                    <li>
                      By opting out of signing, you accept the responsibility of paying to replace
                      your order if the following circumstance should arise.
                    </li>

                    <li>
                      We do not recommend opting out of signing for this medication due to the risk
                      of the medication getting too warm while sitting outside and during transit.
                    </li>
                    <li>
                      Opting out of signing increases the chance of theft if the package is left
                      unattended after delivery.
                    </li>
                    <li>Opting out of signing increases risk of possible inaccurate delivery.</li>
                    <li>
                      By choosing to opt out of signing, the member agrees that if the courier's
                      tracking shows the order is being held, or a message stating it is
                      undeliverable - ( i.e. attempted delivery, insufficient address or any other
                      notation that they cannot deliver your order), it will be the member's
                      responsibility to contact the courier for next steps.
                    </li>
                    <li>{firstBulletPoint()}</li>
                  </ul>

                  <br />
                </Box>
              </Typography>
            </Paper>
            <RadioGroupComponent
              label={''}
              sx={{ padding: '1em' }}
              name={'chosenSignForPackageMaybe'}
              labelplacement="start"
              radioOptions={[
                { label: 'Sign for package (recommended)', value: 'true' },
                { label: 'OPT-OUT of signing for my package', value: 'false' },
              ]}
            />
            {optOutAlert ? (
              <AlertComponent sx={{ m: 1 }} alertType="error" message="Please select an option" />
            ) : null}
            <Box display={'flex'} marginTop={2}>
              <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                I, {firstName} {lastName}, verify that I have read the Signature Opt-Out Policy
                described above.
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignContent: 'center',
                marginTop: '1',
                marginBottom: '1',
              }}
            >
              <Box
                sx={{
                  flexBasis: '70%',
                }}
              >
                <TextFieldComponent
                  label="Initial Here"
                  name={'initials'}
                  fullWidth={false}
                  inputProps={{ maxLength: 2 }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: '1',
                marginBottom: '1',
              }}
            >
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={previousOnClickCallback}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SignatureOptOut;
