import { FunctionComponent, useEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ImageComponent from 'Components/ImageComponent/ImageComponent';
import MessageDialog from 'Components/MessageDialog/MessageDialog';

import {
  useGetUnreadMessageStatusQuery,
  useMarkAllMessagesAsReadMutation,
} from 'services/messaging';
import { useGetAdvocateInfoQuery } from 'services/user';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '4px',
    padding: theme.spacing(1),
    backgroundColor: '#fff',
    textAlign: 'left',
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    border: 'solid',
    display: 'flex',
    marginBottom: theme.spacing(4),
    boxShadow: '5px 10px 18px #888888',
    cursor: 'pointer',
  },
  rightContainer: {
    marginLeft: '-5px',
  },
}));

const MessageNotificationComponent: FunctionComponent = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: advocateInfo } = useGetAdvocateInfoQuery();

  const { data: unreadMessageStatus, refetch: refectchUnreadMessageStatus } =
    useGetUnreadMessageStatusQuery();
  const [hasUnreadMessage, setUnreadMessageStatus] = useState(unreadMessageStatus);

  const [markAllMessagesAsRead] = useMarkAllMessagesAsReadMutation();

  useEffect(() => {
    refectchUnreadMessageStatus();
    setUnreadMessageStatus(unreadMessageStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setUnreadMessageStatus(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    markAllMessagesAsRead();
  };

  return hasUnreadMessage ? (
    <>
      <MessageDialog open={isModalOpen} onClose={handleModalClose} advocateInfo={advocateInfo} />
      <>
        <Box className={classes.container} onClick={handleModalOpen}>
          <Box
            textAlign="center"
            style={{
              alignSelf: 'center',
              fontSize: '40px',
              width: '33%',
              marginLeft: '-10px',
              marginTop: '7px',
            }}
          >
            <ImageComponent
              src={'/Assets/Images/advocate_abstract_small_orange.png'}
              alt={'small orange single color icon depicting an advocate wearing a headset'}
            />
          </Box>
          <Box className={classes.rightContainer}>
            <Typography variant={'h6'} color={'secondary'}>
              {advocateInfo?.advocateFirstName} messaged you!
            </Typography>
            <Typography variant={'body1'}>Click here to view.</Typography>
          </Box>
        </Box>
      </>
    </>
  ) : null;
};

export default MessageNotificationComponent;
