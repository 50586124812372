import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

export interface File {
  file: string;
  taskKeystring: string;
  needTypeName: string;
}

export interface FileNeedInfo {
  patientPersonId: string;
  taskKeystring: string;
  patientsName: string;
  needTypeName: string;
  description?: string;
  instructions?: string;
  files?: FileForMember[] | null;
}

export interface FileForMember {
  patientPersonID: string;
  fileName: string;
  fileKeystring: string;
  taskKeystring: string;
  needTypeName: string;
  labelForFile: string | null;
  displayTitleOfFile: string | null;
  isFileApproved: boolean;
}

export const fileApi = createApi({
  reducerPath: 'services/file',
  tagTypes: ['AllFiles'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    fileNeeds: builder.query<FileNeedInfo[], void>({
      query: () => {
        return {
          url: 'file/fileNeeds',
          method: 'get',
        };
      },
    }),
    allFileNeedsNotApproved: builder.query<FileNeedInfo[], void>({
      query: () => {
        return {
          url: 'file/notApprovedFileNeeds',
          method: 'get',
        };
      },
      providesTags: ['AllFiles'],
    }),
    allFilesApproved: builder.query<FileForMember[], void>({
      query: () => {
        return {
          url: 'file/allFiles',
          method: 'get',
        };
      },
      providesTags: ['AllFiles'],
    }),
    uploadFiles: builder.mutation({
      query: (uploadedFilesFormData) => {
        return {
          url: 'file/uploadFiles',
          method: 'put',
          data: uploadedFilesFormData,
          header: { 'Content-Type': 'multipart/form-data' },
        };
      },
      invalidatesTags: ['AllFiles'],
    }),
    openFile: builder.query({
      query: (fileKeystring) => {
        return {
          url: `file/openFile/${fileKeystring}`,
          method: 'get',
        };
      },
    }),
    deleteFile: builder.mutation({
      query: (fileKeystring) => {
        return {
          url: `file/delete/${fileKeystring}`,
          method: 'delete',
        };
      },
      invalidatesTags: ['AllFiles'],
    }),
  }),
});

export const {
  useFileNeedsQuery,
  useAllFileNeedsNotApprovedQuery,
  useAllFilesApprovedQuery,
  useUploadFilesMutation,
  useOpenFileQuery,
  useDeleteFileMutation,
} = fileApi;
