import { FunctionComponent, ReactNode, useEffect, useState, useRef, useContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import AppInstallContext, { AppInstallProps } from 'App/contexts/appInstall/AppInstallContext';
import { KEY_APP_INSTALL_SHOWN } from 'App/contexts/constants';
import AppInstallCta from 'Components/AppInstallCta';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide, { SlideProps } from '@mui/material/Slide';
import { isIos, isInStandaloneMode } from 'lib/device';

const AUTO_HIDE_DURATION = 10000;

interface AppInstallProviderProps {
  children: ReactNode;
}

function SlideUpTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function SlideInTransition(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

const AppInstallProvider: FunctionComponent<AppInstallProviderProps> = ({ children }) => {
  const [isPromotedBannerOpen, setIsPromotedBannerOpen] = useState(false);
  const { whiteLabelInfo, isLoading: isWhiteLabelInfoLoading } = useContext(WhiteLabelContext);
  const providerIsSHARx = whiteLabelInfo?.providerIsSHARx;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  // Initialize deferredPrompt for use later to show browser install prompt
  const deferredPromptRef = useRef<BeforeInstallPromptEvent | null>(null);

  const handlePromotedBannerClose = () => {
    setIsPromotedBannerOpen(false);
  };

  const openInstallBanner = () => {
    const appInstallShown = localStorage.getItem(KEY_APP_INSTALL_SHOWN);

    // If banner has already been shown
    // TODO: Maybe reset this at some point?
    if (appInstallShown) {
      return;
    }

    // iOS handling
    if (isIos && isInStandaloneMode) {
      return;
    }

    // Android handling
    if (!isIos && !deferredPromptRef.current) {
      return;
    }

    setIsPromotedBannerOpen(true);

    localStorage.setItem(KEY_APP_INSTALL_SHOWN, '1');
  };

  const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPromptRef.current = e;
  };

  const handleAppInstalled = () => {
    // Hide the app-provided install promotion
    setIsPromotedBannerOpen(false);
    // Clear the deferredPrompt so it can be garbage collected
    deferredPromptRef.current = null;
    // Optionally, send analytics event to indicate successful install
    console.log('PWA was installed');
  };

  const showInstallPrompt = async () => {
    setIsPromotedBannerOpen(false);

    deferredPromptRef?.current?.prompt();
    // Wait for the user to respond to the prompt
    const result = await deferredPromptRef?.current?.userChoice;

    deferredPromptRef.current = null;
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as any);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as any);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const isAppInstallable = !!deferredPromptRef.current && !isInStandaloneMode && !!providerIsSHARx;

  return (
    <AppInstallContext.Provider
      value={{
        isAppInstalled: false,
        isAppInstallable,
        showInstallPrompt,
        openInstallBanner,
      }}
    >
      {children}
      <Snackbar
        open={isPromotedBannerOpen}
        autoHideDuration={AUTO_HIDE_DURATION}
        TransitionComponent={isMobile ? SlideUpTransition : SlideInTransition}
        onClose={handlePromotedBannerClose}
      >
        <Box>
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 4,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handlePromotedBannerClose}
          >
            <CloseIcon />
          </IconButton>
          <AppInstallCta onClick={showInstallPrompt} />
        </Box>
      </Snackbar>
    </AppInstallContext.Provider>
  );
};

export const useAppInstall = (): AppInstallProps => useContext(AppInstallContext);

export default AppInstallProvider;
