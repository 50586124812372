import { useEffect, useContext, FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { Box } from '@mui/material';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { useConfirmEmailMutation } from 'services/auth';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import useAuth from 'Hooks/useAuth';
import { encodeToHttpSafeBase64String } from 'containers/Common/commonFunctions';

const EmailConfirmedLayout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const urks = encodeToHttpSafeBase64String(query.get('urks') || '');
  const { logout } = useAuth();
  const [putConfirmEmail] = useConfirmEmailMutation();

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
    if (urks) {
      putConfirmEmail(urks);
    }
  }, [appName, dispatch, putConfirmEmail, urks]);

  return (
    <>
      <InfoLayoutBodyComponent
        header={<span>Email Confirmed</span>}
        paragraph={
          <span>
            Thank you for confirming your email. You may continue to login to access your account.
          </span>
        }
      />
      <Box
        sx={{
          justifyContent: 'center',
        }}
      >
        <PrimaryButtonComponent
          text={'Go to LOGIN'}
          onClick={() => {
            logout({ redirect: true });
          }}
        />
      </Box>
    </>
  );
};

export default EmailConfirmedLayout;
