import ErrorView from 'Views/ErrorView/ErrorView';
import EmailConfirmationView from 'Views/EmailConfirmationView/EmailConfirmationView';
import LoginView from 'Views/LoginView/LoginView';

import Onboarding from 'Pages/Onboarding';
import Logout from 'Pages/Logout';
import MailOrderPage from 'Pages/MailOrder';
import HipaaPage from 'Pages/Hippa';
import Dashboard from 'Pages/Dashboard';
import FileUpload from 'Pages/FileUpload';
import AddMedication from 'Pages/AddMedication';
import EditMedication from 'Pages/EditMedication';
import Registration from 'Pages/Registration';
import Profile from 'Pages/Profile';
import Contact from 'Pages/Contact';
import MedicationManagement from 'Pages/MedicationManagement';
import FileManagement from 'Pages/FileManagement';
import ProxyAuthApproval from 'Pages/ProxyAuthApproval';
import Welcome from 'Pages/Welcome';
import ScriptCoRegistration from 'Pages/ScriptCoRegistration';
import HouseHoldInfoSC from 'Pages/HouseHoldInfoSC';

interface RouteInfo {
  redirect?: {
    to: string;
  };
  auth?: boolean;
  path: string;
  exact?: boolean;
  showMenu: boolean;
  component?: any;
  showSecondaryMenu?: boolean;
}

const Routes: Array<RouteInfo> = [
  {
    path: '/registration',
    exact: true,
    showMenu: false,
    component: Registration,
    showSecondaryMenu: false,
  },
  {
    path: '/getHouseholdInfo',
    exact: true,
    showMenu: false,
    component: HouseHoldInfoSC,
    showSecondaryMenu: true,
  },
  {
    path: '/addMedication/:personId',
    auth: true,
    exact: true,
    showMenu: true,
    component: AddMedication,
    showSecondaryMenu: false,
  },
  {
    path: '/editMedication/:mid',
    auth: true,
    exact: true,
    showMenu: true,
    component: EditMedication,
    showSecondaryMenu: false,
  },
  {
    path: '/welcome',
    auth: true,
    exact: true,
    showMenu: false,
    component: Welcome,
    showSecondaryMenu: false,
  },

  {
    path: '/mailOrder',
    auth: true,
    exact: true,
    showMenu: true,
    component: MailOrderPage,
    showSecondaryMenu: false,
  },
  {
    path: '/hipaa',
    auth: true,
    exact: true,
    showMenu: true,
    component: HipaaPage,
    showSecondaryMenu: false,
  },
  {
    path: '/fileUpload',
    auth: true,
    exact: true,
    showMenu: true,
    component: FileUpload,
    showSecondaryMenu: false,
  },
  {
    path: '/profile',
    auth: true,
    exact: true,
    showMenu: true,
    component: Profile,
    showSecondaryMenu: false,
  },
  {
    path: '/fileMgmt',
    auth: true,
    exact: true,
    showMenu: true,
    component: FileManagement,
    showSecondaryMenu: false,
  },
  {
    path: '/contact',
    auth: true,
    exact: true,
    showMenu: true,
    component: Contact,
    showSecondaryMenu: false,
  },
  {
    path: '/onboarding/:wizard?',
    auth: true,
    exact: true,
    showMenu: true,
    component: Onboarding,
    showSecondaryMenu: false,
  },
  {
    path: '/error',
    exact: true,
    showMenu: false,
    component: ErrorView,
    showSecondaryMenu: false,
  },
  {
    path: '/login/:layout?',
    showMenu: false,
    component: LoginView,
    showSecondaryMenu: false,
  },
  {
    path: '/emailConfirmation',
    exact: true,
    showMenu: false,
    component: EmailConfirmationView,
    showSecondaryMenu: false,
  },
  {
    path: '/logout',
    exact: true,
    showMenu: false,
    component: Logout,
    showSecondaryMenu: false,
  },
  {
    path: '/medications',
    auth: true,
    exact: true,
    showMenu: true,
    component: MedicationManagement,
    showSecondaryMenu: false,
  },
  {
    path: '/confirm',
    showMenu: false,
    component: ProxyAuthApproval,
    showSecondaryMenu: false,
  },
  {
    path: '/member/UserReg',
    redirect: {
      to: '/registration',
    },
    showMenu: false,
    showSecondaryMenu: false,
  },
  {
    path: '/member/UserRegOpenEmployer',
    redirect: {
      to: '/registration',
    },
    showMenu: false,
    showSecondaryMenu: false,
  },
  {
    path: '/GetStarted',
    redirect: {
      to: '/registration',
    },
    showMenu: false,
    showSecondaryMenu: false,
  },
  {
    path: '/',
    auth: true,
    exact: true,
    showMenu: true,
    showSecondaryMenu: false,
    component: Dashboard,
  },
];

export default Routes;
