import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { Address } from 'services/person';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import SelectComponent from 'Components/SelectComponent/SelectComponent';

interface AddressEntryFormProps {
  onSubmit: (values: Address) => Promise<void>;
  primaryActionText?: string;
  secondaryActionClick?(): void;
  continueLaterClick?(): void;
  address: Address;
}

const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
const EditAddressSchema = Yup.object().shape({
  address1: Yup.string().required('Required'),
  address2: Yup.string().nullable(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string().matches(zipRegExp, '12345-6789 or 12345').required('Required'),
});

const AddressEntryForm: React.FC<AddressEntryFormProps> = (props) => {
  const {
    onSubmit,
    primaryActionText = 'Add Address',
    secondaryActionClick,
    continueLaterClick,
    address = {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    },
  } = props;

  return (
    <Formik
      initialValues={{
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zip: address.zip,
      }}
      validationSchema={EditAddressSchema}
      onSubmit={async (values, { setSubmitting }) => {
        if (onSubmit) {
          await onSubmit(values);
        }
      }}
    >
      {({ isSubmitting, submitForm, errors, values }: FormikProps<Address>) => (
        <Form>
          <Box marginBottom={2} marginTop={2}>
            <Box display={'flex'}>
              <TextFieldComponent label={'Street'} name={'address1'} />
            </Box>
            <Box display={'flex'} marginTop={1}>
              <TextFieldComponent
                label={'Line 2'}
                name={'address2'}
                placeholder="Apt 3, Floor 2, etc."
              />
            </Box>
            <Box display={'flex'} marginTop={1}>
              <TextFieldComponent label={'City'} name={'city'} />
            </Box>
            <Box display={'flex'} marginTop={1}>
              <SelectComponent
                label={'State'}
                name={'state'}
                errorMessage={errors.state}
                value={values.state || ''}
                selectOptions={[
                  { label: 'Alabama', value: 'AL' },
                  { label: 'Alaska', value: 'AK' },
                  { label: 'Arizona', value: 'AZ' },
                  { label: 'Arkansas', value: 'AR' },
                  { label: 'California', value: 'CA' },
                  { label: 'Colorado', value: 'CO' },
                  { label: 'Connecticut', value: 'CT' },
                  { label: 'Delaware', value: 'DE' },
                  { label: 'District of Columbia', value: 'DC' },
                  { label: 'Florida', value: 'FL' },
                  { label: 'Georgia', value: 'GA' },
                  { label: 'Hawaii', value: 'HI' },
                  { label: 'Idaho', value: 'ID' },
                  { label: 'Illinois', value: 'IL' },
                  { label: 'Indiana', value: 'IN' },
                  { label: 'Iowa', value: 'IA' },
                  { label: 'Kansas', value: 'KS' },
                  { label: 'Kentucky', value: 'KY' },
                  { label: 'Louisiana', value: 'LA' },
                  { label: 'Maine', value: 'ME' },
                  { label: 'Maryland', value: 'MD' },
                  { label: 'Massachusetts', value: 'MA' },
                  { label: 'Michigan', value: 'MI' },
                  { label: 'Minnesota', value: 'MN' },
                  { label: 'Mississippi', value: 'MS' },
                  { label: 'Missouri', value: 'MO' },
                  { label: 'Montana', value: 'MT' },
                  { label: 'Nebraska', value: 'NE' },
                  { label: 'Nevada', value: 'NV' },
                  { label: 'New Hampshire', value: 'NH' },
                  { label: 'New Jersey', value: 'NJ' },
                  { label: 'New Mexico', value: 'NM' },
                  { label: 'New York', value: 'NY' },
                  { label: 'North Carolina', value: 'NC' },
                  { label: 'North Dakota', value: 'ND' },
                  { label: 'Ohio', value: 'OH' },
                  { label: 'Oklahoma', value: 'OK' },
                  { label: 'Oregon', value: 'OR' },
                  { label: 'Pennsylvania', value: 'PA' },
                  { label: 'Puerto Rico', value: 'PR' },
                  { label: 'Rhode Island', value: 'RI' },
                  { label: 'South Carolina', value: 'SC' },
                  { label: 'South Dakota', value: 'SD' },
                  { label: 'Tennessee', value: 'TN' },
                  { label: 'Texas', value: 'TX' },
                  { label: 'Utah', value: 'UT' },
                  { label: 'Vermont', value: 'VT' },
                  { label: 'Virginia', value: 'VA' },
                  { label: 'Washington', value: 'WA' },
                  { label: 'West Virginia', value: 'WV' },
                  { label: 'Wisconsin', value: 'WI' },
                  { label: 'Wyoming', value: 'WY' },
                ]}
              />
              <TextFieldComponent label={'Zipcode'} name={'zip'} />
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {secondaryActionClick ? (
              <ProgressButtonGroupComponent
                nextOnClickCallback={submitForm}
                previousOnClickCallback={secondaryActionClick}
              />
            ) : (
              <PrimaryButtonComponent
                text={primaryActionText}
                isLoading={isSubmitting}
                onClick={submitForm}
              />
            )}

            {continueLaterClick ? (
              <TertiaryButtonComponent text={'Continue Later'} onClick={continueLaterClick} />
            ) : null}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddressEntryForm;
