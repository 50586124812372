import { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';

import IntroHasMedsLayout from './layouts/IntroHasMedsLayout';
import IntroNoMedsLayout from './layouts/IntroNoMedsLayout';
import ConfirmMedicationLayout from './layouts/ConfirmMedicationLayout';
import AddMedicationLayout from './layouts/AddMedicationLayout';
import OutroLayout from './layouts/OutroLayout';

import { Prescriber, useGetAllPrescribersQuery } from 'services/prescriber';
import { useGetAllAssistedMedsUserCanSeeQuery } from 'services/medication';
import useStepNavigator from '../hooks/useStepNavigator';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import { useHistory } from 'react-router';
import { StepProps } from '../types';
import { useDispatch } from 'react-redux';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import Wizard from '../Wizard';
import { OnboardingWizardProps } from 'Pages/Onboarding';

enum Steps {
  INTRO_HAS_MEDS = 1,
  INTRO_NO_MEDS,
  CONFIRM_MEDICATION,
  ADD_MEDICATION,
  OUTRO,
}

const COMPONENT_MAP: any = {
  [Steps.INTRO_HAS_MEDS]: IntroHasMedsLayout,
  [Steps.INTRO_NO_MEDS]: IntroNoMedsLayout,
  [Steps.CONFIRM_MEDICATION]: ConfirmMedicationLayout,
  [Steps.ADD_MEDICATION]: AddMedicationLayout,
  [Steps.OUTRO]: OutroLayout,
};

export interface MedicationWizardStepProps extends StepProps {
  contextData: any;
  groupInfo: {
    groupThreshold: string;
    isPapQualified: boolean;
    shouldWeAskMemberAboutSecondaryInsurance: boolean;
    canMemberAddMedications: boolean;
  };
  onPrescriberAdded: (prescriber: Prescriber) => void;
}

const MedicationWizard: FunctionComponent<OnboardingWizardProps> = (props) => {
  const { groupInfoContextData, onComplete, onPrevious } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: currentMedicationsMaybe, isLoading: isCurrentMedicationsLoading } =
    useGetAllAssistedMedsUserCanSeeQuery();
  const { data: queriedPrescribers } = useGetAllPrescribersQuery('');

  const [prescribers, setPrescribers] = useState<Prescriber[]>(queriedPrescribers || []);
  const needToConfirmMedications = currentMedicationsMaybe && currentMedicationsMaybe?.length > 0;

  const contextData = {
    currentMedicationsMaybe,
    prescribers,
  };

  const groupInfo = {
    groupThreshold: groupInfoContextData.groupThreshold,
    isPapQualified: groupInfoContextData.isPapQualified,
    shouldWeAskMemberAboutSecondaryInsurance:
      groupInfoContextData.shouldWeAskMemberAboutSecondaryInsurance,
    canMemberAddMedications: groupInfoContextData.canMemberAddMedications,
  };

  const order = [
    needToConfirmMedications ? Steps.INTRO_HAS_MEDS : Steps.INTRO_NO_MEDS,
    needToConfirmMedications && Steps.CONFIRM_MEDICATION,
    Steps.ADD_MEDICATION,
    Steps.OUTRO,
  ].filter(Boolean);

  const { current, next, previous } = useStepNavigator({ steps: order });

  const handlePrescriberAdded = (prescriber: Prescriber) => {
    setPrescribers([...prescribers, prescriber]);
  };

  const handlePreviousStep = () => {
    if ((current === Steps.INTRO_HAS_MEDS || current === Steps.INTRO_NO_MEDS) && onPrevious) {
      onPrevious();
    } else {
      previous();
    }
  };

  const skipOutroStep =
    !groupInfoContextData.isPapQualified &&
    !groupInfoContextData.shouldWeAskMemberAboutSecondaryInsurance;
  const handleNextStep = () => {
    if (current === Steps.ADD_MEDICATION && skipOutroStep) {
      onComplete();
    } else {
      next();
    }
  };

  useEffect(() => {
    if (queriedPrescribers) {
      setPrescribers(queriedPrescribers);
    }
  }, [queriedPrescribers]);

  const Component: FunctionComponent<MedicationWizardStepProps> = COMPONENT_MAP[current];

  const isLoading = isCurrentMedicationsLoading;

  const handleContinueLater = () => {
    dispatch(setIsNextStepReminderEligible(false));
    history.push('/');
  };

  return (
    <Wizard isLoading={isLoading}>
      <>
        <Component
          contextData={contextData}
          groupInfo={groupInfo}
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
          onComplete={onComplete}
          onPrescriberAdded={handlePrescriberAdded}
        />
        <TertiaryButtonComponent text="Continue Later" onClick={handleContinueLater} />
      </>
    </Wizard>
  );
};

export default MedicationWizard;
