import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, InputAdornment, TextField } from '@mui/material';

import { makeStyles } from '@mui/styles';

import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import SelectComponent from 'Components/SelectComponent/SelectComponent';
import { stateSelectList } from 'App/contexts/stateList';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import {
  setNumberOfAdults,
  setNumberOfChildren,
  setHouseholdIncome,
} from 'Store/Reducers/householdInfoSlice';

import { setUsaState } from 'Store/Reducers/contactInfoSlice';

import { useVerifyPAPforMedsMutation } from 'services/scriptCo';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import {
  medicationsSelected,
  selectSelfServiceDrugList,
  setDrugsHaveBeenQualified,
  sharxDrugsSelected,
} from 'Store/Reducers/drugSearchSlice';
import { GetDrugInfo } from 'services/scriptCo';
// import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

// import { useVerifyPAPforMedsMutation } from 'services/scriptCo';

interface QualificationInfoLayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
}

interface HouseholdInfo {
  numberOfAdults?: string;
  numberOfChildren?: string;
  annualIncome?: string;
  state: string;
}

const HouseHoldSchema = Yup.object().shape({
  numberOfAdults: Yup.number()
    .typeError('Invalid value')
    .integer('Number of Adults must be an integer')
    .min(1, 'Please enter your household size')
    .required('Required'),
  numberOfChildren: Yup.number()
    .typeError('Invalid value')
    .integer('Number of Children must be an integer'),
  annualIncome: Yup.number()
    .typeError('Invalid amount (remove commas if present)')
    .min(1, "Please enter your household's annual income")
    .required('Required'),
  state: Yup.string()
    .typeError('Invalid value')
    .min(1, 'Please select a state of residence')
    .required(),
});
// The state validation is preventing submittion without selecting a state, but is not showing the required messaging.

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  leadIcon: {
    'max-width': '100%',
    'max-height': '20vh',
    background: '#D46833',
    borderRadius: '90px',
    padding: '25px',
    marginBottom: '20px',
  },
  accentText: {
    color: theme.palette.secondary.main,
    'font-weight': 'bold',
  },
}));

const QualificationInfoLayout: FunctionComponent<QualificationInfoLayoutProps> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const dispatch = useDispatch();
  const sharxMedsIds: number[] = [];

  const selfServiceDrugList = useSelector(selectSelfServiceDrugList);

  const medicationsSelectedList = useSelector(medicationsSelected);
  const sharxDrugsSelectedList = useSelector(sharxDrugsSelected);

  for (const currentDrug of sharxDrugsSelectedList) {
    if (currentDrug?.sharxIDMaybe) {
      sharxMedsIds.push(currentDrug.sharxIDMaybe);
    }
  }

  const [postVerify] = useVerifyPAPforMedsMutation();

  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        numberOfAdults: '',
        numberOfChildren: '',
        annualIncome: '',
        state: '',
      }}
      validationSchema={HouseHoldSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const tempNumberOfChildren = values.numberOfChildren ? values.numberOfChildren : 0;
        dispatch(setNumberOfAdults(Number(values.numberOfAdults)));
        dispatch(setNumberOfChildren(Number(tempNumberOfChildren)));
        dispatch(setHouseholdIncome(Number(values.annualIncome)));
        dispatch(setUsaState(String(values.state)));

        const drugQualificationInfo = {
          annualIncome: Number(values.annualIncome),
          numberOfAdults: Number(values.numberOfAdults),
          numberOfChildren: Number(tempNumberOfChildren),
          stateOfResidence: String(values.state),
          drugsToQualify: sharxMedsIds,
        };
        const result = await postVerify(drugQualificationInfo).unwrap();
        dispatch(setDrugsHaveBeenQualified(result));

        onNextStep();
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<HouseholdInfo>) => (
        <Form>
          <Box>
            <Box marginBottom={2}>
              <Box marginBottom={1}>
                <Typography
                  align="center"
                  variant="body1"
                  color="textPrimary"
                  style={{ fontWeight: 'bold' }}
                >
                  How many people live in your household?
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  color="textPrimary"
                  style={{ fontWeight: 'normal' }}
                >
                  (Include only children or relatives that you claimed as dependents on last year’s
                  tax return)
                </Typography>
              </Box>
              <TextFieldComponent label="Number of Adults (including you)" name="numberOfAdults" />
              <TextFieldComponent
                label="Number of Children (17 and under)"
                name="numberOfChildren"
              />
            </Box>
            <Box marginBottom={2}>
              <InfoLayoutBodyComponent
                header="What is your household's annual income?"
                paragraph="Income is based on last year's tax return. Include income from your spouse or any other member contributing to your household income."
              />
              <TextFieldComponent
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                label="Household Income"
                name="annualIncome"
              />
            </Box>
            <Box>
              <InfoLayoutBodyComponent header="What is your state of residence?" />
              <SelectComponent
                label={'State of residence'}
                name={'state'}
                selectOptions={stateSelectList}
              />
            </Box>
            <Box
              className={classes.screenBox}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                previousOnClickCallback={onPreviousStep}
                nextOnClickCallback={submitForm}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default QualificationInfoLayout;
