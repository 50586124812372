import { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import ImageComponent from 'Components/ImageComponent/ImageComponent';

interface LeadImageComponentProps {
  src?: string;
  alt?: string;
  testId?: string;
}

const LeadImageComponent: FunctionComponent<LeadImageComponentProps> = ({
  src,
  alt,
  testId = 'leadImage',
}) => {
  const useStyles = makeStyles((theme) => ({
    leadImage: {
      padding: theme.spacing(1),
      'max-width': '100%',
      'max-height': '20vh',
    },
  }));
  const classes = useStyles();

  return <ImageComponent testId={testId} src={src} alt={alt} className={classes.leadImage} />;
};

export default LeadImageComponent;
