import { FILE_UPLOAD_MAX_SIZE_BYTES } from 'App/Constants';

export const encodeToHttpSafeBase64String = (value: string): string => {
  if (value) {
    const encodedString = value.replace('+', '-').replace('/', '_').replace('=', '');
    return encodedString;
  } else {
    return '';
  }
};

export const displayBooleanAsYesNo = (value: boolean | null | undefined): string => {
  if (value) {
    return 'Yes';
  } else if (value === false) {
    return 'No';
  } else {
    return '';
  }
};

export const validateSizeOfEachUploadedFileInArray = (files: File[]): boolean => {
  const filesExceedingMaxSize = files
    .map((file) => {
      if (file.size > FILE_UPLOAD_MAX_SIZE_BYTES) {
        return file;
      }
    })
    .filter((item) => item !== undefined);

  return filesExceedingMaxSize.length > 0;
};
