import { useState } from 'react';
import { FunctionComponent } from 'react';
import merge from 'lodash.merge';

import useStepNavigator from 'containers/Wizards/hooks/useStepNavigator';

import SecondaryInsuranceLayout from './layouts/MedicaidAndMedicareQuestions';

import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import { useHistory } from 'react-router';
import { Member } from 'services/user';
import { StepProps, WizardProps } from '../types';
import useHouseholdMembersData from 'Hooks/useHouseholdMembersData';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import { useDispatch } from 'react-redux';
import Wizard from '../Wizard';

type SaveDataType = {
  [patientId: string]: {
    isMarried?: boolean;
    isVeteran?: boolean;
    isDisabled?: boolean;
    medicarePartC?: boolean;
    medicarePartD?: boolean;
    medicaid?: boolean;
  };
};

export interface MedicaidAndMedicareCommonProps extends StepProps {
  onSaveData: (payload: SaveDataType) => void;
  savedData: SaveDataType;
  viewableMembers: Member[];
}

enum Steps {
  // ADVOCACY_DETAILS = 1,
  //SECONDARY_INSURANCE = 1,
  MEDICARE_AND_MEDICAID = 1,
}

const COMPONENT_MAP: any = {
  // [Steps.ADVOCACY_DETAILS]: AdvocacyDetailsLayout,
  //[Steps.SECONDARY_INSURANCE]: SecondaryInsuranceCheck,
  [Steps.MEDICARE_AND_MEDICAID]: SecondaryInsuranceLayout,
};

const MedicaidAndMedicare: FunctionComponent<WizardProps> = (props) => {
  const { onComplete, onPrevious } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const { viewableMembers, isLoading } = useHouseholdMembersData();
  const [savedData, setSavedData] = useState({});

  const order = [
    // Steps.ADVOCACY_DETAILS,
    //Steps.SECONDARY_INSURANCE,
    Steps.MEDICARE_AND_MEDICAID,
  ];

  const { next, previous, current } = useStepNavigator({ steps: order });

  const Component: FunctionComponent<MedicaidAndMedicareCommonProps> = COMPONENT_MAP[current];

  const handleSaveData = (payload: Record<string, unknown>) => {
    const data = merge(savedData, payload);
    setSavedData(data);
  };

  const handleContinueLater = () => {
    dispatch(setIsNextStepReminderEligible(false));
    history.push('/');
  };

  const handlePreviousStep = () => {
    if (current === Steps.MEDICARE_AND_MEDICAID && onPrevious) {
      onPrevious();
    } else {
      previous();
    }
  };

  const handleNextStep = () => {
    if (current === Steps.MEDICARE_AND_MEDICAID) {
      onComplete();
      return;
    }

    next();
  };

  return (
    <Wizard isLoading={isLoading}>
      <>
        <Component
          savedData={savedData}
          onSaveData={handleSaveData}
          onPreviousStep={handlePreviousStep}
          onNextStep={handleNextStep}
          onComplete={onComplete}
          viewableMembers={viewableMembers as Member[]}
        />
        <TertiaryButtonComponent text="Continue Later" onClick={handleContinueLater} />
      </>
    </Wizard>
  );
};

export default MedicaidAndMedicare;
