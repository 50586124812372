import { useState, useEffect } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';
import { Add, NorthEast } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import {
  selectDrugsHaveBeenQualified,
  sharxDrugsSelected,
  removeSharxDrugsSelected,
  addSharxDrugsSelected,
  addMedropDrugsSelected,
} from 'Store/Reducers/drugSearchSlice';
import { DrugQualification, GetDrugInfo } from 'services/scriptCo';
import { providerLinkList } from 'App/contexts/constants';

import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

interface AdvocateInfoFPALayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  leadIcon: {
    'max-width': '100%',
    'max-height': '20vh',
    background: '#D46833',
    borderRadius: '90px',
    padding: '25px',
    marginBottom: '20px',
  },
  accentText: {
    color: theme.palette.secondary.main,
    'font-weight': 'bold',
  },
}));

const DeterminPapQualificationLayout: FunctionComponent<AdvocateInfoFPALayoutProps> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const dispatch = useDispatch();
  const drugsHaveBeenQualified = useSelector(selectDrugsHaveBeenQualified);
  const sharxDrugsSelectedList = useSelector(sharxDrugsSelected);
  const [sharxDrugsQualified, setSharxDrugsQualified] = useState<GetDrugInfo[]>([]);
  const [sharxDrugsNotQualified, setSharxDrugsNotQualified] = useState<GetDrugInfo[]>([]);

  useEffect(() => {
    const qualified: GetDrugInfo[] = [];
    const notQualified: GetDrugInfo[] = [];

    drugsHaveBeenQualified.forEach((drug: DrugQualification) => {
      const currentDrug = sharxDrugsSelectedList.find(
        (sharxDrug: GetDrugInfo) => sharxDrug.sharxIDMaybe === drug.sharxDrugID
      );

      const tempDrug = {
        ...currentDrug,
        hasQualifiedForPAP: drug.hasQualifiedForPap,
      };

      if (tempDrug.hasQualifiedForPAP) {
        dispatch(removeSharxDrugsSelected(currentDrug));
        dispatch(addSharxDrugsSelected(tempDrug));
        qualified.push(tempDrug);
      } else {
        dispatch(removeSharxDrugsSelected(currentDrug));
        dispatch(addMedropDrugsSelected(tempDrug));
        notQualified.push(tempDrug);
      }
    });

    setSharxDrugsQualified(qualified);
    setSharxDrugsNotQualified(notQualified);
  }, [drugsHaveBeenQualified]);

  const goToPage = () => {
    const provider = 'Medrop';
    window.open(providerLinkList[`${provider}`], '_blank', 'noopener,noreferrer');
  };

  const continueToSharx = () => {
    onNextStep();
  };

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {sharxDrugsQualified.length > 0 && sharxDrugsNotQualified.length === 0 && (
        <div>
          <Typography
            align={'center'}
            variant={'body1'}
            style={{ fontWeight: 'bold' }}
            color={'textPrimary'}
            paragraph={true}
          >
            Eligible for Manufacturer Program:
          </Typography>
          {sharxDrugsQualified.map((drug: any) => (
            <p key={drug.sharxIDMaybe}>{drug.cleanDrugName}</p>
          ))}
          <Typography align={'center'} variant={'body1'} color={'textSecondary'} paragraph={true}>
            Based on the household information provided, it seems that the medications you are
            presently taking qualify for a manufacturer program. This means you might be eligible
            for either discounted or free medications directly from the manufacturer.
          </Typography>

          <Button onClick={() => continueToSharx()} variant="contained">
            Apply for program
          </Button>
        </div>
      )}

      {sharxDrugsNotQualified.length > 0 && sharxDrugsQualified.length === 0 && (
        <div>
          <Typography
            align={'center'}
            variant={'body1'}
            style={{ fontWeight: 'bold' }}
            color={'textPrimary'}
            paragraph={true}
          >
            Available through Medrop:
          </Typography>
          {sharxDrugsNotQualified.map((drug: any) => (
            <p key={drug.sharxIDMaybe}>{drug.cleanDrugName}</p>
          ))}
          <Typography align={'center'} variant={'body1'} color={'textSecondary'} paragraph={true}>
            Based on your household information, your medications will not qualify for a
            manufacturer program. We recommend you access these medications through our mail order
            pharmacy partner, Medrop.
          </Typography>

          <Button onClick={() => goToPage()} variant="contained">
            Go to Medrop
          </Button>
        </div>
      )}

      {sharxDrugsQualified.length > 0 && sharxDrugsNotQualified.length > 0 && (
        <div>
          <Typography
            align={'center'}
            variant={'body1'}
            style={{ fontWeight: 'bold' }}
            color={'textPrimary'}
            paragraph={true}
          >
            Eligible for Manufacturer Program:
          </Typography>
          {sharxDrugsQualified.map((drug: any) => (
            <p key={drug.sharxIDMaybe}>{drug.cleanDrugName}</p>
          ))}
          <Typography align={'center'} variant={'body1'} color={'textSecondary'} paragraph={true}>
            Based on your household information, it appears only some of your medications qualify
            for a manufacturer program. For these you may be eligible for either discounted or free
            medications directly from the manufacturer.
          </Typography>
          {/* <h3>Eligible for Manufacturer Program:</h3>
          {sharxDrugsQualified.map((drug: any) => (
            <p key={drug.sharxIDMaybe}>{drug.cleanDrugName}</p>
          ))} */}
          <Button onClick={() => continueToSharx()} variant="contained">
            Apply to program
          </Button>
          <Typography
            align={'center'}
            variant={'body1'}
            style={{ fontWeight: 'bold', marginTop: '25px' }}
            color={'textPrimary'}
            paragraph={true}
          >
            Available through Medrop:
          </Typography>

          {sharxDrugsNotQualified.map((drug: any) => (
            <p key={drug.sharxIDMaybe}>{drug.cleanDrugName}</p>
          ))}
          <Typography align={'center'} variant={'body1'} color={'textSecondary'} paragraph={true}>
            For the medications that do not qualify we recommend that you access them through our
            mail order pharmacy partner, Medrop.
          </Typography>

          <Button onClick={() => goToPage()} variant="contained">
            Go to Medrop
          </Button>
        </div>
      )}
    </Box>
  );
};

export default DeterminPapQualificationLayout;
