import WelcomeWizard from 'containers/Wizards/Welcome';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';

const WelcomePage: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleComplete = () => {
    dispatch(setIsNextStepReminderEligible(true));
    history.push('/');
  };

  return <WelcomeWizard onComplete={handleComplete} />;
};

export default WelcomePage;
