import './index.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Root from 'App/Root';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const domain = window.location.hostname;
if (domain.includes('truerx')) {
  document
    .querySelector('#my-manifest-placeholder')
    ?.setAttribute('href', '/manifests/manifest-trueAdvocate.json');
  document.querySelector('#apple-favicon')?.setAttribute('href', '/apple_favicon_TrueAdvocate.png');
  document
    .querySelector('#touch-icon-iphone')
    ?.setAttribute('href', '/trueAdvocate-touch-icon-iphone.png');
  document
    .querySelector('#touch-icon-ipad')
    ?.setAttribute('href', '/trueAdvocate-touch-icon-ipad.png');
  document
    .querySelector('#touch-icon-iphone-retina')
    ?.setAttribute('href', '/trueAdvocate-touch-icon-iphone-retina.png');
  document
    .querySelector('#touch-icon-ipad-retina')
    ?.setAttribute('href', '/trueAdvocate-touch-icon-ipad-retina.png');
} else if (domain.includes('intercept')) {
  document
    .querySelector('#my-manifest-placeholder')
    ?.setAttribute('href', '/manifests/manifest-intercept.json');
  document.querySelector('#apple-favicon')?.setAttribute('href', '/apple_favicon_InterceptRx.png');
  document
    .querySelector('#touch-icon-iphone')
    ?.setAttribute('href', '/intercept-touch-icon-iphone.png');
  document
    .querySelector('#touch-icon-ipad')
    ?.setAttribute('href', '/intercept-touch-icon-ipad.png');
  document
    .querySelector('#touch-icon-iphone-retina')
    ?.setAttribute('href', '/intercept-touch-icon-iphone-retina.png');
  document
    .querySelector('#touch-icon-ipad-retina')
    ?.setAttribute('href', '/intercept-touch-icon-ipad-retina.png');
} else {
  document
    .querySelector('#my-manifest-placeholder')
    ?.setAttribute('href', '/manifests/manifest.json');
  document.querySelector('#apple-favicon')?.setAttribute('href', '/favicon.ico');
  document
    .querySelector('#touch-icon-iphone')
    ?.setAttribute('href', '/sharx-touch-icon-iphone.png');
  document.querySelector('#touch-icon-ipad')?.setAttribute('href', '/sharx-touch-icon-ipad.png');
  document
    .querySelector('#touch-icon-iphone-retina')
    ?.setAttribute('href', '/sharx-touch-icon-iphone-retina.png');
  document
    .querySelector('#touch-icon-ipad-retina')
    ?.setAttribute('href', '/sharx-touch-icon-ipad-retina.png');
}

ReactDOM.render(
  <StrictMode>
    <Root />
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const configuration = {
  onUpdate: (registration: any) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function (names) {
          for (const name of names) caches.delete(name);
        });
      }
      window.location.reload();
    }
  },
};

serviceWorkerRegistration.register(configuration);
