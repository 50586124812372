import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Divider,
  Toolbar,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { makeStyles } from '@mui/styles';
import { setTitle } from 'Store/Reducers/appInfoSlice';
import {
  useAllFileNeedsNotApprovedQuery,
  useAllFilesApprovedQuery,
  useDeleteFileMutation,
} from 'services/file';
import { useGetAllHouseholdMembersQuery } from 'services/user';
import useAuth from 'Hooks/useAuth';
import { ExpandMore } from '@mui/icons-material';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import FileItemComponent from 'Components/FileItemComponent/FileItemComponent';
import FileCard from 'Components/FileCard';
import Page from 'Pages/BasePage';

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: '0px',
    padding: '0px 8px',
    borderRadius: '5px 5px 5px 5px',
    background: theme.palette.common.white,
    '&$expanded': {
      minHeight: '0px',
      padding: '0px 8px',
      borderRadius: '5px 5px 0px 0px',
      background: theme.palette.primary.main,
    },
  },
  content: {
    color: theme.palette.common.black,
    margin: '5px 0px',
    '&$expanded': {
      color: theme.palette.common.white,
      margin: '5px 0px',
    },
  },
  expanded: {},
}))(AccordionSummary);

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 5,
    marginBottom: '0.5em',
  },
  toolbarBtn: {
    marginLeft: 'auto',
  },
}));

const FileMgmtLayout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data: allHouseholdMembers } = useGetAllHouseholdMembersQuery();
  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: fileNeedsNotApproved, isLoading: areFileNeedsLoading } =
    useAllFileNeedsNotApprovedQuery();
  const { data: filesMaybe, isLoading: areFilesLoading } = useAllFilesApprovedQuery();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [deleteFile, { isLoading: isDeletingFile }] = useDeleteFileMutation();
  const [sortedMembers, setSortedMembers] = useState<any[]>();

  useEffect(() => {
    if (filesMaybe && allHouseholdMembers) {
      const processedMembers = allHouseholdMembers.map((member) => {
        return {
          id: member.patientId,
          ...member,
          fileCount: filesMaybe.filter((file) => file.patientPersonID === member.patientId).length,
        };
      });

      setSortedMembers(processedMembers.sort((a: any, b: any) => b.fileCount - a.fileCount));
    }
  }, [personId, allHouseholdMembers, filesMaybe]);

  useEffect(() => {
    dispatch(setTitle('File Management'));
  });

  const handleFileDelete = async (fileKeystring: string) => {
    const response: any = await deleteFile(fileKeystring);
    if (response.error?.data?.userInputValidationError) {
      setErrorMessage(response.error.data.userInputValidationError);
    }
  };

  const handleOnError = (message: string) => {
    setErrorMessage(message);
  };

  return (
    <Page isLoading={areFileNeedsLoading || areFilesLoading}>
      {errorMessage ? (
        <AlertComponent
          alertType="error"
          title="Delete Failed"
          message={errorMessage}
          onClose={() => setErrorMessage(undefined)}
        />
      ) : null}
      <Box>
        <Box>
          {sortedMembers?.map((member, i) => (
            <Accordion
              key={member.id}
              style={{ margin: '5px', minHeight: '0px' }}
              defaultExpanded={i === 0}
            >
              <StyledAccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={'h5'}>{`${member.firstName} ${member.lastName}`}</Typography>
              </StyledAccordionSummary>
              <AccordionDetails style={{ display: 'block', padding: '16px 16px 16px' }}>
                <Box width={'100%'}>
                  {fileNeedsNotApproved &&
                  fileNeedsNotApproved.length > 0 &&
                  fileNeedsNotApproved.filter((need) => need.patientPersonId === member.id).length >
                    0
                    ? fileNeedsNotApproved
                        .filter((need) => need.patientPersonId === member.id)
                        .map((need) => (
                          <>
                            <FileCard
                              fileDelete={handleFileDelete}
                              member={member}
                              title={need.needTypeName}
                              need={need}
                              isLoading={isDeletingFile}
                              onError={handleOnError}
                            />
                          </>
                        ))
                    : null}
                  {filesMaybe && filesMaybe.length > 0 ? (
                    <Card className={classes.card} elevation={5}>
                      <Toolbar>
                        <Typography variant="h6" align="left">
                          Approved Files
                        </Typography>
                      </Toolbar>
                      <Divider />
                      <CardContent>
                        {filesMaybe &&
                        filesMaybe.length > 0 &&
                        filesMaybe.filter((file) => file.patientPersonID === member.id).length > 0
                          ? filesMaybe
                              .filter((file) => file.patientPersonID === member.id)
                              .map((file, idx) => (
                                <Box
                                  key={`${file.fileKeystring} + ${idx}`}
                                  display={'flex'}
                                  flexDirection={'row'}
                                  alignItems={'center'}
                                >
                                  <FileItemComponent
                                    fileKeystring={file.fileKeystring}
                                    fileName={file.fileName}
                                    fileItemComponentNdx={idx}
                                    displayFileName={
                                      file.displayTitleOfFile ? file.displayTitleOfFile : null
                                    }
                                  />
                                </Box>
                              ))
                          : null}
                      </CardContent>
                    </Card>
                  ) : (
                    <Typography variant="h5" align="center" color="textSecondary">
                      No Approved Files
                    </Typography>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Page>
  );
};

export default FileMgmtLayout;
