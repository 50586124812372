import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { slide as Menu } from 'react-burger-menu';

import { selectPhoneNumber, selectTitle } from 'Store/Reducers/appInfoSlice';
import { Link } from 'react-router-dom';
import useAuth from 'Hooks/useAuth';
import { useAppInstall } from 'App/contexts/appInstall/AppInstallProvider';
import AppInstallCta from 'Components/AppInstallCta';
import { KEY_APP_VERSION } from 'App/contexts/constants';

const MENU_ITEMS = [
  { id: 'home', href: '/', label: 'Home' },
  { id: 'profile', href: '/profile', label: 'Profile' },
  { id: 'medications', href: '/medications', label: 'Medications' },
  { id: 'files', href: '/fileMgmt', label: 'Files' },
  { id: 'contact', href: '/contact', label: 'Contact Us' },
  { id: 'logout', href: '/logout', label: 'Logout' },
];
const useStyles = makeStyles((theme) => ({
  content: {
    width: 'fit-content',
    display: 'inline',
  },
  version: {
    width: 'fit-content',
    display: 'inline',
    fontSize: '0.75rem',
    paddingLeft: '0.25rem',
  },
  burger: {
    height: '32px',
    width: 'auto',
    display: 'inline',
  },
  appBar: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  menuItem: {
    color: 'white',
    textDecoration: 'none',
    padding: theme.spacing(1),
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: '1rem',
    '&:hover': {
      backgroundImage: 'linear-gradient(to right, #3771A1, rgba(0, 0, 0, 0));',
    },
    borderRadius: '5px',
  },
  menuDesktop: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    menuDesktop: {
      display: 'block',
    },
    menuMobile: {
      display: 'none',
    },
    menuItem: {
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
  },
}));

interface AppBarComponentProps {
  showMenu?: boolean;
}

const AppBarComponent: FunctionComponent<AppBarComponentProps> = ({ showMenu = true }) => {
  const appTitle = useSelector(selectTitle);
  const appPhoneNumber = useSelector(selectPhoneNumber);
  const { isLoggedIn } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { showInstallPrompt, isAppInstallable } = useAppInstall();
  const foundVersion = localStorage.getItem(KEY_APP_VERSION);

  const styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      right: '15px',
      top: '15px',
    },
    bmBurgerBars: {
      background: '#FFFFFF',
    },
    bmBurgerBarsHover: {
      background: '#a90000',
    },
    bmCrossButton: {
      height: '48px',
      width: '48px',
      right: '16px',
      top: '16px',
    },
    bmCross: {
      background: '#bdc3c7',
      width: '6px',
      height: '28px',
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: '0px',
    },
    bmMenu: {
      background: '#373a47',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
    },
    bmMorphShape: {
      fill: '#373a47',
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em',
    },
    bmItem: {
      display: 'block',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      textDecoration: 'none',
      fontWeight: 'bold',
      fontSize: '1.5rem',
      padding: '16px',
      borderBottom: '1px solid grey',
    },
    bmItemHover: {
      color: 'green',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  };

  const handleStateChange = (state: { isOpen: boolean }) => {
    setIsMenuOpen(state.isOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  const menuItems = MENU_ITEMS.map(({ href, id, label }) => (
    <Link className={classes.menuItem} id={id} to={href} onClick={handleMenuItemClick} key={id}>
      {label}
    </Link>
  ));

  return (
    <AppBar data-testid={'appBar'} position={'sticky'} className={classes.appBar}>
      <Box>
        <Typography className={classes.content} variant={'h5'}>
          {appTitle}
        </Typography>
      </Box>
      {isLoggedIn && showMenu ? (
        <Box>
          <Box className={classes.menuMobile}>
            <Menu onStateChange={handleStateChange} isOpen={isMenuOpen} right styles={styles}>
              {menuItems}
              {isAppInstallable ? (
                <Box sx={{ my: 2 }}>
                  <AppInstallCta onClick={showInstallPrompt} />
                </Box>
              ) : null}
            </Menu>
          </Box>
          <Box className={classes.menuDesktop}>{menuItems}</Box>
        </Box>
      ) : undefined}
      {isLoggedIn && showMenu === false ? (
        <Link className={classes.menuItem} to={'/logout'} onClick={handleMenuItemClick}>
          Logout
        </Link>
      ) : undefined}
    </AppBar>
  );
};

export default AppBarComponent;
