import { FunctionComponent, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { useEditPersonDetailsMutation } from 'services/user';
import RadioGroupComponent from 'Components/RadioGroupComponent/RadioGroupComponent';
import { useUpdateSsnMutation } from 'services/person';
import { PersonDetails } from 'services/user';
import Dialog from 'Components/Dialog';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

const ssnRegExp =
  /^((?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4})|((?!219 09 9999|078 05 1120)(?!666|000|9\d{2})\d{3} (?!00)\d{2} (?!0{4})\d{4})|((?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4})$/;
const EditPersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  middleName: Yup.string().nullable().notRequired(),
  preferredName: Yup.string().nullable().notRequired(),
  suffix: Yup.string().nullable().notRequired(),
  gender: Yup.string().required('Required'),
  dateOfBirth: Yup.string()
    .required('Required')
    .matches(
      /^(0?[1-9]|1[0-2])(\/|-|\s|\.)(0?[1-9]|1\d|2\d|3[01])(\/|-|\s|\.)(19|20|)\d{2}$/gi,
      'Must be a valid date. (MM/DD/YYYY)'
    ),
  ssn: Yup.string()
    .nullable()
    .matches(ssnRegExp, 'Invalid value')
    .min(9, 'Invalid value')
    .max(11, 'Invalid value'),
});

interface EditPersonalInfoValues {
  firstName: string;
  preferredName?: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  gender: string;
  dateOfBirth: string;
  ssn: string;
}

type CustomizedDialogsProps = {
  onClose: () => void;
  open: boolean;
  contextData: any;
};

const EditPersonalInfoDialog: FunctionComponent<CustomizedDialogsProps> = (props) => {
  const { onClose, open, contextData } = props;

  const [editPersonalDetails] = useEditPersonDetailsMutation();
  const [editSsn] = useUpdateSsnMutation();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const personId = contextData?.personId;

  const isSsnDisabled = !!contextData?.ssn;
  const isGenderDisabled = !!contextData?.gender;
  const isDOBDisabled = !!contextData?.dateOfBirth;
  const isFirstNameDisabled = !!contextData?.firstName;
  const isLastNameDisabled = !!contextData?.lastName;
  const isAnyInputsDisabled =
    isSsnDisabled || isGenderDisabled || isDOBDisabled || isFirstNameDisabled || isLastNameDisabled;

  return (
    <Dialog title="Edit Personal Information" onClose={onClose} open={open}>
      <Formik
        initialValues={{
          firstName: contextData?.firstName,
          preferredName: contextData?.preferredName,
          middleName: contextData?.middleName,
          lastName: contextData?.lastName,
          suffix: contextData?.suffix,
          gender: contextData?.gender,
          dateOfBirth: contextData?.dateOfBirth,
          ssn: contextData?.ssn,
        }}
        validationSchema={EditPersonalInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const personalInfo: PersonDetails = {
            firstName: values.firstName,
            preferredName: values.preferredName === '' ? null : values.preferredName,
            middleName: values.middleName === '' ? null : values.middleName,
            lastName: values.lastName,
            suffix: values.suffix === '' ? null : values.suffix,
            gender: values.gender,
            dateOfBirth: values.dateOfBirth,
          };

          await editPersonalDetails({
            patientPersonId: personId,
            personalDetails: personalInfo,
          });
          const response: any = await editSsn({
            patientPersonId: personId,
            ssn: values.ssn,
          });

          if (response.error) {
            if (response.error.data?.userInputValidationError) {
              setErrorMsg(response.error.data.userInputValidationError);
            }
          } else {
            setErrorMsg(undefined);
            onClose();
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }: FormikProps<EditPersonalInfoValues>) => (
          <Form>
            <Box>
              <Box marginBottom={3} marginTop={2}>
                {isAnyInputsDisabled ? (
                  <Typography>
                    If any information is incorrect, please reach out to your advocate.
                  </Typography>
                ) : null}
                <TextFieldComponent
                  label={'First Name'}
                  name={'firstName'}
                  disabled={isFirstNameDisabled}
                />
                <TextFieldComponent
                  label={'Preferred Name (if different than first)'}
                  name={'preferredName'}
                />
                <TextFieldComponent label={'Middle Name'} name={'middleName'} />
                <TextFieldComponent
                  label={'Last Name'}
                  name={'lastName'}
                  disabled={isLastNameDisabled}
                />
                <TextFieldComponent label={'Suffix (Jr, Sr, III, etc.)'} name={'suffix'} />
                <TextFieldComponent
                  label={'Date of Birth'}
                  name={'dateOfBirth'}
                  disabled={isDOBDisabled}
                />
                <TextFieldComponent label={'SSN'} name={'ssn'} disabled={isSsnDisabled} />
                <RadioGroupComponent
                  label={'Gender'}
                  name={'gender'}
                  labelPlacement="start"
                  disabled={isGenderDisabled}
                  radioOptions={[
                    { label: 'Female', value: 'F' },
                    { label: 'Male', value: 'M' },
                  ]}
                />
              </Box>
            </Box>
            {errorMsg ? <AlertComponent alertType="error" message={errorMsg} /> : null}
            <PrimaryButtonComponent
              text="Save Personal Info"
              onClick={submitForm}
              width="auto"
              isLoading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditPersonalInfoDialog;
