import { FunctionComponent, useContext } from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { isIos, isInStandaloneMode } from 'lib/device';

import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import LoadingSpinner from 'Components/LoadingSpinner';

interface AppInstallCtaProps {
  onClick: () => void;
}

const AppInstallCta: FunctionComponent<AppInstallCtaProps> = ({ onClick }) => {
  const isIosInstallable = isIos && !isInStandaloneMode;

  const { whiteLabelInfo, isLoading: isWhiteLabelInfoLoading } = useContext(WhiteLabelContext);
  const providerIsSHARx = whiteLabelInfo?.providerIsSHARx;
  const appName = whiteLabelInfo?.name;

  let appIconPath = '/pwa-icons/sharx/SHARx192_trans.png';
  let tapSharxIconPath = '/Assets/Images/ios-share-icon.png';
  if (providerIsSHARx === false) {
    appIconPath = '/pwa-icons/scriptCo/ScriptCo_Shield_192x_trans.png';
    tapSharxIconPath = '/pwa-icons/scriptCo/ScriptCo_Shield_192x_trans.png';
  }

  if (isWhiteLabelInfoLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        p: 2,
        borderWidth: '1px',
        borderTopStyle: 'solid',
        borderColor: (theme) => theme.palette.grey[400],
        backgroundColor: '#fff',
        borderStyle: {
          sm: 'solid',
        },
        borderRadius: {
          sm: '8px',
        },
        display: {
          sm: 'flex',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mb: 2,
        }}
      >
        <Box
          sx={{
            pr: 2,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <AppIcon src={appIconPath} />
        </Box>
        <Box
          sx={{
            pr: {
              sm: 2,
            },
          }}
        >
          <Typography variant="h6">{appName}</Typography>
          <Typography>
            Install the app for easier access
            {isIosInstallable && (
              <>
                . Tap the <img width="16" src={tapSharxIconPath} /> and then Add to homescreen
              </>
            )}
          </Typography>
        </Box>
      </Box>
      {!isIosInstallable && (
        <Box
          sx={{
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button variant="contained" onClick={onClick}>
            Install
          </Button>
        </Box>
      )}
    </Box>
  );
};

const AppIcon = styled('img')(({ theme }) => ({
  width: '64px',
  height: '64px',
  borderRadius: '8px',
  padding: '4px',
  backgroundColor: theme.palette.grey[300],
}));

export default AppInstallCta;
