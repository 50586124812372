import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import { Box, Typography } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { HouseholdInfo, useUpdateHouseholdInformationMutation } from 'services/person';
import { StepProps } from 'containers/Wizards/types';

interface HouseholdSizeLayoutProps extends StepProps {
  onSaveData(payload: { numberOfAdults: number; numberOfChildren: number }): void;
  contextData: HouseholdInfo;
}

interface HouseHoldSizeLayoutValues {
  numberOfAdults: number;
  numberOfChildren: number;
}

const HouseHoldSizeSchema = Yup.object().shape({
  numberOfAdults: Yup.number()
    .typeError('Invalid value')
    .integer('Number of Adults must be an integer')
    .min(1, 'Please enter your household size')
    .required('Required'),
  numberOfChildren: Yup.number()
    .typeError('Invalid value')
    .integer('Number of Children must be an integer')
    .required('Required'),
});

const HouseHoldSizeLayout: FunctionComponent<HouseholdSizeLayoutProps> = ({
  onNextStep,
  onPreviousStep,
  onSaveData,
  contextData,
}) => {
  const dispatch = useDispatch();
  const [updateHouseholdInfo] = useUpdateHouseholdInformationMutation();

  useEffect(() => {
    dispatch(setTitle('Patient Assistance Programs'));
  }, [dispatch]);
  return (
    <Formik
      initialValues={{
        numberOfAdults: contextData.numberOfAdults ? contextData.numberOfAdults : 0,
        numberOfChildren: contextData.numberOfChildren ? contextData.numberOfChildren : 0,
      }}
      validationSchema={HouseHoldSizeSchema}
      onSubmit={async (values, { setSubmitting }) => {
        onSaveData({
          numberOfAdults: Number(values.numberOfAdults),
          numberOfChildren: Number(values.numberOfChildren),
        });
        const updatedHouseholdInfo: HouseholdInfo = {
          annualIncome: contextData.annualIncome,
          numberOfAdults: Number(values.numberOfAdults),
          numberOfChildren: Number(values.numberOfChildren),
          isContributingToHSAMaybe: contextData.isContributingToHSAMaybe,
        };

        await updateHouseholdInfo(updatedHouseholdInfo);

        onNextStep();
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<HouseHoldSizeLayoutValues>) => (
        <Form>
          <Box>
            <Box marginBottom={2}>
              <Box marginBottom={1}>
                <Typography
                  align="center"
                  variant="body1"
                  color="textPrimary"
                  style={{ fontWeight: 'bold' }}
                >
                  How many people live in your household?
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  color="textPrimary"
                  style={{ fontWeight: 'normal' }}
                >
                  (Include only children or relatives that you claimed as dependents on last year’s
                  tax return)
                </Typography>
              </Box>
              <TextFieldComponent label="Number of Adults (including you)" name="numberOfAdults" />
              <TextFieldComponent
                label="Number of Children (17 and under)"
                name="numberOfChildren"
              />
            </Box>
            <Box>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={onPreviousStep}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default HouseHoldSizeLayout;
