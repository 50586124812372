import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'appInfo',
  initialState: {
    title: 'Home',
    phoneNumber: '',
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
  },
});
export const { setTitle, setPhoneNumber } = slice.actions;

export const setTitleAync = (payload: string) => (dispatch: any) => {
  setTimeout(() => {
    dispatch(setTitle(payload));
  }, 1000);
};

export const setPhoneNumberAync = (payload: string) => (dispatch: any) => {
  setTimeout(() => {
    dispatch(setPhoneNumber(payload));
  }, 1000);
};

export const selectPhoneNumber = (state: Record<string, any>) => state.appInfo.title;

export const selectTitle = (state: Record<string, any>) => state.appInfo.title;

export default slice.reducer;
