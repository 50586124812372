import { FunctionComponent, useState, useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';
import SelectComponent from 'Components/SelectComponent/SelectComponent';
import AddPrescriberDialog from 'Components/AddPrescriberDialog/AddPrescriberDialog';
import EditPrescriberDialog from 'Components/EditPrescriberDialog';

import { useGetAllPrescribersQuery } from 'services/prescriber';
import {
  Medication,
  useGetMedicationGvnIdQuery,
  useEditMedicationMutation,
} from 'services/medication';
import useGetPrescriberInfoById from 'Hooks/useGetPresciberInfoById';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import Page from 'Pages/BasePage';
import Wizard from 'containers/Wizards/Wizard';

interface Drug {
  name: string;
  id: number | null;
}

interface EditMedicationValues {
  drug?: Drug | undefined;
  recipient?: string;
  prescriberId?: string;
}

interface EditMedicationParams {
  mid: string;
}

const addPrescriptionSchema = yup.object().shape({
  prescriberId: yup.mixed().required('Required'),
});

const EditMedication: FunctionComponent = () => {
  const { mid } = useParams<EditMedicationParams>();
  const medicationId = mid;
  const history = useHistory();
  const dispatch = useDispatch();

  const [isAddPrescriberDialogOpen, setAddPrescriberDialogOpen] = useState<boolean>(false);
  const [isEditPrescriberDialogOpen, setEditPrescriberDialogOpen] = useState<boolean>(false);

  const { data: queriedPrescribers = [], isLoading: isLoadingPrescribers } =
    useGetAllPrescribersQuery('');
  const { data: medicationInfo, isLoading: isLoadingMedicationInfo } =
    useGetMedicationGvnIdQuery(medicationId);
  const [selectedPrescriber, setSelectedPrescriber] = useState<string>(
    medicationInfo?.prescriberId ? medicationInfo.prescriberId : ''
  );
  const { prescriberInfo, isLoadingPrescriberInfo } = useGetPrescriberInfoById(
    selectedPrescriber || medicationInfo?.prescriberId,
    { skip: !selectedPrescriber || !medicationInfo?.prescriberId }
  );

  const [putEditMedication] = useEditMedicationMutation();

  const handlePrescriberAdded = (id: string) => {
    setSelectedPrescriber(id);
  };

  const handleAddPrescriberDialogClose = () => {
    setAddPrescriberDialogOpen(false);
  };

  const handleEditPrescriberDialogClose = () => {
    setEditPrescriberDialogOpen(false);
  };

  const prescriberSelectOptions = useMemo(() => {
    return (
      queriedPrescribers?.map((prescriber: any) => ({
        label: `${prescriber.firstName} ${prescriber.lastName}`,
        value: prescriber.prescriberId,
      })) || []
    );
  }, [queriedPrescribers]);

  const handleAddPrescriberClick = () => {
    setAddPrescriberDialogOpen(true);
  };

  const handleEditPrescriberClick = () => {
    setEditPrescriberDialogOpen(true);
  };

  const handleSubmit = async (values: any) => {
    const updatedMedication: Medication = {
      medicationId: medicationId,
      patientPersonId: medicationInfo?.patientPersonId || '',
      drugId: medicationInfo?.drugId || null,
      name: medicationInfo?.name || '',
      strength: medicationInfo?.strength || null,
      quantity: medicationInfo?.quantity || null,
      inhaler: medicationInfo?.inhaler || false,
      tempSensitive: medicationInfo?.tempSensitive || false,
      papQualified: medicationInfo?.papQualified || false,
      prescriberId: values.prescriberId || medicationInfo?.prescriberId || '',
      assistanceType: medicationInfo?.assistanceType || null,
      priorAuthDetailsMaybe: medicationInfo?.priorAuthDetailsMaybe || null,
      new: false,
      authorizedToView: true,
      medicationName: medicationInfo?.name || '',
      assistanceTypeNdxMaybe: medicationInfo?.assistanceTypeNdxMaybe || '',
      isPrescriberRequired: true,
    };

    await putEditMedication(updatedMedication);
    history.push('/medications');
  };

  const handleCancellation = () => {
    history.push('/medications');
  };

  useEffect(() => {
    dispatch(setTitle('Edit Medication'));
  }, [dispatch]);

  return (
    <Wizard isLoading={isLoadingPrescribers || isLoadingMedicationInfo || isLoadingPrescriberInfo}>
      <AddPrescriberDialog
        onClose={handleAddPrescriberDialogClose}
        open={isAddPrescriberDialogOpen}
        onPrescriberAdded={handlePrescriberAdded}
      />
      <EditPrescriberDialog
        onClose={handleEditPrescriberDialogClose}
        open={isEditPrescriberDialogOpen}
        prescriberInfo={prescriberInfo}
        turnOffEditName={true}
      />
      <Formik
        enableReinitialize
        initialValues={{
          prescriberId: selectedPrescriber || medicationInfo?.prescriberId,
        }}
        validationSchema={addPrescriptionSchema}
        onSubmit={(values: EditMedicationValues) => {
          handleSubmit(values);
        }}
      >
        {({ submitForm, isSubmitting, errors }: FormikProps<EditMedicationValues>) => (
          <Form>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              sx={{ fontWeight: 'bold', mb: 2 }}
            >
              Please provide the doctor who prescribed the medication
            </Typography>
            <Typography>
              Medication: {medicationInfo?.name || medicationInfo?.medicationName}{' '}
              {medicationInfo?.strength}
            </Typography>
            <Box
              sx={{
                my: 3,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <SelectComponent
                  label={'Who is the doctor/prescriber?'}
                  name={'prescriberId'}
                  errorMessage={errors.prescriberId}
                  selectOptions={prescriberSelectOptions}
                  onChange={(e: { target: { value: string } }) => {
                    setSelectedPrescriber(e.target.value);
                  }}
                />
                <SecondaryButtonComponent
                  text={'Add...'}
                  width={'auto'}
                  style={{
                    margin: '0',
                    marginBottom: '3px',
                    marginLeft: '8px',
                  }}
                  onClick={handleAddPrescriberClick}
                />
              </Box>
            </Box>
            {prescriberInfo && (
              <>
                <Typography
                  align={'center'}
                  variant={'body1'}
                  color={'textPrimary'}
                  style={{ fontWeight: 'bold' }}
                >
                  Is the prescriber information correct?
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    my: 2,
                  }}
                >
                  <Box
                    sx={{
                      my: 1,
                      marginLeft: 'auto',
                    }}
                  >
                    <Typography variant={'body2'}>
                      Doctor/Prescriber:{' '}
                      {prescriberInfo
                        ? prescriberInfo?.firstName + ' ' + prescriberInfo?.lastName
                        : 'No doctor selected'}
                    </Typography>
                    <Typography variant={'body2'}>
                      Phone Number:{' '}
                      {prescriberInfo?.phoneNumber
                        ? prescriberInfo?.phoneNumber
                        : 'No phone number'}
                    </Typography>
                  </Box>
                  <SecondaryButtonComponent
                    text={'Edit...'}
                    width={'auto'}
                    style={{
                      margin: '0',
                      marginBottom: '3px',
                      marginLeft: 'auto',
                    }}
                    onClick={handleEditPrescriberClick}
                  />
                </Box>
              </>
            )}
            <ProgressButtonGroupComponent
              isLoading={isSubmitting}
              nextOnClickCallback={submitForm}
              previousOnClickCallback={handleCancellation}
              nextButtonProps={{
                text: 'Submit',
              }}
              previousButtonProps={{
                text: 'Cancel',
              }}
            />
          </Form>
        )}
      </Formik>
    </Wizard>
  );
};

export default EditMedication;
