import { FunctionComponent, useContext } from 'react';

import LeadImageComponent from 'Components/LeadImageComponent/LeadImageComponent';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

const LeadLogoComponent: FunctionComponent = () => {
  const { isLoading, whiteLabelInfo } = useContext(WhiteLabelContext);

  return (
    <>
      {isLoading ? undefined : (
        <LeadImageComponent
          testId={'logo'}
          src={whiteLabelInfo?.logoPath}
          alt={`${whiteLabelInfo?.name} logo`}
        />
      )}
    </>
  );
};

export default LeadLogoComponent;
