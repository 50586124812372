import { FunctionComponent, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ImageComponent from 'Components/ImageComponent/ImageComponent';

interface AppBarComponentProps {
  showMenu?: boolean;
}

const useStyles = makeStyles((theme) => ({
  appBarLogoContainer: {
    marginTop: '10px',
    marginLeft: '100px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '50px',
    },
  },
  appBarBorderLine: {
    width: '100%',
    borderBottom: '1px solid #3771A1',
    height: '5px',
  },
  leadImage: {
    padding: theme.spacing(1),
    'max-width': '100%',
    'max-height': '20vh',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '15vh',
    },
  },
}));

const SecondaryAppBarComponent: FunctionComponent<AppBarComponentProps> = ({ showMenu = true }) => {
  const logoName = 'ScriptCo Logo Small.png';
  const scriptCoLogoPath = `${process.env.REACT_APP_LOGO_PATH}${logoName}`;
  const styles = useStyles();

  return (
    <div>
      <div className={styles.appBarLogoContainer}>
        <ImageComponent
          testId={'logo'}
          src={scriptCoLogoPath}
          alt={`ScriptCo logo`}
          className={styles.leadImage}
        />
      </div>
      <div className={styles.appBarBorderLine}></div>
    </div>
  );
};

export default SecondaryAppBarComponent;
