import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';

interface IntroPAPLayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
  onComplete(): void;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const IntroPAPLayout: FunctionComponent<IntroPAPLayoutProps> = ({ onNextStep, onPreviousStep }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(setTitle('Patient Assistance Programs'));
  }, [dispatch]);

  return (
    <Box className={classes.screenBox}>
      <InfoLayoutBodyComponent
        header="Patient Assistance Programs"
        paragraph="Pharmaceutical manufacturers may sponsor patient assistance
         programs (PAPs) to provide free medications to those who qualify.
          The following questions regarding household size and income will help determine eligibility for the program."
      />
      <ProgressButtonGroupComponent
        nextOnClickCallback={onNextStep}
        previousOnClickCallback={onPreviousStep}
      />
    </Box>
  );
};

export default IntroPAPLayout;
