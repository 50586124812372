import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

export interface RegistrationInfo {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  groupId: string;
  oldIndividualGroupIdMaybe: number | undefined;
  emailAddress: string;
  confirmEmail: boolean;
  username: string;
  password: string;
  passwordConfirmation: string;
}

export const registerApi = createApi({
  reducerPath: 'services/register',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    verifyRegistration: builder.mutation({
      query: (registrationVerifications) => {
        return {
          url: 'register/verify',
          method: 'post',
          data: registrationVerifications,
        };
      },
    }),
    registerNewMember: builder.mutation({
      query: (payload) => {
        const { values } = payload;
        return {
          url: 'register/new',
          method: 'post',
          data: values,
        };
      },
    }),
    registerExistingMember: builder.mutation({
      query: (payload) => {
        const { values, token } = payload;

        return {
          url: 'register/existing',
          method: 'post',
          data: values,
          headers: { Authorization: token },
        };
      },
    }),
    checkIfMemberAlreadyRegistered: builder.query({
      query: (personId) => {
        return {
          url: `register/check-if-registered/${personId}`,
          method: 'get',
        };
      },
    }),
    postValidateEmail: builder.mutation({
      query: (validationInfo) => {
        return {
          url: 'login/validate-email',
          method: 'post',
          data: validationInfo,
        };
      },
    }),
    sendEmailVerificationCode: builder.mutation({
      query: (emailValidationInfo) => {
        return {
          url: 'login/send-email-verification-code',
          method: 'post',
          data: emailValidationInfo,
        };
      },
    }),
  }),
});

// postValidateEmail should be in auth service

export const {
  useVerifyRegistrationMutation,
  useRegisterExistingMemberMutation,
  useRegisterNewMemberMutation,
  useCheckIfMemberAlreadyRegisteredQuery,
  usePostValidateEmailMutation,
  useSendEmailVerificationCodeMutation,
} = registerApi;
