import { FunctionComponent, ChangeEvent } from 'react';
import { useField, FieldInputProps } from 'formik';
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';
import { useErrorMessage } from 'Hooks';

interface CheckboxComponentProps extends Partial<FieldInputProps<boolean>> {
  name: string;
  label: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom' | undefined;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  onChangeCallback?(event: ChangeEvent<HTMLInputElement>, checked: boolean): void;
  [transferProps: string]: any;
}

const CheckboxComponent: FunctionComponent<CheckboxComponentProps> = ({
  name,
  label,
  labelPlacement = 'end',
  disabled = false,
  required = false,
  helperText,
  onChangeCallback = undefined,
  ...transferProps
}) => {
  const [field, meta, helpers] = useField(name);
  const errorMessage = useErrorMessage(meta);
  const isInErrorState = !!errorMessage;
  const errorMessageOrHelperText = errorMessage || helperText;

  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (typeof onChangeCallback === 'function') {
      onChangeCallback(event, checked);
    } else {
      helpers.setValue(checked);
    }
  };

  return (
    <FormControl error={isInErrorState}>
      <FormControlLabel
        label={label + (required ? ' *' : '')}
        labelPlacement={labelPlacement}
        control={
          <Checkbox
            name={name}
            disabled={disabled}
            required={required}
            checked={field.value}
            onChange={handleChange}
            {...transferProps}
          />
        }
      />
      {errorMessageOrHelperText && <FormHelperText>{errorMessageOrHelperText}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxComponent;
