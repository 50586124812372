import { FunctionComponent } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Box, Typography } from '@mui/material';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import * as yup from 'yup';
import { StepProps } from 'containers/Wizards/types';

interface Props extends StepProps {
  firstName: string;
  lastName: string;
  appName: string;
  onSubmit: (values: any, helpers: FormikHelpers<any>) => void;
}

const ssnRegex = /^[0-9]*$/;

const identityConfirmSchema = yup.object().shape({
  ssnLastFour: yup
    .string()
    .required('Required')
    .matches(ssnRegex, 'Invalid value. Please only enter numbers.')
    .typeError('Provide the last 4 digits of your SSN.')
    .min(4, 'Provide the last 4 digits of your SSN.')
    .max(4, 'Provide only the last 4 digits of your SSN.'),
});

const SSNConfirm: FunctionComponent<Props> = (props) => {
  const { firstName, lastName, appName, onSubmit } = props;

  const initialValues = {
    ssnLastFour: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={identityConfirmSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Box>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Welcome to {appName}, {firstName}
              <br />
              Please verify your identity by entering the last 4 digits of your social security
              number.
            </Typography>
            <Typography align={'center'} variant={'body2'} color={'textSecondary'} paragraph>
              If you are not {firstName} {lastName}, please contact your HR department.
            </Typography>
            <Box marginBottom={3} marginTop={2}>
              <TextFieldComponent
                label={'Last 4 Digits of SSN'}
                name={'ssnLastFour'}
                type={'password'}
                inputProps={{
                  maxLength: 4,
                }}
              />
            </Box>
            <ProgressButtonGroupComponent
              isLoading={isSubmitting}
              nextButtonProps={{
                type: 'submit',
              }}
              nextOnClickCallback={submitForm}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SSNConfirm;
