import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Box } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { ContactInformation } from 'services/person';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import SelectComponent from 'Components/SelectComponent/SelectComponent';
import CheckboxComponent from 'Components/CheckboxComponent/CheckboxComponent';
import {
  PreferredContactType,
  useGetContactInformationQuery,
  useUpdateContactInfoMutation,
} from 'services/person';
import { PersonalInfoStepProps } from '..';

interface PreferredContactValues {
  preferredContact?: PreferredContactType;
  updatesInSpanish?: boolean;
}

const preferredContactSchema = Yup.object().shape({
  preferredContact: Yup.mixed().required('Required'),
});

const PreferredContactLayout: FunctionComponent<PersonalInfoStepProps> = ({
  onNextStep,
  onPreviousStep,
  contextData,
}) => {
  const dispatch = useDispatch();
  const [postUpdateContactInfo] = useUpdateContactInfoMutation();

  const { patient } = contextData;
  const { data: contactInformation } = useGetContactInformationQuery(patient.patientId, {
    skip: !patient.patientId,
  });
  const {
    phonePrimaryIsCell: phoneIsCell,
    preferredContact,
    preferredSpanishUpdates: preferSpanish,
  } = contactInformation || {};

  useEffect(() => {
    dispatch(setTitle('Personal Info'));
  }, [dispatch, phoneIsCell, contactInformation]);

  if (phoneIsCell === false) {
    return (
      <Box>
        <InfoLayoutBodyComponent
          header={<span>Your primary phone is not a cell phone.</span>}
          paragraph={
            <span>
              We&apos;ll make sure to contact you about important updates via your email address.
            </span>
          }
        />
        <ProgressButtonGroupComponent
          nextOnClickCallback={onNextStep}
          previousOnClickCallback={onPreviousStep}
        />
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        preferredContact: preferredContact || 'Text',
        updatesInSpanish: !!preferSpanish,
      }}
      validationSchema={preferredContactSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const updatedContactInfo: ContactInformation = {
          patientPersonId: patient.patientId,
          name: contactInformation?.name || '',
          phonePrimary: contactInformation?.phonePrimary as string,
          phoneSecondary: contactInformation?.phoneSecondary,
          phonePrimaryIsCell: contactInformation?.phonePrimaryIsCell as boolean,
          phoneSecondaryIsCell: contactInformation?.phoneSecondaryIsCell as boolean,
          emailPrimary: contactInformation?.emailPrimary as string,
          emailSecondary: contactInformation?.emailSecondary,
          preferredContact: values.preferredContact,
          preferredSpanishUpdates: values.updatesInSpanish,
        };
        postUpdateContactInfo({
          patientPersonId: patient.patientId,
          contactInfo: updatedContactInfo,
        });

        onNextStep();
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm, errors }: FormikProps<PreferredContactValues>) => (
        <Form>
          <InfoLayoutBodyComponent
            header={<span>Would you prefer to receive status updates via text or email?</span>}
            paragraph={<span>The updates are to let you know medications have shipped, etc.</span>}
          />
          <Box marginBottom={3} marginTop={2}>
            <SelectComponent
              label={'Preferred contact method'}
              name={'preferredContact'}
              errorMessage={errors.preferredContact}
              selectOptions={[
                { label: 'Text', value: 'Text' },
                { label: 'Email', value: 'Email' },
              ]}
            />
            <CheckboxComponent label="My preferred language is Spanish" name="updatesInSpanish" />
          </Box>
          <ProgressButtonGroupComponent
            isLoading={isSubmitting}
            nextOnClickCallback={submitForm}
            previousOnClickCallback={onPreviousStep}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PreferredContactLayout;
