import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface InfoLayoutBodyComponentProps {
  header?: React.ReactNode;
  paragraph?: React.ReactNode;
}

const InfoLayoutBodyComponent: FunctionComponent<InfoLayoutBodyComponentProps> = ({
  header,
  paragraph,
}) => {
  const useStyles = makeStyles((theme) => ({
    infoBox: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  return (
    <Box data-testid={'infoLayoutBody'} className={classes.infoBox}>
      {header ? (
        <Typography
          align={'center'}
          variant={'body1'}
          style={{ fontWeight: 'bold' }}
          color={'textPrimary'}
          paragraph={true}
        >
          {header}
        </Typography>
      ) : null}
      {paragraph ? (
        <Typography align={'center'} variant={'body1'} color={'textSecondary'} paragraph={true}>
          {paragraph}
        </Typography>
      ) : null}
    </Box>
  );
};

export default InfoLayoutBodyComponent;
