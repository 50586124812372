import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';
import { AdvocateInfo } from 'services/user';

interface UserLinkDetails {
  personId: string;
  groupId: string;
  emailToConfirm: string;
  hasLinkExpired: boolean;
}

export interface ValidationOutput {
  userLinkDetails: UserLinkDetails;
  advocateInfo: AdvocateInfo;
}

export const authApi = createApi({
  reducerPath: 'services/auth',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => {
        return {
          url: 'login',
          method: 'post',
          data: credentials,
        };
      },
    }),
    logout: builder.mutation({
      query: () => {
        return {
          url: 'login/logout',
          method: 'post',
        };
      },
    }),
    validateEmailLink: builder.query<ValidationOutput, string>({
      query: (userIdentifyingLink) => {
        return {
          url: 'register/validateLink',
          method: 'get',
          params: {
            urks: userIdentifyingLink,
          },
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (username) => {
        return {
          url: 'login/forgotPassword',
          method: 'post',
          params: {
            username: username,
          },
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (resetPswdInfo) => {
        return {
          url: 'login/resetPassword',
          method: 'put',
          data: resetPswdInfo,
        };
      },
    }),
    confirmEmail: builder.mutation({
      query: (userIdentifyingLink) => {
        return {
          url: 'login/confirmEmail',
          method: 'put',
          data: { urks: userIdentifyingLink },
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useValidateEmailLinkQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useConfirmEmailMutation,
} = authApi;
