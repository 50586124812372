import { encodeToHttpSafeBase64String } from 'containers/Common/commonFunctions';
import useAuth from 'Hooks/useAuth';
import React from 'react';
import { useLocation } from 'react-router';
import { useValidateEmailLinkQuery } from 'services/auth';

import { useGetWhiteLabelInfoGvnGroupQuery, useGetWhiteLabelInfoQuery } from 'services/whiteLabel';
import WhiteLabelContext, { defaultValue as fallbackWhiteLabel } from './WhiteLabelContext';

interface WhiteLabelProviderProps {
  children: React.ReactNode;
}

const WhiteLabelProvider: React.FC<WhiteLabelProviderProps> = (props) => {
  const { children } = props;

  const { data: defaultWhiteLabel, isLoading: isLoadingDefaultWhiteLabel } =
    useGetWhiteLabelInfoQuery();
  const { data: { groupIdEncryptedMaybe: groupIdFromAuth } = {} } = useAuth();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const memberKeystring = encodeToHttpSafeBase64String(query.get('urks') || '');
  const { data: validationOutput } = useValidateEmailLinkQuery(memberKeystring, {
    skip: !memberKeystring,
  });
  const userLinkDetails = validationOutput?.userLinkDetails;

  const groupIdFromLink = userLinkDetails?.groupId
    ? userLinkDetails.groupId
    : encodeToHttpSafeBase64String(query.get('gid') || query.get('GID') || '');

  const groupId = groupIdFromAuth ? groupIdFromAuth : groupIdFromLink;

  const { data: groupWhiteLabel, isLoading: isLoadingGroupWhiteLabel } =
    useGetWhiteLabelInfoGvnGroupQuery(groupId, {
      skip: !groupId,
    });

  const whiteLabel = groupWhiteLabel || defaultWhiteLabel;
  let value = fallbackWhiteLabel;

  if (whiteLabel) {
    const {
      advocacyProviderName: name,
      appLogoName: appLogoName,
      webLogoName: logoName,
      faviconFileName: faviconFileName,
      address1And2: address,
      cityStateZip: cityStateZip,
      phoneNumber: phoneNumber,
      providerIsSHARx: providerIsSHARx,
      providerIsTrueAdvocate: providerIsTrueAdvocate,
    } = whiteLabel;

    value = {
      ...fallbackWhiteLabel,
      name,
      appLogoPath: `${process.env.REACT_APP_LOGO_PATH}${appLogoName}`,
      logoPath: `${process.env.REACT_APP_LOGO_PATH}${logoName}`,
      faviconPath: `${process.env.REACT_APP_LOGO_PATH}${faviconFileName}`,
      address,
      cityStateZip,
      phoneNumber,
      providerIsSHARx,
      providerIsTrueAdvocate,
    };
  }

  const isLoading = isLoadingDefaultWhiteLabel || isLoadingGroupWhiteLabel;

  return (
    <WhiteLabelContext.Provider value={{ isLoading, whiteLabelInfo: value }}>
      {children}
    </WhiteLabelContext.Provider>
  );
};

export default WhiteLabelProvider;
