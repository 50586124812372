import { useEffect, FunctionComponent } from 'react';

import useAuth from 'Hooks/useAuth';
import Page from 'Pages/BasePage';

const Logout: FunctionComponent = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout({ redirect: true });
  }, []);

  return <Page>Logging out</Page>;
};

export default Logout;
