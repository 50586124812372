import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { selectIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import { Link } from 'react-router-dom';
import useNextStep from 'containers/NextStep/hooks/useNextStep';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '4px',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    textAlign: 'left',
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    border: 'solid',
    display: 'flex',
    marginBottom: theme.spacing(4),
    boxShadow: '5px 10px 18px #888888',
  },
  icon: {
    fontSize: '4rem',
  },
  rightContainer: {
    marginLeft: theme.spacing(2),
  },
}));

const NextStepBannerComponent: FunctionComponent = () => {
  const isNextStepReminderEligible = useSelector(selectIsNextStepReminderEligible);
  const styles = useStyles();
  const { href, nextStep } = useNextStep();

  if (isNextStepReminderEligible) {
    return null;
  }

  return href ? (
    <Link to={href}>
      <Box className={styles.container}>
        <Box textAlign="center" style={{ alignSelf: 'center', fontSize: '40px', width: '33%' }}>
          <NotificationsIcon className={styles.icon} color="secondary" />
        </Box>
        <Box className={styles.rightContainer}>
          <Typography variant={'h6'} color={'secondary'}>
            Don&apos;t Forget!
          </Typography>
          <Typography variant={'body1'}>
            We still need some things from you, click here to continue {nextStep}.
          </Typography>
        </Box>
      </Box>
    </Link>
  ) : null;
};

export default NextStepBannerComponent;
