import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Formik, Form, FormikProps } from 'formik';
import { Box, Typography } from '@mui/material';
import * as yup from 'yup';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

import { setRegistrationTempToken } from 'Store/Reducers/authSlice';
import { setPersonIDEncrypted } from 'Store/Reducers/personInfoSlice';
import { setFirstName, setLastName, setPersonDOB } from 'Store/Reducers/personInfoSlice';

import { useVerifyRegistrationMutation } from 'services/register';
import { scriptCoShieldGroupIdEncoded } from 'App/contexts/constants';

interface VerifyEligibilityLayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
}

interface AccountInfo {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

const initialValues = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
};

const identityConfirmSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  dateOfBirth: yup
    .string()
    .required('Required')
    .matches(
      /^(0?[1-9]|1[0-2])(\/|-|\s|\.)(0?[1-9]|1\d|2\d|3[01])(\/|-|\s|\.)(19|20|)\d{2}$/gi,
      'Must be a valid date. (MM/DD/YYYY)'
    ),
});

const VerifyEligibilityLayout: FunctionComponent<VerifyEligibilityLayoutProps> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [errorMessage, setErrorMsg] = useState<string | undefined>();

  const [verifyRegistration] = useVerifyRegistrationMutation();

  const setNotEligibleAlert = () => {
    history.push('./notEligible');
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={identityConfirmSchema}
      onSubmit={async (values, { setSubmitting }) => {
        dispatch(setFirstName(values.firstName));
        dispatch(setLastName(values.lastName));
        dispatch(setPersonDOB(values.dateOfBirth));

        const response: any = await verifyRegistration({
          dateOfBirth: values.dateOfBirth,
          ssnLastFour: null,
          FirstName: values.firstName,
          LastName: values.lastName,
          personId: null,
          GroupId: scriptCoShieldGroupIdEncoded,
        });

        if (response && response.data?.registrationToken && response.data?.personIdEncrypted) {
          await dispatch(setRegistrationTempToken(response.data.registrationToken));
          await dispatch(setPersonIDEncrypted(response.data.personIdEncrypted));

          onNextStep();
          setSubmitting(false);
        } else {
          setSubmitting(false);
          setErrorMsg(
            'We could not find an eligible member matching the information that you provided. Please, check your information. If you believe you are receiving this message in error, there could be a delay. Try again tomorrow'
          );
        }
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<AccountInfo>) => (
        <Form>
          <Box>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              <br />
              First, let&apos;s get some basic info to verify your eligilibity.
            </Typography>
            <Box marginBottom={3} marginTop={2}>
              <TextFieldComponent label={'First Name'} name={'firstName'} />
              <TextFieldComponent label={'Last Name'} name={'lastName'} />
              <TextFieldComponent label={'Date of Birth'} name={'dateOfBirth'} />
            </Box>

            {errorMessage ? (
              <AlertComponent
                alertType="error"
                title="Verification failed"
                message={errorMessage}
                onClose={() => setErrorMsg(undefined)}
              />
            ) : null}
            <ProgressButtonGroupComponent
              isLoading={isSubmitting}
              nextButtonProps={{
                type: 'submit',
              }}
              nextOnClickCallback={submitForm}
              previousOnClickCallback={onPreviousStep}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default VerifyEligibilityLayout;
