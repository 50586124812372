import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

export interface WhiteLabelInfo {
  advocacyProviderName: string;
  appLogoName: string;
  webLogoName: string;
  faviconFileName: string;
  address1And2: string;
  cityStateZip: string;
  phoneNumber: string;
  providerIsSHARx: boolean;
  providerIsTrueAdvocate: boolean;
}

export interface GroupInfo {
  groupName: string;
  groupThreshold: string;
  isOpenEnrollmentAllowed: string;
  requestedToNotHaveHsaClause: boolean;
  isCallCentersGroup: boolean;
  callCentersGroupId: string;
  oldIndividualGroupIdMaybe: number | undefined;
  hasBeenCanceled: boolean;
  membersGroupSendsClaims: boolean;
  shouldWeAskMemberAboutSecondaryInsurance: boolean;
  canMemberAddMedications: boolean;
}

export const whiteLabelApi = createApi({
  reducerPath: 'services/whiteLabel',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getGroupInfo: builder.query<GroupInfo, string>({
      query: (groupId) => {
        return {
          url: `group/info/${groupId}`,
          method: 'get',
        };
      },
    }),
    getWhiteLabelInfo: builder.query<WhiteLabelInfo, void>({
      query: () => {
        return {
          url: 'whiteLabel',
          method: 'get',
        };
      },
    }),
    getWhiteLabelInfoGvnGroup: builder.query<WhiteLabelInfo, string>({
      query: (groupId) => {
        return {
          url: 'whiteLabel/gvnGroupId',
          method: 'get',
          params: {
            groupId: groupId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetGroupInfoQuery,
  useGetWhiteLabelInfoQuery,
  useGetWhiteLabelInfoGvnGroupQuery,
} = whiteLabelApi;
