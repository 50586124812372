import { useState, FunctionComponent } from 'react';

import { useGetSsnQuery } from 'services/person';
import { useGetPersonalDetailsQuery } from 'services/user';
import EditPersonalInfoDialog from 'Components/EditPersonalInfoDialog/EditPersonalInfoDialog';
import InfoCardPrimary from 'Components/InfoCardPrimary';

interface ProfilePersonalInfoCardProps {
  personId: string;
}

const ProfilePersonalInfoCard: FunctionComponent<ProfilePersonalInfoCardProps> = (props) => {
  const { personId } = props;
  const { data: ssn } = useGetSsnQuery(personId);

  const lastFourSsn = ssn?.ssn?.slice(-4);
  const { data: personalDetails, isLoading } = useGetPersonalDetailsQuery(personId);
  const { firstName, lastName, gender, preferredName, middleName, suffix, dateOfBirth } =
    personalDetails || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const contextData = {
    personId: personId,
    firstName: firstName,
    preferredName: preferredName,
    middleName: middleName,
    lastName: lastName,
    suffix: suffix,
    gender: gender,
    dateOfBirth: dateOfBirth,
    ssn: ssn?.ssn,
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <EditPersonalInfoDialog
        open={isModalOpen}
        onClose={handleModalClose}
        contextData={contextData}
      />
      <InfoCardPrimary
        title="Personal Details"
        isLoading={isLoading}
        onActionClick={handleModalOpen}
        contentItems={[
          {
            title: `Name: `,
            content: `${firstName} ${lastName}`,
          },
          {
            title: `Gender: `,
            content: `${gender ? (gender === 'F' ? 'Female' : 'Male') : ''}`,
          },
          {
            title: `Birth Date: `,
            content: `${dateOfBirth ? dateOfBirth : ''}`,
          },
          lastFourSsn
            ? {
                title: `SSN: `,
                content: `***-**-${lastFourSsn}`,
              }
            : null,
        ].filter(Boolean)}
      />
    </>
  );
};

export default ProfilePersonalInfoCard;
