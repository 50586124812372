import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ImageComponent from 'Components/ImageComponent/ImageComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { useGetAdvocateInfoQuery } from 'services/user';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

interface AdvocateInfoFPALayoutProps {
  onComplete(): void;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  leadIcon: {
    'max-width': '100%',
    'max-height': '20vh',
    background: '#D46833',
    borderRadius: '90px',
    padding: '25px',
    marginBottom: '20px',
  },
  accentText: {
    color: theme.palette.secondary.main,
    'font-weight': 'bold',
  },
}));

const ScriptCoCompleteLayout: FunctionComponent<AdvocateInfoFPALayoutProps> = ({ onComplete }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <InfoLayoutBodyComponent
        header={
          <span>
            Thanks, we have all the information we need from you regarding Patient Assistance
            Programs.
          </span>
        }
        paragraph={
          <span>
            We will reach out soon with a status update. Please contact ScriptCo if you have any
            questions.
          </span>
        }
      />
      <ProgressButtonGroupComponent nextOnClickCallback={onComplete} />
    </Box>
  );
};

export default ScriptCoCompleteLayout;
