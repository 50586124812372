import { FunctionComponent } from 'react';
import { Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import EditIcon from '@mui/icons-material/Edit';

interface InfoCardProps {
  contentItems?: ({ title: string; content: string } | null)[];
  isLoading?: boolean;
  onActionClick?: () => void;
  title: string;
}

const InfoCardPrimary: FunctionComponent<InfoCardProps> = ({
  contentItems,
  isLoading,
  onActionClick,
  title,
}) => {
  return (
    <>
      <Card
        sx={{
          textAlign: 'left',
        }}
      >
        <CardHeader
          title={isLoading ? <Skeleton /> : title}
          action={
            isLoading ? (
              <Skeleton variant="circular" />
            ) : onActionClick ? (
              <IconButton aria-label="edit" onClick={onActionClick}>
                <EditIcon />
              </IconButton>
            ) : null
          }
        />
        <CardContent>
          {contentItems && contentItems.length > 0
            ? contentItems.map((item, index) => {
                return (
                  <div key={index}>
                    <Typography display="inline" variant="body2" style={{ fontWeight: 500 }}>
                      {isLoading ? <Skeleton /> : item?.title}
                    </Typography>
                    <Typography display="inline" variant="body2">
                      {isLoading ? <Skeleton /> : item?.content}
                    </Typography>
                  </div>
                );
              })
            : 'No information'}
        </CardContent>
      </Card>
    </>
  );
};

export default InfoCardPrimary;
