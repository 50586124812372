import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { TEXT_FIELD_MAX_LENGTH_DESCRIPTION } from 'App/Constants';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import CheckboxComponent from 'Components/CheckboxComponent/CheckboxComponent';
import { MailOrderProps } from '../';

interface AllergiesLayoutValues {
  allergies: string | null;
  noAllergies: boolean;
}

const allergiesSchema = Yup.object().shape({
  noAllergies: Yup.bool(),
  allergies: Yup.string().max(
    TEXT_FIELD_MAX_LENGTH_DESCRIPTION,
    'Maximum number of characters exceeded'
  ),
});

const AllergiesLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  onSaveData,
  savedData,
  contextData,
}) => {
  const dispatch = useDispatch();
  const { isLoggedInUserAlsoPatient, patientFirstName } = contextData;

  const drugAllergiesMaybe = contextData?.drugAllergiesMaybe || '';
  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const paragraph = isLoggedInUserAlsoPatient
    ? `Enter any drug allergies you have. If you do not have any drug allergies,
    check "I am not allergic to any medications" below.`
    : `Enter any drug allergies ${patientFirstName} has. If ${patientFirstName} does not have any drug allergies,
    check "${patientFirstName} is not allergic to any medications" below.`;

  const checkboxLabel = isLoggedInUserAlsoPatient
    ? 'I am not allergic to any medications'
    : `${patientFirstName} is not allergic to any medications`;

  return (
    <Formik
      initialValues={{
        allergies: (savedData.allergies as string) || drugAllergiesMaybe,
        noAllergies: !!savedData.noAllergies,
      }}
      validationSchema={allergiesSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(false);
        if (
          (values.allergies && values.noAllergies) ||
          (!values.allergies && !values.noAllergies)
        ) {
          setFieldError(
            'allergies',
            'Enter allergies or check "I am not allergic to any medications"'
          );
          return;
        }

        onSaveData(values);
        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<AllergiesLayoutValues>) => (
        <Form>
          <Box marginBottom={2}>
            <InfoLayoutBodyComponent
              header={<span>Drug Allergies</span>}
              paragraph={<span>{paragraph}</span>}
            />
            <TextFieldComponent
              label={''}
              name={'allergies'}
              placeholder={'i.e. allergic to aspirin, penicillin, etc.'}
              multiline
              rows={4}
            />
            <CheckboxComponent label={checkboxLabel} name="noAllergies" />
          </Box>
          <ProgressButtonGroupComponent
            isLoading={isSubmitting}
            nextOnClickCallback={submitForm}
            previousOnClickCallback={previousOnClickCallback}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AllergiesLayout;
