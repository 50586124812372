import { createContext } from 'react';
import { UserDetails } from 'services/user';

const AuthContext = createContext<{
  isLoading: boolean,
  isLoggedIn: boolean,
  isEmailNotConfirmed: boolean,
  isMemberMaybe: boolean | null,
  data: any | undefined,
  userDetails: UserDetails | undefined,
  login: (credentials: { username: string, password: string }, redirect?: boolean) => void,
  logout: (options: { redirect?: boolean }) => void
    }>({
      isLoading: false,
      isLoggedIn: false,
      isEmailNotConfirmed: false,
      isMemberMaybe: null,
      data: undefined,
      userDetails: undefined,
      login: () => undefined,
      logout: () => undefined
    });

export default AuthContext;
