import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Box, Typography } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import SelectComponent from 'Components/SelectComponent/SelectComponent';
import LoadingSpinner from 'Components/LoadingSpinner';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import {
  PreferredContactType,
  useGetContactPersonQuery,
  useGetEligibleContactPersonsQuery,
  useGetContactInformationQuery,
  useUpdateContactInfoMutation,
} from 'services/person';
import { ContactInformation } from 'services/person';
import { PersonalInfoStepProps } from '..';

interface AddPhoneValues {
  phoneNumber: string;
  isCell: string;
}

const phoneRegExp = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-/\s.]|\d)+$/;

const AddPhoneSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Must be a valid phone number')
    .required('Required'),
  email: Yup.string().email().required('Required'),
});

const AddPhoneLayout: FunctionComponent<PersonalInfoStepProps> = ({
  onSaveData,
  onNextStep,
  onPreviousStep,
  contextData,
}) => {
  const dispatch = useDispatch();
  const [postUpdateContactInfo] = useUpdateContactInfoMutation();

  const { patient } = contextData;
  const { data: contactPerson, isLoading: isLoadingContactPerson } = useGetContactPersonQuery(
    patient.patientId,
    { skip: !patient.patientId }
  );
  const [selectedContactPerson, setSelectedContactPerson] = useState<string>(
    contactPerson?.patientPersonId || patient.patientId || ''
  );
  const { data: contactInformation, isLoading: isLoadingContactInfo } =
    useGetContactInformationQuery(selectedContactPerson || patient.patientId, {
      skip: !selectedContactPerson && !patient.patientId,
    });
  const { phonePrimary, phonePrimaryIsCell, emailPrimary } = contactInformation || {};

  const [needValueForIsCell, setNeedValueForIsCell] = useState<boolean>(false);

  const { data: eligibleContactPersons, isLoading: isLoadingEligibleContactPersons } =
    useGetEligibleContactPersonsQuery(patient.patientId, {
      skip: !patient.patientId,
    });
  const contactPersonSelectOptions =
    eligibleContactPersons?.map((contactPerson) => ({
      label: `${contactPerson.name}`,
      value: contactPerson.patientPersonId,
    })) || [];

  useEffect(() => {
    dispatch(setTitle('Personal Info'));
  }, [dispatch]);

  const handleContactPersonSelection = (contactPersonId: string) => {
    setNeedValueForIsCell(false);
    setSelectedContactPerson(contactPersonId);
  };

  const isPatientsContactInfo = selectedContactPerson === patient.patientId;

  if (isLoadingContactPerson || isLoadingContactInfo || isLoadingEligibleContactPersons) {
    return <LoadingSpinner />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        contactPersonId: selectedContactPerson || '',
        phoneNumber: phonePrimary || '',
        isCell: phonePrimaryIsCell ? 'true' : '',
        email: emailPrimary || '',
      }}
      validationSchema={AddPhoneSchema}
      onSubmit={async (values, { setSubmitting }) => {
        if ((eligibleContactPersons?.length == 1 || isPatientsContactInfo) && !values.isCell) {
          setNeedValueForIsCell(true);
          return;
        }

        const updatedPhoneInfo: ContactInformation = {
          patientPersonId: selectedContactPerson || contactPerson?.patientPersonId || '',
          name: contactInformation?.name || '',
          phonePrimary: values.phoneNumber as string,
          phoneSecondary: contactInformation?.phoneSecondary,
          phonePrimaryIsCell: values.isCell === 'true' ? true : false,
          phoneSecondaryIsCell: contactInformation?.phoneSecondaryIsCell as boolean,
          emailPrimary: values.email as string,
          emailSecondary: contactInformation?.emailSecondary,
          preferredContact:
            values.isCell === 'false'
              ? 'Email'
              : (contactInformation?.preferredContact as PreferredContactType),
          preferredSpanishUpdates: contactInformation?.preferredSpanishUpdates as boolean,
        };

        await postUpdateContactInfo({
          patientPersonId: patient.patientId,
          contactInfo: updatedPhoneInfo,
        });
        setSubmitting(false);

        onSaveData(selectedContactPerson || contactPerson?.patientPersonId);
        onNextStep();
      }}
    >
      {({ isSubmitting, submitForm, errors }: FormikProps<AddPhoneValues>) => (
        <Form>
          {eligibleContactPersons?.length == 1 ? (
            <>
              <Typography
                align={'center'}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                What&apos;s the best number and email to reach you?
              </Typography>
              <Box marginBottom={3} marginTop={2}>
                <TextFieldComponent label={'Phone'} name={'phoneNumber'} />
              </Box>
            </>
          ) : (
            <>
              <Typography
                align={'center'}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                Who should we contact on your behalf?
              </Typography>
              <SelectComponent
                label={'Point of Contact'}
                name={'contactPersonId'}
                selectOptions={contactPersonSelectOptions}
                onChange={(e: { target: { value: string } }) => {
                  handleContactPersonSelection(e.target.value);
                }}
              />
              <Box marginBottom={3} marginTop={2}>
                <TextFieldComponent
                  label={'Phone'}
                  name={'phoneNumber'}
                  disabled={!isPatientsContactInfo}
                />
              </Box>
            </>
          )}
          {eligibleContactPersons?.length == 1 || isPatientsContactInfo ? (
            <Box marginBottom={3} marginTop={2}>
              <SelectComponent
                label={'Is this a cellphone where we may text you?'}
                name={'isCell'}
                errorMessage={errors.isCell}
                selectOptions={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
              />
            </Box>
          ) : null}
          <Box sx={{ mb: 3, mt: 2 }}>
            <TextFieldComponent label={'Email'} name={'email'} disabled={!isPatientsContactInfo} />
          </Box>
          {needValueForIsCell ? (
            <AlertComponent
              alertType="error"
              message="Need to know if phone is a cellphone we may text"
            />
          ) : null}
          <ProgressButtonGroupComponent
            isLoading={isSubmitting}
            nextOnClickCallback={submitForm}
            previousOnClickCallback={onPreviousStep}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddPhoneLayout;
