import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { useCancelMedicationMutation, useEditMedicationMutation } from 'services/medication';

import ConfirmMedication from './ConfirmMedication';
import { MedicationWizardStepProps } from '../..';

const ConfirmMedicationLayout: FunctionComponent<MedicationWizardStepProps> = ({
  onNextStep,
  onPreviousStep,
  contextData,
}) => {
  const dispatch = useDispatch();

  const { currentMedicationsMaybe: medications } = contextData;

  const [medicationIndex, setMedicationIndex] = useState(0);

  const [putEditMedication] = useEditMedicationMutation();
  const [postCancelMedication] = useCancelMedicationMutation();
  const currentMedication = medications[medicationIndex];

  const nextMedication = () => {
    if (medicationIndex === medications.length - 1) {
      onNextStep();
    } else {
      setMedicationIndex(medicationIndex + 1);
    }
  };

  const handleSkipMedication = async () => {
    // canceling med because member no longer needs it
    await postCancelMedication(currentMedication.medicationId);
    nextMedication();
  };

  const handleMedicationSubmit = async (values: any = {}) => {
    const drugId = values.drug?.id ?? null;
    const updatedMedication = {
      ...currentMedication,
      prescriberId: values.prescriberId ?? '',
      drugId,
      medicationName: values.drug?.name,
      new: false,
    };

    await putEditMedication(updatedMedication);
    nextMedication();
  };

  useEffect(() => {
    dispatch(setTitle('Medications'));
  }, [dispatch]);

  return (
    <>
      <ConfirmMedication
        medication={currentMedication}
        onSubmit={handleMedicationSubmit}
        onPreviousClick={onPreviousStep}
        onSkipMedication={handleSkipMedication}
      />
    </>
  );
};

export default ConfirmMedicationLayout;
