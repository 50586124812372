import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Formik, Form, FormikProps } from 'formik';
import * as yup from 'yup';

import { Box, Typography } from '@mui/material';
import ErrorIconComponent from 'Components/ErrorIconComponent/ErrorIconComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { useEffect } from 'react';
import { useState } from 'react';
import { useGetUserDetailsQuery } from 'services/user';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useResetPasswordMutation, useValidateEmailLinkQuery } from 'services/auth';
import useAuth from 'Hooks/useAuth';
import LoadingSpinner from 'Components/LoadingSpinner';
import { encodeToHttpSafeBase64String } from 'containers/Common/commonFunctions';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

interface CreateNewPasswordValues {
  ssnLastFour: string | null;
  dateOfBirth: string | null;
  password: string;
  passwordConfirmation: string;
}

const CreateNewPasswordLayout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: { personIdEncryptedMaybe: personId } = {} } = useAuth();
  const [userVerificationType, setUserVerificationType] = useState('');
  const ssnRegex = /^[0-9]*$/;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const urks = encodeToHttpSafeBase64String(query.get('urks') || '');

  const { data: validationOutput, isLoading } = useValidateEmailLinkQuery(urks ?? skipToken);
  const personDetailsFromLink = validationOutput?.userLinkDetails;
  const personIdFinal = personDetailsFromLink?.personId ? personDetailsFromLink.personId : personId;
  const { data: userDetails } = useGetUserDetailsQuery(personIdFinal ?? skipToken);
  const [putResetPassword] = useResetPasswordMutation();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  useEffect(() => {
    const asyncEffectFunc = async () => {
      if (userDetails) {
        if (userDetails.hasSsn) {
          setUserVerificationType('ssn');
        } else if (userDetails.hasDateOfBirth) {
          setUserVerificationType('dob');
        }
      }
    };

    asyncEffectFunc();
  }, [dispatch, userDetails]);

  const getInfoStringType = () => {
    if (userVerificationType === 'ssn') {
      return 'the last four digits of your social security number';
    } else {
      return 'your date of birth';
    }
  };

  const createNewPasswordSchema = yup.object().shape({
    ssnLastFour:
      userVerificationType === 'ssn'
        ? yup
            .string()
            .required('Required')
            .matches(ssnRegex, 'Invalid value. Please only enter numbers.')
            .typeError('Provide the last 4 digits of your SSN')
            .min(4, 'Please provide the last 4 digits of your SSN')
            .max(4, 'Please provide the last 4 digits of your SSN')
        : yup.string(),
    dateOfBirth:
      userVerificationType === 'dob'
        ? yup
            .string()
            .required('Required')
            .matches(
              /^(0?[1-9]|1[0-2])(\/|-|\s|\.)(0?[1-9]|1\d|2\d|3[01])(\/|-|\s|\.)(19|20|)\d{2}$/gi,
              'Must be a valid date. (MM/DD/YYYY)'
            )
        : yup.string(),
    password: yup.string().required().min(8),
    passwordConfirmation: yup
      .string()
      .required()
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref('password')], 'Both password fields must match.'),
      }),
  });

  const redirectToLogin = () => {
    history.push('/login');
  };

  if (isLoading) {
    return <LoadingSpinner />;
  } else if (personDetailsFromLink?.hasLinkExpired) {
    return (
      <Box>
        <ErrorIconComponent />
        <Typography
          align={'center'}
          variant={'body1'}
          color={'textPrimary'}
          paragraph
          sx={{
            marginBottom: 20,
            fontWeight: 'bold',
          }}
        >
          Uh oh! The link has expired.
          <br />
          To request a new link, click FORGOT PASSWORD on the login page and provide the username
          associated with the account.
        </Typography>
        <PrimaryButtonComponent text={'Back To Login'} onClick={redirectToLogin} />
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        ssnLastFour: '',
        dateOfBirth: '',
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={createNewPasswordSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setErrorMsg(undefined);
        const response: any = await putResetPassword({
          personId: personIdFinal,
          password1: values.password,
          password2: values.passwordConfirmation,
          ssn: values.ssnLastFour,
          dateOfBirth: values.dateOfBirth,
        });

        if (response.error) {
          if (response.error.data?.userInputValidationError) {
            setErrorMsg(response.error.data.userInputValidationError);
          }
        } else {
          history.push('/');
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<CreateNewPasswordValues>) => (
        <Form>
          <Typography
            align={'center'}
            variant={'body1'}
            color={'textPrimary'}
            style={{ fontWeight: 'bold' }}
          >
            Create New Password
          </Typography>
          <Box marginBottom={3} marginTop={2}>
            <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
              Please enter {getInfoStringType()} to verify your account.
            </Typography>
            {userVerificationType === 'ssn' ? (
              <TextFieldComponent
                label={'Last 4 Digits of SSN'}
                name={'ssnLastFour'}
                type={'password'}
              />
            ) : (
              <TextFieldComponent label={'Date Of Birth'} name={'dateOfBirth'} />
            )}
          </Box>
          <Box marginBottom={2}>
            <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
              New Password
            </Typography>
            <TextFieldComponent label={'New Password'} name={'password'} type={'password'} />
            <TextFieldComponent
              label={'Confirm New Password'}
              name={'passwordConfirmation'}
              type={'password'}
            />
          </Box>
          {errorMsg ? (
            <AlertComponent alertType="error" title="Reset Password Failed" message={errorMsg} />
          ) : null}
          <PrimaryButtonComponent
            type={'submit'}
            text={'CONTINUE'}
            onClick={submitForm}
            isLoading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateNewPasswordLayout;
