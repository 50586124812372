import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Box, Typography } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useEditPersonDetailsMutation, useGetPersonalDetailsQuery } from 'services/user';
import SelectComponent from 'Components/SelectComponent/SelectComponent';
import { PersonDetails } from 'services/user';
import LoadingSpinner from 'Components/LoadingSpinner';
import { PersonalInfoStepProps } from '..';

interface PersonalInfoValues {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  gender?: string;
}

const PersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dateOfBirth: Yup.string()
    .required('Required')
    .matches(
      /^(0?[1-9]|1[0-2])(\/|-|\s|\.)(0?[1-9]|1\d|2\d|3[01])(\/|-|\s|\.)(19|20|)\d{2}$/gi,
      'Must be a valid date. (MM/DD/YYYY)'
    ),
  gender: Yup.mixed().required('Required'),
});

const PersonalInfoLayout: FunctionComponent<PersonalInfoStepProps> = ({
  onNextStep,
  onPreviousStep,
  contextData,
}) => {
  const dispatch = useDispatch();
  const { patient } = contextData;

  const { data: personalDetails, isLoading } = useGetPersonalDetailsQuery(patient.patientId, {
    skip: !patient.patientId,
  });
  const { firstName, lastName } = personalDetails || {};
  const [putEditPersonalDetails] = useEditPersonDetailsMutation();

  const isGenderDisabled = !!personalDetails?.gender;
  const isDOBDisabled = !!personalDetails?.dateOfBirth;
  const isFirstNameDisabled = !!personalDetails?.firstName;
  const isLastNameDisabled = !!personalDetails?.lastName;

  const isAnyInputsDisabled =
    isGenderDisabled || isDOBDisabled || isFirstNameDisabled || isLastNameDisabled;
  useEffect(() => {
    dispatch(setTitle('Personal Info'));
  }, [dispatch]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Formik
      initialValues={{
        firstName: firstName,
        lastName: lastName,
        gender: personalDetails?.gender,
        dateOfBirth: personalDetails?.dateOfBirth,
      }}
      validationSchema={PersonalInfoSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const editPersonaldetails: PersonDetails = {
          firstName: values.firstName as string,
          preferredName: personalDetails?.preferredName,
          middleName: personalDetails?.middleName,
          lastName: values.lastName as string,
          suffix: personalDetails?.suffix,
          gender: values.gender as string,
          dateOfBirth: values.dateOfBirth as string,
        };

        await putEditPersonalDetails({
          patientPersonId: patient.patientId,
          personalDetails: editPersonaldetails,
        });

        setSubmitting(false);
        onNextStep();
      }}
    >
      {({ isSubmitting, submitForm, errors }: FormikProps<PersonalInfoValues>) => (
        <Form>
          <Typography
            align={'center'}
            variant={'body1'}
            color={'textPrimary'}
            style={{ fontWeight: 'bold' }}
          >
            Personal Information
          </Typography>
          {isAnyInputsDisabled ? (
            <Typography>
              If any information is incorrect, please reach out to your advocate.
            </Typography>
          ) : null}
          <Box marginBottom={2} marginTop={2}>
            <Box display={'flex'}>
              <TextFieldComponent
                label={'First Name'}
                name={'firstName'}
                disabled={isFirstNameDisabled}
              />
            </Box>
            <Box display={'flex'} marginTop={1}>
              <TextFieldComponent
                label={'Last Name'}
                name={'lastName'}
                disabled={isLastNameDisabled}
              />
            </Box>
            <Box display={'flex'} marginTop={1}>
              <TextFieldComponent
                label={'Date of Birth'}
                name={'dateOfBirth'}
                disabled={isDOBDisabled}
              />
            </Box>
            <SelectComponent
              label={'Gender'}
              name={'gender'}
              errorMessage={errors.gender}
              disabled={isGenderDisabled}
              selectOptions={[
                { label: 'Female', value: 'F' },
                { label: 'Male', value: 'M' },
              ]}
            />
          </Box>
          <ProgressButtonGroupComponent
            nextOnClickCallback={submitForm}
            previousOnClickCallback={onPreviousStep}
            isLoading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PersonalInfoLayout;
