import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import useAuth from 'Hooks/useAuth';
import AdvocateBarComponent from 'Components/AdvocateBarComponent/AdvocateBarComponent';
import {
  selectClientError,
  selectUnauthorizedResponse,
  selectInternalServerError,
} from 'Store/Reducers/authSlice';
import Routes from 'Routes/Routes';
import AuthenticatedRoute from 'Routes/AuthenticatedRoute';
import useWhiteLabelApp from 'Hooks/useWhiteLabelApp';
import DefaultLayout from 'App/layouts/Default';

const App: FunctionComponent = () => {
  const history = useHistory();
  const { whiteLabelApp } = useWhiteLabelApp();
  const { isLoggedIn } = useAuth();
  const clientError = useSelector(selectClientError);
  const unauthorizedResponse = useSelector(selectUnauthorizedResponse);
  const internalServerError = useSelector(selectInternalServerError);

  whiteLabelApp();

  useEffect(() => {
    if (clientError) {
      history.push('/error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientError]);

  useEffect(() => {
    if (unauthorizedResponse) {
      history.push('/logout');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unauthorizedResponse]);

  useEffect(() => {
    if (internalServerError) {
      history.push('/error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalServerError]);

  return (
    <Switch>
      {Routes.map((route, i) => {
        const RouteComponent = route.auth ? AuthenticatedRoute : Route;

        return (
          <RouteComponent
            key={i}
            path={route.path}
            render={(props: { location: { search: string } }) => (
              <DefaultLayout
                showMenu={!!route.auth && route.showMenu === true}
                showSecondaryMenu={route.showSecondaryMenu === true}
              >
                {route.redirect ? (
                  <Redirect to={`${route.redirect.to}${props.location.search}`} />
                ) : (
                  <route.component {...props} />
                )}
                {isLoggedIn && route.auth ? <AdvocateBarComponent /> : undefined}
              </DefaultLayout>
            )}
          />
        );
      })}
    </Switch>
  );
};

export default App;
