import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { useGetAdvocateInfoQuery } from 'services/user';
import { useDoWeHaveAllScriptsQuery } from 'services/mailOrder';
import LoadingSpinner from 'Components/LoadingSpinner';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

interface SubmitLayoutProps {
  nextOnClickCallback(): void;
  previousOnClickCallback?(): void;
  onComplete?: () => void;
  contextData: {
    mailOrderAuthKeystring: string;
    isLoggedInUserAlsoPatient: boolean;
    patientFirstName: string;
  };
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1),
    fontWeight: 'bold',
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const SubmitLayout: FunctionComponent<SubmitLayoutProps> = ({ onComplete, contextData }) => {
  const dispatch = useDispatch();
  const { data: advocateInfo } = useGetAdvocateInfoQuery();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const classes = useStyles();
  const { data: weHaveAllScriptsFromDoctor, isLoading } = useDoWeHaveAllScriptsQuery(
    contextData?.mailOrderAuthKeystring ?? skipToken
  );
  const { isLoggedInUserAlsoPatient, patientFirstName } = contextData;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const statementRegardingNextStep = weHaveAllScriptsFromDoctor
    ? 'Once your authorization has been submitted we will begin working on placing your mail order.'
    : isLoggedInUserAlsoPatient
    ? 'We are still waiting on receiving your prescription from your prescriber. Once we receive the prescription, we will begin working on placing your mail order.'
    : `We are still waiting on receiving the prescription from ${patientFirstName}'s prescriber. Once we receive the prescription, we will begin working on placing your mail order.`;

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography
        align={'center'}
        variant={'body1'}
        color={'textPrimary'}
        className={classes.title}
      >
        Complete Mail Order Authorization
      </Typography>
      <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
        You will be receiving the medication in the mail via a third-party pharmacy. This may take
        2-4 weeks or longer depending on shipment locations, though typically will be much sooner.
        Please be aware that it will be your responsibility to use your tracking # to monitor your
        order and will be responsible for replacement if your order is damaged due to a missed
        delivery.
        <br />
        To submit your authorization, click Complete Authorization below.
        <br />
        <br />
        {statementRegardingNextStep}
        <br />
        <br />
        If you have any questions or concerns click the{' '}
        {advocateInfo?.advocateTitle ? advocateInfo.advocateTitle : 'Advocate'} Button at the bottom
        of the page to speak to your{' '}
        {advocateInfo?.advocateTitle ? advocateInfo.advocateTitle.toLowerCase() : 'advocate'}.
      </Typography>
      <PrimaryButtonComponent
        text="Complete Authorization"
        onClick={onComplete}
        width="auto"
        className={classes.button}
      />
    </Box>
  );
};

export default SubmitLayout;
