import { FunctionComponent } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import { orange } from '@mui/material/colors';
import classNames from 'classnames';

interface LoadingSpinnerProps {
  size?: number;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      color: orange[500],
    },
  })
);

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = (props) => {
  const { size = 24, className } = props;

  const styles = useStyles();

  return <CircularProgress size={size} className={classNames(className, styles.progress)} />;
};

export default LoadingSpinner;
