import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import { Box, FormGroup, Typography, Checkbox } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import {
  useEditAdvocacyDetailsMutation,
  AdvocacyDetails,
  useGetAdvocacyDetailsQuery,
} from 'services/user';
import { MedicaidAndMedicareCommonProps } from '../index';
import LoadingSpinner from 'Components/LoadingSpinner';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

interface SecondaryInsuranceLayoutValues {
  noIns: boolean;
  medicarePartB: boolean;
  medicarePartC: boolean;
  medicarePartD: boolean;
  medicaid: boolean;
  miltaryVA: boolean;
  childrens: boolean;
  spouse: boolean;
}

const SecondaryInsuranceLayout: FunctionComponent<MedicaidAndMedicareCommonProps> = ({
  onNextStep,
  onPreviousStep,
  viewableMembers,
}) => {
  const dispatch = useDispatch();

  const [insuranceError, setInsuranceError] = useState(false);
  const [nothingSelectedError, setNothingSelectedError] = useState(false);
  const [currentMemberIndex, setCurrentMemberIndex] = useState(0);
  const memberDetails = viewableMembers[currentMemberIndex];

  const { firstName, lastName, relationship, patientId } = memberDetails || {};

  const { data: advocacyDetails, isLoading: isLoadingAdvocacyDetails } = useGetAdvocacyDetailsQuery(
    patientId,
    { skip: !patientId }
  );

  const currentMemberName = `${firstName} ${lastName}`;
  const {
    noIns,
    medicaid,
    medicarePartB,
    medicarePartC,
    medicarePartD,
    miltaryVA,
    childrens,
    spouse,
  } = advocacyDetails || {};

  const [editAdvocacyDetails] = useEditAdvocacyDetailsMutation();

  const [secondaryIns, setSecondaryIns] = useState<AdvocacyDetails | undefined>();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecondaryIns({ ...secondaryIns, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setSecondaryIns(advocacyDetails);
  }, [advocacyDetails]);

  useEffect(() => {
    dispatch(setTitle('Additional Coverage'));
  }, [dispatch]);

  if (isLoadingAdvocacyDetails) {
    return <LoadingSpinner />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        noIns: noIns ? noIns : false,
        medicarePartB: medicarePartB ? medicarePartB : false,
        medicarePartC: medicarePartC ? medicarePartC : false,
        medicarePartD: medicarePartD ? medicarePartD : false,
        medicaid: medicaid ? medicaid : false,
        miltaryVA: miltaryVA ? miltaryVA : false,
        childrens: childrens ? childrens : false,
        spouse: spouse ? spouse : false,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setInsuranceError(false);
        setNothingSelectedError(false);

        if (secondaryIns && secondaryIns.noIns === true) {
          const obj = { ...secondaryIns };
          const clone = (({ noIns, ...secondaryIns }) => secondaryIns)(obj);
          const insCount = Object.values(clone).filter((x) => x === true).length;
          if (insCount > 0) {
            setInsuranceError(true);
            return;
          }
        } else if (secondaryIns) {
          const insCount = Object.values(secondaryIns).filter((x) => x === true).length;
          if (insCount == 0) {
            setNothingSelectedError(true);
            return;
          }
        } else if (secondaryIns === undefined) {
          setNothingSelectedError(true);
          return;
        }

        const selectedNoIns = secondaryIns.noIns === true ? true : false;
        const updateAdvocacyDetails: AdvocacyDetails = {
          noIns: selectedNoIns,
          medicarePartB: selectedNoIns ? false : secondaryIns.medicarePartB ? true : false,
          medicarePartC: selectedNoIns ? false : secondaryIns.medicarePartC ? true : false,
          medicarePartD: selectedNoIns ? false : secondaryIns.medicarePartD ? true : false,
          medicaid: selectedNoIns ? false : secondaryIns.medicaid ? true : false,
          miltaryVA: selectedNoIns ? false : secondaryIns.miltaryVA ? true : false,
          childrens: selectedNoIns ? false : secondaryIns.childrens ? true : false,
          spouse: selectedNoIns ? false : secondaryIns.spouse ? true : false,
        };

        await editAdvocacyDetails({
          patientPersonId: patientId,
          advocacyDetails: updateAdvocacyDetails,
        });

        setSubmitting(false);

        if (currentMemberIndex === viewableMembers.length - 1) {
          onNextStep();
        } else {
          setCurrentMemberIndex(currentMemberIndex + 1);
        }
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<SecondaryInsuranceLayoutValues>) => (
        <Form>
          <Box>
            <Typography
              align="center"
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Secondary Insurance
            </Typography>
            <InfoLayoutBodyComponent paragraph="Secondary insurance is insurance that applies after you use your primary insurance, it is supplemental coverage." />
            <Box marginBottom={1}>
              <Typography
                align="center"
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                {relationship === 'Self'
                  ? 'If you have Secondary Insurance, choose the coverage below.'
                  : `If  ${currentMemberName} has Secondary Insurance, choose the coverage below.`}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.noIns ? secondaryIns.noIns : false}
                      name="noIns"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="No secondary insurance"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.spouse ? secondaryIns.spouse : false}
                      name="spouse"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Spouse's Plan"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.medicaid ? secondaryIns.medicaid : false}
                      name="medicaid"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Medicaid"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.medicarePartB ? secondaryIns.medicarePartB : false}
                      name="medicarePartB"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Medicare Part B"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.medicarePartC ? secondaryIns.medicarePartC : false}
                      name="medicarePartC"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Medicare Advantage Part C"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secondaryIns?.medicarePartD ? secondaryIns.medicarePartD : false}
                      name="medicarePartD"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Medicare Part D"
                  labelPlacement="end"
                />
                <Typography
                  align="left"
                  justifyContent="end"
                  variant={'body1'}
                  color={'textPrimary'}
                  style={{ fontWeight: 'bold' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={secondaryIns?.miltaryVA ? secondaryIns.miltaryVA : false}
                        name="miltaryVA"
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Veteran Benefits (Tri-Care, VA Benefits, CHAMPVA)"
                    labelPlacement="end"
                  />
                </Typography>
                <Typography
                  align="left"
                  justifyContent="end"
                  variant={'body1'}
                  color={'textPrimary'}
                  style={{ fontWeight: 'bold' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={secondaryIns?.childrens ? secondaryIns.childrens : false}
                        name="childrens"
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Children's Special Health Care Services"
                    labelPlacement="end"
                  />
                </Typography>
              </FormGroup>
              {insuranceError ? (
                <AlertComponent
                  alertType="error"
                  title="Invalid selection"
                  message="Cannot have No secondary insurance with other insurance options."
                />
              ) : null}
              {nothingSelectedError ? (
                <AlertComponent
                  alertType="error"
                  title="Invalid selection"
                  message="Please select at least one insurance option."
                />
              ) : null}
            </Box>
          </Box>
          <ProgressButtonGroupComponent
            isLoading={isSubmitting}
            nextOnClickCallback={submitForm}
            previousOnClickCallback={onPreviousStep}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SecondaryInsuranceLayout;
