import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

export interface GetDrugInfo {
  cleanDrugName: string;
  scriptCoIDMaybe: number | null;
  sharxIDMaybe: number | null;
  isGenericMaybe: boolean | null;
  brandNamesOfGenericMaybe: string | null;
  genericNameOfBrandMaybe: string | null;
  hasQualifiedForPAP?: boolean | null;
}

export interface VerifyDrugProgram {
  drugId: number;
}

export interface HouseholdInfoForPAPQualify {
  annualIncome: number;
  numberOfAdults: number | null;
  numberOfChildren: number | null;
  stateOfResidence: string;
  drugsToQualify: number[];
}

export interface DrugQualification {
  sharxDrugID: number;
  hasQualifiedForPap: boolean;
}

export const scriptCoApi = createApi({
  reducerPath: 'services/scriptCo',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getDrugList: builder.query<GetDrugInfo[], string>({
      query: () => {
        return {
          url: 'drugs/getdruglist',
          method: 'get',
        };
      },
    }),
    verifyDrugHasManufacturerProgram: builder.mutation<boolean, VerifyDrugProgram>({
      query: (drugId) => {
        return {
          url: `qualification/verifydrugprogram`,
          method: 'post',
          data: drugId,
        };
      },
    }),
    verifyPAPforMeds: builder.mutation({
      query: (householdInfoForPAPQualify) => {
        return {
          url: `qualification/qualifydrugs`,
          method: 'post',
          data: householdInfoForPAPQualify,
        };
      },
    }),
  }),
});

export const {
  useGetDrugListQuery,
  useVerifyPAPforMedsMutation,
  useVerifyDrugHasManufacturerProgramMutation,
} = scriptCoApi;
