import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import useAuth from 'Hooks/useAuth';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { useHasUserAlreadySeenSHARxQuery } from 'services/user';
import LoadingSpinner from 'Components/LoadingSpinner';

interface IntroWelcomeProps {
  onNextStep(): void;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Intro: FunctionComponent<IntroWelcomeProps> = ({ onNextStep }) => {
  const dispatch = useDispatch();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;
  const { userDetails } = useAuth();
  const firstName = userDetails?.firstName;

  const { data: hasUserAlreadySeenSHARx, isLoading } = useHasUserAlreadySeenSHARxQuery();

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
  }, [appName, dispatch]);

  const classes = useStyles();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box className={classes.screenBox}>
      <InfoLayoutBodyComponent
        header={
          <span>
            {hasUserAlreadySeenSHARx
              ? `Welcome back to ${appName}, ${firstName}!`
              : `Welcome to ${appName}, ${firstName}!`}
          </span>
        }
        paragraph={
          <span>
            {hasUserAlreadySeenSHARx
              ? 'We have a new look!'
              : `${appName} makes it simple for individuals and families to maintain access to high cost, specialty, and orphan prescriptions while reducing the cost it takes to have them filled.`}
          </span>
        }
      />
      <PrimaryButtonComponent text="Next" onClick={onNextStep} />
    </Box>
  );
};

export default Intro;
