import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { Box } from '@mui/material';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import useAuth from 'Hooks/useAuth';

const EmailConfirmationLayout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;
  const { logout } = useAuth();

  const redirectToLogin = () => {
    logout({ redirect: true });
  };

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
  }, [appName, dispatch]);

  return (
    <Box>
      <InfoLayoutBodyComponent
        header={
          <span>Your account has been created but we need you to confirm your email address</span>
        }
        paragraph={
          <p>
            Check your email inbox for an email from {appName} asking you to confirm your email
            address. Click on the link in that email to confirm your email, and then login to
            continue to {appName}. The confirmation email is time sensitive so please confirm your
            email as soon as possible. You will not be able to log into your account and continue
            onboarding until your email has been confirmed.
          </p>
        }
      />
      <PrimaryButtonComponent text={'OKAY'} onClick={redirectToLogin} />
    </Box>
  );
};

export default EmailConfirmationLayout;
