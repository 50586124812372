import { FunctionComponent, useState } from 'react';
import { useGetContactPersonQuery } from 'services/person';
import EditContactInfoDialog from 'Components/EditContactInfoDialog/EditContactInfoDialog';
import InfoCardPrimary from 'Components/InfoCardPrimary';

interface ProfileContactCardProps {
  personId: string;
  isAdultMaybe?: boolean;
}

const ProfileContactCard: FunctionComponent<ProfileContactCardProps> = (props) => {
  const { personId, isAdultMaybe } = props;
  const { data: contactInfo, isLoading } = useGetContactPersonQuery(personId);
  const {
    patientPersonId,
    name,
    phonePrimary,
    phonePrimaryIsCell,
    phoneSecondary,
    phoneSecondaryIsCell,
    emailPrimary,
    emailSecondary,
    preferredContact,
    preferredSpanishUpdates,
  } = contactInfo || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const contextData = {
    patientPersonId: personId,
    contactPersonId: patientPersonId,
    phonePrimary: phonePrimary,
    phonePrimaryIsCell: phonePrimaryIsCell,
    phoneSecondary: phoneSecondary,
    phoneSecondaryIsCell: phoneSecondaryIsCell,
    emailPrimary: emailPrimary,
    emailSecondary: emailSecondary,
    preferredContact: preferredContact,
    updatesInSpanish: preferredSpanishUpdates,
    isAdultMaybe: isAdultMaybe,
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <EditContactInfoDialog
        open={isModalOpen}
        onClose={handleModalClose}
        contextData={contextData}
      />
      <InfoCardPrimary
        title="Contact Information"
        isLoading={isLoading}
        onActionClick={handleModalOpen}
        contentItems={[
          {
            title: `Name: `,
            content: `${name}`,
          },
          {
            title: `Preferred Phone: `,
            content: `${phonePrimary ? phonePrimary : ''} ${phonePrimaryIsCell ? '(Cell)' : ''}`,
          },
          phoneSecondary
            ? {
                title: `Secondary Phone: `,
                content: `${phoneSecondary} ${phoneSecondaryIsCell ? '(Cell)' : ''}`,
              }
            : null,
          {
            title: `Preferred Email: `,
            content: `${emailPrimary ? emailPrimary : ''}`,
          },
          emailSecondary
            ? {
                title: `Secondary Email: `,
                content: `${emailSecondary}`,
              }
            : null,
          {
            title: `Preferred Update Method: `,
            content: `${preferredContact ? preferredContact : ''}`,
          },
        ].filter(Boolean)}
      />
    </>
  );
};

export default ProfileContactCard;
