import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import LoadingSpinner from 'Components/LoadingSpinner';

interface WizardProps {
  children: React.ReactNode;
  isLoading?: boolean;
}

const Wizard: FunctionComponent<WizardProps> = ({ children, isLoading = false }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box
          sx={{
            maxWidth: (theme) => theme.spacing(50),
            width: '100%',
            p: 2,
            textAlign: 'center',
            padding: 3,
            boxShadow: 3,
            borderRadius: 3,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Wizard;
