import { FieldMetaProps } from 'formik';

const useErrorMessage = <T = any>({ error, touched }: FieldMetaProps<T>): string | undefined => {
  if (error && touched) {
    return error;
  }

  return undefined;
};

export default useErrorMessage;
