import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { useGetAdvocateInfoQuery } from 'services/user';
import { MedicationWizardStepProps } from '..';
import useAuth from 'Hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
}));

const IntroHasMedsLayout: FunctionComponent<MedicationWizardStepProps> = ({
  groupInfo,
  onNextStep,
  onPreviousStep,
}) => {
  const dispatch = useDispatch();
  const { data: advocateInfo } = useGetAdvocateInfoQuery();
  const advocate = advocateInfo?.advocateFirstName;
  const { userDetails } = useAuth();
  const firstName = userDetails?.firstName;
  const canMemberAddMedications = groupInfo.canMemberAddMedications;

  useEffect(() => {
    dispatch(setTitle('Medications'));
  }, [dispatch]);

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <InfoLayoutBodyComponent
        header={<span>Thanks {firstName}, we have all the personal details we need.</span>}
        paragraph={
          canMemberAddMedications ? (
            <span>
              Now, let&apos;s confirm the medications we have on file, you can also add any more
              medications that your advocate, {advocate}, should be aware of.
            </span>
          ) : (
            <span>Now, let&apos;s confirm the medications we have on file.</span>
          )
        }
      />
      <ProgressButtonGroupComponent
        nextOnClickCallback={onNextStep}
        previousOnClickCallback={onPreviousStep}
      />
    </Box>
  );
};

export default IntroHasMedsLayout;
