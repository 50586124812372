import { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reset, clearQueryCache } from 'Store/actions/commonActions';

import { useGetUserDetailsQuery } from 'services/user';
import { useLoginMutation, useLogoutMutation } from 'services/auth';

import AuthContext from 'App/contexts/auth/AuthContext';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import { KEY_AUTHENTICATED, KEY_AUTH_TOKEN, KEY_LOGIN_DATA } from 'App/contexts/constants';

type LoginDataType =
  | {
      loginFailureMsgMaybe: string;
      loginSuccessful: boolean;
      emailNotConfirmed: boolean;
      personIdEncryptedMaybe: string | null;
      groupIdEncryptedMaybe: string | null;
      usernameMaybe: string | null;
      tokenMaybe: string | null;
    }
  | undefined;

interface AuthProviderProps {
  children: any;
}

const initialData = {
  loginFailureMsgMaybe: '',
  loginSuccessful: false,
  emailNotConfirmed: false,
  personIdEncryptedMaybe: '',
  groupIdEncryptedMaybe: '',
  usernameMaybe: '',
  tokenMaybe: '',
};

const AuthProvider: FunctionComponent<AuthProviderProps> = (props) => {
  const { children } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmailNotConfirmed, setIsEmailNotConfirmed] = useState<boolean>(false);
  const [isMemberMaybe, setIsMemberMaybe] = useState<boolean | null>(null);
  const [data, setData] = useState<LoginDataType>();

  const [postLogin] = useLoginMutation();
  const [postLogout] = useLogoutMutation();

  const { data: userDetails } = useGetUserDetailsQuery(data?.personIdEncryptedMaybe || '', {
    skip: !isLoggedIn || !data?.personIdEncryptedMaybe,
  });
  const login = async (credentials: any, redirect = true) => {
    const result: any = await postLogin(credentials);
    if (!result?.data?.loginSuccessful) {
      if (result?.data?.emailNotConfirmed) {
        setIsEmailNotConfirmed(true);
      }
      setData(initialData);
      setIsMemberMaybe(result?.data?.isMemberMaybe);
      return;
    }

    setData(result.data);

    dispatch(setIsNextStepReminderEligible(true));

    setIsLoggedIn(true);
    setIsMemberMaybe(result.data.isMemberMaybe);

    localStorage.setItem(KEY_AUTHENTICATED, '1');
    localStorage.setItem(KEY_AUTH_TOKEN, result.data.tokenMaybe);
    localStorage.setItem(KEY_LOGIN_DATA, JSON.stringify(result.data));

    if (redirect) {
      history.push('/');
    }
  };

  const logout = async ({ redirect = true }) => {
    if (!isLoggedIn) {
      if (redirect) {
        history.push('/login');
      }
      return;
    }

    setIsLoggedIn(false);
    setData(undefined);
    setIsEmailNotConfirmed(false);

    await postLogout('logout');

    localStorage.removeItem(KEY_AUTHENTICATED);
    localStorage.removeItem(KEY_LOGIN_DATA);
    localStorage.removeItem(KEY_AUTH_TOKEN);

    dispatch(clearQueryCache());
    dispatch(reset());

    if (redirect) {
      history.push('/login');
    }
  };

  useEffect(() => {
    const previouslyAuthenticated = localStorage.getItem(KEY_AUTHENTICATED);
    const loginData = localStorage.getItem(KEY_LOGIN_DATA) || '{}';

    if (previouslyAuthenticated && parseInt(previouslyAuthenticated, 10)) {
      setIsLoggedIn(true);
      setData(JSON.parse(loginData) as LoginDataType);
    }
    setIsLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isLoggedIn,
        isEmailNotConfirmed,
        isMemberMaybe,
        data,
        userDetails,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
