import { createSlice } from '@reduxjs/toolkit';

export type AppSliceState = {
  isNextStepReminderEligible: boolean;
};

export const slice = createSlice({
  name: 'app',
  initialState: {
    isNextStepReminderEligible: false,
  },
  reducers: {
    setIsNextStepReminderEligible: (state, action) => {
      state.isNextStepReminderEligible = action.payload;
    },
  },
});
export const { setIsNextStepReminderEligible } = slice.actions;

export const selectIsNextStepReminderEligible = (state: Record<string, any>) =>
  state.app.isNextStepReminderEligible;

export default slice.reducer;
