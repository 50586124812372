import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

export type PreferredContactType = 'Text' | 'Email' | false;

export interface ShippingAddress {
  shippingAddressId: string | null;
  address: string;
  address2?: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface ContactInformation {
  patientPersonId: string;
  name: string;
  phonePrimary: string;
  phoneSecondary?: string;
  phonePrimaryIsCell: boolean;
  phoneSecondaryIsCell: boolean;
  emailPrimary: string;
  emailSecondary?: string;
  preferredContact: PreferredContactType;
  preferredSpanishUpdates: boolean;
}

export interface ContactInfoChanges {
  patientPersonId: string;
  contactInfo: ContactInformation;
}

export interface HouseholdInfo {
  annualIncome: number | null;
  numberOfAdults: number | null;
  numberOfChildren: number | null;
  isContributingToHSAMaybe: boolean | null;
}

export const personApi = createApi({
  reducerPath: 'services/person',
  tagTypes: ['ContactInformation', 'HomeAddress', 'HouseholdInfo', 'Ssn'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getHomeAddress: builder.query<Address, string>({
      query: (patientPersonId) => {
        return {
          url: `person/address/home/${patientPersonId}`,
          method: 'get',
        };
      },
      providesTags: ['HomeAddress'],
    }),
    updateHomeAddress: builder.mutation({
      query: (homeAddress) => {
        return {
          url: 'person/address/home',
          method: 'put',
          data: homeAddress,
        };
      },
      invalidatesTags: ['HomeAddress'],
    }),
    getShippingAddress: builder.query<ShippingAddress, string>({
      query: (shippingAddressId) => {
        return {
          url: `person/address/shipping/${shippingAddressId}`,
          method: 'get',
        };
      },
    }),
    getAllShippingAddresses: builder.query<ShippingAddress[], void>({
      query: () => {
        return {
          url: 'person/address/shipping/all',
          method: 'get',
        };
      },
    }),
    addNewShippingAddress: builder.mutation({
      query: (newShippingAddress) => {
        return {
          url: 'person/adress/shipping',
          method: 'post',
          data: newShippingAddress,
        };
      },
    }),
    updateShippingAddress: builder.mutation({
      query: (shippingAddressId) => {
        return {
          url: `person/address/shipping/${shippingAddressId}`,
          method: 'put',
        };
      },
    }),
    getContactInformation: builder.query<ContactInformation, string>({
      query: (patientPersonId) => {
        return {
          url: `contact/getContactInfo/${patientPersonId}`,
          method: 'get',
        };
      },
      providesTags: ['ContactInformation'],
    }),
    getContactPerson: builder.query<ContactInformation, string>({
      query: (patientPersonId) => {
        return {
          url: 'contact/getContactPerson',
          method: 'get',
          params: {
            patientPersonId: patientPersonId,
          },
        };
      },
      providesTags: ['ContactInformation'],
    }),
    getEligibleContactPersons: builder.query<ContactInformation[], string>({
      query: (patientPersonId) => {
        return {
          url: 'contact/contactPersons',
          method: 'get',
          params: {
            patientPersonId: patientPersonId,
          },
        };
      },
      providesTags: ['ContactInformation'],
    }),
    updateContactInfo: builder.mutation<void, ContactInfoChanges>({
      query: (changes) => {
        return {
          url: 'contact/updateContactInfo',
          method: 'post',
          params: {
            patientPersonId: changes.patientPersonId,
          },
          data: changes.contactInfo,
        };
      },
      invalidatesTags: ['ContactInformation'],
    }),
    getSsn: builder.query({
      query: (patientPersonId) => {
        return {
          url: `person/ssn/${patientPersonId}`,
          method: 'get',
        };
      },
      providesTags: ['Ssn'],
    }),
    updateSsn: builder.mutation({
      query: (ssnInfo) => {
        return {
          url: 'person/ssn',
          method: 'post',
          data: ssnInfo,
        };
      },
      invalidatesTags: ['Ssn'],
    }),
    getHouseholdInformation: builder.query<HouseholdInfo, void>({
      query: () => {
        return {
          url: 'household/info',
          method: 'get',
        };
      },
      providesTags: ['HouseholdInfo'],
    }),
    updateHouseholdInformation: builder.mutation({
      query: (householdInfo) => {
        return {
          url: 'household/info',
          method: 'post',
          data: householdInfo,
        };
      },
      invalidatesTags: ['HouseholdInfo'],
    }),
  }),
});
export const {
  useGetHomeAddressQuery,
  useUpdateHomeAddressMutation,
  useGetShippingAddressQuery,
  useGetAllShippingAddressesQuery,
  useAddNewShippingAddressMutation,
  useUpdateShippingAddressMutation,
  useGetContactInformationQuery,
  useGetContactPersonQuery,
  useGetEligibleContactPersonsQuery,
  useUpdateContactInfoMutation,
  useGetSsnQuery,
  useUpdateSsnMutation,
  useGetHouseholdInformationQuery,
  useUpdateHouseholdInformationMutation,
} = personApi;
