import { FunctionComponent, useEffect } from 'react';
import AccountWizard from 'containers/Wizards/Account';
import { useHistory } from 'react-router';
import useAuth from 'Hooks/useAuth';

const RegistrationPage: FunctionComponent = () => {
  const history = useHistory();
  const { isLoggedIn, logout } = useAuth();

  const handleComplete = () => {
    history.push('/welcome');
  };

  useEffect(() => {
    const checkLoggedInStatus = async () => {
      if (isLoggedIn) {
        await logout({ redirect: false });
        window.location.reload();
      }
    };

    checkLoggedInStatus();
  }, [isLoggedIn, logout]);

  if (isLoggedIn) {
    return null;
  }

  return <AccountWizard onComplete={handleComplete} />;
};

export default RegistrationPage;
