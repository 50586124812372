import { FunctionComponent } from 'react';

import ButtonComponent from 'Components/ButtonComponent/ButtonComponent';

interface TertiaryButtonComponentProps {
  children?: React.ReactNode;
  text?: string;
  [transferProps: string]: any;
}

const TertiaryButtonComponent: FunctionComponent<TertiaryButtonComponentProps> = ({
  children,
  text,
  ...transferProps
}) => {
  return (
    <ButtonComponent {...transferProps} color={'primary'} disableElevation>
      {text || children}
    </ButtonComponent>
  );
};

export default TertiaryButtonComponent;
