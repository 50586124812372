import { FunctionComponent } from 'react';

import { useSelector } from 'react-redux';

import useStepNavigator from 'containers/Wizards/hooks/useStepNavigator';

import {
  IntroLayout,
  QualificationInfoLayout,
  MedicationInformationLayout,
  AddPhoneLayout,
  RegisterAccountLayout,
  HipaaInfoLayout,
  ScriptCoCompleteLayout,
  AddAddressLayout,
  UserAcknowledgementsLayout,
  UploadIncomeLayout,
  DeterminPapQualificationLayout,
  VerifyEligibilityLayout,
  VerifyEmailAddress,
} from './layouts';
import SignatureLayout from '../Hipaa/layouts/SignatureLayout';
import Wizard from '../Wizard';

import { WizardProps, StepProps } from '../types';

enum Steps {
  INTRO = 1,
  QUALIFICATION_INFO,
  DETERMINE_PAP_QUALIFICATION,
  VERIFY_ELIGIBILITY,
  VERIFY_EMAIL,
  REGISTER_ACCOUNT,
  USER_ACKNOWLEDGEMENTS,
  ADD_ADDRESS,
  ADD_PHONE,
  MEDICATION_INFO,
  HIPAA,
  UPLOAD_INCOME,
  COMPLETE,
}

const COMPONENT_MAP: any = {
  [Steps.INTRO]: IntroLayout,
  [Steps.USER_ACKNOWLEDGEMENTS]: UserAcknowledgementsLayout,
  [Steps.QUALIFICATION_INFO]: QualificationInfoLayout,
  [Steps.DETERMINE_PAP_QUALIFICATION]: DeterminPapQualificationLayout,
  [Steps.VERIFY_ELIGIBILITY]: VerifyEligibilityLayout,
  [Steps.VERIFY_EMAIL]: VerifyEmailAddress,
  [Steps.REGISTER_ACCOUNT]: RegisterAccountLayout,
  [Steps.ADD_ADDRESS]: AddAddressLayout,
  [Steps.ADD_PHONE]: AddPhoneLayout,
  [Steps.MEDICATION_INFO]: MedicationInformationLayout,
  [Steps.HIPAA]: HipaaInfoLayout,
  [Steps.UPLOAD_INCOME]: UploadIncomeLayout,
  [Steps.COMPLETE]: ScriptCoCompleteLayout,
};

const ScriptCoWizard: FunctionComponent<WizardProps> = (props) => {
  const { onComplete, onPrevious } = props;

  const order = [
    Steps.INTRO,
    Steps.QUALIFICATION_INFO,
    Steps.DETERMINE_PAP_QUALIFICATION,
    Steps.VERIFY_ELIGIBILITY,
    Steps.VERIFY_EMAIL,
    Steps.REGISTER_ACCOUNT,
    Steps.USER_ACKNOWLEDGEMENTS,
    Steps.ADD_ADDRESS,
    Steps.ADD_PHONE,
    Steps.MEDICATION_INFO,
    Steps.HIPAA,
    // Steps.UPLOAD_INCOME,
    Steps.COMPLETE,
  ].filter(Boolean);
  const { next, previous, current, reset } = useStepNavigator({ steps: order });
  const Component: FunctionComponent<StepProps> = COMPONENT_MAP[current];

  const handlePreviousStep = () => {
    if (current === Steps.INTRO && onPrevious) {
      onPrevious();
    } else {
      previous();
    }
  };

  const handleNextStep = () => {
    next();
  };
  return (
    <Wizard isLoading={false}>
      <Component
        onPreviousStep={handlePreviousStep}
        onNextStep={handleNextStep}
        onComplete={onComplete}
      />
    </Wizard>
  );
};

export default ScriptCoWizard;
