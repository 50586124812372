import { FunctionComponent, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { Box, Typography } from '@mui/material';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';

import { LoginSelfService } from './.';
import {
  useCheckIfMemberAlreadyRegisteredQuery,
  useSendEmailVerificationCodeMutation,
} from 'services/register';
import { selectPersonIDEncrypted } from 'Store/Reducers/personInfoSlice';
import { setEmail } from 'Store/Reducers/contactInfoSlice';
import { setIsMemberAlreadyRegistered } from 'Store/Reducers/memberSlice';

interface VerifyEmailAddressLayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
}
interface VerifyEmailInfoProps {
  emailAddress: string;
}

interface VerifyCodeProps {
  emailVerificationCode: string;
}

const VerifyEmailAddressLayout: FunctionComponent<VerifyEmailAddressLayoutProps> = ({
  onNextStep,
}) => {
  const dispatch = useDispatch();

  const [sendEmailVerification, { error }] = useSendEmailVerificationCodeMutation();
  const personIdEncrypted = useSelector(selectPersonIDEncrypted);

  const [registrationErrorMessage, setErrorMsg] = useState<string | undefined>();
  const [verificationCodeErrorMessage, setCodeErrorMsg] = useState<string | undefined>();
  const [waitingForCode, setWaitingForCode] = useState<boolean>(false);
  const [firstCodeFailed, setFirstCodeFailed] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<number>();

  const handleLogIn = () => {
    onNextStep();
  };

  const retryEmailVerification = () => {
    setFirstCodeFailed(true);
    setWaitingForCode(false);
  };

  const { data: isMemberAlreadyRegistered, isLoading: isMemberAlreadyRegisteredLoading } =
    useCheckIfMemberAlreadyRegisteredQuery(personIdEncrypted, {
      skip: !personIdEncrypted,
    });

  useEffect(() => {
    if (isMemberAlreadyRegistered && !isMemberAlreadyRegisteredLoading) {
      dispatch(setIsMemberAlreadyRegistered(true));
    }
  }, [isMemberAlreadyRegistered]);

  const accountCreationSchema = yup.object().shape({
    emailAddress: yup.string().required('Must be a valid email'),
  });

  return (
    <Fragment>
      {!isMemberAlreadyRegisteredLoading && !isMemberAlreadyRegistered ? (
        <>
          {!waitingForCode ? (
            <Formik
              initialValues={{
                emailAddress: '',
              }}
              validationSchema={accountCreationSchema}
              onSubmit={async (values, { setSubmitting, setValues }) => {
                await dispatch(setEmail(values.emailAddress));
                const emailVerificationInfo = {
                  emailAddress: values.emailAddress,
                  personIdEncrypted: personIdEncrypted,
                };

                const response = await sendEmailVerification(emailVerificationInfo).unwrap();
                setVerificationCode(response);
                setValues({ emailAddress: '' });
                setWaitingForCode(true);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }: FormikProps<VerifyEmailInfoProps>) => (
                <Form>
                  <Box marginBottom={1}>
                    <Typography
                      align={'center'}
                      variant={'body1'}
                      color={'textPrimary'}
                      style={{ fontWeight: 'bold' }}
                    >
                      We need you to confirm your email address. Check your email inbox for an email
                      from ScriptCo Shield asking you to confirm your email address.
                    </Typography>
                  </Box>
                  <Box marginTop={2}>
                    <>
                      <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                        Please enter an email for your account
                      </Typography>
                      <TextFieldComponent
                        label={'Email Address'}
                        name={'emailAddress'}
                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                          if (event.key === 'Enter') {
                            submitForm();
                          }
                        }}
                      />
                    </>
                  </Box>
                  {registrationErrorMessage ? (
                    <AlertComponent
                      alertType="error"
                      title="Registration Unsuccessful"
                      message={registrationErrorMessage}
                      onClose={() => setErrorMsg(undefined)}
                    />
                  ) : null}
                  <ProgressButtonGroupComponent
                    isLoading={isSubmitting}
                    nextOnClickCallback={submitForm}
                  />
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                emailVerificationCode: '',
              }}
              onSubmit={async (values, { setSubmitting, setValues }) => {
                if (values?.emailVerificationCode?.toString() === verificationCode?.toString()) {
                  setValues({ emailVerificationCode: '' });
                  onNextStep();
                  setSubmitting(false);
                } else {
                  setCodeErrorMsg('Invalid code');
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, submitForm }: FormikProps<VerifyCodeProps>) => (
                <Form>
                  <Box marginBottom={1}>
                    <Typography
                      align={'center'}
                      variant={'body1'}
                      color={'textPrimary'}
                      style={{ fontWeight: 'bold' }}
                    >
                      Check your email for a code.
                    </Typography>
                  </Box>
                  <Box marginTop={2}>
                    <>
                      <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                        Please enter code
                      </Typography>
                      <TextFieldComponent
                        label={'Email Verification Code'}
                        name={'emailVerificationCode'}
                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                          if (event.key === 'Enter') {
                            submitForm();
                          }
                        }}
                      />
                    </>
                  </Box>
                  {verificationCodeErrorMessage ? (
                    <AlertComponent
                      alertType="error"
                      title="Invalid Code"
                      message={verificationCodeErrorMessage}
                      onClose={() => setCodeErrorMsg(undefined)}
                    />
                  ) : null}
                  <ProgressButtonGroupComponent
                    isLoading={isSubmitting}
                    nextOnClickCallback={submitForm}
                    previousOnClickCallback={retryEmailVerification}
                  />
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : null}
      {!isMemberAlreadyRegisteredLoading && isMemberAlreadyRegistered ? (
        <LoginSelfService onSubmitLogIn={handleLogIn}></LoginSelfService>
      ) : null}
    </Fragment>
  );
};

export default VerifyEmailAddressLayout;
