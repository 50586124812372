import { createContext } from 'react';
import { Palette } from '@mui/material/styles';

interface WhiteLabelInfo {
  name: string;
  appLogoPath: string;
  logoPath: string;
  faviconPath: string;
  address: string;
  cityStateZip: string;
  phoneNumber: string;
  providerIsSHARx: boolean | undefined;
  providerIsTrueAdvocate: boolean;
  colors: Palette | Record<string, unknown>;
}

export const defaultTheme = {
  primary: {
    light: '#6b9fd2',
    main: '#3771A1',
    dark: '#004672',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ff985f',
    main: '#D46833',
    dark: '#9d3a04',
    contrastText: '#fff',
  },
};

export const defaultValue = {
  name: '',
  appLogoPath: '',
  logoPath: '',
  faviconPath: '',
  address: '',
  cityStateZip: '',
  phoneNumber: '',
  providerIsSHARx: true,
  providerIsTrueAdvocate: false,
  colors: defaultTheme,
};

const WhiteLabelContext = createContext<{
  isLoading: boolean;
  whiteLabelInfo: WhiteLabelInfo | undefined;
}>({ isLoading: false, whiteLabelInfo: defaultValue });

export default WhiteLabelContext;
