import { FunctionComponent, ReactNode, ChangeEvent } from 'react';
import { useField, FieldInputProps } from 'formik';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useErrorMessage } from 'Hooks';

interface SelectComponentProps {
  name: string;
  label: string;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  selectOptions: SelectOption[];
  onChange?: (e: any) => void;
  [transferProps: string]: any;
}

interface SelectOption {
  label: string;
  value: string | number;
  disabled?: boolean;
}

const renderOptions = (selectOptions: SelectOption[]): JSX.Element[] => {
  return selectOptions.map((selectOption, index) => (
    <MenuItem
      key={index}
      value={selectOption.value}
      disabled={selectOption.disabled}
      style={{ whiteSpace: 'normal', maxWidth: '400px' }}
    >
      {selectOption.label}
    </MenuItem>
  ));
};

interface SelectFieldProps extends Partial<FieldInputProps<string | number>> {
  errorString?: string;
  children: ReactNode;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

const SelectField: FunctionComponent<SelectFieldProps> = ({
  errorString,
  label,
  children,
  value,
  name,
  required = false,
  disabled = false,
  ...transferProps
}) => {
  return (
    <FormControl sx={{ my: 1 }} fullWidth error={!!errorString}>
      <InputLabel required={required}>{label}</InputLabel>
      <Select
        {...transferProps}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        style={{ maxWidth: '100%' }}
        label={label}
      >
        {children}
      </Select>
      <FormHelperText>{errorString}</FormHelperText>
    </FormControl>
  );
};

const SelectComponent: FunctionComponent<SelectComponentProps> = ({
  name,
  label,
  errorMessage = null,
  required = false,
  disabled = false,
  selectOptions,
  onChange,
  ...transferProps
}) => {
  const [field, meta, helpers] = useField(name);
  const errorString = useErrorMessage(meta);

  return (
    <SelectField
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        helpers.setValue(e.target.value);
        if (onChange) {
          onChange(e);
        }
      }}
      {...transferProps}
      name={name}
      label={label}
      value={field.value}
      required={required}
      disabled={disabled}
      errorString={errorMessage ? errorString : ''}
    >
      {renderOptions(selectOptions)}
    </SelectField>
  );
};

export default SelectComponent;
