import { FunctionComponent } from 'react';
import { Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import EditIcon from '@mui/icons-material/Edit';

interface InfoCardProps {
  contentItems?: (string | null)[];
  isLoading?: boolean;
  onActionClick?: () => void;
  title: string;
}

const InfoCardSecondary: FunctionComponent<InfoCardProps> = ({
  contentItems,
  isLoading,
  onActionClick,
  title,
}) => {
  return (
    <>
      <Card
        sx={{
          textAlign: 'left',
        }}
      >
        <CardHeader
          title={isLoading ? <Skeleton /> : title}
          sx={{
            height: '80px',
            alignItems: 'self-start',
            overflow: 'hidden',
          }}
          action={
            isLoading ? (
              <Skeleton variant="circular" />
            ) : onActionClick ? (
              <IconButton aria-label="edit" onClick={onActionClick}>
                <EditIcon />
              </IconButton>
            ) : null
          }
        />
        <CardContent>
          {contentItems && contentItems.length > 0
            ? contentItems.map((item, index) => (
                <Typography variant="body2" key={index}>
                  {isLoading ? <Skeleton /> : item}
                </Typography>
              ))
            : 'No information'}
        </CardContent>
      </Card>
    </>
  );
};

export default InfoCardSecondary;
