import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ImageComponent from 'Components/ImageComponent/ImageComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { useGetAdvocateInfoQuery } from 'services/user';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

interface AdvocateInfoFPALayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  leadIcon: {
    'max-width': '100%',
    'max-height': '20vh',
    background: '#D46833',
    borderRadius: '90px',
    padding: '25px',
    marginBottom: '20px',
  },
  accentText: {
    color: theme.palette.secondary.main,
    'font-weight': 'bold',
  },
}));

const UploadIncomeLayout: FunctionComponent<AdvocateInfoFPALayoutProps> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <div>Gather other information here</div>
      <div>Do we need HSA info here</div>
      <ProgressButtonGroupComponent
        previousOnClickCallback={onPreviousStep}
        nextOnClickCallback={onNextStep}
      />
    </Box>
  );
};

export default UploadIncomeLayout;
