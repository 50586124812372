import { FunctionComponent } from 'react';
import Dialog from 'Components/Dialog';

import { Address } from 'services/person';

import AddressEntryForm from 'Components/AddressEntryForm';

type EditAddressDialogProps = {
  onClose: () => void;
  onSubmit: (values: Address) => Promise<void>;
  open: boolean;
  address?: Address;
  title?: string;
  actionText?: string;
};

const EditAddressDialog: FunctionComponent<EditAddressDialogProps> = (props) => {
  const {
    onClose,
    open,
    address,
    onSubmit,
    title = 'Edit Home Address',
    actionText = 'Save Address',
  } = props;

  return (
    <Dialog title={title} onClose={onClose} open={open}>
      <AddressEntryForm
        onSubmit={onSubmit}
        primaryActionText={actionText}
        address={address as Address}
      />
    </Dialog>
  );
};

export default EditAddressDialog;
