import { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Store from 'Store/Store';

import AppInstallProvider from 'App/contexts/appInstall/AppInstallProvider';
import AuthProvider from 'App/contexts/auth/AuthProvider';
import ThemeProvider from 'App/contexts/theme/ThemeProvider';
import WhiteLabelProvider from 'App/contexts/whiteLabel/WhiteLabelProvider';

import loggerService from 'api/loggerService';

import ErrorView from 'Views/ErrorView/ErrorView';

import App from './App';
import ErrorBoundary from './ErrorBoundary';
import DefaultLayout from './layouts/Default';

const handleError = (error: unknown, errorInfo: { componentStack: string }) => {
  loggerService({
    statusCode: 'React_Component_Exception',
    message: `${error}${errorInfo?.componentStack}`,
  });
};

const renderError = () => (
  <DefaultLayout showMenu={false} showSecondaryMenu={false}>
    <ErrorView />
  </DefaultLayout>
);

const Root: FunctionComponent = () => {
  return (
    <Store>
      <BrowserRouter>
        <AuthProvider>
          <WhiteLabelProvider>
            <ThemeProvider>
              <ErrorBoundary onError={handleError} renderError={renderError}>
                <AppInstallProvider>
                  <App />
                </AppInstallProvider>
              </ErrorBoundary>
            </ThemeProvider>
          </WhiteLabelProvider>
        </AuthProvider>
      </BrowserRouter>
    </Store>
  );
};

export default Root;
