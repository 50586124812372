import { useState, useMemo, FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';
import * as yup from 'yup';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { Form, Formik, FormikProps } from 'formik';
import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';
import SelectComponent from 'Components/SelectComponent/SelectComponent';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';

import AddPrescriberDialog from 'Components/AddPrescriberDialog/AddPrescriberDialog';

import { useGetAllHouseholdMembersQuery } from 'services/user';
import { useGetAllPrescribersQuery } from 'services/prescriber';
import { Medication, useAddMedicationMutation } from 'services/medication';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import LoadingSpinner from 'Components/LoadingSpinner';
import EditPrescriberDialog from 'Components/EditPrescriberDialog';
import useGetPrescriberInfoById from 'Hooks/useGetPresciberInfoById';

interface Drug {
  name: string;
  id: number | null;
}

interface EditMedicationValues {
  drug?: Drug | undefined;
  recipient?: string;
  prescriberId?: string;
}

interface MedicationEntryProps {
  medication?: EditMedicationValues;
  onPreviousClick?: () => void;
  previousBtnText?: string;
  onSubmit: () => void;
  nextbtntext?: string;
}

const addPrescriptionSchema = yup.object().shape({
  drug: yup.object().shape({
    name: yup.string().required('Required'),
    id: yup.mixed().required('Required'),
  }),
  recipient: yup.mixed().required('Recipient required'),
  prescriberId: yup.mixed().required('Prescriber required'),
});

const MedicationEntry: FunctionComponent<MedicationEntryProps> = (props) => {
  const { onSubmit, medication, onPreviousClick, previousBtnText, nextbtntext } = props;

  const [postAddMedication] = useAddMedicationMutation();
  const [isAddPrescriberDialogOpen, setAddPrescriberDialogOpen] = useState<boolean>(false);
  const [isEditPrescriberDialogOpen, setEditPrescriberDialogOpen] = useState<boolean>(false);

  const { data: allMembersInHousehold, isLoading: isLoadingRecipients } =
    useGetAllHouseholdMembersQuery();
  const { data: queriedPrescribers = [], isLoading: isLoadingPrescribers } =
    useGetAllPrescribersQuery('');

  const [selectedRecipient, setSelectedRecipient] = useState<string>(
    medication?.recipient ? medication.recipient : ''
  );
  const [selectedPrescriber, setSelectedPrescriber] = useState<string>(
    medication?.prescriberId ? medication.prescriberId : ''
  );
  const { prescriberInfo, isLoadingPrescriberInfo } = useGetPrescriberInfoById(selectedPrescriber);

  const [errorMessage, setErrorMessage] = useState(undefined);

  const handleSubmit = async (values: any) => {
    const drugId = values.drug?.id ?? 0;
    const patientId = values.recipient;
    const newMedication: Medication = {
      medicationId: null,
      patientPersonId: patientId,
      drugId: drugId,
      name: values.drug?.name ?? '',
      strength: null,
      quantity: null,
      inhaler: false,
      tempSensitive: false,
      papQualified: false,
      prescriberId: values.prescriberId ?? '',
      assistanceType: null,
      priorAuthDetailsMaybe: null,
      new: true,
      authorizedToView: true,
      medicationName: values.drug?.name ?? '',
      assistanceTypeNdxMaybe: '',
      isPrescriberRequired: true,
    };

    const response: any = await postAddMedication(newMedication);

    if (response.error?.data?.userInputValidationError) {
      setErrorMessage(response.error.data.userInputValidationError);
    } else {
      onSubmit();
    }
  };

  const handlePrescriberAdded = (id: string) => {
    setSelectedPrescriber(id);
  };

  const handleAddPrescriberDialogClose = () => {
    setAddPrescriberDialogOpen(false);
  };

  const handleEditPrescriberDialogClose = () => {
    setEditPrescriberDialogOpen(false);
  };

  const householdMemberSelectOptions = useMemo(() => {
    return (
      allMembersInHousehold?.map((member) => ({
        label: `${member.firstName} ${member.lastName}`,
        value: member.patientId,
      })) || []
    );
  }, [allMembersInHousehold]);

  const prescriberSelectOptions = useMemo(() => {
    return (
      queriedPrescribers?.map((prescriber: any) => ({
        label: `${prescriber.firstName} ${prescriber.lastName}`,
        value: prescriber.prescriberId,
      })) || []
    );
  }, [queriedPrescribers]);

  const handleAddPrescriberClick = () => {
    setAddPrescriberDialogOpen(true);
  };

  const handleEditPrescriberClick = () => {
    setEditPrescriberDialogOpen(true);
  };

  if (isLoadingPrescribers || isLoadingRecipients) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AddPrescriberDialog
        onClose={handleAddPrescriberDialogClose}
        open={isAddPrescriberDialogOpen}
        onPrescriberAdded={handlePrescriberAdded}
      />
      <EditPrescriberDialog
        onClose={handleEditPrescriberDialogClose}
        open={isEditPrescriberDialogOpen}
        prescriberInfo={prescriberInfo}
        turnOffEditName={true}
      />

      <Formik
        enableReinitialize
        initialValues={{
          drug: {
            name: medication?.drug?.name || '',
            id: medication?.drug?.id || null,
          },
          recipient: selectedRecipient || medication?.recipient || '',
          prescriberId: selectedPrescriber || medication?.prescriberId || '',
        }}
        validationSchema={addPrescriptionSchema}
        onSubmit={(values: EditMedicationValues, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ submitForm, isSubmitting, errors }: FormikProps<EditMedicationValues>) => (
          <Form>
            <Box>
              <Box marginBottom={3} marginTop={2}>
                <Box>
                  <InfoLayoutBodyComponent
                    header={medication?.drug?.name}
                    paragraph="Please select the individual for whom this prescription is intended, and then add the doctor who prescribed this medication."
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} alignItems={'flex-end'}>
                  <SelectComponent
                    label={'Who is the medication for?'}
                    name={'recipient'}
                    errorMessage={errors.recipient}
                    selectOptions={householdMemberSelectOptions}
                    onChange={(e: { target: { value: string } }) => {
                      setSelectedRecipient(e.target.value);
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <SelectComponent
                    label={'Who is the doctor/prescriber?'}
                    name={'prescriberId'}
                    errorMessage={errors.prescriberId}
                    selectOptions={prescriberSelectOptions}
                    onChange={(e: { target: { value: string } }) => {
                      setSelectedPrescriber(e.target.value);
                    }}
                  />
                  <SecondaryButtonComponent
                    text={'Add...'}
                    width={'auto'}
                    style={{
                      margin: '0',
                      marginBottom: '3px',
                      marginLeft: '8px',
                    }}
                    onClick={handleAddPrescriberClick}
                  />
                </Box>
              </Box>
              {prescriberInfo && (
                <>
                  <Typography
                    align={'center'}
                    variant={'body1'}
                    color={'textPrimary'}
                    style={{ fontWeight: 'bold' }}
                  >
                    Is the prescriber information correct?
                  </Typography>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginTop={1}
                    marginBottom={3}
                  >
                    <Box marginTop={1} marginBottom={1} style={{ marginLeft: 'auto' }}>
                      <Typography variant={'body2'}>
                        Doctor/Prescriber:{' '}
                        {prescriberInfo
                          ? prescriberInfo?.firstName + ' ' + prescriberInfo?.lastName
                          : 'No doctor selected'}
                      </Typography>
                      <Typography variant={'body2'}>
                        Phone Number:{' '}
                        {prescriberInfo?.phoneNumber
                          ? prescriberInfo?.phoneNumber
                          : 'No phone number'}
                      </Typography>
                    </Box>
                    <SecondaryButtonComponent
                      text={'Edit...'}
                      width={'auto'}
                      style={{
                        margin: '0',
                        marginBottom: '3px',
                        marginLeft: 'auto',
                      }}
                      onClick={handleEditPrescriberClick}
                    />
                  </Box>
                </>
              )}
              {onPreviousClick ? (
                <ProgressButtonGroupComponent
                  isLoading={isSubmitting}
                  nextOnClickCallback={submitForm}
                  previousOnClickCallback={onPreviousClick}
                  nextButtonProps={{
                    text: nextbtntext ? nextbtntext : 'Next',
                  }}
                  previousButtonProps={{
                    text: previousBtnText ? previousBtnText : 'Previous',
                  }}
                />
              ) : (
                <PrimaryButtonComponent
                  isLoading={isSubmitting}
                  text="Submit"
                  onClick={submitForm}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MedicationEntry;
