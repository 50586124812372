import { FunctionComponent } from 'react';

import ButtonComponent from 'Components/ButtonComponent/ButtonComponent';

interface SecondaryButtonComponentProps {
  children?: React.ReactNode;
  text?: string | React.ReactNode;
  [transferProps: string]: any;
}

const SecondaryButtonComponent: FunctionComponent<SecondaryButtonComponentProps> = ({
  children,
  text,
  ...transferProps
}) => {
  return (
    <ButtonComponent {...transferProps} color={'secondary'} variant={'contained'}>
      {text || children}
    </ButtonComponent>
  );
};

export default SecondaryButtonComponent;
