import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { TEXT_FIELD_MAX_LENGTH_DESCRIPTION } from 'App/Constants';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import CheckboxComponent from 'Components/CheckboxComponent/CheckboxComponent';
import { MailOrderProps } from '../';

interface ConditionsLayoutValues {
  conditions: string | null;
  noConditions: boolean;
}

const conditionsSchema = Yup.object().shape({
  noConditions: Yup.bool(),
  conditions: Yup.string().max(
    TEXT_FIELD_MAX_LENGTH_DESCRIPTION,
    'Maximum number of characters exceeded'
  ),
});

const ConditionsLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  onSaveData,
  contextData,
  savedData,
}) => {
  const dispatch = useDispatch();

  const { isLoggedInUserAlsoPatient, patientFirstName } = contextData;
  const medicalConditionsMaybe = contextData?.medicalConditionsMaybe || '';

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const paragraph = isLoggedInUserAlsoPatient
    ? `Enter any medical conditions you have. If you do not have any medical conditions,
    check "I have no medication conditions" below.`
    : `Enter any medical conditions ${patientFirstName} has. If ${patientFirstName} does not have any medical conditions,
    check "${patientFirstName} has no medical conditions" below.`;

  const checkboxLabel = isLoggedInUserAlsoPatient
    ? 'I have no medical conditions'
    : `${patientFirstName} has no medical conditions`;

  return (
    <Formik
      initialValues={{
        conditions: (savedData.conditions as string) || medicalConditionsMaybe,
        noConditions: !!savedData.noConditions,
      }}
      validationSchema={conditionsSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(false);
        if (
          (values.conditions && values.noConditions) ||
          (!values.conditions && !values.noConditions)
        ) {
          setFieldError(
            'conditions',
            'Enter medical conditions or check "I have no medical conditions"'
          );
          return;
        }
        onSaveData(values);

        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<ConditionsLayoutValues>) => (
        <Form>
          <Box marginBottom={2}>
            <InfoLayoutBodyComponent
              header={<span>Medical Conditions</span>}
              paragraph={<span>{paragraph}</span>}
            />
            <TextFieldComponent
              label={''}
              name={'conditions'}
              placeholder={'i.e. diabetes, high blood pressure, etc.'}
              multiline
              rows={4}
            />
            <CheckboxComponent label={checkboxLabel} name="noConditions" />
          </Box>
          <ProgressButtonGroupComponent
            isLoading={isSubmitting}
            nextOnClickCallback={submitForm}
            previousOnClickCallback={previousOnClickCallback}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ConditionsLayout;
