import { FunctionComponent } from 'react';

import ErrorLayout from './Layouts/ErrorLayout/ErrorLayout';
import Wizard from 'containers/Wizards/Wizard';

const ErrorView: FunctionComponent = () => {
  return (
    <Wizard>
      <ErrorLayout />
    </Wizard>
  );
};

export default ErrorView;
