import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { TEXT_FIELD_MAX_LENGTH_NOTE } from 'App/Constants';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import CheckboxComponent from 'Components/CheckboxComponent/CheckboxComponent';
import { MailOrderProps } from '../';

interface OtherMedsLayoutValues {
  otherMeds: string | null;
  noOtherMeds: boolean;
}

const otherMedsSchema = Yup.object().shape({
  noOtherMeds: Yup.bool(),
  otherMeds: Yup.string().max(TEXT_FIELD_MAX_LENGTH_NOTE, 'Maximum number of characters exceeded'),
});

const OtherMedsLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  onSaveData,
  contextData,
  savedData,
}) => {
  const dispatch = useDispatch();

  const { isLoggedInUserAlsoPatient, patientFirstName } = contextData;
  const currentMedication = contextData?.currentMedicationsMaybe || '';

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const paragraph = isLoggedInUserAlsoPatient
    ? `Enter any medications you will be starting or are currently taking. If you are not starting or taking
    additional medications, check "I am taking no other medications" below.`
    : `Enter any medications ${patientFirstName} will be starting or is currently taking. If ${patientFirstName} is not starting or taking additional medications,
    check "${patientFirstName} is taking no other medications" below.`;

  const checkboxLabel = isLoggedInUserAlsoPatient
    ? 'I am taking no other medications'
    : `${patientFirstName} is taking no other medications`;

  return (
    <Formik
      initialValues={{
        otherMeds: (savedData.otherMeds as string) || currentMedication,
        noOtherMeds: !!savedData.noOtherMeds,
      }}
      validationSchema={otherMedsSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        setSubmitting(false);
        if (
          (values.otherMeds && values.noOtherMeds) ||
          (!values.otherMeds && !values.noOtherMeds)
        ) {
          setFieldError(
            'otherMeds',
            'Enter any current medications or check "I am taking no other medications"'
          );
          return;
        }
        onSaveData(values);

        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<OtherMedsLayoutValues>) => (
        <Form>
          <Box marginBottom={2}>
            <InfoLayoutBodyComponent
              header={<span>Additional Medications</span>}
              paragraph={<span>{paragraph}</span>}
            />
            <TextFieldComponent
              label={''}
              name={'otherMeds'}
              placeholder={'i.e. over-the-counter, prescribed medications, etc.'}
              multiline
              rows={4}
            />
            <CheckboxComponent label={checkboxLabel} name="noOtherMeds" />
          </Box>
          <ProgressButtonGroupComponent
            isLoading={isSubmitting}
            nextOnClickCallback={submitForm}
            previousOnClickCallback={previousOnClickCallback}
          />
        </Form>
      )}
    </Formik>
  );
};

export default OtherMedsLayout;
