import { IconButton, ListItem, ListItemText, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Fragment, FunctionComponent } from 'react';

interface FileItemComponentProps {
  fileKeystring?: string;
  fileName: string;
  displayFileName?: string | null;
  fileItemComponentNdx: number;
  onDeleteFileClick?: () => void;
  href?: string;
}

const FileItemComponent: FunctionComponent<FileItemComponentProps> = ({
  fileKeystring,
  fileName,
  displayFileName,
  fileItemComponentNdx,
  onDeleteFileClick,
  href,
}) => {
  return (
    <ListItem
      key={`${fileKeystring} + ${fileItemComponentNdx}`}
      disableGutters={true}
      style={{
        padding: 0,
      }}
    >
      <ListItemText
        primary={displayFileName ? displayFileName : fileName}
        secondary={
          <Fragment>
            <Typography component="span" variant="body2">
              {displayFileName ? fileName : null}
            </Typography>
          </Fragment>
        }
      />
      {fileKeystring && !href ? (
        <a href={`${process.env.REACT_APP_BASE_URL}file/openFile/${fileKeystring}`}>
          <IconButton size="large">
            <GetAppIcon />
          </IconButton>
        </a>
      ) : null}
      {!fileKeystring && href ? (
        <a href={`${href}`}>
          <IconButton size="large">
            <GetAppIcon />
          </IconButton>
        </a>
      ) : null}
      {onDeleteFileClick ? (
        <IconButton aria-label="Delete File" onClick={onDeleteFileClick} size="large">
          <DeleteIcon />
        </IconButton>
      ) : null}
    </ListItem>
  );
};

export default FileItemComponent;
