import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import useAuth from 'Hooks/useAuth';
import { MedicationWizardStepProps } from '..';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
}));

const OutroLayout: FunctionComponent<MedicationWizardStepProps> = ({
  onPreviousStep,
  onComplete,
}) => {
  const dispatch = useDispatch();
  const { userDetails } = useAuth();

  const firstName = userDetails?.firstName;

  useEffect(() => {
    dispatch(setTitle('Medications'));
  }, [dispatch]);

  const classes = useStyles();

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <InfoLayoutBodyComponent
        header={<span>Thanks {firstName}, you&apos;re almost done.</span>}
        paragraph={
          <span>
            We need a few more details about you. If you need to add more medications, you can do so
            from the dashboard page after you have finished setting up your account.
          </span>
        }
      />
      <ProgressButtonGroupComponent
        nextOnClickCallback={onComplete}
        previousOnClickCallback={onPreviousStep}
      />
    </Box>
  );
};

export default OutroLayout;
