import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import useAuth from 'Hooks/useAuth';

interface ProgressButtonGroupComponentProps {
  nextOnClickCallback?(): void;
  previousOnClickCallback?(): void;
  continueLaterOnClickCallback?(): void;
  nextButtonProps?: { [key: string]: any };
  previousButtonProps?: { [key: string]: any };
  continueLaterButtonProps?: { [key: string]: any };
  isLoading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {},
  buttonGroup: {
    width: '100%',
    margin: theme.spacing(1),
    maxWidth: theme.spacing(24),
  },
  primaryButton: {
    width: '100%',
  },
  secondaryButton: {
    width: '100%',
    'margin-right': theme.spacing(1),
  },
}));

const ProgressButtonGroupComponent: FunctionComponent<ProgressButtonGroupComponentProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  continueLaterOnClickCallback,
  nextButtonProps,
  previousButtonProps,
  continueLaterButtonProps,
  isLoading,
}) => {
  const { isLoggedIn } = useAuth();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.buttonGroup}>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
          {previousOnClickCallback ? (
            <SecondaryButtonComponent
              className={classes.secondaryButton}
              text={'PREVIOUS'}
              onClick={previousOnClickCallback}
              {...previousButtonProps}
            />
          ) : null}{' '}
          {nextOnClickCallback ? (
            <PrimaryButtonComponent
              className={classes.primaryButton}
              text={'NEXT'}
              onClick={nextOnClickCallback}
              isLoading={isLoading}
              {...nextButtonProps}
            />
          ) : null}
        </Box>
        {continueLaterOnClickCallback && isLoggedIn ? (
          <TertiaryButtonComponent
            text={'CONTINUE LATER'}
            onClick={continueLaterOnClickCallback}
            {...continueLaterButtonProps}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default ProgressButtonGroupComponent;
