import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import { setCity } from 'Store/Reducers/contactInfoSlice';
import AddressEntryForm from 'Components/AddressEntryForm';
import { Address, useGetHomeAddressQuery, useUpdateHomeAddressMutation } from 'services/person';
import useAuth from 'Hooks/useAuth';
import LoadingSpinner from 'Components/LoadingSpinner';

interface AddAddressLayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
}
const blankAddress = {
  patientPersonId: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
};

const AddAddressLayout: FunctionComponent<AddAddressLayoutProps> = ({
  onNextStep,
  onPreviousStep,
}) => {
  const dispatch = useDispatch();
  const {
    data: { groupIdEncryptedMaybe: groupId, personIdEncryptedMaybe: personId, isLoggedIn },
  } = useAuth();

  // const { data: address, isLoading: isAddressLoading } = useGetHomeAddressQuery(personId);
  const [updateHomeAddress] = useUpdateHomeAddressMutation();
  const handleSubmit = async (values: any) => {
    const newAddress = {
      patientPersonId: personId,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      zip: values.zip,
    };
    await dispatch(setCity(values.city));

    const result = await updateHomeAddress(newAddress);
    if (result) {
      onNextStep();
    }
  };

  useEffect(() => {
    dispatch(setTitle('Personal Info'));
  }, [dispatch]);

  return (
    <Box>
      <Typography
        align={'center'}
        variant={'body1'}
        color={'textPrimary'}
        style={{ fontWeight: 'bold' }}
      >
        Home Address
      </Typography>
      <AddressEntryForm
        onSubmit={handleSubmit}
        primaryActionText="Next"
        secondaryActionClick={onPreviousStep}
        address={blankAddress as Address}
      />
    </Box>
  );
};

export default AddAddressLayout;
