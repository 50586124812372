import { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import { Box, Typography, BoxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AddPrescriberDialog from 'Components/AddPrescriberDialog/AddPrescriberDialog';
import EditPrescriberDialog from 'Components/EditPrescriberDialog';
import SelectComponent from 'Components/SelectComponent/SelectComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';
import {
  Prescriber,
  useEditPrescriberMutation,
  useGetAllPrescribersQuery,
} from 'services/prescriber';
import useGetPrescriberInfoById from 'Hooks/useGetPresciberInfoById';

import { Medication } from 'services/medication';
import useAuth from 'Hooks/useAuth';
import { useGetPersonalDetailsQuery } from 'services/user';

interface ConfirmMedicationProps {
  medication: Medication;
  onSubmit: (values: any) => void;
  onPreviousClick: () => void;
  onSkipMedication: () => void;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  selected: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

interface Drug {
  name: string;
  id: number | null;
}

interface EditMedicationValues {
  drug?: Drug | undefined;
  prescriberId?: string;
  phoneNumber?: string;
}

const addPrescriptionSchema = yup.object().shape({
  prescriberId: yup.mixed().required('Required'),
});
const noPhoneSchema = yup.object().shape({
  prescriberId: yup.mixed().required('Required'),
  phoneNumber: yup.string().required('Please provide a phone number'),
});

const ConfirmMedication: FunctionComponent<ConfirmMedicationProps> = (props) => {
  const { onSkipMedication, onSubmit, medication, onPreviousClick } = props;

  const classes = useStyles();
  const {
    data: { personIdEncryptedMaybe: userId },
  } = useAuth();
  const { data: memberDetails } = useGetPersonalDetailsQuery(medication.patientPersonId);
  const { data: queriedPrescribers = [] } = useGetAllPrescribersQuery('');
  const medIsForUser = medication.patientPersonId === userId;
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isAddPrescriberDialogOpen, setAddPrescriberDialogOpen] = useState<boolean>(false);
  const [isEditPrescriberDialogOpen, setEditPrescriberDialogOpen] = useState<boolean>(false);
  const [isTakingMedicine, setIsTakingMedicine] = useState<-1 | 0 | 1>(-1);
  const [selectedPrescriber, setSelectedPrescriber] = useState<string>(
    medication?.prescriberId ? medication.prescriberId : ''
  );
  const { prescriberInfo, isLoadingPrescriberInfo } = useGetPrescriberInfoById(selectedPrescriber);
  const [postEditPrescriber] = useEditPrescriberMutation();

  const { name, drugId, patientPersonId, prescriberId } = medication;

  const medicationEntry = {
    drug: {
      name,
      id: drugId,
    },
    recipient: patientPersonId,
    prescriberId,
  };

  const prescriberSelectOptions = useMemo(() => {
    return (
      queriedPrescribers?.map((prescriber: any) => ({
        label: `${prescriber.firstName} ${prescriber.lastName}`,
        value: prescriber.prescriberId,
      })) || []
    );
  }, [queriedPrescribers]);

  const handleSelectPrescriberChange = (e: { target: { value: string } }) => {
    setSelectedPrescriber(e.target.value);
  };

  const handleAddPrescriberClick = () => {
    setAddPrescriberDialogOpen(true);
  };

  const handleAddPrescriberDialogClose = () => {
    setAddPrescriberDialogOpen(false);
  };

  const handlePrescriberAdded = (id: string) => {
    setSelectedPrescriber(id);
  };

  const handleEditPrescriberDialogClose = () => {
    setEditPrescriberDialogOpen(false);
  };

  const handleEditPrescriberClick = () => {
    setEditPrescriberDialogOpen(true);
  };

  const hasPhone = !!prescriberInfo?.phoneNumber;
  const validationSchema = useMemo(() => {
    if (!isTakingMedicine) {
      return null;
    } else {
      return prescriberInfo?.phoneNumber ? addPrescriptionSchema : noPhoneSchema;
    }
  }, [isTakingMedicine, prescriberInfo?.phoneNumber]);
  const isNextButtonEnabled =
    isTakingMedicine === 0 ||
    (isTakingMedicine === 1 && !!prescriberInfo && (hasPhone || phoneNumber));

  useEffect(() => {
    setIsTakingMedicine(-1);
    setSelectedPrescriber(medication.prescriberId);
    setPhoneNumber('');
  }, [medication]);

  return (
    <>
      <AddPrescriberDialog
        onClose={handleAddPrescriberDialogClose}
        open={isAddPrescriberDialogOpen}
        onPrescriberAdded={handlePrescriberAdded}
      />
      <EditPrescriberDialog
        onClose={handleEditPrescriberDialogClose}
        open={isEditPrescriberDialogOpen}
        prescriberInfo={prescriberInfo}
        turnOffEditName={true}
      />
      <Box className={classes.screenBox}>
        <Formik
          enableReinitialize
          initialValues={{
            drugName: '',
            prescriberId: selectedPrescriber || '',
            phoneNumber: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values: EditMedicationValues) => {
            const updatePrescriber: Prescriber = {
              prescriberId: prescriberInfo?.prescriberId,
              firstName: prescriberInfo?.firstName,
              lastName: prescriberInfo?.lastName,
              phoneNumber: values.phoneNumber || prescriberInfo?.phoneNumber,
              fax: prescriberInfo?.fax,
            };

            if (!isTakingMedicine) {
              onSkipMedication();
            } else {
              await postEditPrescriber({
                prescriberId: prescriberInfo?.prescriberId,
                prescriberInfo: updatePrescriber,
              });
              onSubmit({
                drug: medicationEntry?.drug,
                prescriberId: values.prescriberId,
                phoneNumber: undefined,
              });
            }
          }}
        >
          {({ submitForm, isSubmitting }: FormikProps<EditMedicationValues>) => (
            <Form>
              <Section inline>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {medIsForUser
                    ? 'Are you currently taking or plan to take'
                    : `Is ${memberDetails?.firstName} ${memberDetails?.lastName} currently taking or planning to take`}{' '}
                  {medication?.name}?
                </Typography>
                <ToggleButtonGroup
                  exclusive
                  onChange={(e, value) => {
                    setIsTakingMedicine(value);
                  }}
                >
                  <ToggleButton
                    classes={{ selected: classes.selected }}
                    selected={isTakingMedicine === 1}
                    value={1}
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    classes={{ selected: classes.selected }}
                    selected={isTakingMedicine === 0}
                    value={0}
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </Section>

              {isTakingMedicine === 1 ? (
                <>
                  {prescriberSelectOptions?.length ? (
                    <>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Please verify the prescribing doctor
                      </Typography>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <SelectComponent
                          label={'Who is the doctor/prescriber?'}
                          name={'prescriberId'}
                          errorMessage={''}
                          selectOptions={prescriberSelectOptions}
                          onChange={handleSelectPrescriberChange}
                        />
                        <SecondaryButtonComponent
                          text={'Add...'}
                          width={'auto'}
                          sx={{
                            margin: '0',
                            marginBottom: '3px',
                            marginLeft: '8px',
                          }}
                          onClick={handleAddPrescriberClick}
                        />
                      </Box>
                      {prescriberInfo && hasPhone ? (
                        <>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            Is the prescriber information correct?
                          </Typography>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            marginTop={1}
                            marginBottom={3}
                          >
                            <Box marginTop={1} marginBottom={1} sx={{ marginLeft: 'auto' }}>
                              <Typography variant={'body2'}>
                                Doctor/Prescriber:{' '}
                                {prescriberInfo
                                  ? prescriberInfo?.firstName + ' ' + prescriberInfo?.lastName
                                  : 'No doctor selected'}
                              </Typography>
                              <Typography variant={'body2'}>
                                Phone Number:{' '}
                                {prescriberInfo?.phoneNumber
                                  ? prescriberInfo?.phoneNumber
                                  : 'No phone number'}
                              </Typography>
                            </Box>
                            <SecondaryButtonComponent
                              text={'Edit...'}
                              width={'auto'}
                              style={{
                                margin: '0',
                                marginBottom: '3px',
                                marginLeft: 'auto',
                              }}
                              onClick={handleEditPrescriberClick}
                            />
                          </Box>
                        </>
                      ) : null}
                      {prescriberInfo && !hasPhone ? (
                        <>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            Please provide a phone number for {prescriberInfo?.firstName}{' '}
                            {prescriberInfo?.lastName} before moving forward.
                          </Typography>
                          <TextFieldComponent
                            label={"Doctor's Phone Number"}
                            name={'phoneNumber'}
                            type={'tel'}
                            onInput={(e: { target: { value: string } }) => {
                              setPhoneNumber(e.target.value);
                            }}
                          />
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Typography>Please provide the doctor prescribing this medication</Typography>
                      <SecondaryButtonComponent
                        text={'Add Prescriber'}
                        onClick={handleAddPrescriberClick}
                      />
                    </>
                  )}
                </>
              ) : null}
              <Box marginTop={8}>
                <ProgressButtonGroupComponent
                  isLoading={isSubmitting}
                  nextOnClickCallback={submitForm}
                  previousOnClickCallback={onPreviousClick}
                  nextButtonProps={{
                    disabled: !isNextButtonEnabled,
                  }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

type StyledBoxProps = BoxProps & { hide?: boolean; inline?: boolean };

const Section = styled(Box)<StyledBoxProps>(({ theme, hide, inline }) => ({
  ...(inline
    ? {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }
    : {}),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  opacity: hide ? 0 : 1,
  visibility: hide ? 'hidden' : 'visible',
}));

export default ConfirmMedication;
