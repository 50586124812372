import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ContactInfoState = {
  usaState: string;
  shippingAddressId: string | null;
  address: string;
  address2?: string | null;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  isCellPhone: boolean | null;
  email: string;
};

const initialState: ContactInfoState = {
  usaState: '',
  shippingAddressId: null,
  address: '',
  address2: null,
  city: '',
  state: '',
  zip: '',
  phoneNumber: '',
  isCellPhone: null,
  email: '',
};

export const addressInfoSlice = createSlice({
  name: 'addressInfo',
  initialState,
  reducers: {
    setUsaState(state, action: PayloadAction<string>) {
      state.usaState = action.payload;
    },
    setShippingAddressId(state, action: PayloadAction<string | null>) {
      state.shippingAddressId = action.payload;
    },
    setAddress(state, action: PayloadAction<string>) {
      state.address = action.payload;
    },
    setAddress2(state, action: PayloadAction<string | null>) {
      state.address2 = action.payload;
    },
    setCity(state, action: PayloadAction<string>) {
      state.city = action.payload;
    },
    setState(state, action: PayloadAction<string>) {
      state.state = action.payload;
    },
    setZip(state, action: PayloadAction<string>) {
      state.zip = action.payload;
    },
    setPhoneNumber(state, action: PayloadAction<string>) {
      state.phoneNumber = action.payload;
    },
    setIsCellPhone(state, action: PayloadAction<boolean | null>) {
      state.isCellPhone = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
  },
});

export const {
  setUsaState,
  setShippingAddressId,
  setAddress,
  setAddress2,
  setCity,
  setState,
  setZip,
  setPhoneNumber,
  setIsCellPhone,
  setEmail,
} = addressInfoSlice.actions;

export default addressInfoSlice.reducer;

// Selectors
export const selectUsaState = (state: { addressInfo: ContactInfoState }): string =>
  state.addressInfo.usaState;

export const selectShippingAddressId = (state: { addressInfo: ContactInfoState }): string | null =>
  state.addressInfo.shippingAddressId;

export const selectAddress = (state: { addressInfo: ContactInfoState }): string =>
  state.addressInfo.address;

export const selectAddress2 = (state: {
  addressInfo: ContactInfoState;
}): string | null | undefined => state.addressInfo.address2;

export const selectCity = (state: { addressInfo: ContactInfoState }): string =>
  state.addressInfo.city;

export const selectState = (state: { addressInfo: ContactInfoState }): string =>
  state.addressInfo.state;

export const selectZip = (state: { addressInfo: ContactInfoState }): string =>
  state.addressInfo.zip;

export const selectPhoneNumber = (state: { addressInfo: ContactInfoState }): string =>
  state.addressInfo.phoneNumber;

export const selectIsCellPhone = (state: { addressInfo: ContactInfoState }): boolean | null =>
  state.addressInfo.isCellPhone;

export const selectEmailAddress = (state: { addressInfo: ContactInfoState }): string =>
  state.addressInfo.email;

export const selectAddressInfo = (state: { addressInfo: ContactInfoState }): ContactInfoState =>
  state.addressInfo;
