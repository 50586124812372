import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton, Input, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from 'yup';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { Formik, Form } from 'formik';

import { FILE_UPLOAD_MAX_SIZE_ERROR_MESSAGE } from 'App/Constants';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { useHistory } from 'react-router-dom';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import { useFileNeedsQuery, useUploadFilesMutation } from 'services/file';
import useNextStep from 'containers/NextStep/hooks/useNextStep';
import LoadingSpinner from 'Components/LoadingSpinner';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import useAuth from 'Hooks/useAuth';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import Page from 'Pages/BasePage';
import { validateSizeOfEachUploadedFileInArray } from 'containers/Common/commonFunctions';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '3px solid #D46833',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'auto',
  },
}));

interface UploadedFile {
  file: File;
  patientPersonId: string;
  taskKeystring: string;
  needTypeName: string;
}

const FileUploadLayout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();

  const classes = useStyles();
  const [putUploadFiles] = useUploadFilesMutation();
  const { goToNextStep } = useNextStep();
  const { data: fileNeedInfos, isLoading: isFileNeedInfosLoading } = useFileNeedsQuery();
  const [fileNeedInfosIndex, setFileNeedInfosIndex] = useState<number>(0);
  const [uploadedFilesArray, updateUploadedFiles] = useState([] as UploadedFile[]);
  const [fileNameArray, updateFileNameArray] = useState(['']);
  const [uploadError, setUploadError] = useState<string | undefined>();
  const [isMaxFileSizeExceeded, setIsMaxFileSizeExceeded] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setTitle('File Upload'));
  }, [dispatch]);

  const uploadFilesSchema = Yup.object().shape({
    uploadedFilesArray: Yup.array().min(1, 'At least one file needs to be uploaded'),
  });

  const handleContinueLater = () => {
    dispatch(setIsNextStepReminderEligible(false));
    history.push('/');
  };

  const nextFileInfoNeed = () => {
    const nextIndex = fileNeedInfosIndex + 1;

    if (fileNeedInfos && nextIndex === fileNeedInfos?.length) {
      goToNextStep();
    } else {
      setFileNeedInfosIndex(nextIndex);
    }
  };

  const uploadFile = async (singleUploadedFile: UploadedFile) => {
    const formToUploadFiles = new FormData();
    formToUploadFiles.append('file', singleUploadedFile.file);
    formToUploadFiles.append('patientPersonId', singleUploadedFile.patientPersonId);
    formToUploadFiles.append('taskKeystring', singleUploadedFile.taskKeystring);
    formToUploadFiles.append('needTypeName', singleUploadedFile.needTypeName);

    const response: any = await putUploadFiles(formToUploadFiles);
    if (response.error?.data?.userInputValidationError) {
      setUploadError(response.error.data.userInputValidationError);
    }
  };

  const handleUploadedFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    const doOneOrMoreExceedMaxSize = validateSizeOfEachUploadedFileInArray(files);

    if (!files || files.length === 0) {
      return;
    } else if (doOneOrMoreExceedMaxSize) {
      setIsMaxFileSizeExceeded(true);
      return;
    } else {
      setUploadError(undefined);
      setIsMaxFileSizeExceeded(false);
      const newUploadedFileName = files[0]!.name;

      let count = 1;
      let currentFileName = newUploadedFileName;
      while (fileNameArray.includes(currentFileName)) {
        const fileName =
          newUploadedFileName.substring(0, newUploadedFileName.lastIndexOf('.')) ||
          newUploadedFileName;
        const extension =
          newUploadedFileName.substring(
            newUploadedFileName.lastIndexOf('.'),
            newUploadedFileName.length
          ) || '';
        currentFileName = fileName + `(${count})` + extension;
        count++;
      }

      const adjustedFile: UploadedFile = {
        file: new File([files[0]], currentFileName, {
          type: files[0].type,
        }),
        patientPersonId: personId,
        taskKeystring: fileNeedInfo ? fileNeedInfo.taskKeystring : '',
        needTypeName: fileNeedInfo ? fileNeedInfo.needTypeName : '',
      };

      updateUploadedFiles([...uploadedFilesArray, adjustedFile]);
      updateFileNameArray([...fileNameArray, adjustedFile.file?.name]);

      event.target.value = '';
    }
  };

  const fileNeedInfo = fileNeedInfos?.[fileNeedInfosIndex];

  if (isFileNeedInfosLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Page>
      <Formik
        initialValues={{
          uploadedFile: '',
        }}
        validationSchema={uploadFilesSchema}
        onSubmit={async () => {
          await Promise.all(uploadedFilesArray.map((file) => uploadFile(file)));
          updateUploadedFiles([]);
          nextFileInfoNeed();
        }}
      >
        {(submitFormProps) => (
          <Form>
            <Box
              className={classes.screenBox}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                padding: 3,
                boxShadow: 3,
                borderRadius: 3,
                backgroundColor: '#fff',
              }}
            >
              <Typography
                align={'center'}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                File upload {fileNeedInfosIndex + 1} of {fileNeedInfos?.length} needed for{' '}
                {fileNeedInfo?.patientsName}:
              </Typography>
              <Typography
                align={'center'}
                variant={'h5'}
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                {fileNeedInfo?.needTypeName}
              </Typography>
              {fileNeedInfo?.description ? (
                <Typography color={'textPrimary'}>{fileNeedInfo?.description}</Typography>
              ) : null}
              {fileNeedInfo?.instructions ? (
                <Typography color={'textPrimary'}>
                  Instructions: {fileNeedInfo?.instructions}
                </Typography>
              ) : null}
              <Box display={'flex'} marginTop={2} alignItems={'center'}>
                <AlertComponent alertType="info" message="Upload files one at a time" />
              </Box>
              {uploadedFilesArray
                ? uploadedFilesArray.map((singleFile, index) => (
                    <Box key={index} display={'flex'} marginTop={2} alignItems={'center'}>
                      <Typography noWrap color={'primary'}>
                        {singleFile.file?.name}
                      </Typography>
                      <IconButton
                        id={singleFile.file?.name}
                        size={'small'}
                        aria-label={'Delete File'}
                        color={'secondary'}
                        onClick={() => {
                          // removes file from current list
                          updateUploadedFiles(
                            uploadedFilesArray.filter(
                              (item) => item.file?.name !== singleFile.file?.name
                            )
                          );
                          updateFileNameArray(
                            fileNameArray.filter((item: string) => item !== singleFile.file?.name)
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))
                : null}
              <Box marginTop={2} marginBottom={2}>
                <Input
                  name="uploadedFile"
                  type="file"
                  inputProps={{ accept: 'image/*,.pdf' }}
                  onChange={handleUploadedFile}
                />
              </Box>
              {uploadError ? (
                <AlertComponent
                  alertType="error"
                  title="Upload Unsuccessful"
                  message={uploadError}
                  onClose={() => setUploadError(undefined)}
                />
              ) : null}
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <PrimaryButtonComponent
                  text={'Submit Files'}
                  width={'wide'}
                  onClick={submitFormProps.handleSubmit}
                  isLoading={submitFormProps.isSubmitting}
                />
                {isMaxFileSizeExceeded && (
                  <AlertComponent
                    alertType="error"
                    title="File Not Uploaded"
                    message={FILE_UPLOAD_MAX_SIZE_ERROR_MESSAGE}
                    onClose={() => setIsMaxFileSizeExceeded(false)}
                  />
                )}
                <TertiaryButtonComponent text={'Continue later'} onClick={handleContinueLater} />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default FileUploadLayout;
