import { Typography, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import RadioGroupComponent from 'Components/RadioGroupComponent/RadioGroupComponent';
import Dialog from 'Components/Dialog';
import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import { FunctionComponent } from 'react';

interface SignatureDialogProps {
  onClose: () => void;
  open: boolean;
  onRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSignatureSelection: (event: React.FormEvent<HTMLFormElement>) => void;
  signatureName: string;
}

const useStyles = makeStyles(() => ({
  dialogButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sig1: {
    fontSize: '25px',
    fontFamily: 'Dancing Script',
  },
  sig2: {
    fontFamily: 'Fuggles',
    fontSize: '25px',
  },
  sig3: {
    fontFamily: 'Allison',
    fontSize: '25px',
  },
  sig4: {
    fontFamily: 'Liu Jian Mao Cao',
    fontSize: '25px',
  },
}));

const SignatureDialog: FunctionComponent<SignatureDialogProps> = (props) => {
  const { onClose, open, onRadioChange, signatureName = '', onSignatureSelection } = props;

  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open} title="Choose your signature">
      <RadioGroupComponent
        label={'Choose the digital signature you prefer'}
        name={'signatureValue'}
        onChange={onRadioChange}
        radioOptions={[
          {
            label: (
              <Typography
                className={classes.sig1}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontSize: '1.5rem' }}
              >
                {signatureName}
              </Typography>
            ),
            value: 'Dancing Script',
          },
          {
            label: (
              <Typography
                className={classes.sig2}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontSize: '1.5rem' }}
              >
                {signatureName}
              </Typography>
            ),
            value: 'Fuggles',
          },
          {
            label: (
              <Typography
                className={classes.sig3}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontSize: '1.5rem' }}
              >
                {signatureName}
              </Typography>
            ),
            value: 'Allison',
          },
          {
            label: (
              <Typography
                className={classes.sig4}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontSize: '1.5rem' }}
              >
                {signatureName}
              </Typography>
            ),
            value: 'Liu Jian Mao Cao',
          },
        ]}
      />
      <DialogActions className={classes.dialogButtons}>
        <SecondaryButtonComponent text={'Choose Signature'} onClick={onSignatureSelection} />
        <TertiaryButtonComponent text={'Close'} onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default SignatureDialog;
