import { Redirect } from 'react-router-dom';

import { Route } from 'react-router';
import useAuth from 'Hooks/useAuth';

const AuthenticatedRoute = (props: any) => {
  const { children, render, ...rest } = props;
  const { isLoggedIn } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => (isLoggedIn ? render(props) : <Redirect to={'/login'} />)}
    />
  );
};

export default AuthenticatedRoute;
