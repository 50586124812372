import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useConfirmAcknowledgementsMutation, useGetUserDetailsQuery } from 'services/user';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import useAuth from 'Hooks/useAuth';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import { WizardProps } from 'containers/Wizards/types';

const AcknowledgementsLayout: FunctionComponent<WizardProps> = (props) => {
  const { onComplete } = props;
  const dispatch = useDispatch();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;
  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const firstName = userDetails ? userDetails.firstName : 'N/A';
  const lastName = userDetails ? userDetails.lastName : 'N/A';
  const userInitials = `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  const [postConfirmAcknowledgements] = useConfirmAcknowledgementsMutation();

  useEffect(() => {
    dispatch(setTitle('Application User Acknowledgements'));
  }, [dispatch]);

  const AcknowledgementsSchema = Yup.object().shape({
    initials: Yup.string()
      .uppercase()
      .oneOf([userInitials], "Characters don't match your initials")
      .required('Initials Required'),
  });

  const useStyles = makeStyles((theme) => ({
    screenBox: {
      padding: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Formik
        initialValues={{
          initials: '',
        }}
        validationSchema={AcknowledgementsSchema}
        onSubmit={async () => {
          await postConfirmAcknowledgements();

          onComplete();
        }}
      >
        {(submitAcknowledgementsProp) => (
          <Form>
            <Box
              className={classes.screenBox}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography
                align={'center'}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                Application User Acknowledgements
                <br />
              </Typography>
              <Paper elevation={2}>
                <Typography
                  align={'left'}
                  variant={'body2'}
                  color={'textPrimary'}
                  component={'div'}
                  style={{
                    overflow: 'scroll',
                    height: '250px',
                  }}
                >
                  <ul>
                    <li>{appName} is not a pharmacy</li>
                    <li>{appName} is not insurance</li>
                    <li>
                      {appName} will be acting as a prescription advocate in order to assist with
                      acquiring prescribed medication
                    </li>
                    <li>{appName} cannot guarantee your medications will be free</li>
                    <li>
                      {appName} may contact physicians to discuss and request patient’s health
                      information
                    </li>
                    <li>
                      {appName} is authorized to provide patient health, financial and other
                      information to drug manufacturers, pharmacies and third party administrators
                      of co-pay cards and grants
                    </li>
                    <li>
                      {appName} is authorized to sign patient assistance program documents on
                      patient’s behalf
                    </li>
                  </ul>
                </Typography>
              </Paper>
              <Box display={'flex'} marginTop={2}>
                <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                  I, {firstName} {lastName}, verify that I have read all of the Acknowledgements
                  described above.
                </Typography>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                marginBottom={1}
              >
                <Box flexBasis="60%">
                  <TextFieldComponent
                    label="Initial Here"
                    name={'initials'}
                    fullWidth={false}
                    inputProps={{ maxLength: 2 }}
                    onInput={(e: { target: { value: string } }) => {
                      e.target.value = e.target.value.slice(0, 2);
                    }}
                  />
                </Box>
              </Box>
              <Box marginTop={1} marginBottom={1}>
                <PrimaryButtonComponent
                  isLoading={submitAcknowledgementsProp.isSubmitting}
                  text={'Submit'}
                  width={'wide'}
                  onClick={submitAcknowledgementsProp.handleSubmit}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AcknowledgementsLayout;
