import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FormikHelpers } from 'formik';

import { Box, Typography } from '@mui/material';
import ErrorIconComponent from 'Components/ErrorIconComponent/ErrorIconComponent';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { useVerifyRegistrationMutation } from 'services/register';
import { useGetUserDetailsQuery } from 'services/user';

import SSNConfirm from './SSNConfirm';
import DateOfBirthConfirm from './DateOfBirthConfirm';
import DefaultConfirm from './DefaultConfirm';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import LoadingSpinner from 'Components/LoadingSpinner';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import LeadLogoComponent from 'Components/LeadLogoComponent/LeadLogoComponent';
import { useLoggerService } from 'api/loggerService';

interface IdentityConfirmLayoutProps {
  nextOnClickCallback(): void;
  previousOnClickCallback?(): void;
  contextData: {
    personId?: string;
    groupId?: string;
    groupName?: string;
    isOpenEnrollmentAllowed?: boolean;
  };
  onSaveData(payload: {
    registrationType?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    token?: string;
  }): void;
}

interface IdentityConfirmValues {
  firstName: string;
  lastName: string;
  dateOfBirth: string | null;
  ssnLastFour: string | null;
}

const IdentityConfirmLayout: FunctionComponent<IdentityConfirmLayoutProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  contextData,
  onSaveData,
}) => {
  const { personId, groupId, groupName, isOpenEnrollmentAllowed } = contextData || {};

  const dispatch = useDispatch();
  const [verificationError, setVerificationError] = useState<string | undefined>();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;

  const { data: userMemberDetails, isLoading } = useGetUserDetailsQuery(personId || '', {
    skip: !personId,
  });
  const isIncompleteRegistrationType =
    !groupId && !userMemberDetails?.hasSsn && !userMemberDetails?.hasDateOfBirth;

  const [verifyRegistration] = useVerifyRegistrationMutation();
  const { log } = useLoggerService();

  const handleSubmit = async (
    values: IdentityConfirmValues,
    { setSubmitting }: FormikHelpers<IdentityConfirmValues>
  ) => {
    const response: any = await verifyRegistration({
      dateOfBirth: values.dateOfBirth,
      ssnLastFour: values.ssnLastFour,
      firstName: userMemberDetails?.firstName || values.firstName,
      lastName: userMemberDetails?.lastName || values.lastName,
      personId: personId,
      groupId: groupId,
    });

    setSubmitting(false);

    if (response.error) {
      if (response.error.data?.userInputValidationError) {
        if (isOpenEnrollmentAllowed) {
          // We can't find them in the system ...
          onSaveData({
            registrationType: 'new', // so we set the registrationType as 'new'
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth: values.dateOfBirth || '',
          });
          nextOnClickCallback();
        } else {
          // otherwise, we found them, but verification failed
          setVerificationError(
            'There was an issue verifying your information. Double check your information is correct, if the problem persists please contact your human resources department.'
          );
        }
      }
    } else {
      onSaveData({
        token: response?.data?.registrationToken,
      });
      // identity confirmed, no errors
      nextOnClickCallback();
    }
  };

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
  }, [appName, dispatch]);

  useEffect(() => {
    if (isIncompleteRegistrationType && contextData) {
      try {
        log({
          statusCode: 'React_Component_Error',
          message: [
            'Incomplete registration error shown',
            `contextData: ${JSON.stringify(contextData)}`,
            `location: ${window.location}`,
          ].join(';'),
        });
      } catch (e) {
        // nothing
      }
    }
  // Purposely only include isIncompleteRegistrationType, as other values do not matter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIncompleteRegistrationType]);

  const Component: any = useMemo(() => {
    if (userMemberDetails?.hasSsn) {
      return SSNConfirm;
    } else if (userMemberDetails?.hasDateOfBirth) {
      return DateOfBirthConfirm;
    } else {
      return DefaultConfirm;
    }
  }, [userMemberDetails]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isIncompleteRegistrationType) {
    return (
      <Box>
        <ErrorIconComponent />
        <Typography
          align={'center'}
          variant={'body1'}
          color={'textPrimary'}
          paragraph
          sx={{
            marginBottom: 20,
            fontWeight: 'bold',
          }}
        >
          Uh oh! It looks like your employer hasn&apos;t finished the setup process. Please contact
          your human resources department.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <LeadLogoComponent />
      {verificationError ? (
        <AlertComponent alertType="error" message={verificationError} />
      ) : undefined}
      <Typography
        align={'center'}
        variant={'h5'}
        color={'primary'}
        paragraph
        style={{
          marginBottom: 20,
          fontWeight: 'bold',
        }}
      >
        {groupName}
      </Typography>
      <Component
        firstName={userMemberDetails?.firstName || ''}
        lastName={userMemberDetails?.lastName || ''}
        onSubmit={handleSubmit}
        personId={personId}
        appName={appName}
        onPreviousStep={previousOnClickCallback}
        isOpenEnrollmentAllowed={isOpenEnrollmentAllowed}
      />
    </>
  );
};

export default IdentityConfirmLayout;
