import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

import LoginLayout from './Layouts/LoginLayout/LoginLayout';
import CreateNewLPasswordLayout from './Layouts/CreateNewPasswordLayout';
import ForgotPasswordLayout from './Layouts/ForgotPasswordLayout';
import EmailConfirmedLayout from './Layouts/EmailConfirmedLayout';
import Wizard from 'containers/Wizards/Wizard';

interface LoginViewRouterParams {
  layout?: string;
}

const COMPONENT_MAP: Record<string, FunctionComponent> = {
  forgotPassword: ForgotPasswordLayout,
  createNewPassword: CreateNewLPasswordLayout,
  confirmEmail: EmailConfirmedLayout,
  default: LoginLayout,
};

const LoginView: FunctionComponent = () => {
  const { layout = 'default' } = useParams<LoginViewRouterParams>();

  const Component = COMPONENT_MAP[layout] || LoginLayout;

  return (
    <Wizard>
      <Component />
    </Wizard>
  );
};

export default LoginView;
