import React, { useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dialog from 'Components/Dialog';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import useAuth from 'Hooks/useAuth';
import { SendProxyAuthInfo, useSendProxyAuthorizationMutation } from 'services/hipaaAuth';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';

import SelectComponent from 'Components/SelectComponent/SelectComponent';
import { Member } from 'services/user';
import { useGetContactInformationQuery } from 'services/person';
import LoadingSpinner from 'Components/LoadingSpinner';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

const useStyles = makeStyles((theme) => ({
  textfield: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 3),
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '3px solid #D46833',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const phoneRegExp = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-/\s.]|\d)+$/;
const ProxyAuthSchema = Yup.object().shape({
  email: Yup.string().email(),
  text: Yup.string().matches(phoneRegExp, 'Invalid value').min(10, 'Invalid value'),
});

interface ProxyAuthValues {
  email: string;
  text: string;
}

type EditAddressDialogProps = {
  onClose: () => void;
  open: boolean;
  proxyMember: Member;
};

const ProxyAuthDialog: React.FC<EditAddressDialogProps> = (props) => {
  const classes = useStyles();
  const { onClose, open, proxyMember } = props;
  const { firstName, lastName, patientId } = proxyMember || {};
  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: contactInformation, isLoading: isLoadingContactInfo } =
    useGetContactInformationQuery(personId);
  const [requestProxyAuth] = useSendProxyAuthorizationMutation();
  const [proxyContactInvalid, setProxyContactInvalid] = useState(false);
  const [invalidContact, setInvalidContact] = useState(false);
  const [proxyAuthSent, setProxyAuthSent] = useState(false);
  const [emailOrText, setEmailOrText] = useState('');

  const handleAlertClose = () => {
    setProxyAuthSent(false);
    setInvalidContact(false);
    onClose();
  };

  const checkIfContactIsValid = (proxyContactInfo: string) => {
    if (proxyContactInfo === '') {
      setInvalidContact(true);
      setProxyContactInvalid(false);
      setProxyAuthSent(false);
    } else if (
      proxyContactInfo === contactInformation?.emailPrimary ||
      proxyContactInfo === contactInformation?.emailSecondary ||
      proxyContactInfo.replace(/[^\d]/g, '') ===
        (contactInformation?.phonePrimary
          ? contactInformation.phonePrimary.replace(/[^\d]/g, '')
          : null) ||
      proxyContactInfo.replace(/[^\d]/g, '') ===
        (contactInformation?.phoneSecondary
          ? contactInformation.phoneSecondary.replace(/[^\d]/g, '')
          : null)
    ) {
      setInvalidContact(false);
      setProxyContactInvalid(true);
      setProxyAuthSent(false);
      return false;
    } else {
      setProxyContactInvalid(false);
      return true;
    }
  };

  if (isLoadingContactInfo) {
    return <LoadingSpinner />;
  }

  return (
    <Dialog title="Prescriptions Authorization Request" onClose={handleAlertClose} open={open}>
      <Formik
        initialValues={{
          email: '',
          text: '',
        }}
        validationSchema={ProxyAuthSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);

          if (checkIfContactIsValid(values.email ? values.email : values.text)) {
            const proxyAuthInfo: SendProxyAuthInfo = {
              requesterPersonId: personId,
              patientPersonId: patientId,
              emailOrText: values.email ? values.email : values.text,
            };

            requestProxyAuth(proxyAuthInfo);

            setProxyAuthSent(true);
            setProxyContactInvalid(false);
            setInvalidContact(false);
          }
        }}
      >
        {({ isSubmitting, submitForm, setFieldValue }: FormikProps<ProxyAuthValues>) => (
          <Form>
            <InfoLayoutBodyComponent
              header={`Choose an option below to send a request for authorization to ${firstName} ${lastName}.`}
              paragraph={`The request will contain a link back to this system where ${firstName} can confirm authorization.`}
            />
            <SelectComponent
              name={'emailOrText'}
              label={'Would you like to send an email or text?'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmailOrText(e.target.value);
                setFieldValue('emailOrText', e.target.value);

                if (e.target.value === 'email') {
                  setFieldValue('text', '');
                } else if (e.target.value === 'text') {
                  setFieldValue('email', '');
                } else {
                  setFieldValue('text', '');
                  setFieldValue('email', '');
                }
              }}
              selectOptions={[
                { value: 'email', label: 'Email' },
                { value: 'text', label: 'Text' },
              ]}
            />
            {emailOrText === 'email' ? (
              <Box className={classes.textfield}>
                <TextFieldComponent label={'Email'} name={'email'} />
                <PrimaryButtonComponent
                  text={'Send Email'}
                  onClick={submitForm}
                  isLoading={isSubmitting}
                />
              </Box>
            ) : emailOrText === 'text' ? (
              <Box className={classes.textfield}>
                <TextFieldComponent label={'Phone Number'} name={'text'} />
                <PrimaryButtonComponent
                  text={'Send Text'}
                  onClick={submitForm}
                  isLoading={isSubmitting}
                />
              </Box>
            ) : null}
            {proxyContactInvalid ? (
              <AlertComponent
                alertType="error"
                title="Message not sent"
                message="Please provide an email address or phone number that is not your own."
              />
            ) : null}
            {invalidContact ? (
              <AlertComponent
                alertType="error"
                title="Message not sent"
                message="Please provide an email address or phone number."
              />
            ) : null}
            {proxyAuthSent ? (
              <AlertComponent
                alertType="success"
                title="Prescription Authorization Sent!"
                message={`After ${firstName} approves the authorization, their prescription information will be available to view.`}
                onClose={handleAlertClose}
              />
            ) : null}
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ProxyAuthDialog;
