import { FunctionComponent, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Medication, useAddMedicationMutation } from 'services/medication';
import { selectIsNextStepReminderEligible } from 'Store/Reducers/appSlice';

import {
  useGetAllAssistedMedsUserCanSeeQuery,
  useGetAllNotAssistedMedsUserCanSeeQuery,
} from 'services/medication';

import AlertComponent from 'Components/AlertComponent/AlertComponent';
import MedicationEntry from 'Components/MedicationEntry';
import { setTitle } from 'Store/Reducers/appInfoSlice';
import useNextStep from 'containers/NextStep/hooks/useNextStep';

import Wizard from 'containers/Wizards/Wizard';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

interface AddMedicationParams {
  personId: string;
}

const Medications: FunctionComponent = () => {
  const { personId } = useParams<AddMedicationParams>();
  const history = useHistory();
  const { href, nextStep, refetchNextStep, isLoading: isLoadingNextStep } = useNextStep();
  const [postAddMedication] = useAddMedicationMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [didAddMedication, setDidAddMedication] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { data: assistedMedications } = useGetAllAssistedMedsUserCanSeeQuery();
  const { data: notAssistedMedications } = useGetAllNotAssistedMedsUserCanSeeQuery();

  const dispatch = useDispatch();
  const isNextStepReminderEligible = useSelector(selectIsNextStepReminderEligible);

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    setErrorMessage(undefined);

    const drugId = values.drug?.id ?? 0;
    const patientId = values.recipient;

    const medicationExistsInAssisted = assistedMedications?.some(
      (med: Medication) => med.patientPersonId === patientId && med.drugId === drugId
    );

    const medicationExistsInNotAssisted = notAssistedMedications?.some(
      (med: Medication) => med.patientPersonId === patientId && med.drugId === drugId
    );

    if (medicationExistsInAssisted || medicationExistsInNotAssisted) {
      setErrorMessage('This medication is already in the list for this person.');
      setIsSubmitting(false);
      return;
    }
    const newMedication: Medication = {
      medicationId: null,
      patientPersonId: patientId,
      drugId: drugId,
      name: values.drug?.name ?? '',
      strength: null,
      quantity: null,
      inhaler: false,
      tempSensitive: false,
      papQualified: false, // this shouldn't be here
      prescriberId: values.prescriberId ?? '',
      assistanceType: null,
      priorAuthDetailsMaybe: null,
      new: true,
      authorizedToView: true,
      medicationName: values.drug?.name ?? '',
      assistanceTypeNdxMaybe: '',
      isPrescriberRequired: true,
    };

    const response: any = await postAddMedication(newMedication); // Add spinner for loading state...
    setDidAddMedication(true);
    if (response.error?.data?.userInputValidationError) {
      setErrorMessage(response.error.data.userInputValidationError);
    } else {
      refetchNextStep();
    }
    setIsSubmitting(false);
  };

  const handleCancellation = () => {
    history.push('/medications');
  };

  const classes = useStyles();

  useEffect(() => {
    dispatch(setTitle('Add Medication'));
  }, [dispatch]);

  useEffect(() => {
    if (didAddMedication && !isLoadingNextStep) {
      setDidAddMedication(false);

      if (isNextStepReminderEligible && href) {
        history.push(href);
      } else {
        history.push('/medications');
      }
    }
  }, [href, nextStep, isLoadingNextStep, history, isNextStepReminderEligible, didAddMedication]);

  return (
    <Wizard>
      <Box>
        <Typography
          align={'center'}
          variant={'body1'}
          color={'textPrimary'}
          style={{ fontWeight: 'bold' }}
        >
          Please provide the medication and the doctor who prescribed it
        </Typography>
      </Box>
      <MedicationEntry
        medication={{
          recipient: personId,
        }}
        onSubmit={handleSubmit}
        onPreviousClick={handleCancellation}
        previousBtnText="Cancel"
        nextbtntext="Submit"
        isSubmitting={isSubmitting}
        errorMessage={errorMessage}
      />
      {errorMessage ? (
        <AlertComponent
          alertType="error"
          title="Medication Info Invalid"
          message={errorMessage}
          onClose={() => setErrorMessage(undefined)}
          className={classes.margin}
        />
      ) : null}
    </Wizard>
  );
};

export default Medications;
