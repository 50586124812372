import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import { PersonalInfoStepProps } from '..';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Intro: FunctionComponent<PersonalInfoStepProps> = ({ onNextStep, contextData }) => {
  const dispatch = useDispatch();
  const firstName = contextData.patient.firstName;

  useEffect(() => {
    dispatch(setTitle('Personal Details'));
  }, [dispatch]);

  const classes = useStyles();

  return (
    <Box className={classes.screenBox}>
      {contextData.isUser ? (
        <>
          <InfoLayoutBodyComponent
            header={<span>Information about you</span>}
            paragraph={
              <span>
                Please fill in any personal details we may be missing and confirm the data we have
                is correct. If you need to change any info you&apos;ve provided, you can do so from
                the Profile page after you have finished setting up your account.
              </span>
            }
          />
          <PrimaryButtonComponent text="Next" onClick={onNextStep} />
        </>
      ) : (
        <>
          <InfoLayoutBodyComponent
            header={<span>Information about {firstName}</span>}
            paragraph={
              <span>
                Please fill in any personal details we may be missing and confirm the data we have
                for {firstName}.
              </span>
            }
          />
          <ProgressButtonGroupComponent
            nextOnClickCallback={onNextStep}
            // TODO: not sure how to get this to go to the previous person
            //previousOnClickCallback={onPreviousStep}
          />
        </>
      )}
    </Box>
  );
};

export default Intro;
