import { FunctionComponent } from 'react';
import ErrorIcon from '@mui/icons-material/Error';

interface ErrorIconProps {
  iconSize?: number;
  marginBottom?: number;
}

const ErrorIconComponent: FunctionComponent<ErrorIconProps> = (props) => {
  const { iconSize = 130, marginBottom = 5 } = props;

  return (
    <ErrorIcon
      color={'error'}
      sx={{
        fontSize: iconSize,
        marginBottom: marginBottom,
      }}
    />
  );
};

export default ErrorIconComponent;
