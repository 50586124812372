import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

export interface Message {
  date: string;
  name: string;
  message: string;
}

export const messagingApi = createApi({
  reducerPath: 'services/messaging',
  tagTypes: ['Message'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getMessages: builder.query<Message[], void>({
      query: () => {
        return {
          url: 'messaging',
          method: 'get',
        };
      },
      providesTags: ['Message'],
    }),
    getUnreadMessageStatus: builder.query<boolean, void>({
      query: () => {
        return {
          url: 'messaging/unreadMessageStatus',
          method: 'get',
        };
      },
      providesTags: ['Message'],
    }),
    markAllMessagesAsRead: builder.mutation<void, void>({
      query: () => {
        return {
          url: 'messaging/markAllMessagesAsRead',
          method: 'post',
        };
      },
    }),
    addMessage: builder.mutation({
      query: (message) => {
        return {
          url: 'messaging',
          method: 'post',
          data: message,
        };
      },
      invalidatesTags: ['Message'],
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useGetUnreadMessageStatusQuery,
  useMarkAllMessagesAsReadMutation,
  useAddMessageMutation,
} = messagingApi;
