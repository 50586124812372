import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import { HipaaWizardStepProps } from '..';
import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';

const useStyles = makeStyles((theme) => ({
  margins: {
    margin: theme.spacing(1),
  },
}));

const HipaaCompleteLayout: FunctionComponent<HipaaWizardStepProps> = ({ onComplete }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setTitle('HIPAA Authorization'));
  }, [dispatch]);

  return (
    <Box>
      <Typography className={classes.margins} variant="h6">
        HIPAA Authorization Complete
      </Typography>
      <Typography className={classes.margins}>
        Your HIPAA authorization will remain in effect for three (3) years from the date of
        signature or once you are no longer eligible for benefits. A copy of the signed HIPAA
        Privacy Notice is always available in the Files section of the application.
      </Typography>
      <SecondaryButtonComponent text="done" onClick={onComplete} />
    </Box>
  );
};

export default HipaaCompleteLayout;
