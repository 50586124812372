import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { MailOrderProps } from '..';

const PharmacyConsultLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Typography
        align={'center'}
        variant={'body1'}
        color={'textPrimary'}
        style={{ fontWeight: 'bold' }}
      >
        Pharmacy Consult
      </Typography>

      <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
        At times it may be necessary for a health care professional to consult with you regarding
        your medication(s). You may receive a phone call from the pharmacy if a consult is needed.
        It will be important for you to respond quickly to avoid any delays in processing your
        order. If you miss a phone call from the pharmacy, please contact them back right away.
      </Typography>

      <Box marginBottom={2} marginTop={1}>
        <ProgressButtonGroupComponent
          nextOnClickCallback={nextOnClickCallback}
          previousOnClickCallback={previousOnClickCallback}
        />
      </Box>
    </Box>
  );
};

export default PharmacyConsultLayout;
