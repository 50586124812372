import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';
import { medicationObj } from '../App/contexts/constants';
export interface Medication {
  medicationId: string | null;
  patientPersonId: string;
  drugId: number | null;
  name: string;
  medicationName?: string;
  strength: string | null;
  quantity: number | null;
  inhaler: boolean;
  tempSensitive: boolean;
  prescriberId: string;
  papQualified: boolean;
  assistanceType: string | null;
  priorAuthDetailsMaybe: string | null;
  assistanceStatus?: string;
  waitingOnStatus?: string;
  additionalInstructions?: string;
  new: boolean;
  authorizedToView: boolean;
  assistanceTypeNdxMaybe: string;
  isPrescriberRequired: boolean;
}

export interface DrugsToCheck {
  householdIncome: number;
  numberOfAdults: number;
  numberOfChildren: number;
  homeState: string;
  drugList: Array<medicationObj>;
}

export const medicationApi = createApi({
  reducerPath: 'services/medication',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ['PapQualified', 'Medication'],
  endpoints: (builder) => ({
    editMedication: builder.mutation({
      query: (editMedInfo: Medication) => {
        return {
          url: 'medication',
          method: 'put',
          data: editMedInfo,
        };
      },
      invalidatesTags: ['Medication'],
    }),
    addMedication: builder.mutation({
      query: (addMedInfo: Medication) => {
        return {
          url: 'medication',
          method: 'post',
          data: addMedInfo,
        };
      },
      invalidatesTags: ['PapQualified'],
    }),
    addReferredMedication: builder.mutation({
      query: (addMedInfo: Medication) => {
        return {
          url: 'medication/addReferredMedication',
          method: 'post',
          data: addMedInfo,
        };
      },
      invalidatesTags: ['PapQualified'],
    }),
    cancelMedication: builder.mutation({
      query: (medicationId) => {
        return {
          url: `medication/cancelMed/${medicationId}`,
          method: 'post',
        };
      },
    }),
    getIfPapQualified: builder.query<boolean, void>({
      query: () => {
        return {
          url: 'medication/papInfoNeeded',
          method: 'get',
        };
      },
      providesTags: ['PapQualified'],
    }),
    getMedicationGvnId: builder.query<Medication, string>({
      query: (medicationId: string) => {
        return {
          url: 'medication',
          method: 'get',
          params: {
            medicationId: medicationId,
          },
        };
      },
      providesTags: ['Medication'],
    }),
    getAllAssistedMedsUserCanSee: builder.query<Medication[], void>({
      query: () => {
        return {
          url: 'medications/allAssisted',
          method: 'get',
        };
      },
    }),
    getAllNotAssistedMedsUserCanSee: builder.query<Medication[], void>({
      query: () => {
        return {
          url: 'medications/allNotAssisted',
          method: 'get',
        };
      },
    }),
    checkDrugsQualifyForPap: builder.mutation({
      query: (drugToCheckInfo: DrugsToCheck) => {
        return {
          url: 'medication/checkDrugsQualifyForPap',
          method: 'post',
          data: drugToCheckInfo,
        };
      },
    }),
    getSimilarNameDrugs: builder.query({
      query: (search: string) => {
        return {
          url: 'drugs/getsimilarnames',
          method: 'get',
          params: {
            partialDrugName: search,
          },
        };
      },
    }),
  }),
});

export const {
  useEditMedicationMutation,
  useAddMedicationMutation,
  useCancelMedicationMutation,
  useGetIfPapQualifiedQuery,
  useGetMedicationGvnIdQuery,
  useGetAllAssistedMedsUserCanSeeQuery,
  useGetAllNotAssistedMedsUserCanSeeQuery,
  useCheckDrugsQualifyForPapMutation,
  useLazyGetSimilarNameDrugsQuery,
  useAddReferredMedicationMutation,
} = medicationApi;
