import { FunctionComponent } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Box, Typography } from '@mui/material';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import * as yup from 'yup';
import { StepProps } from 'containers/Wizards/types';

interface Props extends StepProps {
  firstName: string;
  lastName: string;
  appName: string;
  onSubmit: (values: any, helpers: FormikHelpers<any>) => void;
}

const initialValues = {
  dateOfBirth: '',
};

const identityConfirmSchema = yup.object().shape({
  dateOfBirth: yup
    .string()
    .required('Required')
    .matches(
      /^(0?[1-9]|1[0-2])(\/|-|\s|\.)(0?[1-9]|1\d|2\d|3[01])(\/|-|\s|\.)(19|20|)\d{2}$/gi,
      'Must be a valid date. (MM/DD/YYYY)'
    ),
});

const DateOfBirthConfirm: FunctionComponent<Props> = (props) => {
  const { firstName, lastName, appName, onSubmit } = props;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={identityConfirmSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Box>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Welcome to {appName}, {firstName}
              <br />
              Please verify your identity by entering your date of birth.
            </Typography>
            <Typography align={'center'} variant={'body2'} color={'textSecondary'} paragraph>
              If you are not {firstName} {lastName}, please contact your HR department.
            </Typography>
            <Box marginBottom={3} marginTop={2}>
              <TextFieldComponent label={'Date of Birth'} name={'dateOfBirth'} />
            </Box>
            <ProgressButtonGroupComponent
              isLoading={isSubmitting}
              nextButtonProps={{
                type: 'submit',
              }}
              nextOnClickCallback={submitForm}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default DateOfBirthConfirm;
