import { FunctionComponent, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import { LoginSelfService } from './.';
import useAuth from 'Hooks/useAuth';

import {
  RegistrationInfo,
  useRegisterExistingMemberMutation,
  useCheckIfMemberAlreadyRegisteredQuery,
  usePostValidateEmailMutation,
} from 'services/register';

import { scriptCoShieldGroupIdEncoded } from 'App/contexts/constants';

import { selectRegistrationTempToken } from 'Store/Reducers/authSlice';
import {
  selectFirstName,
  selectLastName,
  selectPersonDOB,
  selectPersonIDEncrypted,
} from 'Store/Reducers/personInfoSlice';

import { selectEmailAddress } from 'Store/Reducers/contactInfoSlice';

import { setIsMemberAlreadyRegistered } from 'Store/Reducers/memberSlice';

import ImageComponent from 'Components/ImageComponent/ImageComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { useGetAdvocateInfoQuery } from 'services/user';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

interface RegisterAccountLayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
}

interface RegistrationInfoProps {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  groupId: string;
  oldIndividualGroupIdMaybe: number | undefined;
  username: string;
  password: string;
  passwordConfirmation: string;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  leadIcon: {
    'max-width': '100%',
    'max-height': '20vh',
    background: '#D46833',
    borderRadius: '90px',
    padding: '25px',
    marginBottom: '20px',
  },
  accentText: {
    color: theme.palette.secondary.main,
    'font-weight': 'bold',
  },
}));

const RegisterAccountLayout: FunctionComponent<RegisterAccountLayoutProps> = ({ onNextStep }) => {
  const dispatch = useDispatch();
  const { login, data: loginData, isEmailNotConfirmed } = useAuth();

  const [postRegisterExistingMember] = useRegisterExistingMemberMutation();
  const [postValidateEmail] = usePostValidateEmailMutation();
  const currentFirstName = useSelector(selectFirstName);
  const currentLastName = useSelector(selectLastName);
  const currentDOB = useSelector(selectPersonDOB);
  const currentEmail = useSelector(selectEmailAddress);

  const tempRegistrationToken = useSelector(selectRegistrationTempToken);
  const personIdEncrypted = useSelector(selectPersonIDEncrypted);

  const [errorMessage, setErrorMsg] = useState<string | undefined>();

  const handleLogIn = () => {
    onNextStep();
  };

  const { data: isMemberAlreadyRegistered, isLoading: isMemberAlreadyRegisteredLoading } =
    useCheckIfMemberAlreadyRegisteredQuery(personIdEncrypted, {
      skip: !personIdEncrypted,
    });

  useEffect(() => {
    if (isMemberAlreadyRegistered && !isMemberAlreadyRegisteredLoading) {
      dispatch(setIsMemberAlreadyRegistered(true));
    }
  }, [isMemberAlreadyRegistered]);

  const accountCreationSchema = yup.object().shape({
    password: yup
      .string()
      .required(
        'Password needs to be at least 8 characters long, with a number, uppercase and lowercase, and a special character like an ! or @'
      )
      .min(8, 'Password must be at least 8 characters long')
      .matches(/\d/g, 'Password must contain at least 1 number')
      .matches(
        /[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g,
        'Password must contain at least 1 special character'
      )
      .matches(/[A-Z]/g, 'Password must contain at least 1 uppercase letter')
      .matches(/[a-z]/g, 'Password must contain at least 1 lowercase letter'),
    passwordConfirmation: yup
      .string()
      .required('Both password fields must match')
      .oneOf([yup.ref('password')], 'Passwords must match')
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref('password')], 'Both password fields must match.'),
      }),
  });

  return (
    <Fragment>
      {!isMemberAlreadyRegisteredLoading && !isMemberAlreadyRegistered ? (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            groupId: '',
            oldIndividualGroupIdMaybe: undefined,
            username: '',
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={accountCreationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const registerNewValues: RegistrationInfo = {
              firstName: currentFirstName,
              lastName: currentLastName,
              dateOfBirth: currentDOB,
              groupId: scriptCoShieldGroupIdEncoded,
              oldIndividualGroupIdMaybe: undefined,
              emailAddress: currentEmail,
              confirmEmail: false,
              username: values.username,
              password: values.password,
              passwordConfirmation: values.passwordConfirmation,
            };

            const response: any = await postRegisterExistingMember({
              values: registerNewValues,
              token: tempRegistrationToken,
            });

            if (response.error) {
              if (response.error.data?.userInputValidationError) {
                setErrorMsg(response.error.data.userInputValidationError);
              } else {
                setErrorMsg('Unable to create your account');
              }
            } else {
              await postValidateEmail({
                personIdEncrypted,
                emailAddress: currentEmail,
              });

              await login(
                {
                  username: values.username,
                  password: values.password,
                },
                false
              );
              onNextStep();
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, submitForm }: FormikProps<RegistrationInfoProps>) => (
            <Form>
              <Box marginBottom={1}>
                <Typography
                  align={'center'}
                  variant={'body1'}
                  color={'textPrimary'}
                  style={{ fontWeight: 'bold' }}
                >
                  You will need to set up a unique username for yourself, as well as a secure
                  password to access the member portal. We recommend using your email address as
                  your username.
                </Typography>
              </Box>
              <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                Password requirements: at least 8 characters long, with a number, uppercase and
                lowercase, and a special character like an ! or @
              </Typography>
              <Box marginTop={2}>
                <TextFieldComponent
                  label={'Username'}
                  name={'username'}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      submitForm();
                    }
                  }}
                />
                <TextFieldComponent
                  label={'Password'}
                  name={'password'}
                  type={'password'}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      submitForm();
                    }
                  }}
                />
                <TextFieldComponent
                  label={'Confirm Password'}
                  name={'passwordConfirmation'}
                  type={'password'}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      submitForm();
                    }
                  }}
                />
              </Box>
              {errorMessage ? (
                <AlertComponent
                  alertType="error"
                  title="Registration Unsuccessful"
                  message={errorMessage}
                  onClose={() => setErrorMsg(undefined)}
                />
              ) : null}
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
              />
            </Form>
          )}
        </Formik>
      ) : null}
      {!isMemberAlreadyRegisteredLoading && isMemberAlreadyRegistered ? (
        <LoginSelfService onSubmitLogIn={handleLogIn}></LoginSelfService>
      ) : null}
    </Fragment>
  );
};

export default RegisterAccountLayout;
