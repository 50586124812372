import { useState, FunctionComponent } from 'react';
import { useGetAdvocacyDetailsQuery } from 'services/user';
import EditAdditionalCoverageDialog from 'Components/EditAdditionalCoverageDialog/EditAddtionalCoverageDialog';
import InfoCardPrimary from 'Components/InfoCardPrimary';
import { displayBooleanAsYesNo } from 'containers/Common/commonFunctions';
import LoadingSpinner from 'Components/LoadingSpinner';

interface ProfileAdditionalCoverageCardProps {
  personId: string;
}

const ProfileAdditionalCoverageCard: FunctionComponent<ProfileAdditionalCoverageCardProps> = (
  props
) => {
  const { personId } = props;
  const { data: advocacyDetails, isLoading } = useGetAdvocacyDetailsQuery(personId, {
    skip: !personId,
  });
  const {
    noIns,
    medicarePartB,
    medicarePartC,
    medicarePartD,
    medicaid,
    miltaryVA,
    childrens,
    spouse,
  } = advocacyDetails || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const contextData = {
    personId: personId,
    noIns: noIns,
    medicarePartB: medicarePartB,
    medicarePartD: medicarePartD,
    medicarePartC: medicarePartC,
    medicaid: medicaid,
    miltaryVA: miltaryVA,
    childrens: childrens,
    spouse: spouse,
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <EditAdditionalCoverageDialog
        open={isModalOpen}
        onClose={handleModalClose}
        contextData={contextData}
      />
      <InfoCardPrimary
        title="Additional Coverage"
        isLoading={isLoading}
        onActionClick={handleModalOpen}
        contentItems={[
          {
            title: `Spouse's Plan: `,
            content: `${displayBooleanAsYesNo(spouse)}`,
          },
          {
            title: `Medicaid: `,
            content: `${displayBooleanAsYesNo(medicaid)}`,
          },
          {
            title: `Medicare Part B: `,
            content: `${displayBooleanAsYesNo(medicarePartB)}`,
          },
          {
            title: `Medicare Advantage (Part C): `,
            content: `${displayBooleanAsYesNo(medicarePartC)}`,
          },
          {
            title: `Medicare Part D: `,
            content: `${displayBooleanAsYesNo(medicarePartD)}`,
          },
          {
            title: `Veteran Benefits: `,
            content: `${displayBooleanAsYesNo(miltaryVA)}`,
          },
          {
            title: `Children's Special Health Care Services: `,
            content: `${displayBooleanAsYesNo(childrens)}`,
          },
        ].filter(Boolean)}
      />
    </>
  );
};

export default ProfileAdditionalCoverageCard;
