import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

export interface SendProxyAuthInfo {
  requesterPersonId: string;
  patientPersonId: string;
  emailOrText: string;
}

export interface HipaaAuthsToSignInfo {
  patientPersonId: string;
  relationshipToPatient: string;
}

export interface GetProxyAuthInfo {
  proxyAuthenticationToken: string;
  patientName: string;
  requesterName: string;
}

export interface PapQualifiedDependent {
  patientId: string | null;
}

export interface HipaaAuth {
  patientPersonId: string;
  signerPersonId: string;
  signature: string;
  relationshipToPatient: string;
}

export const hipaaAuthApi = createApi({
  reducerPath: 'services/hipaaAuth',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    signHipaaAuth: builder.mutation({
      query: (hipaaAuthData) => {
        return {
          url: 'hipaaAuth',
          method: 'put',
          data: hipaaAuthData,
        };
      },
    }),
    getAllHipaaAuthsToSign: builder.query<HipaaAuthsToSignInfo[], void>({
      query: () => {
        return {
          url: 'hipaaAuth',
          method: 'get',
        };
      },
    }),
    sendProxyAuthorization: builder.mutation({
      query: (sendProxyAuthInfo: SendProxyAuthInfo) => {
        return {
          url: 'proxy-authorization/send',
          method: 'post',
          data: sendProxyAuthInfo,
        };
      },
    }),
    getProxyAuthorizationInfo: builder.query<GetProxyAuthInfo, string>({
      query: (proxyAuthToken) => {
        return {
          url: `proxy-authorization/info/${proxyAuthToken}`,
          method: 'get',
        };
      },
    }),
    approveProxyAuthorization: builder.mutation({
      query: (proxyAuthToken) => {
        return {
          url: `proxy-authorization/approve/${proxyAuthToken}`,
          method: 'post',
        };
      },
    }),
  }),
});

export const {
  useSignHipaaAuthMutation,
  useGetAllHipaaAuthsToSignQuery,
  useSendProxyAuthorizationMutation,
  useGetProxyAuthorizationInfoQuery,
  useApproveProxyAuthorizationMutation,
} = hipaaAuthApi;
