import { ReactNode, FunctionComponent, useContext } from 'react';
import { Box } from '@mui/material';
import LoadingSpinner from 'Components/LoadingSpinner';
import WhiteLabelContext from '../App/contexts/whiteLabel/WhiteLabelContext';
interface PageProps {
  children: ReactNode;
  isLoading?: boolean;
}

const Page: FunctionComponent<PageProps> = ({ children, isLoading }) => {
  const { whiteLabelInfo } = useContext(WhiteLabelContext);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: isLoading ? 'center' : undefined,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        backgroundColor: '#d8e2eb',
        p: 2,
        '&:after': {
          content: '""',
          backgroundImage: `url(${whiteLabelInfo?.logoPath})`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'bottom',
          width: '30%',
          height: '100%',
          backgroundSize: 'contain',
          opacity: '0.25',
          bottom: '10px',
          left: '10px',
          minHeight: '68px',
          maxWidth: '200px',
          mr: 'auto',
        },
      }}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box
          sx={{
            width: '100%',
            maxWidth: '1280px',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Page;
