import { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';

import useStepNavigator from 'containers/Wizards/hooks/useStepNavigator';

import HipaaCompleteLayout from './layouts/HipaaCompleteLayout';
import IntroLayout from './layouts/IntroLayout';
import SignatureLayout from './layouts/SignatureLayout';

import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import { useHistory } from 'react-router';
import { Member } from 'services/user';
import { HipaaAuthsToSignInfo, useGetAllHipaaAuthsToSignQuery } from 'services/hipaaAuth';
import { WizardProps, StepProps } from '../types';
import useHouseholdMembersData from 'Hooks/useHouseholdMembersData';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import useAuth from 'Hooks/useAuth';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import ImageComponent from 'Components/ImageComponent/ImageComponent';
import Wizard from '../Wizard';

const useStyles = makeStyles(() => ({
  imageIcon: {
    maxWidth: '100%',
    maxHeight: '20vh',
  },
}));

export interface HipaaWizardStepProps extends StepProps {
  hipaaAuth: HipaaAuthsToSignInfo;
  memberDetails: Member | Record<string, unknown>;
}

enum Steps {
  INTRO = 1,
  SIGNATURE,
  COMPLETE,
}

const COMPONENT_MAP: any = {
  [Steps.INTRO]: IntroLayout,
  [Steps.SIGNATURE]: SignatureLayout,
  [Steps.COMPLETE]: HipaaCompleteLayout,
};

const HipaaWizard: FunctionComponent<WizardProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();

  const { onComplete, onPrevious } = props;

  const { data: hipaaAuthsToSign = [], isLoading } = useGetAllHipaaAuthsToSignQuery();
  const { getMemberById } = useHouseholdMembersData();
  const [currentHipaaIndex, setCurrentHipaaIndex] = useState(0);

  const order = [Steps.INTRO, Steps.SIGNATURE, Steps.COMPLETE];

  const { next, previous, current, reset } = useStepNavigator({ steps: order });

  const Component: FunctionComponent<HipaaWizardStepProps> = COMPONENT_MAP[current];

  const handlePreviousStep = () => {
    if (current === Steps.INTRO && onPrevious) {
      onPrevious();
    } else {
      previous();
    }
  };

  const handleNextStep = () => {
    // When we get to the COMPLETE step for the current patient
    // we need to check if we are done with all the hipaa auths.
    // If we're not, we reset the steps back to the first step, and
    // increment the hipaa auth index to start the process for the next user
    if (current === Steps.COMPLETE) {
      if (currentHipaaIndex < hipaaAuthsToSign.length - 1) {
        setCurrentHipaaIndex(currentHipaaIndex + 1);
        reset();
        return;
      }
    }
    next();
  };

  const handleContinueLater = () => {
    dispatch(setIsNextStepReminderEligible(false));
    history.push('/');
  };

  useEffect(() => {
    if (!isLoading && !hipaaAuthsToSign.length) {
      onComplete();
    }
  }, [hipaaAuthsToSign, isLoading, onComplete]);

  const currentHipaaAuth = hipaaAuthsToSign[currentHipaaIndex] || {};
  const currentMemberDetails = getMemberById(currentHipaaAuth?.patientPersonId) || {};
  return (
    <Wizard isLoading={isLoading}>
      <>
        <ImageComponent
          className={classes.imageIcon}
          src="Assets/Images/security.png"
          alt="black lock and shield icon"
        />
        <Component
          hipaaAuth={currentHipaaAuth}
          memberDetails={currentMemberDetails}
          onPreviousStep={handlePreviousStep}
          onNextStep={handleNextStep}
          onComplete={onComplete}
        />
        <TertiaryButtonComponent text="Continue Later" onClick={handleContinueLater} />
      </>
    </Wizard>
  );
};

export default HipaaWizard;
