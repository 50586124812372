import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

interface update {
  shouldUpdate?: boolean;
  updateVersion: string;
}

export const utilityApi = createApi({
  reducerPath: 'services/utility',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getUpdateAvailable: builder.query<update, string>({
      query: () => {
        return {
          url: 'utility/updateAvailable',
          method: 'get',
        };
      },
    }),
  }),
});

export const { useGetUpdateAvailableQuery } = utilityApi;
