import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import ImageComponent from 'Components/ImageComponent/ImageComponent';
import { MailOrderProps } from '../';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  leadIcon: {
    'max-width': '100%',
    'max-height': '40vh',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const InhalerLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Typography
        align={'center'}
        variant={'body1'}
        color={'textPrimary'}
        style={{ fontWeight: 'bold' }}
      >
        Inhaler Information
      </Typography>
      <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
        Some inhalers from outside of the United States appear to have slightly different strengths
        than the same ones in the US. However, both inhalers release the same amount of medication.
        The difference is how the countries label the products. The inhalers are made by the same
        drug manufacturer, but in other countries, regulators chose to label the strength from a
        higher point in the inhaler, the valve, while in the US it is measured from the mouthpiece.
      </Typography>
      <ImageComponent
        className={classes.leadIcon}
        src={'/Assets/Images/inhalerCandaInfo.png'}
        alt={'Inhaler Image'}
      />
      <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
        For example, Flovent 125 mcg measurement from the valve vs. 110 mcg measurement from the
        mouthpiece. Both inhalers release 110 mcg of drug.
      </Typography>

      <Box marginBottom={2} marginTop={1}>
        <ProgressButtonGroupComponent
          nextOnClickCallback={nextOnClickCallback}
          previousOnClickCallback={previousOnClickCallback}
        />
      </Box>
    </Box>
  );
};

export default InhalerLayout;
