import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrugQualification } from '../../services/scriptCo';

interface DrugInfo {
  cleanDrugName: string;
  scriptCoIDMaybe: number | null;
  sharxIDMaybe: number | null;
  isGenericMaybe: boolean | null;
  brandNamesOfGenericMaybe: string | null;
  genericNameOfBrandMaybe: string | null;
}

export type DrugSearchInfoState = {
  selected: string;
  showDrugSearchBox: boolean;
  medicationsSelected: Array<string>;
  drugsHaveBeenQualified: Array<DrugQualification>;
  selfServiceDrugList: Array<DrugInfo>;
  scriptCoDrugsSelected: Array<DrugInfo>;
  sharxDrugsSelected: Array<DrugInfo>;
  medropDrugsSelected: Array<DrugInfo>;
};

const initialState = {
  selected: '',
  showDrugSearchBox: false,
  medicationsSelected: [],
  drugsHaveBeenQualified: [],
  selfServiceDrugList: [],
  scriptCoDrugsSelected: [],
  sharxDrugsSelected: [],
  medropDrugsSelected: [],
} as DrugSearchInfoState;

export const slice = createSlice({
  name: 'drugSearchInfo',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setShowDrugSearchBox: (state, { payload }: PayloadAction<boolean>) => {
      state.showDrugSearchBox = payload;
    },
    setDrugsHaveBeenQualified: (
      state: { drugsHaveBeenQualified: any[] },
      action: { payload: any }
    ) => {
      state.drugsHaveBeenQualified = action.payload;
    },
    addMedicationSelected: (state: { medicationsSelected: any[] }, action: { payload: any }) => {
      if (state.medicationsSelected.indexOf(action.payload) < 0) {
        state.medicationsSelected = [...state.medicationsSelected, action.payload];
      }
    },
    setSelfServiceDrugList: (state: { selfServiceDrugList: any[] }, action: { payload: any }) => {
      state.selfServiceDrugList = action.payload;
    },

    addSharxDrugsSelected: (state, action: PayloadAction<DrugInfo>) => {
      const newSharxDrug = action.payload;
      const drugExists = state.sharxDrugsSelected.some(
        (drug) => drug.cleanDrugName === newSharxDrug.cleanDrugName
      );
      if (!drugExists) {
        state.sharxDrugsSelected.push(newSharxDrug);
      }
    },
    addScriptCoDrugsSelected: (state, action: PayloadAction<DrugInfo>) => {
      const newScriptCoDrug = action.payload;
      const drugExists = state.scriptCoDrugsSelected.some(
        (drug) => drug.cleanDrugName === newScriptCoDrug.cleanDrugName
      );
      if (!drugExists) {
        state.scriptCoDrugsSelected.push(newScriptCoDrug);
      }
    },
    addMedropDrugsSelected: (state, action: PayloadAction<DrugInfo>) => {
      const newMedropDrug = action.payload;
      const drugExists = state.medropDrugsSelected.some(
        (drug) => drug.cleanDrugName === newMedropDrug.cleanDrugName
      );
      if (!drugExists) {
        state.medropDrugsSelected.push(newMedropDrug);
      }
    },

    removeMedropDrugsSelected: (
      state: { medropDrugsSelected: any[] },
      action: { payload: any }
    ) => {
      const incomingDrug = action.payload;
      const updatedDrugList = state.medropDrugsSelected.filter(
        (item) => item.cleanDrugName !== incomingDrug.cleanDrugName
      );
      state.medropDrugsSelected = updatedDrugList;
    },
    removeSharxDrugsSelected: (state: { sharxDrugsSelected: any[] }, action: { payload: any }) => {
      const incomingDrug = action.payload;
      const updatedDrugList = state.sharxDrugsSelected.filter(
        (item) => item.cleanDrugName !== incomingDrug.cleanDrugName
      );
      state.sharxDrugsSelected = updatedDrugList;
    },
    removeScriptCoDrugsSelected: (
      state: { scriptCoDrugsSelected: any[] },
      action: { payload: any }
    ) => {
      const incomingDrug = action.payload;
      const updatedDrugList = state.scriptCoDrugsSelected.filter(
        (item) => item.cleanDrugName !== incomingDrug.cleanDrugName
      );
      state.scriptCoDrugsSelected = updatedDrugList;
    },
    removeMedicationSelected: (state: { medicationsSelected: any[] }, action: { payload: any }) => {
      const incomingDrug = action.payload;

      const updatedDrugList = state.medicationsSelected.filter((item) => item !== incomingDrug);
      state.medicationsSelected = updatedDrugList;
    },
  },
});
export const {
  addMedicationSelected,
  removeMedropDrugsSelected,
  removeMedicationSelected,
  removeSharxDrugsSelected,
  removeScriptCoDrugsSelected,
  setDrugsHaveBeenQualified,
  addMedropDrugsSelected,
  addScriptCoDrugsSelected,
  setSelected,
  setSelfServiceDrugList,
  addSharxDrugsSelected,
  setShowDrugSearchBox,
} = slice.actions;

export const setTitleAync = (payload: string) => (dispatch: any) => {
  setTimeout(() => {
    dispatch(setSelected(payload));
  }, 1000);
};

export const selectMedication = (state: Record<string, any>) => state.drugSearchInfo.selected;

export const medicationsSelected = (state: Record<string, any>) =>
  state.drugSearchInfo.medicationsSelected;
export const medropDrugsSelected = (state: Record<string, any>) =>
  state.drugSearchInfo.medropDrugsSelected;
export const sharxDrugsSelected = (state: Record<string, any>) =>
  state.drugSearchInfo.sharxDrugsSelected;
export const scriptCoDrugsSelected = (state: Record<string, any>) =>
  state.drugSearchInfo.scriptCoDrugsSelected;
export const selectSelfServiceDrugList = (state: Record<string, any>) =>
  state.drugSearchInfo.selfServiceDrugList;
export const selectShowDrugSearchBox = (state: Record<string, DrugSearchInfoState>): boolean =>
  state.drugSearchInfo.showDrugSearchBox;
export const selectDrugsHaveBeenQualified = (state: Record<string, any>) =>
  state.drugSearchInfo.drugsHaveBeenQualified;

export default slice.reducer;
