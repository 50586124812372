import { useContext } from 'react';
import AuthContext from 'App/contexts/auth/AuthContext';

const useAuth = () => {
  const authContext = useContext(AuthContext);

  return authContext;
};

export default useAuth;
