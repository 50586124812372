import { FunctionComponent } from 'react';

import EmailConfirmationLayout from 'Views/EmailConfirmationView/EmailConfirmationLayout/EmailConfirmationLayout';
import Wizard from 'containers/Wizards/Wizard';

const EmailConfirmationView: FunctionComponent = () => {
  return (
    <Wizard>
      <EmailConfirmationLayout />
    </Wizard>
  );
};

export default EmailConfirmationView;
