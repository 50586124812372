import { useCallback, useMemo } from 'react';
import { useGetAllHouseholdMembersQuery } from 'services/user';
import useAuth from './useAuth';

const useHouseholdMembersData = () => {
  const { data: allHouseholdMembers, isLoading } = useGetAllHouseholdMembersQuery();
  const {
    data: { personIdEncryptedMaybe: userId },
  } = useAuth();

  const getMemberById = useCallback(
    (id: string) => allHouseholdMembers?.find((member) => member.patientId === id),
    [allHouseholdMembers]
  );

  const viewableMembers = useMemo(
    () =>
      allHouseholdMembers?.filter(
        (mem) =>
          mem.patientId == userId || (mem.hasPhiAccess == true && mem.hasMedsInAdvocacy == true)
      ),
    [allHouseholdMembers, userId]
  );

  const viewableMembersWithMedsOnly = useMemo(
    () =>
      allHouseholdMembers?.filter(
        (mem) => mem.hasPhiAccess == true && mem.hasMedsInAdvocacy == true
      ),
    [allHouseholdMembers]
  );

  return {
    getMemberById,
    allHouseholdMembers,
    viewableMembers,
    viewableMembersWithMedsOnly,
    isLoading,
  };
};

export default useHouseholdMembersData;
