import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useAuthMailOrderInitMutation } from 'services/mailOrder';
import useAuth from 'Hooks/useAuth';
import { useGetUserDetailsQuery, useGetAdvocateInfoQuery } from 'services/user';
import { MailOrderProps } from '../';

const confirmNeedType = 'ColdPckProvision';

interface TempSensitiveLayoutValues {
  initials: string;
}

const TempSensitiveLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  onSaveData,
  contextData,
  savedData,
}) => {
  const dispatch = useDispatch();
  const { mailOrderAuthKeystring: mailOrderId } = contextData;
  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const { firstName, lastName } = userDetails || {};
  const userInitials = `${firstName?.[0].toUpperCase()}${lastName?.[0].toUpperCase()}`;

  const [putAuthMailOrderInit] = useAuthMailOrderInitMutation();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const tempSensitiveSchema = Yup.object().shape({
    initials: Yup.string()
      .uppercase()
      .oneOf([userInitials], "Characters don't match your initials")
      .required('Initials Required'),
  });

  const useStyles = makeStyles((theme) => ({
    screenBox: {
      padding: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        initials: (savedData.tempSensitive as string) || '',
      }}
      validationSchema={tempSensitiveSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSaveData({
          tempSensitive: values.initials,
        });

        const mailOrderInitInfo = {
          mailOrderId,
          authorizationInitials: values.initials,
          confirmationNeedType: confirmNeedType,
        };

        putAuthMailOrderInit(mailOrderInitInfo);
        setSubmitting(false);
        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<TempSensitiveLayoutValues>) => (
        <Form>
          <Box
            className={classes.screenBox}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Temperature Sensitive Drug Policy
            </Typography>
            <Paper elevation={2}>
              <Typography
                align={'left'}
                variant={'body1'}
                color={'textPrimary'}
                style={{ overflow: 'scroll', height: '250px', margin: '0.5em' }}
              >
                Due to your order containing temperature-sensitive items, a mandatory signature will
                be required upon delivery. You will receive an email with a tracking number once
                your order has shipped, and delivery typically takes 2-5 days from the shipping
                date.
                <br />
                <br />
                If you are unavailable to sign for the package, you may change the shipping address
                to a location such as your workplace, a nearby neighbor, friend, or family member
                who can sign on your behalf. Please note, once the order is authorized, the shipping
                address cannot be updated. If you miss the delivery, you can reschedule it with the
                carrier using the link provided with your tracking number or by visiting their
                website.
                <br />
                <br />
                <b>IMPORTANT:</b>
                <br />
                Once you receive your package, please open it immediately and inspect its contents.
                The items are packed in a thermal insulated wrapper with an ice pack inside a
                Styrofoam box to maintain temperature. During warmer months, additional ice packs
                are included. While the ice pack may be partially thawed upon arrival, the
                insulation will keep the contents cooler for longer.
                <br />
                <br />
                Next, remove the medication from the Styrofoam container and place it in your
                refrigerator. If the medication does not feel cool to the touch or you notice any
                issues with your shipment, please take photos of the medication and packaging, save
                the temperature logger (which can sometimes be hidden between the packages of
                medication), and contact us immediately.
                <br />
                <br />
                Thank you for your attention, and feel free to reach out if you have any questions.
                <br />
              </Typography>
            </Paper>
            <Box display={'flex'} marginTop={2}>
              <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                I, {firstName} {lastName}, verify that I have read the Temperature Sensitive Drug
                Policy described above.
              </Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              marginTop={1}
              marginBottom={1}
            >
              <Box flexBasis={'70%'}>
                <TextFieldComponent
                  label="Initial Here"
                  name={'initials'}
                  fullWidth={false}
                  inputProps={{ maxLength: 2 }}
                  onInput={(e: { target: { value: string } }) => {
                    e.target.value = e.target.value.slice(0, 2);
                  }}
                />
              </Box>
            </Box>
            <Box marginBottom={1}>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={previousOnClickCallback}
                nextButtonProps={{ text: 'Next' }}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TempSensitiveLayout;
