import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';

type NextSteps = 'Mail Order Authorization' | 'File Upload' | 'Onboarding' | 'Nothing';

export interface UserDetails {
  firstName: string;
  middleName?: string | null;
  lastName: string;
  preferredName?: string | null;
  gender: string;
  hasSsn: boolean;
  hasDateOfBirth: boolean;
  isPrimary: boolean;
}

export interface PersonDetails {
  firstName: string;
  preferredName?: string | null;
  middleName?: string | null;
  lastName: string;
  suffix?: string | null;
  gender: string;
  dateOfBirth: string;
}

export interface AdvocateInfo {
  advocateFirstName: string;
  advocatePhoneNumber: string;
  advocateTitle: string;
}

export interface AdvocacyDetails {
  noIns?: boolean | null;
  medicarePartB?: boolean | null;
  medicarePartC?: boolean | null;
  medicarePartD?: boolean | null;
  medicaid?: boolean | null;
  miltaryVA?: boolean | null;
  childrens?: boolean | null;
  spouse?: boolean | null;
}

export interface Member {
  patientId: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  relationship: string;
  dateOfBirth?: string | null;
  genderMaybe?: string | null;
  hasPhiAccess: boolean;
  hasMedsInAdvocacy: boolean;
  someMedsAreHidden: boolean;
  isOtherAdultNeedingToSignHipaaAuth: boolean;
  isAdult?: boolean;
}

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: 'services/user',
  tagTypes: ['Acknowledgements', 'Dependents', 'PersonalDetails', 'AdvocacyDetails'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    hasUserAlreadySeenSHARx: builder.query<boolean, void>({
      query: () => {
        return {
          url: 'acknowledgements/alreadySeenSHARx',
          method: 'get',
        };
      },
    }),
    hasConfirmedAcknowledgements: builder.query<boolean, void>({
      query: () => {
        return {
          url: 'acknowledgements',
          method: 'get',
        };
      },
      providesTags: ['Acknowledgements'],
    }),
    confirmAcknowledgements: builder.mutation<boolean, void>({
      query: () => {
        return {
          url: 'acknowledgements',
          method: 'post',
        };
      },
      invalidatesTags: ['Acknowledgements'],
    }),
    hasMemberOnboarded: builder.query<boolean, void>({
      query: () => {
        return {
          url: 'user/hasMemberOnboarded',
          method: 'get',
        };
      },
    }),
    getUserDetails: builder.query<UserDetails, string>({
      query: (patientPersonId) => {
        return {
          url: `user/details/${patientPersonId}`,
          method: 'get',
        };
      },
    }),
    getPersonalDetails: builder.query<PersonDetails, string>({
      query: (patientPersonId) => {
        return {
          url: `person/details/${patientPersonId}`,
          method: 'get',
        };
      },
      providesTags: ['PersonalDetails'],
    }),
    getNextStep: builder.query<NextSteps, void>({
      query: () => {
        return {
          url: 'user/nextstep',
          method: 'get',
        };
      },
    }),
    getOnboardingNextStep: builder.query<NextSteps, void>({
      query: () => {
        return {
          url: 'user/onboarding/nextsteps',
          method: 'get',
        };
      },
    }),
    //
    editPersonDetails: builder.mutation({
      query: ({ patientPersonId, personalDetails }) => {
        return {
          url: `person/details/${patientPersonId}`,
          method: 'put',
          data: personalDetails,
        };
      },
      invalidatesTags: ['PersonalDetails'],
    }),
    getAdvocateInfo: builder.query<AdvocateInfo, void>({
      query: () => {
        return {
          url: 'user/advocate',
          method: 'get',
        };
      },
    }),
    getAdvocacyDetails: builder.query<AdvocacyDetails, string>({
      query: (patientPersonId) => {
        return {
          url: `user/advocacydetails/${patientPersonId}`,
          method: 'get',
        };
      },
      providesTags: ['AdvocacyDetails'],
    }),
    //
    editAdvocacyDetails: builder.mutation({
      query: ({ patientPersonId, advocacyDetails }) => {
        return {
          url: `user/advocacydetails/${patientPersonId}`,
          method: 'put',
          data: advocacyDetails,
        };
      },
      invalidatesTags: ['AdvocacyDetails'],
    }),
    addDependent: builder.mutation({
      query: (newDependent) => {
        return {
          url: 'user/dependent',
          method: 'post',
          data: newDependent,
        };
      },
      invalidatesTags: ['Dependents'],
    }),
    getAllHouseholdMembers: builder.query<Member[], void>({
      query: () => {
        return {
          url: 'user/dependents',
          method: 'get',
        };
      },
      providesTags: ['Dependents'],
    }),
    inviteAdultDependentToRegister: builder.mutation({
      query: (personId) => {
        return {
          url: `user/invite/${personId}`,
          method: 'post',
        };
      },
    }),
  }),
});

export const {
  useHasUserAlreadySeenSHARxQuery,
  useHasConfirmedAcknowledgementsQuery,
  useConfirmAcknowledgementsMutation,
  useHasMemberOnboardedQuery,
  useGetUserDetailsQuery,
  useGetPersonalDetailsQuery,
  useGetNextStepQuery,
  useGetOnboardingNextStepQuery, // don't use right now
  useEditPersonDetailsMutation,
  useGetAdvocateInfoQuery,
  useGetAdvocacyDetailsQuery, // don't use right now
  useEditAdvocacyDetailsMutation, // incomplete implementation
  useAddDependentMutation,
  useGetAllHouseholdMembersQuery,
  useInviteAdultDependentToRegisterMutation,
} = userApi;
