import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useGetNextStepQuery, useGetOnboardingNextStepQuery } from 'services/user';

const STEP_LINKS = {
  'Mail Order Authorization': '/mailOrder',
  'File Upload': '/fileUpload',
  'Hipaa Renewal': '/hipaa',
  IndividualWizard: '/wizardSelector',
  Onboarding: '/onboarding',
  Nothing: undefined,
};

const useNextStep = () => {
  const { data: nextStep, refetch, isLoading, isFetching } = useGetNextStepQuery();

  const [currentStep, setCurrentStep] = useState<any>(nextStep);
  const [refetchStarted, setRefetchStarted] = useState<boolean>(false);
  const history = useHistory();

  const href = !isFetching ? nextStep && STEP_LINKS[nextStep] : null;

  const goToNextStep = () => {
    setRefetchStarted(true);
    refetch();
  };

  const refetchNextStep = () => {
    refetch();
  };

  useEffect(() => {
    if (!currentStep && nextStep) {
      setCurrentStep(nextStep);
    }
  }, [currentStep, nextStep]);

  useEffect(() => {
    if (!refetchStarted) {
      return;
    }

    if (currentStep !== nextStep) {
      history.push(href || '/');
    }
  }, [currentStep, history, href, nextStep, refetchStarted]);

  return {
    href,
    nextStep,
    isLoading,
    isFetching,
    goToNextStep,
    refetchNextStep,
  };
};

export default useNextStep;
