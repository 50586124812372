import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { KEY_APP_VERSION, KEY_AUTH_TOKEN } from 'App/contexts/constants';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { setInternalServerError, setUnauthorizedResponse } from 'Store/Reducers/authSlice';
import type { RootState } from 'Store/Store';
import loggerService from '../loggerService';

// here, authorization can be set.
// TODO: have to figure out how to inject the user specific token when they authorize
const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // 'https://sharx-test.azurewebsites.net/api/', // `https://localhost:44328/api/`
  timeout: 5000,
  withCredentials: true,
});

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(KEY_AUTH_TOKEN);
  const appVersion = localStorage.getItem(KEY_APP_VERSION);
  // Allow Authorization to be set per request, e.g. registerExisting
  if (!config.headers?.Authorization) {
    config.headers['Authorization'] = token;
    config.headers['X-Client-Version'] = appVersion ?? '0.0';
  }

  return config;
});

let unauthorizedFlagged = false;

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string | undefined } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      headers?: AxiosRequestConfig['headers'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, headers, params }, api) => {
    const state = api.getState() as RootState;
    const initialData = JSON.stringify(data);
    const unauthorizedResponse = state.auth?.unauthorizedResponse;

    if (unauthorizedResponse) {
      return { error: true };
    }

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        withCredentials: true,
        headers,
        params,
      });

      if (url === 'login') {
        unauthorizedFlagged = false;
      }

      return { data: result.data };
    } catch (axiosError) {
      const err = (axiosError as AxiosError) || {};
      const { status, data } = err.response || {};

      if (status === 401 && !unauthorizedFlagged) {
        api.dispatch(setUnauthorizedResponse(true));
        unauthorizedFlagged = true;
      } else if (status && status >= 500) {
        const errorData = data ? JSON.stringify(data) : '';
        const message = [`Endpoint: ${url}`, `Params: ${initialData}`, `Error: ${errorData}`].join(
          ' | '
        );
        loggerService({
          statusCode: `HTTP Response Status Code: ${status}`,
          message,
        });
        api.dispatch(setInternalServerError(true));
      } else if (status && status > 401 && !data?.userInputValidationError) {
        const errorData = data ? JSON.stringify(data) : '';
        const message = [`Endpoint: ${url}`, `Params: ${initialData}`, `Error: ${errorData}`].join(
          ' | '
        );

        loggerService({
          statusCode: `HTTP Response Status Code: ${status}`,
          message,
        });
      }

      return {
        error: { status, data },
      };
    }
  };

export default client;
