import { createSlice } from '@reduxjs/toolkit';

export type HouseHoldInfoState = {
  numberOfAdults: number;
  numberOfChildren: number;
  householdIncome: number;
};

const initialState = {
  numberOfAdults: 0,
  numberOfChildren: 0,
  householdIncome: 0,
} as HouseHoldInfoState;

export const slice = createSlice({
  name: 'householdInfo',
  initialState,
  reducers: {
    setNumberOfAdults: (state, action) => {
      state.numberOfAdults = action.payload;
    },
    setNumberOfChildren: (state, action) => {
      state.numberOfChildren = action.payload;
    },
    setHouseholdIncome: (state, action) => {
      state.householdIncome = action.payload;
    },
  },
});

export const { setNumberOfAdults, setNumberOfChildren, setHouseholdIncome } = slice.actions;

export const selectNumberOfChildren = (state: Record<string, HouseHoldInfoState>): number =>
  state.householdInfo.numberOfChildren;
export const selectNumberOfAdults = (state: Record<string, HouseHoldInfoState>): number =>
  state.householdInfo.numberOfAdults;
export const selectHouseholdIncome = (state: Record<string, HouseHoldInfoState>): number =>
  state.householdInfo.householdIncome;

export default slice.reducer;
