import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import { Box } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import { HouseholdInfo } from 'services/person';

import SelectComponent from 'Components/SelectComponent/SelectComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import { StepProps } from 'containers/Wizards/types';

interface ContributesToHSALayoutProps extends StepProps {
  onSaveData(payload: { isContributingToHSAMaybe: boolean }): void;
  contextData: HouseholdInfo;
}

interface ContributesToHSALayoutValues {
  isContributingToHSAMaybe: boolean | null;
}

const isContributingToHSAMaybeSchema = Yup.object().shape({
  isContributingToHSAMaybe: Yup.boolean().required('Required'),
});

const ContributesToHSALayout: FunctionComponent<ContributesToHSALayoutProps> = ({
  onNextStep,
  onPreviousStep,
  onSaveData,
  contextData,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Patient Assistance Programs'));
  }, [dispatch]);

  return (
    <Formik
      initialValues={{
        isContributingToHSAMaybe: !!contextData.isContributingToHSAMaybe,
      }}
      validationSchema={isContributingToHSAMaybeSchema}
      onSubmit={async (values, { setSubmitting }) => {
        onSaveData({
          isContributingToHSAMaybe: !!values.isContributingToHSAMaybe,
        });

        setSubmitting(false);
        onNextStep();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<ContributesToHSALayoutValues>) => (
        <Form>
          <Box>
            <Box marginBottom={2}>
              <InfoLayoutBodyComponent
                header="Health Savings Account (HSA)"
                paragraph="Do you contribute to an HSA?"
              />
              <SelectComponent
                label={'HSA Contributions?'}
                name={'isContributingToHSAMaybe'}
                selectOptions={[
                  { label: 'Yes', value: 1 },
                  { label: 'No', value: 0 },
                ]}
              />
            </Box>
            <Box>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={onPreviousStep}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ContributesToHSALayout;
