import { FunctionComponent, useState } from 'react';
import { useGetHomeAddressQuery, useUpdateHomeAddressMutation } from 'services/person';
import EditAddressDialog from 'Components/EditAddressDialog/EditAddressDialog';
import InfoCardPrimary from 'Components/InfoCardPrimary';

interface ProfileAddressCardProps {
  personId: string;
}

const ProfileAddressCard: FunctionComponent<ProfileAddressCardProps> = (props) => {
  const { personId } = props;
  const { data: homeAddress, isLoading } = useGetHomeAddressQuery(personId);
  const [updateHomeAddress] = useUpdateHomeAddressMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (values: any) => {
    const updatedAddress = {
      patientPersonId: personId,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      zip: values.zip,
    };

    await updateHomeAddress(updatedAddress);

    handleModalClose();
  };

  return (
    <>
      <EditAddressDialog
        open={isModalOpen}
        onClose={handleModalClose}
        address={homeAddress}
        onSubmit={handleSubmit}
      />
      <InfoCardPrimary
        title="Address"
        isLoading={isLoading}
        onActionClick={handleModalOpen}
        contentItems={[
          {
            title: `Address: `,
            content: `${homeAddress?.address1}`,
          },
          homeAddress?.address2
            ? {
                title: 'Address 2: ',
                content: `${homeAddress?.address2}`,
              }
            : null,
          {
            title: `City: `,
            content: `${homeAddress?.city}`,
          },
          {
            title: `State: `,
            content: `${homeAddress?.state}`,
          },
          {
            title: `Zip Code: `,
            content: `${homeAddress?.zip}`,
          },
        ].filter(Boolean)}
      />
    </>
  );
};

export default ProfileAddressCard;
