import { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';

import useStepNavigator from 'containers/Wizards/hooks/useStepNavigator';

import HouseholdIncomeLayout from './layouts/HouseholdIncomeLayout';
import HouseholdSizeLayout from './layouts/HouseholdSizeLayout';
import ContributesToHSALayout from './layouts/ContributesToHSALayout';
import IntroPAPLayout from './layouts/IntroPAPLayout';
import OutroPAPLayout from './layouts/OutroPAPLayout';
import SSNLayout from './layouts/SSNLayout';

import { useGetIfPapQualifiedQuery } from 'services/medication';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';
import { useHistory } from 'react-router';
import { WizardProps, StepProps } from '../types';
import { HouseholdInfo, useGetHouseholdInformationQuery } from 'services/person';
import merge from 'lodash.merge';
import useAuth from 'Hooks/useAuth';
import { useDispatch } from 'react-redux';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import useHouseholdMembersData from 'Hooks/useHouseholdMembersData';
import { Member } from 'services/user';
import Wizard from '../Wizard';

interface PapWizardProps extends StepProps {
  onSaveData?: (payload: any) => void;
  contextData?: any;
  viewableMembers?: Member[];
}

enum Steps {
  INTRO_PAP = 1,
  HOUSEHOLD_INCOME,
  CONTRIBUTES_HSA,
  HOUSEHOLD_SIZE,
  SSN,
  OUTRO_PAP,
}

const COMPONENT_MAP: any = {
  [Steps.INTRO_PAP]: IntroPAPLayout,
  [Steps.HOUSEHOLD_INCOME]: HouseholdIncomeLayout,
  [Steps.CONTRIBUTES_HSA]: ContributesToHSALayout,
  [Steps.HOUSEHOLD_SIZE]: HouseholdSizeLayout,
  [Steps.SSN]: SSNLayout,
  [Steps.OUTRO_PAP]: OutroPAPLayout,
};
const PapWizard: FunctionComponent<WizardProps> = (props) => {
  const { onComplete, onPrevious } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: isPapQualified, isLoading: isPapQualifiedLoading } = useGetIfPapQualifiedQuery();
  const { data: householdInfo, isLoading: isHouseholdInfoLoading } =
    useGetHouseholdInformationQuery();
  const { viewableMembersWithMedsOnly, isLoading: isLoadingMembers } = useHouseholdMembersData();
  const [savedData, setSavedData] = useState<HouseholdInfo>();

  const { userDetails } = useAuth();
  const shouldAskAboutHSA = userDetails?.isPrimary;

  const order = shouldAskAboutHSA
    ? [
        Steps.INTRO_PAP,
        Steps.HOUSEHOLD_INCOME,
        Steps.CONTRIBUTES_HSA,
        Steps.HOUSEHOLD_SIZE,
        Steps.SSN,
        Steps.OUTRO_PAP,
      ]
    : [
        Steps.INTRO_PAP,
        Steps.HOUSEHOLD_INCOME,
        Steps.HOUSEHOLD_SIZE,
        Steps.SSN,
        Steps.OUTRO_PAP,
      ].filter(Boolean);

  const { next, previous, current } = useStepNavigator({ steps: order });
  useEffect(() => {
    if (!isPapQualified && !isPapQualifiedLoading) {
      onComplete();
    }

    if (!isHouseholdInfoLoading) {
      setSavedData({
        annualIncome: householdInfo?.annualIncome ? householdInfo.annualIncome : 0,
        numberOfAdults: householdInfo?.numberOfAdults ? householdInfo.numberOfAdults : 0,
        numberOfChildren: householdInfo?.numberOfChildren ? householdInfo.numberOfChildren : 0,
        isContributingToHSAMaybe: householdInfo?.isContributingToHSAMaybe
          ? householdInfo.isContributingToHSAMaybe
          : false,
      });
    }
  }, [householdInfo, isHouseholdInfoLoading, isPapQualified, isPapQualifiedLoading, onComplete]);

  const Component: FunctionComponent<PapWizardProps> = COMPONENT_MAP[current];

  const handleContinueLater = () => {
    dispatch(setIsNextStepReminderEligible(false));
    history.push('/');
  };

  const handlePreviousStep = () => {
    if (current === Steps.INTRO_PAP && onPrevious) {
      onPrevious();
    } else {
      previous();
    }
  };

  const handleSaveData = (payload: Record<string, unknown>) => {
    const data = merge(savedData, payload);
    setSavedData(data);
  };

  return (
    <Wizard isLoading={isLoadingMembers || isPapQualifiedLoading || isHouseholdInfoLoading}>
      <>
        <Component
          onNextStep={next}
          onPreviousStep={handlePreviousStep}
          onComplete={onComplete}
          onSaveData={handleSaveData}
          contextData={savedData}
          viewableMembers={viewableMembersWithMedsOnly}
        />
        <TertiaryButtonComponent text="Continue Later" onClick={handleContinueLater} />
      </>
    </Wizard>
  );
};

export default PapWizard;
