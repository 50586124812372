import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import RadioGroupComponent from 'Components/RadioGroupComponent/RadioGroupComponent';
import Dialog from 'Components/Dialog';
import {
  ContactInformation,
  PreferredContactType,
  useUpdateContactInfoMutation,
  useGetEligibleContactPersonsQuery,
  useGetContactInformationQuery,
} from 'services/person';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import SelectComponent from 'Components/SelectComponent/SelectComponent';

const phoneRegExp = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-/\s.]|\d)+$/;
const ContactInfoSchema = Yup.object().shape({
  phonePrimary: Yup.string()
    .matches(phoneRegExp, 'Invalid Value')
    .min(10, 'Invalid Value')
    .required('Required'),
  phonePrimaryIsCell: Yup.boolean().notRequired(),
  phoneSecondary: Yup.string()
    .matches(phoneRegExp, 'Invalid Value')
    .min(10, 'Invalid Value')
    .nullable()
    .notRequired(),
  phoneSecondaryIsCell: Yup.boolean().notRequired(),
  emailPrimary: Yup.string().nullable().required('Required'),
  emailSecondary: Yup.string().nullable().notRequired(),
  preferredContact: Yup.string().required('Required'),
  updatesInSpanish: Yup.boolean().notRequired(),
});

interface EditContactInfoValues {
  contactPersonId: string;
  phonePrimary: string;
  phonePrimaryIsCell: boolean;
  phoneSecondary?: string;
  phoneSecondaryIsCell?: boolean;
  emailPrimary: string;
  emailSecondary?: string;
  preferredContact: PreferredContactType;
  updatesInSpanish?: string;
}

type EditContactInfoDialogProps = {
  onClose: () => void;
  open: boolean;
  contextData: any;
};

const EditContactInfoDialog: React.FC<EditContactInfoDialogProps> = (props) => {
  const { onClose, open, contextData } = props;
  const [state, setState] = useState({
    phonePrimaryIsCell: contextData?.phonePrimaryIsCell ? true : false,
    phoneSecondaryIsCell: contextData?.phoneSecondaryIsCell ? true : false,
    canUserSelectTextAsPreferredContact: true,
  });

  const patientPersonId = contextData.patientPersonId;
  const contactPersonId = contextData.contactPersonId;
  const [selectedContactPerson, setSelectedContactPerson] = useState<string>(contactPersonId);
  const { data: contactPersons } = useGetEligibleContactPersonsQuery(patientPersonId, {
    skip: !patientPersonId,
  });
  const { data: contactInfoOfSelectedPerson } = useGetContactInformationQuery(
    selectedContactPerson || contactPersonId,
    {
      skip: !selectedContactPerson && !contactPersonId,
    }
  );
  const [postUpdateContactInfo] = useUpdateContactInfoMutation();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setState({
      phonePrimaryIsCell: contextData?.phonePrimaryIsCell ? true : false,
      phoneSecondaryIsCell: contextData?.phoneSecondaryIsCell ? true : false,
      canUserSelectTextAsPreferredContact: true,
    });
  }, [contextData?.phonePrimaryIsCell, contextData?.phoneSecondaryIsCell]);

  const contactPersonSelectOptions = useMemo(() => {
    return (
      contactPersons?.map((contactPerson) => ({
        label: `${contactPerson.name}`,
        value: contactPerson.patientPersonId,
      })) || []
    );
  }, [contactPersons]);

  const isPatientsContactInfo =
    (selectedContactPerson ? selectedContactPerson : contactPersonId) === patientPersonId;

  return (
    <Dialog onClose={onClose} open={open} title="Edit Contact Information">
      <Formik
        enableReinitialize
        initialValues={{
          contactPersonId: selectedContactPerson || contactPersonId,
          phonePrimary: contactInfoOfSelectedPerson?.phonePrimary || '',
          phonePrimaryIsCell: contactInfoOfSelectedPerson?.phonePrimaryIsCell || false,
          phoneSecondary: contactInfoOfSelectedPerson?.phoneSecondary,
          phoneSecondaryIsCell: contactInfoOfSelectedPerson?.phoneSecondaryIsCell,
          emailPrimary: contactInfoOfSelectedPerson?.emailPrimary || '',
          emailSecondary: contactInfoOfSelectedPerson?.emailSecondary,
          preferredContact: contactInfoOfSelectedPerson?.preferredContact || 'Email',
          updatesInSpanish:
            contactInfoOfSelectedPerson?.preferredSpanishUpdates === true ? 'true' : 'false',
        }}
        validationSchema={ContactInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (!state.phonePrimaryIsCell && values.preferredContact === 'Text') {
            setState({ ...state, canUserSelectTextAsPreferredContact: false });
          } else {
            onClose();

            const updatedContactInfo: ContactInformation = {
              patientPersonId: values.contactPersonId,
              name: contactInfoOfSelectedPerson?.name || '',
              phonePrimary: values.phonePrimary as string,
              phoneSecondary: values.phoneSecondary,
              phonePrimaryIsCell: state.phonePrimaryIsCell as boolean,
              phoneSecondaryIsCell: state.phoneSecondaryIsCell as boolean,
              emailPrimary: values.emailPrimary as string,
              emailSecondary: values.emailSecondary,
              preferredContact: values.preferredContact,
              preferredSpanishUpdates: values.updatesInSpanish === 'true',
            };
            await postUpdateContactInfo({
              patientPersonId,
              contactInfo: updatedContactInfo,
            });
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }: FormikProps<EditContactInfoValues>) => (
          <Form>
            <SelectComponent
              label={'Who is the point of contact?'}
              name={'contactPersonId'}
              selectOptions={contactPersonSelectOptions}
              onChange={(e: { target: { value: string } }) => {
                setSelectedContactPerson(e.target.value);
              }}
            />
            {!isPatientsContactInfo ? (
              <Box marginBottom={2} marginTop={2} marginLeft={1} marginRight={2}>
                <Typography variant="body2">
                  Preferred Phone: {contactInfoOfSelectedPerson?.phonePrimary}{' '}
                  {contactInfoOfSelectedPerson?.phonePrimaryIsCell ? '(Cell)' : null}{' '}
                </Typography>
                {contactInfoOfSelectedPerson?.phoneSecondary ? (
                  <Typography variant="body2">
                    Secondary Phone: {contactInfoOfSelectedPerson?.phoneSecondary}{' '}
                    {contactInfoOfSelectedPerson?.phoneSecondaryIsCell ? '(Cell)' : null}{' '}
                  </Typography>
                ) : null}
                <Typography variant="body2">
                  Preferred Email: {contactInfoOfSelectedPerson?.emailPrimary}{' '}
                </Typography>
                {contactInfoOfSelectedPerson?.emailSecondary ? (
                  <Typography variant="body2">
                    Secondary Email: {contactInfoOfSelectedPerson?.emailSecondary}{' '}
                  </Typography>
                ) : null}
                <Typography variant="body2">
                  Preferred Update Method: {contactInfoOfSelectedPerson?.preferredContact}{' '}
                </Typography>
              </Box>
            ) : (
              <Box marginBottom={2} marginTop={2}>
                <TextFieldComponent label={'Primary Phone'} name={'phonePrimary'} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.phonePrimaryIsCell}
                      onChange={handleCheckboxChange}
                      name="phonePrimaryIsCell"
                    />
                  }
                  label="Is Cellphone"
                />
                <TextFieldComponent label={'Secondary Phone'} name={'phoneSecondary'} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.phoneSecondaryIsCell}
                      onChange={handleCheckboxChange}
                      name="phoneSecondaryIsCell"
                    />
                  }
                  label="Is Cellphone"
                />
                <TextFieldComponent label={'Primary Email'} name={'emailPrimary'} />
                <TextFieldComponent
                  label={'Secondary Email'}
                  name={'emailSecondary'}
                  style={{ marginBottom: '1em' }}
                />
                <RadioGroupComponent
                  label={'Preferred Method to Receive Status Updates'}
                  name={'preferredContact'}
                  row
                  radioOptions={[
                    { label: 'Email', value: 'Email' },
                    { label: 'Text', value: 'Text' },
                  ]}
                />
                {!state.canUserSelectTextAsPreferredContact ? (
                  <AlertComponent
                    alertType="error"
                    message="Preferred Method must be Email if Primary Phone is not cellphone"
                  />
                ) : null}
              </Box>
            )}
            <PrimaryButtonComponent
              text="Save Contact Info"
              isLoading={isSubmitting}
              onClick={submitForm}
              width="auto"
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditContactInfoDialog;
