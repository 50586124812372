import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reset } from '../actions/commonActions';

export type ServiceWorkerState = {
  isServiceWorkerInitialized: boolean;
  isServiceWorkerUpdated: boolean;
  isServiceWorkerRegistered: boolean;
};

const initialState = {
  isServiceWorkerInitialized: false,
  isServiceWorkerUpdated: false,
  isServiceWorkerRegistered: false,
} as ServiceWorkerState;

export const slice = createSlice({
  name: 'serviceWorker',
  initialState,
  reducers: {
    setServiceWorkerInitialized: (state, { payload }: PayloadAction<boolean>) => {
      state.isServiceWorkerInitialized = payload;
    },
    setServiceWorkerUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.isServiceWorkerUpdated = payload;
    },
    setServiceWorkerRegistration: (state, { payload }: PayloadAction<boolean>) => {
      state.isServiceWorkerRegistered = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState);
  },
});

export const {
  setServiceWorkerInitialized,
  setServiceWorkerUpdated,
  setServiceWorkerRegistration,
} = slice.actions;

export default slice.reducer;

export const selectServiceWorkerInitialized = (
  state: Record<string, ServiceWorkerState>
): boolean => state.serviceWorker.isServiceWorkerInitialized;
export const selectServiceWorkerUpdated = (state: Record<string, ServiceWorkerState>): boolean =>
  state.serviceWorker.isServiceWorkerUpdated;
export const selectServiceWorkerRegistration = (
  state: Record<string, ServiceWorkerState>
): boolean => state.serviceWorker.isServiceWorkerRegistered;
