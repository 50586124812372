import { FunctionComponent } from 'react';

interface ImageComponentProps {
  src?: string;
  alt?: string;
  className?: string;
  testId?: string;
}

const ImageComponent: FunctionComponent<ImageComponentProps> = ({
  src,
  alt,
  className,
  testId = 'image',
}) => {
  if (!src) {
    return null;
  }
  return <img data-testid={testId} className={className} src={src} alt={alt} />;
};

export default ImageComponent;
