import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import useAuth from 'Hooks/useAuth';
import { WizardProps } from 'containers/Wizards/types';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import LeadLogoComponent from 'Components/LeadLogoComponent/LeadLogoComponent';
import Wizard from 'containers/Wizards/Wizard';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(2),
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  margin: {
    margin: theme.spacing(4),
  },
}));

const OnboardingComplete: FunctionComponent<WizardProps> = ({ onComplete }) => {
  const dispatch = useDispatch();

  const { userDetails: { firstName } = {} } = useAuth();

  useEffect(() => {
    dispatch(setTitle('Onboarding'));
  }, [dispatch]);

  const classes = useStyles();

  return (
    <Wizard>
      <Box className={classes.screenBox}>
        <LeadLogoComponent />
        <Typography variant="h6" className={classes.margin}>
          Thanks {firstName}, you have completed the onboarding process!
        </Typography>
        <Box>
          <PrimaryButtonComponent className={classes.button} text="Close" onClick={onComplete} />
        </Box>
      </Box>
    </Wizard>
  );
};

export default OnboardingComplete;
