import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useAuthMailOrderInitMutation, useMedsToVerifyForMOQuery } from 'services/mailOrder';
import { useGetUserDetailsQuery } from 'services/user';
import useAuth from 'Hooks/useAuth';
import { MailOrderProps } from '../index';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

const confirmNeedType = 'ScriptsVerified';

interface verifyPrescriptionValues {
  initials: string;
}

const VerifyPrescriptionLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  contextData,
  onSaveData,
  savedData,
}) => {
  const dispatch = useDispatch();
  const {
    mailOrderAuthKeystring: mailOrderId,
    isLoggedInUserAlsoPatient,
    patientFirstName,
  } = contextData;

  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const { firstName, lastName } = userDetails || {};
  const userInitials = `${firstName?.[0].toUpperCase()}${lastName?.[0].toUpperCase()}`;
  const { data: mailOrderMedInfo } = useMedsToVerifyForMOQuery(mailOrderId ?? skipToken);

  const moMedList = mailOrderMedInfo?.mailOrderMedicationFills;
  const [putAuthMailOrderInit] = useAuthMailOrderInitMutation();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const verifyPrescriptionSchema = Yup.object().shape({
    initials: Yup.string()
      .uppercase()
      .oneOf([userInitials], "Characters don't match your initials")
      .required('Initials Required'),
  });

  const typographyMedList = moMedList?.map((med: any, index) => {
    return (
      <Box marginTop={1} marginBottom={1} key={index}>
        <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
          {med.pharmacyProductName} ({med.pharmacyProductDescription})
        </Typography>
        <Typography variant={'body2'}>
          {med.numberOfPackages} packages of {med.numberOfUnitsPerPackage}{' '}
          {med.dosageFormUnitType.toLowerCase()}: {med.daysSupplyOrdered} days' supply
        </Typography>
        <Typography variant={'body2'}>
          Type: {med.isGeneric ? 'Generic' : 'Brand'}; Dispensed from: {med.dispensingCountry}
        </Typography>
        {mailOrderMedInfo?.isPatientResponsibleForPayment ? (
          <Typography variant="body2">Prescription Cost: ${med.totalCost}</Typography>
        ) : null}
      </Box>
    );
  });

  return (
    <Formik
      initialValues={{
        initials: (savedData.verifyPrescription as string) || '',
      }}
      validationSchema={verifyPrescriptionSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSaveData({
          verifyPrescription: values.initials,
        });

        const mailOrderInitInfo = {
          mailOrderId,
          authorizationInitials: values.initials,
          confirmationNeedType: confirmNeedType,
        };

        await putAuthMailOrderInit(mailOrderInitInfo);
        setSubmitting(false);
        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<verifyPrescriptionValues>) => (
        <Form>
          <Box>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              {isLoggedInUserAlsoPatient
                ? 'Is the prescription information correct?'
                : `Is the prescription information for ${patientFirstName} correct?`}
            </Typography>
            <Box marginTop={1} marginBottom={2}>
              {typographyMedList}
            </Box>
            {mailOrderMedInfo?.isPatientResponsibleForPayment ? (
              <Box marginBottom={2}>
                <Typography variant="body2">
                  <b>Shipping Cost:</b> ${mailOrderMedInfo?.shippingCost}
                </Typography>
                <Typography variant="body2">
                  <b>Total Cost:</b> ${mailOrderMedInfo?.totalCost}
                </Typography>
              </Box>
            ) : null}
            <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
              I, {firstName} {lastName}, confirm that the prescription information listed above is
              correct and understand that I am responsible for the accuracy of the prescription(s)
              being ordered.
            </Typography>
            <br />
            <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
              I also acknowledge that the medication will be dispensed from the location indicated
              above.
            </Typography>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              marginTop={1}
              marginBottom={1}
            >
              <Box flexBasis={'40%'}>
                <TextFieldComponent
                  label={'Initial Here'}
                  name={'initials'}
                  fullWidth={false}
                  inputProps={{ maxLength: 2 }}
                  onInput={(e: { target: { value: string } }) => {
                    e.target.value = e.target.value.slice(0, 2);
                  }}
                />
              </Box>
            </Box>
            <ProgressButtonGroupComponent
              isLoading={isSubmitting}
              nextOnClickCallback={submitForm}
              previousOnClickCallback={previousOnClickCallback}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default VerifyPrescriptionLayout;
