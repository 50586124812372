import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import cardValidator from 'card-validator';
import { Formik, Form, FormikProps } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import { CreditCardInfo, useAddCreditCardInfoMutation } from 'services/mailOrder';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import AlertComponent from 'Components/AlertComponent/AlertComponent';

import { MailOrderProps } from '../';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

interface CreditCardLayoutValues {
  'cc-name': string;
  'cc-number': string | number;
  'cc-csc': string | number;
  'cc-exp': string | number;
}

const creditCardSchema = Yup.object().shape({
  'cc-name': Yup.string().required('Required'),
  'cc-number': Yup.string()
    .required('Required')
    .test(
      'test-cc-number',
      'Credit Card number is invalid',
      (value) => cardValidator.number(value).isValid
    ),
  'cc-csc': Yup.string()
    .required('Required')
    .test('test-cvv', 'CVV Invalid', (value) => cardValidator.cvv(value).isValid),
  'cc-exp': Yup.string()
    .required('Required')
    .test(
      'test-expiry-date',
      'Expiration date invalid',
      (value) => cardValidator.expirationDate(value).isValid
    ),
});

const CreditCardLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  contextData,
}) => {
  const dispatch = useDispatch();
  const { mailOrderAuthKeystring } = contextData;

  const classes = useStyles();
  const [postCreditCardInfo] = useAddCreditCardInfoMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  return (
    <>
      <Formik
        initialValues={{
          'cc-name': '',
          'cc-number': '',
          'cc-csc': '',
          'cc-exp': '',
        }}
        validationSchema={creditCardSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);
          const { month, year } = cardValidator.expirationDate(values['cc-exp']);

          const creditCardInfo: CreditCardInfo = {
            ...values,
            mailOrderId: mailOrderAuthKeystring,
            'cc-expMonth': month || '',
            'cc-expYear': year || '',
          };
          const response: any = await postCreditCardInfo(creditCardInfo);
          if (response.error?.data?.userInputValidationError) {
            setErrorMessage(response.error.data.userInputValidationError);
          } else {
            nextOnClickCallback();
          }
        }}
      >
        {({ isSubmitting, submitForm }: FormikProps<CreditCardLayoutValues>) => (
          <Form>
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Credit Card Information
            </Typography>
            <Typography align={'center'} variant={'body2'} color={'textSecondary'} paragraph={true}>
              We accept Visa, MasterCard, and American Express
            </Typography>
            <Box marginBottom={2} marginTop={2}>
              <Box display={'flex'}>
                <TextFieldComponent label={'Name on Card'} name={'cc-name'} />
              </Box>
              <Box display={'flex'}>
                <TextFieldComponent
                  label={'Card Number'}
                  name={'cc-number'}
                  inputProps={{
                    pattern: '[0-9]',
                    maxLength: 16,
                  }}
                />
                <TextFieldComponent label={'Security Code'} name={'cc-csc'} />
              </Box>
              <Box display={'flex'}>
                <TextFieldComponent label={'Expiry Date'} name={'cc-exp'} />
              </Box>
            </Box>
            <ProgressButtonGroupComponent
              isLoading={isSubmitting}
              nextOnClickCallback={submitForm}
              previousOnClickCallback={previousOnClickCallback}
            />
            {errorMessage ? (
              <AlertComponent
                alertType="error"
                title="Credit Card Info Invalid"
                message={errorMessage}
                onClose={() => setErrorMessage(undefined)}
                className={classes.margin}
              />
            ) : null}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreditCardLayout;
