import { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Fab } from '@mui/material';

import ImageComponent from 'Components/ImageComponent/ImageComponent';
import useAuth from 'Hooks/useAuth';
import { useGetAdvocateInfoQuery } from 'services/user';
import MessageDialog from 'Components/MessageDialog/MessageDialog';
import { useMarkAllMessagesAsReadMutation } from 'services/messaging';

const AdvocateBarComponent: FunctionComponent = () => {
  const useStyles = makeStyles((theme) => ({
    appBar: {
      height: '0px',
    },
    button: {
      position: 'fixed',
      bottom: '0px',
      right: '0px',
      transform: 'translate(-20%, -20%)',
      border: '3px solid white',
      padding: theme.spacing(4),
    },
    buttonIcon: {
      width: '42px',
      height: '42px',
    },
  }));

  const classes = useStyles();
  const { isLoggedIn } = useAuth();
  const { data: advocateInfo } = useGetAdvocateInfoQuery();
  const { advocateFirstName } = advocateInfo || {};
  const advocateExists = Boolean(advocateFirstName);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [markAllMessagesAsRead] = useMarkAllMessagesAsReadMutation();

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    markAllMessagesAsRead();
  };

  return (
    <>
      <MessageDialog open={isModalOpen} onClose={handleModalClose} advocateInfo={advocateInfo} />
      {isLoggedIn && advocateExists ? (
        <AppBar data-testid={'advocateBar'} className={classes.appBar} position={'sticky'}>
          <Fab className={classes.button} color={'secondary'} onClick={handleModalOpen}>
            <ImageComponent
              className={classes.buttonIcon}
              src={'/Assets/Images/advocate_abstract_small.png'}
              alt={'small white single color icon depicting an advocate wearing a headset'}
            />
          </Fab>
        </AppBar>
      ) : null}
    </>
  );
};

export default AdvocateBarComponent;
