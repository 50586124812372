import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { useGetAdvocateInfoQuery } from 'services/user';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';

import {
  selectFirstName,
  selectLastName,
  selectPersonDOB,
  selectPersonIDEncrypted,
} from 'Store/Reducers/personInfoSlice';

interface UserAcknowledgementsLayoutProps {
  onNextStep(): void;
  onPreviousStep(): void;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  leadIcon: {
    'max-width': '100%',
    'max-height': '20vh',
    background: '#D46833',
    borderRadius: '90px',
    padding: '25px',
    marginBottom: '20px',
  },
  accentText: {
    color: theme.palette.secondary.main,
    'font-weight': 'bold',
  },
}));

const UserAcknowledgementsLayout: FunctionComponent<UserAcknowledgementsLayoutProps> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const appName = 'ScriptCo Shield';
  const currentFirstName = useSelector(selectFirstName);
  const currentLastName = useSelector(selectLastName);

  return (
    <Box
      className={classes.screenBox}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography
        align={'center'}
        variant={'body1'}
        color={'textPrimary'}
        style={{ fontWeight: 'bold' }}
      >
        User Acknowledgements
        <br />
      </Typography>
      <Paper elevation={2}>
        <Typography
          align={'left'}
          variant={'body2'}
          color={'textPrimary'}
          component={'div'}
          style={{
            paddingRight: '15px',
            height: '400px',
          }}
        >
          <ul>
            <li>{appName} is not a pharmacy</li>
            <li>{appName} is not insurance</li>
            <li>
              {appName} will be acting as a prescription advocate in order to assist with acquiring
              prescribed medication
            </li>
            <li>{appName} cannot guarantee your medications will be free</li>
            <li>
              {appName} may contact physicians to discuss and request patient’s health information
            </li>
            <li>
              {appName} is authorized to provide patient health, financial and other information to
              drug manufacturers, pharmacies and third party administrators of co-pay cards and
              grants
            </li>
            <li>
              {appName} is authorized to sign patient assistance program documents on patient’s
              behalf
            </li>
          </ul>
        </Typography>
      </Paper>
      {/* <Box display={'flex'} marginTop={2}>
        <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
          I, {currentFirstName} {currentLastName}, verify that I have read all of the
          Acknowledgements described above.
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        marginBottom={1}
      >
        <Box flexBasis="60%">
          <TextFieldComponent
            label="Initial Here"
            name={'initials'}
            fullWidth={false}
            inputProps={{ maxLength: 2 }}
            onInput={(e: { target: { value: string } }) => {
              e.target.value = e.target.value.slice(0, 2);
            }}
          />
        </Box>
      </Box> */}
      <ProgressButtonGroupComponent
        previousOnClickCallback={onPreviousStep}
        nextOnClickCallback={onNextStep}
      />
    </Box>
  );
};

export default UserAcknowledgementsLayout;
