import { Fragment, useEffect, useState, useMemo, FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import MessageNotificationComponent from 'Components/MessageNotificationComponent/MessageNotificationComponent';
import NextStepBannerComponent from 'Components/NextStepBannerComponent/NextStepBannerComponent';

import {
  Member,
  useGetAllHouseholdMembersQuery,
  useHasConfirmedAcknowledgementsQuery,
  useHasMemberOnboardedQuery,
  useInviteAdultDependentToRegisterMutation,
} from 'services/user';
import { useGetAllAssistedMedsUserCanSeeQuery } from 'services/medication';
import { TrackingInfo, useGetTrackingNumbersQuery } from 'services/mailOrder';
import { selectIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import { selectHasAdvocateBeenIntroduced } from 'Store/Reducers/memberSlice';
import useNextStep from 'containers/NextStep/hooks/useNextStep';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import ProxyAuthDialog from 'Components/ProxyAuthDialog/ProxyAuthDialog';
import { ExpandMore } from '@mui/icons-material';
import AlertComponent from 'Components/AlertComponent/AlertComponent';
import useWhiteLabelApp from 'Hooks/useWhiteLabelApp';
import { useGetGroupInfoQuery } from 'services/whiteLabel';
import useAuth from 'Hooks/useAuth';
import { useAppInstall } from 'App/contexts/appInstall/AppInstallProvider';
import Page from 'Pages/BasePage';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import InfoCardPrimary from 'Components/InfoCardPrimary';

import { useGetUpdateAvailableQuery } from 'services/utility';
import { KEY_APP_VERSION } from 'App/contexts/constants';

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: '0px',
    padding: '0px 8px',
    borderRadius: '5px 5px 5px 5px',
    background: theme.palette.common.white,
    '&$expanded': {
      minHeight: '0px',
      padding: '0px 8px',
      borderRadius: '5px 5px 0px 0px',
      background: theme.palette.primary.main,
    },
  },
  content: {
    color: theme.palette.common.black,
    margin: '5px 0px',
    '&$expanded': {
      color: theme.palette.common.white,
      margin: '5px 0px',
    },
  },
  expanded: {},
}))(AccordionSummary);

const Dashboard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    data: { groupIdEncryptedMaybe: groupId, personIdEncryptedMaybe: personId },
  } = useAuth();

  const isNextStepReminderEligible = useSelector(selectIsNextStepReminderEligible);
  const hasAdvocateBeenIntroduced = useSelector(selectHasAdvocateBeenIntroduced);

  const { href: nextStep, isLoading: isLoadingNextStep } = useNextStep();
  const { data: dependents, refetch: refetchMembers } = useGetAllHouseholdMembersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: assistedMedications } = useGetAllAssistedMedsUserCanSeeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: mailOrderTrackingInfo } = useGetTrackingNumbersQuery(personId, {
    skip: !personId,
  });

  const { data: hasConfirmedAcknowledgements, isLoading: isLoadingAcknowledgements } =
    useHasConfirmedAcknowledgementsQuery();
  const { data: hasMemberOnboarded, isLoading: isLoadingHasMemberOnboarded } =
    useHasMemberOnboardedQuery();
  const { data: groupInfo, isLoading: isLoadingGroupInfo } = useGetGroupInfoQuery(groupId, {
    skip: !groupId,
  });
  const [inviteAdultDependent] = useInviteAdultDependentToRegisterMutation();

  const [isProxyDialogOpen, setIsProxyDialogOpen] = useState<boolean>(false);
  const [currentProxyMember, setCurrentProxyMember] = useState({} as Member);
  const sortedMembers = useMemo(() => {
    const processedMembers = dependents?.map((dep) => ({
      id: dep.patientId,
      ...dep,
      medCount: assistedMedications
        ? assistedMedications.filter((med) => med.patientPersonId === dep.patientId).length
        : 0,
    }));

    return processedMembers?.sort(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (a: any, b: any) => b.medCount - a.medCount
    );
  }, [dependents, assistedMedications]);

  const { whiteLabelApp } = useWhiteLabelApp();
  const { openInstallBanner } = useAppInstall();
  const { whiteLabelInfo: whiteLabelInfo, isLoading: isWhiteLabelLoading } =
    useContext(WhiteLabelContext);

  whiteLabelApp();

  const { data: updateInfo } = useGetUpdateAvailableQuery('');
  useEffect(() => {
    if (updateInfo?.shouldUpdate) {
      localStorage.setItem(KEY_APP_VERSION, updateInfo.updateVersion);
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function (names) {
          for (const name of names) caches.delete(name);
        });
      }
      window.location.reload();
    }
  }, [updateInfo]);

  const handleProxyDialogOpen = (member: Member) => () => {
    setCurrentProxyMember(member);
    setIsProxyDialogOpen(true);
  };

  const handleProxyDialogClose = () => {
    setIsProxyDialogOpen(false);
    refetchMembers();
  };

  const handleInvitingMember = (personId: string) => {
    inviteAdultDependent(personId);
  };

  useEffect(() => {
    if (isWhiteLabelLoading) {
      return;
    }
    openInstallBanner();
  }, [openInstallBanner, isWhiteLabelLoading]);

  useEffect(() => {
    if (isLoadingAcknowledgements || isWhiteLabelLoading || isLoadingHasMemberOnboarded) {
      //Do nothing because we are waiting on our query hooks to finish loading
    } else if (
      whiteLabelInfo?.providerIsTrueAdvocate &&
      (hasAdvocateBeenIntroduced || hasMemberOnboarded)
    ) {
      //Do nothing
    } else if (hasConfirmedAcknowledgements === false) {
      history.push('/welcome');
    }
  }, [
    hasConfirmedAcknowledgements,
    history,
    isLoadingAcknowledgements,
    hasAdvocateBeenIntroduced,
    whiteLabelInfo,
    isWhiteLabelLoading,
    hasMemberOnboarded,
    isLoadingHasMemberOnboarded,
  ]);

  useEffect(() => {
    if (isNextStepReminderEligible && nextStep) {
      history.push(nextStep);
    }
  }, [dispatch, history, isNextStepReminderEligible, nextStep]);

  const alertMessage =
    'You can add medications for you and your dependents by going to the Medications tab. Click Add New Medication to add a new medication.';

  return (
    <Page
      isLoading={
        isLoadingNextStep ||
        isLoadingAcknowledgements ||
        isLoadingGroupInfo ||
        isWhiteLabelLoading ||
        isLoadingHasMemberOnboarded
      }
    >
      <ProxyAuthDialog
        open={isProxyDialogOpen}
        onClose={handleProxyDialogClose}
        proxyMember={currentProxyMember}
      />
      {groupInfo?.canMemberAddMedications && assistedMedications?.length === 0 && !nextStep ? (
        <AlertComponent alertType="info" title="Getting Started" message={alertMessage} />
      ) : null}
      {mailOrderTrackingInfo?.length
        ? mailOrderTrackingInfo.map((trackingInfo: TrackingInfo, idx: number) => {
            return (
              <AlertComponent
                key={idx}
                alertType="info"
                title={`A mail order for ${trackingInfo.medicationList} is on the way. Click the tracking number(s) below to check status:`}
                message={''}
                links={trackingInfo.trackingNumbers}
              />
            );
          })
        : null}
      <MessageNotificationComponent />
      <NextStepBannerComponent />

      <Box>
        {sortedMembers?.map((member, i) => (
          <Fragment key={member.id}>
            <Accordion style={{ margin: '5px', minHeight: '0px' }} defaultExpanded={i === 0}>
              <StyledAccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={'h5'}>{`${member.firstName} ${member.lastName}`}</Typography>
              </StyledAccordionSummary>
              <AccordionDetails style={{ display: 'block', padding: '4px 16px 4px' }}>
                <Box
                  display={'grid'}
                  gap={'5px'}
                  gridTemplateColumns={'repeat(auto-fill, minmax(225px, 1fr))'}
                  width={'100%'}
                >
                  {assistedMedications &&
                    assistedMedications
                      .filter((med) => med.patientPersonId === member.id)
                      .map((med) => {
                        return (
                          <Box
                            key={med.drugId}
                            style={{
                              padding: '8px',
                              margin: '0 auto',
                              display: 'grid',
                              gap: '5px',
                              gridTemplateColumns: 'repeat(auto-fill, minmax(225px, 1fr))',
                              width: '100%',
                            }}
                          >
                            <InfoCardPrimary
                              title={med.name}
                              contentItems={[
                                {
                                  title: `Assistance: `,
                                  content: `${med.assistanceType}`,
                                },
                                {
                                  title: `Status: `,
                                  content: `${med.assistanceStatus}`,
                                },
                                {
                                  title: `Waiting On: `,
                                  content: `${med.waitingOnStatus}`,
                                },
                                med.additionalInstructions
                                  ? {
                                      title: 'Additional Instructions: ',
                                      content: med.additionalInstructions,
                                    }
                                  : null,
                              ].filter(Boolean)}
                            ></InfoCardPrimary>
                          </Box>
                        );
                      })}
                </Box>
                {member.hasPhiAccess && member.someMedsAreHidden === false ? undefined : (
                  <Box style={{ margin: '0.5em' }}>
                    <Divider variant="middle" />
                    <Typography style={{ marginTop: '0.5em' }}>
                      You are only allowed to see prescriptions you have created. If you would like
                      to see all information for {member.firstName} {member.lastName}, you must
                      request their authorization below.
                    </Typography>
                    <PrimaryButtonComponent
                      text="Get Authorization"
                      onClick={handleProxyDialogOpen(member)}
                      width="auto"
                    />
                  </Box>
                )}
                {member.isOtherAdultNeedingToSignHipaaAuth && groupInfo?.isOpenEnrollmentAllowed ? (
                  <Box style={{ margin: '0.5em' }}>
                    <Divider variant="middle" />
                    <Typography style={{ marginTop: '0.5em' }}>
                      Below you can invite this member to create a user account and sign their HIPAA
                      Authorization.
                    </Typography>
                    <PrimaryButtonComponent
                      text="Invite User"
                      onClick={() => handleInvitingMember(member.id)}
                      width="auto"
                    />
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
          </Fragment>
        ))}
      </Box>
    </Page>
  );
};

export default Dashboard;
