import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setTitle } from 'Store/Reducers/appInfoSlice';
import { useGetAdvocateInfoQuery } from 'services/user';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import useAuth from 'Hooks/useAuth';
import LoadingSpinner from 'Components/LoadingSpinner';
import { StepProps } from 'containers/Wizards/types';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const OutroPAPLayout: FunctionComponent<StepProps> = ({ onComplete, onPreviousStep }) => {
  const dispatch = useDispatch();
  const { userDetails } = useAuth();
  const { firstName } = userDetails || {};
  const { data: advocate, isLoading } = useGetAdvocateInfoQuery();

  useEffect(() => {
    dispatch(setTitle('Patient Assistance Programs'));
  }, [dispatch]);

  const classes = useStyles();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box className={classes.screenBox}>
      <InfoLayoutBodyComponent
        header={
          <span>
            Thanks {firstName}, we have all the information we need from you, regarding Patient
            Assistance Programs.
          </span>
        }
        paragraph={
          <span>
            We will reach out soon with a status update. Please, contact{' '}
            {advocate?.advocateFirstName} if you have any questions.
          </span>
        }
      />
      <ProgressButtonGroupComponent
        nextOnClickCallback={onComplete}
        previousOnClickCallback={onPreviousStep}
      />
    </Box>
  );
};

export default OutroPAPLayout;
