import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import { MailOrderProps } from '../';

const SignatureRequiredLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Typography
        align={'center'}
        variant={'body1'}
        color={'textPrimary'}
        style={{ fontWeight: 'bold' }}
      >
        Signature Required
      </Typography>

      <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
        Your mail order contains medication that is sent with mandatory signature required upon
        delivery. You will be emailed a tracking number for your package. Once you have received
        your tracking number and your order has shipped, it should arrive in approximately 2-5 days.
        <br />
        <br />
        You may change the shipping address if you would like it delivered to your work, a nearby
        neighbor, friend, or family member who would be able to sign for your package upon receipt.
        If you are not home when your package is delivered, you can contact the shipping carrier to
        redeliver your package by following the link sent with your tracking number or visiting
        their website.
      </Typography>

      <Box marginBottom={2} marginTop={1}>
        <ProgressButtonGroupComponent
          nextOnClickCallback={nextOnClickCallback}
          previousOnClickCallback={previousOnClickCallback}
        />
      </Box>
    </Box>
  );
};

export default SignatureRequiredLayout;
