import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import {
  useCancelMedicationMutation,
  useEditMedicationMutation,
  useAddReferredMedicationMutation,
  useAddMedicationMutation,
  Medication,
} from 'services/medication';

import {
  medicationsSelected,
  selectSelfServiceDrugList,
  sharxDrugsSelected,
} from 'Store/Reducers/drugSearchSlice';
import { selectFirstName, selectLastName } from 'Store/Reducers/personInfoSlice';
import useAuth from 'Hooks/useAuth';
import { GetDrugInfo } from 'services/scriptCo';

import PrescriberInfo from './PrescriberInfoLayout';
import { assistanceTypeNdx_Medrop, assistanceTypeNdx_ScriptCo } from 'App/contexts/constants';
import { scriptCoDrugsSelected, medropDrugsSelected } from 'Store/Reducers/drugSearchSlice';

import { Prescriber } from 'services/prescriber';

interface MedicationWizardStepProps {
  onNextStep: () => void;
  onPreviousStep: () => void;
  onComplete: () => void;

  onPrescriberAdded: (prescriber: Prescriber) => void;
}

const PrescriberInfoLayout: FunctionComponent<MedicationWizardStepProps> = ({
  onNextStep,
  onPreviousStep,
}) => {
  const dispatch = useDispatch();

  const drugsNeedToGetInfoAndSave = [];
  const [postAddMedication] = useAddMedicationMutation();
  const {
    data: { groupIdEncryptedMaybe: groupId, personIdEncryptedMaybe: personId },
  } = useAuth();

  const selfServiceDrugList = useSelector(selectSelfServiceDrugList);
  const medicationsSelectedList = useSelector(medicationsSelected);
  const sharxDrugsSelectedList = useSelector(sharxDrugsSelected);
  const scriptCoDrugsSelectedList = useSelector(scriptCoDrugsSelected);
  const medropDrugsSelectedList = useSelector(medropDrugsSelected);

  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const personFullName = `${firstName} ${lastName}`;

  for (const currentDrug of sharxDrugsSelectedList) {
    const tempDrug = {
      drug: {
        name: currentDrug.cleanDrugName,
        id: currentDrug.sharxIDMaybe,
      },
      recipient: personFullName,
      prescripberId: '',
    };
    drugsNeedToGetInfoAndSave.push(tempDrug);
  }

  const [medicationIndex, setMedicationIndex] = useState(0);

  const currentMedication = drugsNeedToGetInfoAndSave[medicationIndex];

  const nextMedication = () => {
    if (!drugsNeedToGetInfoAndSave.length) {
      // Should never get here with no meds, just continue on
      onNextStep();
    } else if (medicationIndex === drugsNeedToGetInfoAndSave.length - 1) {
      onNextStep();
    } else {
      setMedicationIndex(medicationIndex + 1);
    }
  };

  const handleSkipMedication = async () => {
    nextMedication();
  };

  const handleMedicationSubmit = async () => {
    nextMedication();
  };

  useEffect(() => {
    dispatch(setTitle('Medications'));
  }, [dispatch]);

  useEffect(() => {
    if (scriptCoDrugsSelectedList.length || medropDrugsSelectedList.length) {
      const allReferralMeds = [...scriptCoDrugsSelectedList, ...medropDrugsSelectedList];
      allReferralMeds.forEach((drug) => {
        const assistanceTypeNdx = drug.scriptCoIDMaybe
          ? assistanceTypeNdx_ScriptCo
          : assistanceTypeNdx_Medrop;
        const referralNewMedication: Medication = {
          medicationId: null,
          patientPersonId: personId,
          drugId: drug.sharxIDMaybe ?? null,
          name: drug.cleanDrugName ?? '',
          strength: null,
          quantity: null,
          inhaler: false,
          tempSensitive: false,
          papQualified: false,
          prescriberId: '',
          assistanceType: null,
          priorAuthDetailsMaybe: null,
          new: true,
          authorizedToView: true,
          medicationName: drug.cleanDrugName ?? '',
          assistanceTypeNdxMaybe: assistanceTypeNdx,
          isPrescriberRequired: false,
        };

        postAddMedication(referralNewMedication);
      });
    }
  }, [scriptCoDrugsSelectedList, medropDrugsSelectedList]);

  return (
    <>
      <PrescriberInfo
        medication={currentMedication}
        onSubmit={handleMedicationSubmit}
        onPreviousClick={onPreviousStep}
        // onSkipMedication={handleSkipMedication}
      />
    </>
  );
};

export default PrescriberInfoLayout;
