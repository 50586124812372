import { useState } from 'react';

interface Props {
  steps: Array<any>;
}

const useStepNavigator = ({ steps }: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const next = () => {
    const nextIndex = currentIndex + 1;

    if (nextIndex <= steps.length - 1) {
      setCurrentIndex(nextIndex);
    }
  };

  const previous = () => {
    const previousIndex = currentIndex - 1;

    if (previousIndex >= 0) {
      setCurrentIndex(previousIndex);
    }
  };

  const reset = () => {
    setCurrentIndex(0);
  };

  return {
    current: steps[currentIndex],
    next,
    previous,
    reset,
  };
};

export default useStepNavigator;
