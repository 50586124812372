import { FunctionComponent } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';

import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { Prescriber, useEditPrescriberMutation } from 'services/prescriber';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import Dialog from 'Components/Dialog';

const phoneRegExp = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-/\s.]|\d)+$/;
const EditPrescriberSchema = Yup.object().shape({
  firstName: Yup.string().required('Required').max(100, 'Name is too long'),
  lastName: Yup.string().required('Required').max(100, 'Name is too long'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Invalid value')
    .min(10, 'Invalid value')
    .required('Required'),
  fax: Yup.string().matches(phoneRegExp, 'Invalid value').nullable().notRequired(),
});

interface EditPrescriberValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  fax: string | null;
}

type EditPrescriberDialogProps = {
  onClose: () => void;
  open: boolean;
  prescriberInfo: Prescriber;
  turnOffEditName: boolean;
};

const EditPrescriberDialog: FunctionComponent<EditPrescriberDialogProps> = (props) => {
  const { onClose, open, prescriberInfo, turnOffEditName } = props;
  const { prescriberId, firstName, lastName, phoneNumber, fax } = prescriberInfo || {};

  const [postEditPrescriber] = useEditPrescriberMutation();

  return (
    <Dialog open={open} onClose={onClose} title="Edit Doctor">
      <Formik
        initialValues={{
          firstName: firstName ? firstName : '',
          lastName: lastName ? lastName : '',
          phoneNumber: phoneNumber ? phoneNumber : '',
          fax: fax ? fax : '',
        }}
        validationSchema={EditPrescriberSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const updatePrescriber: Prescriber = {
            prescriberId: prescriberId,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            fax: values.fax,
          };

          await postEditPrescriber({
            prescriberId: prescriberId,
            prescriberInfo: updatePrescriber,
          });

          onClose();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }: FormikProps<EditPrescriberValues>) => (
          <Form>
            <Box>
              <Typography
                align={'center'}
                variant={'body1'}
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                Let&apos;s get some basic info about your doctor.
              </Typography>
              <Typography align={'center'} variant={'body1'} color={'textPrimary'}>
                Fax isn&apos;t required, but it&apos;s nice to have.
              </Typography>
              <Box marginBottom={3} marginTop={2}>
                {!turnOffEditName ? (
                  <>
                    <TextFieldComponent label={"Doctor's First Name"} name={'firstName'} />
                    <TextFieldComponent label={"Doctor's Last Name"} name={'lastName'} />
                  </>
                ) : (
                  <Typography variant={'body1'} color={'textPrimary'} marginBottom={1}>
                    Doctor/Prescriber: {prescriberInfo?.firstName} {prescriberInfo?.lastName}
                  </Typography>
                )}
                <TextFieldComponent
                  label={"Doctor's Phone Number"}
                  name={'phoneNumber'}
                  type={'tel'}
                />
                <TextFieldComponent label={"Doctor's Fax Number"} name={'fax'} type={'tel'} />
              </Box>
            </Box>
            <PrimaryButtonComponent
              text="Save Changes"
              onClick={submitForm}
              isLoading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditPrescriberDialog;
