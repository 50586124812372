import { FunctionComponent, useContext, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { useGetAllHouseholdMembersQuery } from 'services/user';
import {
  Medication,
  useGetAllAssistedMedsUserCanSeeQuery,
  useGetAllNotAssistedMedsUserCanSeeQuery,
  useAddMedicationMutation,
} from 'services/medication';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AlertComponent from 'Components/AlertComponent/AlertComponent';
import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import YesNoButtonGroupComponent from 'Components/YesNoButtonGroupComponent/YesNoButtonGroupComponent';
import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import InfoCardSecondary from 'Components/InfoCardSecondary';

import MedicationEntry from 'Components/MedicationEntry';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import { MedicationWizardStepProps } from '../..';
import LoadingSpinner from 'Components/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const AddMedicationLayout: FunctionComponent<MedicationWizardStepProps> = ({
  groupInfo,
  onNextStep,
  onPreviousStep,
}) => {
  const dispatch = useDispatch();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;
  const {
    data: allHouseholdMembers,
    refetch: refetchAllHouseholdMembers,
    isLoading: isLoadingAllHouseholdMembers,
  } = useGetAllHouseholdMembersQuery(undefined, { refetchOnMountOrArgChange: true });
  const {
    data: medicationsOnFile,
    refetch: refetchMedicationsOnFile,
    isLoading: isLoadingMedicationInfo,
  } = useGetAllAssistedMedsUserCanSeeQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data: notAssistedMedications } = useGetAllNotAssistedMedsUserCanSeeQuery();
  const [shouldShowConfirmation, setShouldShowConfirmation] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [postAddMedication] = useAddMedicationMutation();

  const classes = useStyles();

  useEffect(() => {
    dispatch(setTitle('Medications'));
  }, [dispatch]);

  const handleSubmit = async (values: any) => {
    setErrorMessage(undefined);
    setIsSubmitting(true);
    const drugId = values.drug?.id ?? 0;
    const patientId = values.recipient;

    const medicationExistsInAssisted = medicationsOnFile?.some(
      (med: Medication) => med.patientPersonId === patientId && med.drugId === drugId
    );

    const medicationExistsInNotAssisted = notAssistedMedications?.some(
      (med: Medication) => med.patientPersonId === patientId && med.drugId === drugId
    );

    if (medicationExistsInAssisted || medicationExistsInNotAssisted) {
      setErrorMessage('This medication is already in the list for this person.');
      setIsSubmitting(false);
      return;
    }

    const newMedication: Medication = {
      medicationId: null,
      patientPersonId: patientId,
      drugId: drugId,
      name: values.drug?.name ?? '',
      strength: null,
      quantity: null,
      inhaler: false,
      tempSensitive: false,
      papQualified: false, // this shouldn't be here
      prescriberId: values.prescriberId ?? '',
      assistanceType: null,
      priorAuthDetailsMaybe: null,
      new: true,
      authorizedToView: true,
      medicationName: values.drug?.name ?? '',
      assistanceTypeNdxMaybe: '',
      isPrescriberRequired: true,
    };

    const response: any = await postAddMedication(newMedication); // Add spinner for loading state...
    if (response.error?.data?.userInputValidationError) {
      setErrorMessage(response.error.data.userInputValidationError);
    } else {
      refetchAllHouseholdMembers();
      refetchMedicationsOnFile();
      setShouldShowConfirmation(true);
    }
  };

  const handleConfirmedYes = () => {
    setShouldShowConfirmation(false);
  };

  const handleConfirmedNo = () => {
    onNextStep();
  };

  const medicationSummary: string[] = useMemo(() => {
    const medicationSummary: string[] = [];
    if (allHouseholdMembers && medicationsOnFile) {
      allHouseholdMembers.forEach((member) => {
        medicationsOnFile.forEach((medication) => {
          if (member.patientId === medication.patientPersonId) {
            medicationSummary.push(`${member.firstName}: ${medication.name}`);
          }
        });
      });
    }

    return medicationSummary;
  }, [allHouseholdMembers, medicationsOnFile]);

  if (isLoadingAllHouseholdMembers || isLoadingMedicationInfo) {
    return <LoadingSpinner />;
  }

  if (shouldShowConfirmation) {
    return (
      <Box
        className={classes.screenBox}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box marginBottom={2}>
          <InfoCardSecondary
            title="Your summary of high-cost medications on file:"
            contentItems={medicationSummary}
          />
        </Box>
        {groupInfo.canMemberAddMedications ? (
          <>
            <InfoLayoutBodyComponent
              header={`Do you have other high-cost medications ${appName} could help with?`}
              paragraph={`High-cost medications are medications costing at least ${groupInfo.groupThreshold}`}
            />
            <YesNoButtonGroupComponent
              yesOnClickCallback={handleConfirmedYes}
              noOnClickCallback={handleConfirmedNo}
            />
          </>
        ) : (
          <>
            <InfoLayoutBodyComponent
              header={`Please review your summary of high-cost medications.`}
              paragraph={`Click NEXT if your summary looks correct.`}
            />
            <ProgressButtonGroupComponent
              nextOnClickCallback={onNextStep}
              previousOnClickCallback={onPreviousStep}
            />
          </>
        )}
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Typography
          align={'center'}
          variant={'body1'}
          color={'textPrimary'}
          style={{ fontWeight: 'bold' }}
        >
          Please provide the medication, the doctor who prescribed it, and the patient
        </Typography>
      </Box>
      <MedicationEntry
        onSubmit={handleSubmit}
        onPreviousClick={onPreviousStep}
        isSubmitting={isSubmitting}
        errorMessage={errorMessage}
      />
      {errorMessage ? (
        <AlertComponent
          alertType="error"
          title="Medication Info Invalid"
          message={errorMessage}
          onClose={() => setErrorMessage(undefined)}
          className={classes.margin}
        />
      ) : null}
    </>
  );
};

export default AddMedicationLayout;
