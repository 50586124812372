import { FunctionComponent, ReactNode, ChangeEvent } from 'react';
import { useField, FieldInputProps } from 'formik';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@mui/material';
import { useErrorMessage } from 'Hooks';

interface RadioOption {
  label: any;
  value: string | number | boolean;
  disabled?: boolean;
  radioProps?: { [key: string]: any };
}

interface RadioGroupComponentProps {
  label: string;
  name: string;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  radioOptions: RadioOption[];
  [transferProps: string]: any;
}

interface RadioGroupFieldProps extends Partial<FieldInputProps<string>> {
  errorString?: string;
  label: string;
  name: string;
  required: boolean;
  children: ReactNode;
}

const RadioGroupField: FunctionComponent<RadioGroupFieldProps> = ({
  errorString,
  label,
  name,
  required = false,
  onChange,
  value,
  children,
  ...transferProps
}) => {
  return (
    <FormControl component={'fieldset'} error={!!errorString}>
      <FormLabel component={'legend'} required={required}>
        {label}
      </FormLabel>
      <RadioGroup
        sx={{
          px: ({ spacing }) => spacing(3),
          pt: ({ spacing }) => spacing(1),
          pb: ({ spacing }) => spacing(3),
          textAlign: 'left',
        }}
        aria-label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...transferProps}
      >
        {children}
      </RadioGroup>
      <FormHelperText>{errorString}</FormHelperText>
    </FormControl>
  );
};

const renderOptions = (radioOptions: RadioOption[], allButtonsDisabled: boolean): JSX.Element[] => {
  return radioOptions?.map(({ label, value, disabled = false, radioProps }: RadioOption, index) => {
    const isButtonDisabled = allButtonsDisabled || disabled;

    return (
      <FormControlLabel
        key={index}
        value={value}
        control={<Radio disabled={isButtonDisabled} {...radioProps} />}
        label={label}
        disabled={isButtonDisabled}
      />
    );
  });
};

const RadioGroupComponent: FunctionComponent<RadioGroupComponentProps> = ({
  name,
  label,
  errorMessage = null,
  radioOptions,
  disabled = false,
  required = false,
  ...transferProps
}) => {
  const [field, meta, helpers] = useField(name);
  const errorString = useErrorMessage(meta);

  // if (meta.error && meta.touched) {
  //   transferProps.error = true;
  //   transferProps.helperText = meta.error;
  // }

  return (
    <RadioGroupField
      label={label}
      name={name}
      required={required}
      value={field.value}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(e.currentTarget.value);
      }}
      errorString={errorMessage ? errorString : ''}
      {...transferProps}
    >
      {renderOptions(radioOptions, disabled)}
    </RadioGroupField>
  );
};

export default RadioGroupComponent;
