import { FunctionComponent } from 'react';

import MailOrderWizard from 'containers/Wizards/MailOrder';
import useNextStep from 'containers/NextStep/hooks/useNextStep';
import LoadingSpinner from 'Components/LoadingSpinner';
import Wizard from 'containers/Wizards/Wizard';

const MailOrderPage: FunctionComponent = () => {
  const { goToNextStep, isFetching } = useNextStep();

  const handleComplete = () => {
    goToNextStep();
  };

  return isFetching ? (
    <Wizard>
      <LoadingSpinner />
    </Wizard>
  ) : (
    <MailOrderWizard onComplete={handleComplete} />
  );
};

export default MailOrderPage;
