import { createSlice } from '@reduxjs/toolkit';

export type PersonInfoState = {
  personIDEncrypted: string;
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  personDOB: string;
};

const initialState = {
  personIDEncrypted: '',
  firstName: '',
  lastName: '',
  userName: '',
  password: '',
  personDOB: '',
} as PersonInfoState;

export const slice = createSlice({
  name: 'personInfo',
  initialState,
  reducers: {
    setPersonIDEncrypted: (state, action) => {
      state.personIDEncrypted = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setPersonDOB: (state, action) => {
      state.personDOB = action.payload;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setUserName,
  setPassword,
  setPersonDOB,
  setPersonIDEncrypted,
} = slice.actions;

export const selectPersonIDEncrypted = (state: Record<string, PersonInfoState>): string =>
  state.personInfo.personIDEncrypted;
export const selectFirstName = (state: Record<string, PersonInfoState>): string =>
  state.personInfo.firstName;
export const selectLastName = (state: Record<string, PersonInfoState>): string =>
  state.personInfo.lastName;
export const selectUserName = (state: Record<string, PersonInfoState>): string =>
  state.personInfo.userName;
export const selectPassword = (state: Record<string, PersonInfoState>): string =>
  state.personInfo.password;
export const selectPersonDOB = (state: Record<string, PersonInfoState>): string =>
  state.personInfo.personDOB;

export default slice.reducer;
