import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import InfoLayoutBodyComponent from 'Components/InfoLayoutBodyComponent/InfoLayoutBodyComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import {
  useApproveProxyAuthorizationMutation,
  useGetProxyAuthorizationInfoQuery,
} from 'services/hipaaAuth';
import WhiteLabelContext from 'App/contexts/whiteLabel/WhiteLabelContext';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import Wizard from 'containers/Wizards/Wizard';

const useStyles = makeStyles((theme) => ({
  view: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2, 3),
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ProxyAuthApproval = () => {
  const dispatch = useDispatch();
  const { whiteLabelInfo } = useContext(WhiteLabelContext);
  const appName = whiteLabelInfo?.name;
  const location = useLocation();
  const classes = useStyles();
  const query = new URLSearchParams(location.search);
  const proxyAuthToken = query.get('t'); //t = proxyAuthToken
  const { data: proxyAuthInfo, isLoading } = useGetProxyAuthorizationInfoQuery(
    proxyAuthToken ?? skipToken
  );
  const [approveProxyAuth] = useApproveProxyAuthorizationMutation();
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const { patientName, requesterName } = proxyAuthInfo || {};

  useEffect(() => {
    dispatch(setTitle(`Welcome to ${appName}!`));
  }, [appName, dispatch]);

  if (isLoading) {
    return null;
  }

  return (
    <Wizard>
      <Box className={classes.view}>
        {!isApproved ? (
          <>
            <InfoLayoutBodyComponent
              header="Prescription Authorization"
              paragraph={`By clicking 'Approve' you are stating you are ${patientName} and that you permit ${requesterName} to view and manage your personal information and medications.`}
            />
            <PrimaryButtonComponent
              text="APPROVE"
              onClick={() => {
                approveProxyAuth(proxyAuthToken);
                setIsApproved(true);
              }}
            />
          </>
        ) : (
          <>
            <Typography>
              Prescription Authorization has been approved for {requesterName}.
            </Typography>
          </>
        )}
      </Box>
    </Wizard>
  );
};

export default ProxyAuthApproval;
