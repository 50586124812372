import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as textToImage from 'text-to-image';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import SecondaryButtonComponent from 'Components/SecondaryButtonComponent/SecondaryButtonComponent';
import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';

import ImageComponent from 'Components/ImageComponent/ImageComponent';
import PrimaryButtonComponent from 'Components/PrimaryButtonComponent/PrimaryButtonComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useSignHipaaAuthMutation } from 'services/hipaaAuth';
import useAuth from 'Hooks/useAuth';

import SignatureDialog from '../modals/SignatureDialog';
import { HipaaWizardStepProps } from '..';
import PapInfoDialog from '../modals/PapInfoDialog';

interface SignatureLayoutValues {
  signature: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonGroup: {
    width: '100%',
    margin: theme.spacing(1),
    maxWidth: theme.spacing(24),
    display: 'flex',
    flexDirection: 'row',
  },
  primaryButton: {
    width: '100%',
  },
  secondaryButton: {
    width: '100%',
    'margin-right': theme.spacing(1),
  },
  links: {
    color: '#3771A1',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  documentMargins: {
    margin: theme.spacing(2),
  },
  paragraphWidth: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  signatureImg: {
    maxWidth: '100%',
  },
}));

const signatureSchema = Yup.object().shape({
  signature: Yup.string()
    .matches(/^[-aA-zZ\s]+$/, 'Please only enter letters.')
    .required('Please enter a name to sign with'),
});

const SignatureLayout: FunctionComponent<HipaaWizardStepProps> = ({ onNextStep, hipaaAuth }) => {
  const dispatch = useDispatch();
  const [signHipaaAuth] = useSignHipaaAuthMutation();

  const classes = useStyles();

  const {
    data: { personIdEncryptedMaybe: personId },
    userDetails,
  } = useAuth();
  const { relationshipToPatient, patientPersonId } = hipaaAuth;

  const { firstName, lastName } = userDetails || {};
  const date = new Date();
  const currentDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  const [signatureOpen, setSignatureOpen] = useState(false);
  const [papOpen, setPapOpen] = useState(false);
  const [signatureName, setSignatureName] = useState(`${firstName} ${lastName}`);
  const [isSigned, setIsSigned] = useState(false);
  const [signatureFont, setSignatureFont] = useState('');
  const [signatureDataUri, setSignatureDataUri] = useState<any>();

  const handleSignatureDialogClose = () => {
    setSignatureOpen(false);
  };

  const handlePapInfoDialogOpen = () => {
    setPapOpen(true);
  };

  const handlePapInfoDialogClose = () => {
    setPapOpen(false);
  };

  const handleSignatureSelection = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const dataUri = await textToImage?.generate(signatureName, {
      fontFamily: signatureFont,
      textAlign: 'center',
      fontSize: 35,
      margin: 15,
    });

    setSignatureOpen(false);
    setIsSigned(true);
    setSignatureDataUri(dataUri);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignatureFont((event.target as HTMLInputElement).value);
  };

  const handleReset = () => {
    setIsSigned(false);
  };

  useEffect(() => {
    dispatch(setTitle('HIPAA Authorization'));
  }, [dispatch]);

  return (
    <Formik
      initialValues={{
        signature: signatureName,
      }}
      validationSchema={signatureSchema}
      onSubmit={async (values, { setSubmitting }) => {
        if (!isSigned) {
          setSignatureName(values.signature);
          setSignatureOpen(true);
        } else {
          setSubmitting(true);
          const signHipaaAuthData: any = {
            patientPersonId,
            signerPersonId: personId,
            signature: signatureDataUri,
            relationshipToPatient: relationshipToPatient,
          };
          await signHipaaAuth(signHipaaAuthData);

          onNextStep();
        }
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<SignatureLayoutValues>) => (
        <Form>
          <SignatureDialog
            onClose={handleSignatureDialogClose}
            open={signatureOpen}
            onRadioChange={handleRadioChange}
            onSignatureSelection={handleSignatureSelection}
            signatureName={signatureName}
          />
          <PapInfoDialog onClose={handlePapInfoDialogClose} open={papOpen} />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            marginBottom={2}
          >
            {!isSigned ? (
              <Box>
                <Typography>
                  Please review the HIPAA Notice of Privacy Practices below and apply your digital
                  signature to acknowledge your receipt of this notice
                </Typography>
                <Box className={classes.documentMargins}>
                  <Typography className={classes.links} variant="h6">
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}hipaaAuth/template`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      HIPAA Notice of Privacy Practices
                    </a>
                  </Typography>
                </Box>
                <TextFieldComponent
                  label="Name you would like to sign with:"
                  name={'signature'}
                  fullWidth={false}
                />
                <SecondaryButtonComponent text={'Choose Signature'} onClick={submitForm} />
              </Box>
            ) : (
              <Box>
                <Typography className={classes.paragraphWidth}>
                  I understand this signature will be used to acknowledge receipt of this HIPAA
                  Notice, and accept that this electronic representation of my signature may be used
                  to request prescriptions from health care providers and or on applications for{' '}
                  <a className={classes.links} onClick={handlePapInfoDialogOpen}>
                    patient assistance programs
                  </a>
                  .
                </Typography>
                <ImageComponent
                  alt="Signature"
                  src={signatureDataUri}
                  className={classes.signatureImg}
                />
                <Typography>Date: {currentDate}</Typography>
                <PrimaryButtonComponent
                  text={'SIGN'}
                  isLoading={isSubmitting}
                  onClick={submitForm}
                />
                <TertiaryButtonComponent text={'Reset'} onClick={handleReset} />
              </Box>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SignatureLayout;
