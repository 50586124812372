import { FunctionComponent } from 'react';
import ButtonComponent from 'Components/ButtonComponent/ButtonComponent';

interface PrimaryButtonComponentProps {
  children?: React.ReactNode;
  text?: string;
  [transferProps: string]: any;
}

const PrimaryButtonComponent: FunctionComponent<PrimaryButtonComponentProps> = ({
  children,
  text,
  ...transferProps
}) => (
  <ButtonComponent {...transferProps} color={'primary'} variant={'contained'}>
    {text || children}
  </ButtonComponent>
);

export default PrimaryButtonComponent;
