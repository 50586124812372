import { FunctionComponent, MouseEventHandler } from 'react';
import { Box, Button } from '@mui/material';
import LoadingSpinner from 'Components/LoadingSpinner';

interface ButtonComponentProps {
  children?: React.ReactNode;
  color: 'primary' | 'secondary';
  onClick?: MouseEventHandler;
  width?: 'standard' | 'auto' | 'full' | 'narrow' | 'extra narrow' | 'wide' | 'extra wide';
  type?: 'button' | 'reset' | 'submit';
  marginTop?: boolean;
  marginBottom?: boolean;
  variant?: 'contained' | 'outlined';
  isLoading?: boolean;
  [transferProps: string]: any;
}

const ButtonComponent: FunctionComponent<ButtonComponentProps> = ({
  children,
  onClick,
  color,
  type = 'button',
  width = 'standard',
  marginTop = true,
  marginBottom = true,
  variant,
  isLoading,
  ...transferProps
}) => {
  if (type === 'button' && !onClick) {
    throw Error("onClick event is required unless type is set to 'submit' or 'reset'");
  }

  let maxWidth: number;

  switch (width) {
    case 'standard':
      maxWidth = 22;
      break;
    case 'auto':
      maxWidth = 9999;
      break;
    case 'full':
      maxWidth = 9999;
      break;
    case 'narrow':
      maxWidth = 10;
      break;
    case 'extra narrow':
      maxWidth = 5;
      break;
    case 'wide':
      maxWidth = 30;
      break;
    case 'extra wide':
      maxWidth = 50;
      break;
  }

  return (
    <Button
      data-testid={'button'}
      disabled={isLoading}
      {...transferProps}
      color={color}
      variant={variant}
      onClick={onClick}
      type={type}
      sx={{
        //TODO would like to make buttons lowercase eventually, some are hard coded uppercase
        // textTransform: 'none',
        maxWidth: ({ spacing }) => spacing(maxWidth),
        width: width === 'auto' ? width : '100%',
        mt: ({ spacing }) => (marginTop ? spacing(1) : 0),
        mb: ({ spacing }) => (marginBottom ? spacing(1) : 0),
      }}
    >
      {children}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingSpinner size={24} />
        </Box>
      )}
    </Button>
  );
};

export default ButtonComponent;
